import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    Table,
} from "reactstrap";

import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import { Creatable } from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { firestore } from '../../../../helpers/Firebase'

import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import { checkIfDisabled } from "../../../../helpers/Utils";
import TranslatePopup from "../../../../components/common/translatePopup";


const Subscription = ({
    authUser,
    dismiss,
}) => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)

    const { activeItem } = useSelector(state => state.activeItem)

    const dispatch = useDispatch()
    const [updatedSubscription, UpdatedSubscription] = useState(activeItem?.general || {})
    const [translateObject, showTranslatePopup] = useState(false)

    const [tabs] = useState([
        { name: 'General Information', key: 1 },
    ])

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedSubscription }))
        // eslint-disable-next-line
    }, [updatedSubscription])



    const submit = async () => {
        let { general } = activeItem;

        let id = general.id || firestore().collection('subscriptions').doc().id;
        if (general) {
            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            general.deactivated = false
            general.lastUpdate = Date.now()


            await firestore().collection('subscriptions').doc(id).set(general, { merge: true })
        }

        dispatch(SetActiveItem(false))
        setLoading(false)
    }
    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedSubscription?.addNew ? 'New Subscription' : updatedSubscription?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return !item.hide && (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {[{
                                                key: 'generalInfo', title: 'General Information',
                                                data: [
                                                    { key: 'name', title: 'Package Name', width: '10', type: 'text', translateButton: true },
                                                    { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
                                                    { key: 'price', title: 'Package Price', width: '10', type: 'number' },
                                                    { key: 'count', parent: 'duration', title: 'Duration Count in Months', width: '10', type: 'number' },
                                                    { key: 'title', parent: 'duration', title: 'Duration Title', width: '10', type: 'text', translateButton: true },
                                                    { key: 'offer', title: 'Offer Discount Percentage', width: '10', type: 'number' },
                                                ]
                                            },
                                            {
                                                key: 'packageDetails', title: 'Package Details',
                                                data: [
                                                    { key: 'packageDetails', title: 'Details', width: '10', type: 'multi-select', formatCreateLabel: 'Add New', },
                                                ]
                                            },
                                            {
                                                key: 'cancelationReasons', title: 'Cancelation Reasons',
                                                data: [
                                                    { key: 'cancelationReasons', title: 'Reasons', width: '10', type: 'multi-select', formatCreateLabel: 'Add New', },
                                                ]
                                            }].map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>

                                                        {rec.data.map((item, index) => {
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                        <div>{item.title}</div>
                                                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: updatedSubscription?.[item?.parent]?.[item.key] || updatedSubscription?.[item.key], type: item.type })}>Translate</div>}
                                                                    </div>
                                                                    {item.type === 'multi-select' ?
                                                                        <>
                                                                            <Creatable
                                                                                isMulti
                                                                                style={{ width: '100%' }}
                                                                                placeholder={item?.title}
                                                                                components={{ Input: CustomSelectInput, }}
                                                                                className={`basic-multi-select`}
                                                                                // onChange={(val) => {
                                                                                //     UpdatedSubscription({ ...updatedSubscription, [item.key]: val.map(rec => rec.key), updated: true })
                                                                                // }}
                                                                                isValidNewOption={() => { return item?.formatCreateLabel ? true : false }}
                                                                                formatCreateLabel={() => item?.formatCreateLabel || undefined}
                                                                                onCreateOption={() => {
                                                                                    showTranslatePopup({ key: item.key, creatable: true, type: item.type })
                                                                                }}
                                                                            />

                                                                            {updatedSubscription?.[item.key]?.length ? <div className="mt-3">
                                                                                <Table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Name</th>
                                                                                            <th style={{ textAlign: 'end' }}></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {updatedSubscription?.[item.key]?.map((r, ind) => {

                                                                                            return (<tr key={ind} className="mt-1">
                                                                                                <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{r?.en}</td>

                                                                                                <td className="d-flex align-items-center" style={{ justifyContent: 'flex-end', padding: '21px 0.75rem', cursor: 'pointer', fontWeight: '600', color: '#3498db' }} onClick={() => {
                                                                                                    showTranslatePopup({ key: item.key, value: r, creatable: true, type: item.type, ind })

                                                                                                }}>
                                                                                                    Edit Translation
                                                                                                </td>
                                                                                            </tr>)
                                                                                        })}

                                                                                    </tbody>
                                                                                </Table>

                                                                            </div> : ''}

                                                                        </>
                                                                        : <Input
                                                                            type={item.type}
                                                                            placeholder={"e.g. " + item.title}
                                                                            value={updatedSubscription?.[item.parent]?.[item.key]?.en || updatedSubscription?.[item.parent]?.[item.key] || updatedSubscription?.[item.key]?.en || updatedSubscription?.[item.key]}

                                                                            className="customInput"
                                                                            onChange={(e) => {
                                                                                if (item.parent) {
                                                                                    if (item?.translateButton) {
                                                                                        UpdatedSubscription({ ...updatedSubscription, [item.parent]: { ...updatedSubscription?.[item.parent], [item.key]: { ...updatedSubscription?.[item.parent]?.[item.key], en: e.target.value } }, updated: true })
                                                                                    } else {
                                                                                        UpdatedSubscription({ ...updatedSubscription, [item.parent]: { ...updatedSubscription?.[item.parent], [item.key]: e.target.value, }, updated: true })
                                                                                    }
                                                                                } else {
                                                                                    if (item?.translateButton) {
                                                                                        UpdatedSubscription({ ...updatedSubscription, [item.key]: { ...updatedSubscription?.[item.key], en: e.target.value }, updated: true })
                                                                                    } else {
                                                                                        UpdatedSubscription({ ...updatedSubscription, [item.key]: e.target.value, updated: true })
                                                                                    }
                                                                                }
                                                                            }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedSubscription)), ['name', 'details'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        if (translateObject.creatable) {
                            let options = updatedSubscription?.[translateObject?.key] || []
                            options.splice(translateObject?.ind, 1);
                            options.splice(translateObject?.ind, 0, e);
                            UpdatedSubscription({ ...updatedSubscription, [translateObject.key]: options, updated: true })

                        } else {
                            UpdatedSubscription({ ...updatedSubscription, [translateObject.key]: e, updated: true })

                        }
                        showTranslatePopup(false)
                    }} />
                    }
                </Fragment >
            ))
    );
};

export default Subscription;