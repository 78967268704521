import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { Colxx } from "../../../../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../../../../components/common/CustomSelectInput";

import { useSelector } from "react-redux";

const Service = ({ selectedLocation, update }) => {
    const { services } = useSelector(state => state.settings)
    const { activeItem } = useSelector(state => state.activeItem)
    const [selectedService, setSelectedService] = useState(false)
    const [sortedServices] = useState(activeItem?.general?.services)
    const [variations] = useState(activeItem?.variations || {})
    const [filteredVariations, setFilteredVariations] = useState({})
    const [locationVariations, setLocationVariations] = useState(selectedLocation?.variations || {})
    const [updatedVariations, UpdateVariations] = useState(selectedLocation?.variations || {})

    useEffect(() => {
        setLocationVariations(selectedLocation?.variations || {})
        UpdateVariations(selectedLocation?.variations || {})
    }, [selectedLocation])

    const selectService = (serviceData) => {
        let { id } = serviceData;
        let filteredVariations = variations && Object.values(variations).reduce((val, currentVal) => {

            if (currentVal.service_id === id) {
                val[currentVal.id] = { ...currentVal, id: currentVal.id, key: currentVal.id, value: currentVal?.name, label: currentVal?.name }
            }
            return val
        }, {})

        setFilteredVariations(filteredVariations)
        setSelectedService(serviceData)
    }

    return (
        <Fragment>
            <Row>
                <Colxx xxs="12" className="mb-2">
                    <Row>
                        <Colxx xxs="12" className="mb-2">
                            <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                <h4 className="mb-0">Services</h4>
                            </div>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="basic-multi-select mb-2"
                                value={''}
                                placeholder={'Select services provided'}
                                // eslint-disable-next-line
                                options={services?.filter(rec => rec.active && !rec.deactivated && sortedServices?.includes(rec.id) && !(Object.values(variations).filter(r => locationVariations?.[r.id]).map(r => r.service_id).includes(rec.id))).map(r => {
                                    return ({ ...r, key: r.id, value: r?.name?.en, label: r?.name?.en })
                                })}
                                onChange={val => {
                                    selectService(val)
                                }}
                            />
                        </Colxx>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Name</Colxx>
                        <Colxx style={{ fontWeight: '700' }}>Location Prices</Colxx>
                        <Colxx xxs="3" />
                    </Row>

                    {services.filter(rec => sortedServices.includes(rec.id) && rec.active && (Object.values(variations).filter(r => locationVariations?.[r.id]).map(r => r.service_id).includes(rec.id))).map((service, index) => {
                        let sorted = Object.values(variations)?.filter(r => r.service_id === service.id && locationVariations?.[r.id])?.sort((a, b) => a.index - b.index)
                        return (
                            <div key={index} className="mt-3 pb-3" style={{ borderBottom: '1px solid #f3f3f3' }}>
                                <Row className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>

                                    <Colxx xxs="2">
                                        <span>{service?.name?.en}</span>
                                    </Colxx>

                                    <Colxx >{sorted?.map(r => {
                                        return <div key={r.id}>{r?.name?.en + " " + ((Number(locationVariations?.[r.id] || r?.pricing?.total || 0))).toFixed(2) + ' AED'}</div> || ''
                                    })}
                                    </Colxx>

                                    <Colxx xxs="3" >
                                        <Button
                                            color="primary"
                                            size="sm"
                                            outline
                                            className="float-right"
                                            onClick={() => selectService(service)}>
                                            Edit
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="float-right mr-2"
                                            outline
                                            onClick={() => {
                                                let newlocationVariations = JSON.parse(JSON.stringify(locationVariations))
                                                let newupdatedVariations = JSON.parse(JSON.stringify(updatedVariations))
                                                // eslint-disable-next-line
                                                Object.values(variations || {}).map((currentVal) => {
                                                    if (service.id === currentVal.service_id) {
                                                        delete newlocationVariations[currentVal.id]
                                                        delete newupdatedVariations[currentVal.id]

                                                    }
                                                })
                                                setLocationVariations(newlocationVariations || {})
                                                UpdateVariations(newupdatedVariations || {})
                                                update(JSON.parse(JSON.stringify(newupdatedVariations)))

                                            }
                                            }>
                                            Remove
                                        </Button>
                                    </Colxx>
                                </Row>
                            </div>
                        )
                    })}
                </Colxx>
            </Row>
            {selectedService && <Modal
                size='lg'
                isOpen={selectedService}>
                <ModalHeader>{selectedService?.name?.en}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            <div className="mb-2" >{'Select provided service variations'}</div>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="basic-multi-select mb-2"
                                placeholder={'Select variations'}
                                value={''}
                                options={[{ value: 'All', label: 'All', key: 'all' }, ...(Object.values(filteredVariations || {})?.filter(rec => !updatedVariations?.[rec.id])?.map(rec => { return ({ ...rec, value: rec?.name?.en, label: rec?.name?.en, key: rec.id }) })) || []]}
                                onChange={val => {
                                    if (val.key === 'all') {
                                        let newData = JSON.parse(JSON.stringify(updatedVariations))
                                        Object.values(filteredVariations || {}).filter(rec => !updatedVariations?.[rec.id]).map(rec => {
                                            newData[rec.key] = rec?.pricing?.total
                                            return UpdateVariations(newData)
                                        })
                                    } else {
                                        let newData = JSON.parse(JSON.stringify(updatedVariations))
                                        newData[val.key] = val?.pricing?.total
                                        UpdateVariations(newData)
                                    }

                                }}
                            />
                            {
                                Object.values(filteredVariations || {})?.filter(rec => updatedVariations?.[rec.id])?.length ?
                                    <>
                                        <Row className="d-flex align-items-center mt-3">

                                            <Colxx style={{ fontWeight: '700' }} xxs="3" >
                                                Variation Name
                                            </Colxx>
                                            <Colxx style={{ fontWeight: '700' }} xxs="4">
                                                {"Location Price"}
                                            </Colxx>
                                            <Colxx style={{ fontWeight: '700' }} >
                                                {"Total Price"}
                                            </Colxx>

                                        </Row>
                                        {
                                            Object.values(variations)?.filter(rec => rec.service_id === selectedService.id && updatedVariations[rec?.id])?.sort((a, b) => a.index - b.index)?.map((item, itemIndex) => {
                                                return (
                                                    <div key={itemIndex} className="mt-3 pb-3" style={{ borderBottom: '1px solid #f3f3f3' }}>
                                                        <Row className="d-flex align-items-center">
                                                            <Colxx xxs="3" >
                                                                <div>{item?.name?.en}</div>
                                                            </Colxx>
                                                            <Colxx xxs="4">
                                                                <InputGroup>
                                                                    <Input className="customInput"
                                                                        value={updatedVariations[item.id] || updatedVariations[item.id] === '' ? updatedVariations[item.id] : variations[item.id]?.pricing?.total}
                                                                        placeholder={"e.g. 200 AED"}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            let newData = JSON.parse(JSON.stringify(updatedVariations))
                                                                            newData[item.id] = e.target.value
                                                                            UpdateVariations(newData)
                                                                        }}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>AED</InputGroupText>
                                                                        {variations[item.id]?.pricing?.total !== updatedVariations[item.id] && (updatedVariations[item.id] || updatedVariations[item.id] === '') ? <Button
                                                                            onClick={() => {
                                                                                let newData = JSON.parse(JSON.stringify(updatedVariations))
                                                                                newData[item.id] = variations[item.id]?.pricing?.total
                                                                                UpdateVariations(newData)
                                                                            }}
                                                                            outline color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>
                                                                            : ''}
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </Colxx>

                                                            <Colxx>
                                                                {Number(variations[item.id]?.pricing?.total || variations[item.id]?.pricing?.total).toFixed(2) + " AED"}
                                                            </Colxx>
                                                            <Colxx xxs={"1"} style={{
                                                                fontSize: '23px',
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                display: 'flex', alignItems: 'center',
                                                                justifyContent: 'flex-end'
                                                            }}>
                                                                <i className="simple-icon-trash"
                                                                    style={{ color: '#dc3545' }}
                                                                    onClick={() => {
                                                                        let newData = JSON.parse(JSON.stringify(updatedVariations))
                                                                        delete newData[item.id]
                                                                        UpdateVariations(newData)

                                                                    }} />
                                                            </Colxx>

                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No variations selected</div>
                            }
                        </Colxx>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => {
                        setSelectedService(false)
                        UpdateVariations(selectedLocation?.variations || {})
                    }}>Cancel</Button>
                    <Button color='primary'
                        className='m-1' onClick={() => {
                            setLocationVariations(JSON.parse(JSON.stringify(updatedVariations)))
                            update(updatedVariations)
                            setSelectedService(false)
                        }}>Save</Button>
                </ModalFooter>
            </Modal>}

        </Fragment>
    );
};

export default Service;