import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { Colxx } from "../../../../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../../../../components/common/CustomSelectInput";

import { useSelector } from "react-redux";
import { firestore } from "../../../../../helpers/Firebase";

const Provider = ({ selectedLocation, update }) => {
    const { activeItem } = useSelector(state => state.activeItem)
    const { allProviders } = useSelector(state => state.providers)
    const [selectedProvider, setSelectedProvider] = useState(false)
    const [locationProviders, setLocationProviders] = useState(selectedLocation?.providers || [])
    const [updatedProviders, UpdateProviders] = useState(selectedLocation?.providers || [])
    const [locationVariations, setLocationVariations] = useState(Object.keys(selectedLocation?.variations || {}) || {})

    const { services } = useSelector(state => state.settings)
    const [sortedServices] = useState(activeItem?.general?.services)
    const [variations] = useState(activeItem?.variations || {})

    useEffect(() => {
        setLocationProviders(selectedLocation?.providers || [])
        UpdateProviders(selectedLocation?.providers || [])
        setLocationVariations(Object.keys(selectedLocation?.variations || {}) || {})
    }, [selectedLocation])

    const selectProvider = async (providerData) => {
        let { id } = providerData;

        let tapsData = ["variations"]
        await Promise.all(tapsData.map(async rec => {
            let snap = await firestore().collection('providers').doc(id).collection(rec).where('deactivated', '!=', true).get()
            let data = snap.docs.reduce((val, currentVal) => {
                if (locationVariations.includes(currentVal.id)) {
                    val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }
                }
                return val
            }, {})
            providerData[rec] = data
            return data

        }))

        setSelectedProvider(providerData)
    }

    return (
        <Fragment>
            <Row>
                <Colxx xxs="12" className="mb-2">
                    <Row>
                        <Colxx xxs="12" className="mb-2">
                            <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                <h4 className="mb-0">Providers</h4>
                            </div>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="basic-multi-select mb-2"
                                value={''}
                                placeholder={'Select providers provided'}
                                // eslint-disable-next-line
                                options={allProviders?.filter(rec => !locationProviders?.find(loc => loc.id === rec.id)).map(r => {
                                    return ({ ...r, key: r.id, value: r?.name?.en, label: r?.name?.en })
                                })}
                                onChange={val => {
                                    selectProvider(val)
                                    let newData = JSON.parse(JSON.stringify(updatedProviders)) || []
                                    newData.push({ id: val.id, variations: {} })
                                    UpdateProviders(newData)

                                }}
                            />
                        </Colxx>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Name</Colxx>
                        <Colxx style={{ fontWeight: '700' }}>Location Prices</Colxx>
                        <Colxx xxs="3" />
                    </Row>

                    {allProviders?.filter(rec => locationProviders?.find(loc => loc.id === rec.id))?.map((provider, index) => {
                        let sorted = Object.values(variations)?.filter(r => Object.keys(locationProviders?.find(loc => loc.id === provider.id)?.variations)?.includes(r.id))
                        return (
                            <div key={index} className="mt-3 pb-3" style={{ borderBottom: '1px solid #f3f3f3' }}>
                                <Row className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>

                                    <Colxx xxs="2">
                                        <span>{provider?.name?.en}</span>
                                    </Colxx>

                                    <Colxx >{sorted?.map(r => {
                                        return <div key={r.id}>{r?.name?.en + " " + ((Number(locationProviders?.find(loc => loc.id === provider.id)?.variations?.[r.id] || 0))).toFixed(2) + ' AED'}</div> || ''
                                    })}
                                    </Colxx>

                                    <Colxx xxs="3" >
                                        <Button
                                            color="primary"
                                            size="sm"
                                            outline
                                            className="float-right"
                                            onClick={() => selectProvider(provider)}>
                                            Edit
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="float-right mr-2"
                                            outline
                                            onClick={() => {
                                                let newData = JSON.parse(JSON.stringify(locationProviders))
                                                newData = newData.filter(r => r.id !== provider.id)
                                                setLocationProviders(newData)
                                                UpdateProviders(newData || [])
                                                update(JSON.parse(JSON.stringify(newData)))
                                            }
                                            }>
                                            Remove
                                        </Button>
                                    </Colxx>
                                </Row>
                            </div>
                        )
                    })}
                </Colxx>
            </Row>
            {selectedProvider && <Modal
                size='lg'
                isOpen={selectedProvider}>
                <ModalHeader>{selectedProvider?.name?.en}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            <div className="mb-2" >{'Select provided provider variations'}</div>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="basic-multi-select mb-2"
                                placeholder={'Select variations'}
                                value={''}
                                options={[{ value: 'All', label: 'All', key: 'all' }, ...(Object.values(selectedProvider?.variations || {})?.filter(r => variations?.[r.id] && !Object.keys(updatedProviders.find(loc => loc.id === selectedProvider.id)?.variations)?.includes(r.id))?.map(rec => { return ({ ...rec, value: rec?.name?.en, label: rec?.name?.en, key: rec.id }) })) || []]}
                                onChange={e => {
                                    let newData = updatedProviders.reduce((val, currentVal) => {
                                        let providerVariations = Object.values(selectedProvider?.variations || {})?.filter(r => variations?.[r.id] && !Object.keys(updatedProviders.find(loc => loc.id === selectedProvider.id)?.variations)?.includes(r.id))
                                        if (currentVal.id === selectedProvider.id) {
                                            if (e.key === 'all') {
                                                providerVariations.map(rec => {
                                                    return currentVal.variations[rec.id] = selectedProvider?.variations?.[rec.id]?.providerPrice
                                                })
                                            } else {
                                                currentVal.variations[e.id] = selectedProvider?.variations?.[e.id]?.providerPrice

                                            }
                                        }
                                        val.push(currentVal)
                                        return val
                                    }, [])
                                    UpdateProviders(newData)

                                }}
                            />
                            {
                                Object.values(selectedProvider?.variations || {})?.length ?
                                    <>
                                        <Row className="d-flex align-items-center mt-3">
                                            <Colxx style={{ fontWeight: '700' }} xxs="3" >
                                                Variation Name
                                            </Colxx>
                                            <Colxx style={{ fontWeight: '700' }} xxs="4">
                                                {"New Location Price"}
                                            </Colxx>
                                            <Colxx style={{ fontWeight: '700' }} >
                                                {"Default Provider Price"}
                                            </Colxx>

                                        </Row>
                                        {services.filter(s => sortedServices.includes(s.id)).map((service, index) => {
                                            let filteredVariations = Object.values(selectedProvider?.variations || {})?.filter(r => Object.keys(updatedProviders.find(loc => loc.id === selectedProvider.id)?.variations)?.includes(r.id) && r.service_id === service.id)
                                            return filteredVariations?.length ? <div key={index} style={{ borderBottom: '1px solid #f3f3f3' }} className="mt-3">
                                                <div style={{ fontSize: '14px', fontWeight: '700' }}>{service.name?.en || service.name}</div>
                                                {
                                                    filteredVariations?.map((item, itemIndex) => {
                                                        let provider = updatedProviders.find(loc => loc.id === selectedProvider.id)
                                                        return (
                                                            <div key={itemIndex} className="mt-3 pb-3" >
                                                                <Row className="d-flex align-items-center">
                                                                    <Colxx xxs="3" >
                                                                        <div>{item?.name?.en}</div>
                                                                    </Colxx>
                                                                    <Colxx xxs="4">
                                                                        <InputGroup>
                                                                            <Input className="customInput"
                                                                                value={provider?.variations?.[item.id]}
                                                                                placeholder={"e.g. 200 AED"}
                                                                                type="number"
                                                                                onChange={(e) => {
                                                                                    let newData = updatedProviders.reduce((val, currentVal) => {
                                                                                        if (currentVal.id === provider.id) {
                                                                                            currentVal.variations[item.id] = e.target.value
                                                                                        }
                                                                                        val.push(currentVal)
                                                                                        return val
                                                                                    }, [])
                                                                                    UpdateProviders(newData)
                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="append">
                                                                                <InputGroupText>AED</InputGroupText>
                                                                                {item?.providerPrice !== provider?.variations?.[item.id] && (provider?.variations?.[item.id] || provider?.variations?.[item.id] === '') ? <Button
                                                                                    onClick={() => {
                                                                                        let newData = updatedProviders.reduce((val, currentVal) => {
                                                                                            if (currentVal.id === provider.id) {
                                                                                                currentVal.variations[item.id] = item?.providerPrice
                                                                                            }
                                                                                            val.push(currentVal)
                                                                                            return val
                                                                                        }, [])
                                                                                        UpdateProviders(newData)

                                                                                    }}
                                                                                    outline color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>
                                                                                    : ''}
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </Colxx>

                                                                    <Colxx>
                                                                        {Number(item?.providerPrice || 0).toFixed(2) + " AED"}
                                                                    </Colxx>
                                                                    <Colxx xxs={"1"} style={{
                                                                        fontSize: '23px',
                                                                        fontWeight: '600',
                                                                        cursor: 'pointer',
                                                                        display: 'flex', alignItems: 'center',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <i className="simple-icon-trash"
                                                                            style={{ color: '#dc3545' }}
                                                                            onClick={() => {
                                                                                let newData = updatedProviders.reduce((val, currentVal) => {
                                                                                    if (currentVal.id === provider.id) {
                                                                                        delete currentVal.variations[item.id]
                                                                                    }
                                                                                    val.push(currentVal)
                                                                                    return val
                                                                                }, [])
                                                                                UpdateProviders(newData)
                                                                            }} />
                                                                    </Colxx>

                                                                </Row>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : ''
                                        })}

                                    </>
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No variations selected</div>
                            }
                        </Colxx>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => {
                        setSelectedProvider(false)
                        UpdateProviders(selectedLocation?.providers || [])
                    }}>Cancel</Button>
                    <Button color='primary'
                        className='m-1' onClick={() => {
                            setLocationProviders(JSON.parse(JSON.stringify(updatedProviders)))
                            update(JSON.parse(JSON.stringify(updatedProviders)))
                            setSelectedProvider(false)
                        }}>Save</Button>
                </ModalFooter>
            </Modal>}

        </Fragment>
    );
};

export default Provider;