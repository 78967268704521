import React, { useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

import { Colxx } from "../../../../components/common/CustomBootstrap";
import Select, { components } from "react-select";
import { Creatable } from 'react-select';
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { firestore } from '../../../../helpers/Firebase'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { calcTotalCost, uploadPicture } from "../../../../helpers/Utils";
import Addons from "../../../../components/common/addons";

const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
);
const ManageOrder = ({
    dismiss,
}) => {
    const { user } = useSelector(state => state.authUser)
    const { activeItem } = useSelector(state => state.activeItem)
    const [booking, updateBooking] = useState(activeItem?.general || {})
    const [archiveVersion] = useState(activeItem?.general || {})
    const [currentStatus] = useState(activeItem?.general?.status || '')
    const [loading, setLoading] = useState(false)

    const { addons } = useSelector(state => state.settings)
    const { allProviders } = useSelector(state => state.providers)
    const { allProfessionals } = useSelector(state => state.providers)
    const [orderDate, setOrderDate] = useState((booking?.bookingDate?.date) || new Date())
    const [editAddon, setCreateAddons] = useState(false)
    const [providerAddons, setProviderAddons] = useState([])
    const [attachment, setAttachment] = useState([])
    const [specialDiscount, setSpecialDiscount] = useState({ type: 'percent', val: '' })

    const statusArray = {
        new: { sort: 0, label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept', nextStatus: booking?.provider?.id ? 'accepted' : 'pending', },
        pending: { sort: 1, label: "Pending Assigment", status: 'pending', nextStatus: 'accepted', color: "#3498db", action: 'Accept' },
        accepted: { sort: 2, label: "Accepted", status: 'accepted', nextStatus: 'ontheway', color: "#145388", action: 'On the way' },
        ontheway: { sort: 3, label: "On The Way", status: 'ontheway', nextStatus: 'active', color: "#3e884f", action: 'Arrive' },
        active: { sort: 4, label: "Session Started", status: 'active', nextStatus: 'completed', color: "#3195a5", action: 'Complete' },
        completed: { sort: 5, label: "Completed", status: 'completed', nextStatus: 'completed', color: "#3e884f", action: '' },
        rated: { sort: 5, label: "Rated", status: 'rated', nextStatus: 'rated', color: "#b69329", action: '' },
        canceled: { sort: 6, label: "Booking Canceled", status: 'canceled', color: "#c43d4b", action: 'Cancel' },
    }

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    setAttachment(pictures)

                },
                'blob'
            );

        } else {
            setAttachment(pictures)
            updateBooking({ ...booking, [type]: [], updated: true })
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: booking }))
        // eslint-disable-next-line
    }, [booking])


    useEffect(() => {
        async function fetchData() {
            let snap = await firestore().collection('providers').doc(booking?.provider?.id).collection('addons').get()
            let adds = snap.docs.reduce((val, currentVal) => {
                val[currentVal.id] = {
                    ...currentVal.data(),
                    id: currentVal.id,
                    key: currentVal.id,
                    value: currentVal.data()?.name?.en || currentVal.data()?.name,
                    label: currentVal.data()?.name?.en || currentVal.data()?.name,
                }
                return val
            }, {})
            setProviderAddons(adds)
        }
        fetchData();
        // eslint-disable-next-line
    }, [booking?.provider?.id])

    const submit = async () => {
        let { general } = JSON.parse(JSON.stringify(activeItem));

        let id = general?.id;

        let manageType = JSON.parse(JSON.stringify(general.manageOrder))

        if (id) {
            // general.candidates = general?.provider?.id ? [general?.provider?.id] : general.candidates
            delete general?.brand?.services
            delete general.addNew
            delete general.manageOrder
            delete general.delayedOntheWay
            delete general.delayedActive
            delete general.delayedCompleted
            general.managed = true
            general.updatedBy = user.uid

            switch (manageType) {
                case 'status':
                    general[general?.status] = Date.now()
                    break;
                case 'reschedule':
                    general.rescadual = Date.now()
                    break;
                case 'services':
                    general.receipt.outstanding = Number(general?.receipt?.total) - Number(general?.receipt?.paid || 0)
                    break;
                case 'addons':
                    general.receipt.outstanding = Number(general?.receipt?.total) - Number(general?.receipt?.paid || 0)
                    if (general?.paymentStatus === 'paid' && (Number(general?.receipt?.total) !== Number(general?.receipt?.paid || 0)) && Number(general?.receipt?.outstanding || 0) > 0) {
                        general.paymentStatus = 'partiallyPaid'
                    }
                    if (general?.addons) {
                        let newAddons = JSON.parse(JSON.stringify(general?.addons))
                        Object.keys(newAddons).map(async (val) => {
                            let addon = newAddons?.[val]
                            addon.active = true
                            addon.created = Date.now()
                            addon.lastUpdate = Date.now()
                            addon.deactivated = false
                            addon.id = val

                            if (addon.addNew) {
                                delete addon.addNew
                                delete addon.count
                                await firestore().collection('addons').doc(val).set(addon, { merge: true })
                            }
                            if (general?.provider?.id && !providerAddons[val]) {
                                await firestore().collection('providers').doc(general?.provider?.id).collection('addons').doc(val).set(addon, { merge: true })
                            }
                        })

                    }
                    if (attachment && attachment.length > 0 && attachment[0]?.name) {
                        uploadPicture(attachment, 'orders', id, 'attachment')
                    }
                    break;
                // case 'receipt':
                //     general.rescadual = Date.now()
                //     break;
                default:
                    break;
            }

            await firestore().collection('orders').doc(id).update(general)
            await firestore().collection('orders').doc(id).collection('archive').doc().set({ ...archiveVersion, archiveCreated: Date.now() }, { merge: true })

            dispatch(SetActiveItem(false))
            setLoading(false)
        }

    }


    useEffect(() => {
        if (booking?.items) {
            let receipt = calcTotalCost(booking?.items, false, booking?.addons, booking?.receipt)
            let providerFees = Object.values(booking?.items).reduce((val, currentVal) => {
                val = val + (Number(currentVal?.price?.providerFees || 0) * currentVal.count)
                return val
            }, 0)
            let metadocCommission = Number(receipt?.sub) - (Number(receipt?.totalTopup || 0) || 0) - Number(providerFees || 0)

            let newReceipt = { ...receipt, metadocCommission: metadocCommission, providerFees: providerFees }

            updateBooking({ ...booking, updated: true, receipt: newReceipt })
        }
        // eslint-disable-next-line
    }, [booking?.items, booking?.addons])

    useEffect(() => {
        async function fetchData() {
            if (booking?.provider) {


                let locationVariations = false
                let locationSnap = await firestore().collection('partners').doc(booking?.brand?.id).collection('locations').doc(booking?.location?.cluster).get()
                locationVariations = locationSnap?.exists ? (locationSnap.data()?.providers?.find(r => r.id === booking?.provider?.id)?.variations || false) : false

                let variationSnap = await firestore().collection('providers').doc(booking?.provider?.id).collection('variations').where('deactivated', '!=', true).get()
                variationSnap.docs.filter(rec => Object.keys(booking?.items).includes(rec.id)).map(currentVal => {
                    let item = booking?.items

                    if (locationVariations && locationVariations?.[currentVal.id]) {
                        item[currentVal.id].price.providerFees = locationVariations?.[currentVal.id]
                    } else {
                        item[currentVal.id].price.providerFees = currentVal.data()?.providerPrice || 0
                    }
                    return item

                })

                let providerFees = Object.values(booking?.items).reduce((val, currentVal) => {
                    val = val + (Number(currentVal?.price?.providerFees || 0) * currentVal.count)
                    return val
                }, 0)
                let metadocCommission = Number(booking.receipt?.sub) - (Number(booking.receipt?.totalTopup || 0) || 0) - Number(providerFees || 0)

                let newReceipt = { ...booking?.receipt, metadocCommission: metadocCommission, providerFees: providerFees }
                updateBooking({
                    ...booking, updated: true, receipt: newReceipt
                })
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [booking?.provider])
    const checkProperties = (obj) => {
        for (var key in obj) {
            if (!obj[key]?.price)
                return true;
        }
        return false;
    }
    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Modal backdrop="static" isOpen={true} style={{ maxWidth: '700px' }}>
                    <ModalHeader style={{ height: '100%' }}>
                        <div className="d-flex flex-column justify-flex-start" style={{ alignItems: 'flex-start', justifyContent: 'space-between' }} >
                            <h3>Manage Order: {booking?.orderNumber}</h3>
                            <div className='badge badge-pill' style={{ backgroundColor: statusArray[currentStatus]?.color, color: 'white', borderRadius: '4px' }} >{statusArray[currentStatus]?.label}</div>
                        </div>
                    </ModalHeader>
                    <ModalBody>

                        <Colxx xxs="12" md="12">

                            {[
                                {
                                    key: 'generalInfo',
                                    title: 'General Information',
                                    show: booking?.manageOrder === 'services',
                                    data: [
                                        // { key: 'addons', title: 'Addons', width: '12', type: 'creatable', value: '', formatCreateLabel: 'Add New Addon', data: (booking?.provider?.id ? Object.values(providerAddons || {}) : addons)?.filter(a => !Object.keys(booking?.addons || {}).includes(a.id)), show: true },
                                        { key: 'items', title: 'Services', width: '12', type: 'table', show: true },
                                    ]
                                },
                                {
                                    key: 'generalInfo',
                                    title: 'General Information',
                                    show: booking?.manageOrder === 'addons',
                                    data: [
                                        // { key: 'addons', title: 'Addons', width: '12', type: 'creatable', value: '', formatCreateLabel: 'Add New Addon', data: (booking?.provider?.id ? Object.values(providerAddons || {}) : addons)?.filter(a => !Object.keys(booking?.addons || {}).includes(a.id)), show: true },
                                        { key: 'addons', title: 'Addons', width: '12', type: 'creatable', value: '', formatCreateLabel: 'Add New Addon', data: (addons)?.filter(a => !Object.keys(booking?.addons || {}).includes(a.id)), show: true },
                                        { key: 'attachment', title: 'Attachments', width: '12', type: 'image', value: '', show: true },
                                    ]
                                },
                                {
                                    key: 'dateInfo',
                                    title: 'Date Information',
                                    show: booking?.manageOrder === 'reschedule',

                                    data: [
                                        { key: 'bookingDate', title: 'Select perferred Date and Time', width: '12', type: 'date', show: true },
                                    ]
                                }, {
                                    key: 'providerInfo',
                                    title: 'Provider Information',
                                    show: booking?.manageOrder === 'reassign',

                                    data: [
                                        { key: 'provider', title: 'Provider', width: '6', type: 'select', data: allProviders, value: booking?.provider, show: true },
                                        { key: 'professional', title: 'Professional', width: '6', type: 'select', data: allProfessionals?.filter(rec => rec.provider_id === booking?.provider?.id) || [], value: booking?.professional, show: true, optional: true },
                                    ]
                                },
                                {
                                    key: 'statusInfo',
                                    title: 'Status Information',
                                    show: booking?.manageOrder === 'status',

                                    data: [
                                        { key: 'status', title: 'Manage Status', width: '12', type: 'select', data: Object.values(statusArray)?.filter(s => statusArray?.[currentStatus]?.sort < s?.sort), value: booking?.status, show: true },
                                    ]
                                },
                                {
                                    key: 'receiptInfo',
                                    title: 'Receipt Information',
                                    show: booking?.manageOrder === 'receipt',

                                    data: [
                                        { key: 'receipt', title: 'Apply Special Discount', width: '12', type: 'special_discount', data: Object.values(statusArray)?.filter(s => statusArray?.[currentStatus]?.sort < s.sort), value: booking?.status, show: true },
                                    ]
                                },
                                {
                                    key: 'teamNotes',
                                    title: '',
                                    show: true,
                                    data: [
                                        { key: 'teamNotes', title: 'Additional Notes', width: '12', type: 'textarea', show: true, optional: true },
                                    ]
                                }].map((rec, recIndex) => {
                                    return rec.show && (
                                        <Row key={recIndex}>
                                            <Colxx xxs="12" className="mt-2 mb-2">
                                                <h4>{rec.title}</h4>
                                            </Colxx>

                                            {rec.data.map((item, index) => {
                                                return item.show && (
                                                    <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }} >
                                                            <div className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                                                                <div>{item.title}</div>
                                                                {item.optional && <span
                                                                    style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                                                    className={`ml-1 badge badge-pill badge-light badge-top-left`}
                                                                >
                                                                    Optional
                                                                </span>}
                                                            </div>

                                                        </div>
                                                        {item.type === 'select' ?
                                                            <>
                                                                <Select
                                                                    components={{
                                                                        Input: CustomSelectInput,
                                                                        Option: (props) => (
                                                                            <components.Option {...props} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                                <div>{props?.data?.label}</div>
                                                                                {props?.data?.available && <span
                                                                                    style={{ fontWeight: '300', backgroundColor: '#3984db10', color: '#3984db' }}
                                                                                    className={`ml-1 badge badge-pill  badge-top-left`}
                                                                                >
                                                                                    Active
                                                                                </span>}
                                                                            </components.Option>
                                                                        )

                                                                    }}
                                                                    className="basic-multi-select"
                                                                    placeholder={item.title}
                                                                    value={item.key === 'status' ? {
                                                                        value: statusArray[item.value].label,
                                                                        label: statusArray[item.value].label,
                                                                        key: item?.value
                                                                    } : (item?.value ? {
                                                                        value: item?.value?.name?.en || item?.value?.name,
                                                                        label: item?.value?.name?.en || item?.value?.name,
                                                                        key: item?.value?.id
                                                                    } : '')}
                                                                    options={item.key === 'provider' ?
                                                                        item?.data?.sort((a, b) => booking?.candidates?.includes(a.id) ? -1 : 1)?.map(rec => {
                                                                            return booking?.candidates?.includes(rec.id) ?
                                                                                ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, available: true, key: rec.id }) :
                                                                                ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                        }) :
                                                                        item?.data?.map(rec => {
                                                                            return ({ ...rec, value: rec?.name?.en || rec?.name || rec?.label, label: rec?.name?.en || rec?.name || rec?.label, key: rec.id || rec?.status })
                                                                        })}
                                                                    styles={
                                                                        {
                                                                            option: (provided, state) => ({
                                                                                ...provided,
                                                                                justifyContent: state?.data?.available && 'space-between',
                                                                                fontWeight: state?.data?.available && '700',
                                                                            }),
                                                                        }
                                                                    }
                                                                    onChange={val => {
                                                                        if (item.key === 'status') {
                                                                            updateBooking({ ...booking, [item.key]: val.key, updated: true })
                                                                        } else {
                                                                            let post = {
                                                                                name: val.label,
                                                                                id: val.key
                                                                            }
                                                                            if (item.key === 'provider') {
                                                                                booking.professional = {}
                                                                            }
                                                                            updateBooking({ ...booking, [item.key]: post, updated: true })

                                                                        }

                                                                    }}

                                                                />

                                                            </> : item.type === 'table' ?
                                                                <div className="mt-3">
                                                                    <Table striped>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Price</th>
                                                                                <th style={{ textAlign: 'end' }}>Count</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(booking.items).map((r, ind) => {
                                                                                let selectedItem = booking?.items?.[r]
                                                                                return (<tr key={ind} className="mt-1">
                                                                                    <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{selectedItem?.name?.en || selectedItem?.name}</td>
                                                                                    <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', width: '50%' }} >
                                                                                        <InputGroup>
                                                                                            <InputGroupAddon addonType='prepend'>
                                                                                                <InputGroupText>AED</InputGroupText>
                                                                                            </InputGroupAddon>
                                                                                            <Input className="customInput"
                                                                                                value={selectedItem?.price?.total}
                                                                                                disabled={true}
                                                                                                placeholder={"e.g. 200 AED"}
                                                                                                type="number"
                                                                                            />

                                                                                        </InputGroup>
                                                                                    </td>
                                                                                    <td className="d-flex align-items-center" style={{ justifyContent: 'flex-end', padding: '21px 0.75rem' }}>
                                                                                        <i className="simple-icon-minus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                            let newItems = JSON.parse(JSON.stringify(booking.items))
                                                                                            if (newItems[r].count > 1) {
                                                                                                newItems[r].count = newItems[r].count - 1
                                                                                            } else {
                                                                                                // delete newItems[r]
                                                                                            }
                                                                                            updateBooking({
                                                                                                ...booking, updated: true, items: newItems,
                                                                                            })

                                                                                        }} />
                                                                                        <div className="ml-2 mr-2" style={{ fontSize: '16px', fontWeight: '700' }}>{selectedItem.count}</div>
                                                                                        <i className="simple-icon-plus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                            let newItems = JSON.parse(JSON.stringify(booking.items))
                                                                                            newItems[r].count = newItems[r].count + 1
                                                                                            updateBooking({
                                                                                                ...booking,
                                                                                                updated: true,
                                                                                                items: newItems,
                                                                                            })
                                                                                        }} />
                                                                                    </td>
                                                                                </tr>)
                                                                            })}

                                                                        </tbody>
                                                                    </Table>

                                                                </div> : item.type === 'creatable' ?
                                                                    <div >
                                                                        <Creatable
                                                                            style={{ width: '100%' }}
                                                                            loading={true}
                                                                            components={{
                                                                                Input: CustomSelectInput,
                                                                                Option: (props) => (
                                                                                    <components.Option {...props} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                                        <div style={{ flex: '1' }}>
                                                                                            <div style={{ flex: '1' }}>{props?.data?.label}</div>
                                                                                        </div>
                                                                                    </components.Option>
                                                                                )
                                                                            }}

                                                                            options={(item?.data || [])?.map(rec => {
                                                                                return ({
                                                                                    ...rec,
                                                                                    value: rec?.name?.en,
                                                                                    label: rec?.name?.en,
                                                                                    key: rec.id
                                                                                })
                                                                            })}
                                                                            value={''}
                                                                            onChange={(newValue) => {
                                                                                switch (item.key) {
                                                                                    case 'addons':
                                                                                        let post = {
                                                                                            ...booking.addons,
                                                                                            [newValue.key]: {
                                                                                                count: 1,
                                                                                                name: newValue.name,
                                                                                                price: booking?.provider?.id ? providerAddons?.[newValue?.key]?.price : newValue.price,
                                                                                            },
                                                                                        }
                                                                                        updateBooking({
                                                                                            ...booking,
                                                                                            updated: true,
                                                                                            [item.key]: post,
                                                                                        })
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            }}
                                                                            isValidNewOption={() => { return true; }}
                                                                            formatCreateLabel={() => item.formatCreateLabel}
                                                                            onCreateOption={() => {
                                                                                switch (item.key) {
                                                                                    case 'addons':
                                                                                        setCreateAddons(true)
                                                                                        break;

                                                                                    default:
                                                                                        break;
                                                                                }

                                                                            }}
                                                                        />
                                                                        {item.key === 'addons' && Object.keys(booking?.addons || {})?.length ? <div className="mt-3">
                                                                            <Table striped>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>Price</th>
                                                                                        <th style={{ textAlign: 'end' }}>Count</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {Object.keys(booking.addons).map((r, ind) => {
                                                                                        let clientAddons = (booking?.provider?.id ? Object.values(providerAddons || {}) : addons)?.reduce((val, currentVal) => {
                                                                                            val[currentVal.id] = currentVal
                                                                                            return val
                                                                                        }, {})

                                                                                        let selectedItem = booking.addons[r]
                                                                                        return (<tr key={ind} className="mt-1">
                                                                                            <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{selectedItem?.name?.en}</td>
                                                                                            <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', width: '50%' }} >
                                                                                                <InputGroup>
                                                                                                    <InputGroupAddon addonType='prepend'>
                                                                                                        <InputGroupText>AED</InputGroupText>
                                                                                                    </InputGroupAddon>
                                                                                                    <Input className="customInput"
                                                                                                        value={selectedItem?.price || selectedItem?.price === '' ? selectedItem?.price : clientAddons?.[r]?.price}
                                                                                                        placeholder={"e.g. 200 AED"}
                                                                                                        type="number"
                                                                                                        onChange={(e) => {
                                                                                                            let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                            newItems[r].price = e.target.value
                                                                                                            updateBooking({
                                                                                                                ...booking, updated: true, addons: newItems,
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                    {clientAddons?.[r]?.price !== selectedItem?.price && (selectedItem?.price || selectedItem?.price === '') && !selectedItem?.addNew ? <InputGroupAddon addonType="append">
                                                                                                        <Button
                                                                                                            onClick={() => {

                                                                                                                let newItems = JSON.parse(JSON.stringify(booking.addons))

                                                                                                                newItems[r].price = clientAddons?.[r]?.price || ''

                                                                                                                updateBooking({
                                                                                                                    ...booking, updated: true, addons: newItems,
                                                                                                                })
                                                                                                            }}
                                                                                                            outline style={{ background: '#fff', zIndex: '0' }} color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>

                                                                                                    </InputGroupAddon>
                                                                                                        : ''}
                                                                                                </InputGroup>
                                                                                                {/* {selectedItem?.price?.total} AED */}
                                                                                            </td>
                                                                                            <td className="d-flex align-items-center" style={{ justifyContent: 'flex-end', padding: '21px 0.75rem' }}>
                                                                                                <i className="simple-icon-minus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                    let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                    if (newItems[r].count > 1) {
                                                                                                        newItems[r].count = newItems[r].count - 1
                                                                                                    } else {
                                                                                                        delete newItems[r]
                                                                                                    }
                                                                                                    updateBooking({
                                                                                                        ...booking, updated: true, addons: newItems,
                                                                                                    })

                                                                                                }} />
                                                                                                <div className="ml-2 mr-2" style={{ fontSize: '16px', fontWeight: '700' }}>{selectedItem.count}</div>
                                                                                                <i className="simple-icon-plus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                    let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                    newItems[r].count = newItems[r].count + 1
                                                                                                    updateBooking({
                                                                                                        ...booking,
                                                                                                        updated: true,
                                                                                                        addons: newItems,
                                                                                                    })
                                                                                                }} />
                                                                                            </td>
                                                                                        </tr>)
                                                                                    })}

                                                                                </tbody>
                                                                            </Table>

                                                                        </div> : ''}
                                                                    </div> : item.type === 'special_discount' ?
                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSpecialDiscount({ ...specialDiscount, type: 'percent' })
                                                                                    }}
                                                                                    outline={specialDiscount?.type !== 'percent'}
                                                                                    color={specialDiscount?.type !== 'percent' ? 'light' : "primary"}
                                                                                    size="sm">%</Button>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSpecialDiscount({ ...specialDiscount, type: '' })
                                                                                    }}
                                                                                    outline={specialDiscount?.type === 'percent'}
                                                                                    color={specialDiscount?.type === 'percent' ? 'light' : "primary"} size="sm">AED</Button>


                                                                            </InputGroupAddon>
                                                                            <Input className="customInput"
                                                                                value={specialDiscount?.val}
                                                                                placeholder={"e.g. 200 AED"}
                                                                                type="number"
                                                                                onChange={(e) => {
                                                                                    setSpecialDiscount({ ...specialDiscount, val: e.target.value })

                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        let receipt = calcTotalCost(booking?.items, (!booking?.receipt?.discount ? specialDiscount : { type: '', val: '' }), booking?.addons, booking?.receipt)
                                                                                        updateBooking({
                                                                                            ...booking, updated: true, receipt
                                                                                        })
                                                                                    }}
                                                                                    outline color="primary" size="sm">{booking?.receipt?.discount ? 'Reset' : 'Apply'}</Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup> :
                                                                        item.type === 'date' ?
                                                                            <> <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <DatePicker
                                                                                    placeholderText="Select Order Date & Time"
                                                                                    value={booking?.bookingDate?.date ? moment(booking?.bookingDate?.date) : ''}
                                                                                    popperClassName="redatepicker"
                                                                                    customInput={<DatepickerInput />}
                                                                                    selected={(moment(orderDate).toDate())}
                                                                                    onChange={date => {
                                                                                        setOrderDate(date)

                                                                                        let selectedTime = {
                                                                                            start: moment(date).set('year', 1955).set('month', 0).set('date', 5).valueOf(),
                                                                                            end: moment(date).set('year', 1955).set('month', 0).set('date', 5).valueOf() + 3600000,
                                                                                            id: `${moment(date).format('hh:mm A')} - ${moment(date).add(60, 'minutes').format('hh:mm A')}`
                                                                                        }
                                                                                        updateBooking({ ...booking, updated: true, [item.key]: { ...booking.bookingDate, date: moment(date).valueOf(), time: selectedTime } })


                                                                                    }}

                                                                                    showTimeSelect
                                                                                    timeFormat="hh:mm a"
                                                                                    timeIntervals={30}
                                                                                    timeCaption="time"
                                                                                    dateFormat="dd/MM/yyyy hh:mm a"
                                                                                />

                                                                            </div>
                                                                            </> : item.type === 'image' ?
                                                                                <ImageUploader
                                                                                    className={'w-100'}
                                                                                    withIcon={true}
                                                                                    name={item.key}
                                                                                    label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                                    buttonText='Choose images'
                                                                                    onChange={(picture) => onDrop(picture, item.key)}
                                                                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                                    maxFileSize={15728640}
                                                                                    withPreview={true}
                                                                                    singleImage={true}
                                                                                    defaultImages={booking && booking[item.key]}
                                                                                />
                                                                                : <Input
                                                                                    type={item.type}
                                                                                    placeholder={"e.g. " + item.title}
                                                                                    value={item.parent ? booking?.[item.parent]?.[item.key] : booking?.[item.key]}
                                                                                    className="customInput"
                                                                                    onChange={(e) => {
                                                                                        updateBooking({ ...booking, [item.key]: e.target.value, updated: true })
                                                                                    }} />}
                                                    </Colxx>
                                                )
                                            })}
                                        </Row>
                                    )
                                })}
                        </Colxx>
                        <Colxx xxs="12" md="12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                                {[
                                    {
                                        key: 'paymentDetails',
                                        title: 'Payment Details',
                                        show: (booking?.manageOrder === 'addons' || booking?.manageOrder === 'receipt' || booking?.manageOrder === 'services'),
                                        data: [
                                            { key: 'itemsPrice', title: 'Service Price', width: '12', type: 'price', data: booking?.receipt?.itemsPrice && Number((booking?.receipt?.itemsPrice) || 0)?.toFixed(2) },
                                            { key: 'addonsPrice', title: 'Addons Price', width: '12', type: 'price', data: booking?.receipt?.addonsPrice && Number((booking?.receipt?.addonsPrice) || 0)?.toFixed(2), hide: !booking?.receipt?.addonsPrice },
                                            { key: 'subtotal', title: 'Subtotal', width: '12', type: 'price', data: booking?.receipt?.sub && (Number(booking?.receipt?.sub || 0) - Number(booking?.receipt?.totalTopup || 0)).toFixed(2) },
                                            { key: 'topup', title: 'Client Topup', width: '12', type: 'price', data: booking?.receipt?.totalTopup && Number((booking?.receipt?.totalTopup) || 0)?.toFixed(2), hide: !booking?.receipt?.totalTopup },
                                            { key: 'discount', title: 'Discount', width: '12', type: 'price', data: Number(booking?.receipt?.discount || 0)?.toFixed(2), hide: !booking?.receipt?.discount },
                                            { key: 'total', title: 'Total', width: '12', type: 'price', data: Number(booking?.receipt?.total || 0)?.toFixed(2) }
                                        ]
                                    },
                                ].map((rec, recIndex) => {
                                    return rec.show && (
                                        <>
                                            <Row key={recIndex}>
                                                <Colxx xxs="12" className="mt-4 mb-2">
                                                    <h6>{rec.title}</h6>
                                                </Colxx>
                                                {
                                                    rec.data?.map((item, index) => {
                                                        return !item.hide && item?.data && (
                                                            <Colxx key={index} xxs="12" md={item.width} className="mb-2 d-flex align-items-baseline" style={{ justifyContent: 'space-between' }}>
                                                                <div className="mb-2 text-muted" style={{ fontSize: '12px' }}>{item.title} <span style={{ fontWeight: '600', color: '#000', fontSize: '12px' }}>{item.key === 'discount' ? `"${booking?.receipt?.code}"` : ""}</span></div>
                                                                <Label style={{ fontWeight: '700', textAlign: item.key === 'insturction' && item.data ? 'start' : 'end', width: '70%' }}>{item.data || '--'} {item.type === 'price' && item.data ? "AED" : ''}</Label>
                                                            </Colxx>
                                                        )
                                                    })

                                                }
                                            </Row>
                                        </>

                                    )
                                })}
                            </div>
                        </Colxx>
                        {editAddon ? <Addons
                            addNewAddon={(e) => {
                                let key = firestore().collection('addons').doc().id
                                let post = {
                                    ...booking.addons,
                                    [key]: {
                                        count: Number(e.count),
                                        name: { en: e.name?.en || e.name },
                                        price: e.price,
                                        addNew: true
                                    },
                                }
                                updateBooking({
                                    ...booking,
                                    updated: true,
                                    addons: post,

                                })
                            }}
                            dismiss={() => setCreateAddons(false)}
                            editAddon={editAddon}
                        /> : ''}
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'space-between' }}>

                        <Button
                            color="danger"
                            outline
                            size="lg"
                            onClick={() => dismiss()}>
                            Dismiss
                        </Button>
                        <Button
                            // disabled={
                            //     !booking?.service
                            //     || !Object.keys(booking?.items || {}).length
                            //     || !booking?.bookingDate?.date
                            //     || !booking?.bookingDate?.time?.start
                            //     || !booking?.bookingDate?.time?.end
                            //     || !booking?.candidates
                            //     || !booking?.provider?.id
                            // }
                            disabled={booking?.manageOrder === 'addons' ? (!Object.keys(booking?.addons || {}).length || checkProperties(booking?.addons)) : false}
                            color="primary"
                            size="lg"
                            onClick={() => {
                                setLoading(true)
                                submit()
                            }}>
                            Update Request
                        </Button>
                    </ModalFooter>
                </Modal >
            ))
    );
};

export default ManageOrder;