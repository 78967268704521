
import React, { useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    Label,
} from "reactstrap";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import 'react-phone-input-2/lib/style.css'
import ReactTable from "react-table";
import DataTablePagination from "../../../../components/DatatablePagination";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../../../helpers/Firebase";
import { UpdateItem } from "../../../../redux/activeItem/actions";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import TranslatePopup from "../../../../components/common/translatePopup";
import { checkIfDisabled } from "../../../../helpers/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
import { quillFormats, quillModules } from "../../../../constants/defaultValues";
import { Creatable } from 'react-select';

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import NewHelper from "../../../../components/common/newHelper";
const Service_Variation = ({ saveChanges }) => {
    const dispatch = useDispatch()
    const { variations_categories, conditions, symptoms, test_parameters, drip_ingredients } = useSelector(state => state.settings)
    const { activeItem } = useSelector(state => state.activeItem)
    const [variations, setVariations] = useState(activeItem?.variations || [])
    const [deleteItem, setDeleteItem] = useState(false)
    const [selectedVariation, setSelectedVariation] = useState(false)
    const [translateObject, showTranslatePopup] = useState(false)
    const [newHelper, setNewHelper] = useState(false)
    const search = useSelector(state => state.settings.search) || ''

    const dataTableColumns = [
        {
            Header: "Name",
            accessor: "name.en",
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: props => <p className="list-item-heading">{props.value} AED</p>
        },
        {
            Header: "Minimum Count",
            accessor: "min_count",
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Maximum Count",
            accessor: "max_count",
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            sortable: false,
            width: 80,
            accessor: "delete",
            Cell: props => <div >
                <Button
                    color="danger"
                    size="sm"
                    outline
                    onClick={() => setDeleteItem(props.original)}>
                    Delete
                </Button>
            </div>
        },
        {
            sortable: false,
            width: 70,
            accessor: "edit",
            Cell: props => <div >
                <Button
                    color="primary"
                    size="sm"
                    outline
                    className="top-right-button"
                    onClick={() => setSelectedVariation(props.original)}
                >
                    Edit
                </Button>
            </div>
        }
    ];
    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                async uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600,
                        base64: await getBase64(uri600)
                    });

                    setSelectedVariation({ ...selectedVariation, pictures: pictures, updated: true })

                },
                'blob'
            );

        } else {
            setSelectedVariation({ ...selectedVariation, [type]: [], pictures: [], updated: true })
        }
    }

    const deleteItemFunc = (item) => {
        setDeleteItem(false)
        variations[item.id].deleted = true
        variations[item.id].updated = true
        setVariations(variations)
        saveChanges()

    }
    const submit = (id) => {
        let Ref = firestore().collection('services').doc(activeItem?.general?.id).collection('variations')
        let documentID = id || Ref.doc().id;
        variations[documentID] = selectedVariation
        setVariations(variations)
        setSelectedVariation(false)
        saveChanges()
    }
    useEffect(() => {
        dispatch(UpdateItem({ key: 'variations', val: variations }))
        // eslint-disable-next-line
    }, [variations])
    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                resolve(e.target.result)
            }
        })
    };
    console.log('selectedVariationselectedVariation', selectedVariation);
    return (
        <Card className="rounded">
            <CardBody>
                <Row>

                    <Colxx xxs="12" className="mb-2">
                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                            <h4 className="mb-0">Variations</h4>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => setSelectedVariation({ addNew: true })}>Add new
                                </Button>
                            </div>

                        </div>
                    </Colxx>
                </Row>
                <Separator className="mb-5" />

                <Row>
                    <Colxx xxs="12" className="mb-2">
                        <ReactTable
                            data={Object.values(variations).filter(rec => !rec.deleted && (rec?.name?.en || rec?.name)?.toLowerCase()?.includes(search?.toLowerCase()))}
                            columns={dataTableColumns}
                            defaultPageSize={10}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            PaginationComponent={DataTablePagination}
                        />
                    </Colxx>
                </Row>
            </CardBody>
            {selectedVariation && <Modal
                size='lg'
                isOpen={selectedVariation} >
                <ModalHeader>Edit {selectedVariation?.name?.en}</ModalHeader>
                <ModalBody>
                    <Row>
                        {[
                            { key: 'picture', title: 'Cover Photo', width: '12', type: 'picture' },
                            { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                            { key: 'details', title: 'Details', width: '12', type: 'quill', translateButton: true },
                            { key: 'price', title: 'Price (AED)', width: '4', type: 'number' },
                            { key: 'min_count', title: 'Minimum Count', width: '4', type: 'number' },
                            { key: 'max_count', title: 'Maximuim Count', width: '4', type: 'number' },

                            { key: 'sessions_duration', title: 'Sessions Duration (Mins)', hide: !['consultation', 'procedures', 'vitamin_therapy'].includes(activeItem?.general?.serviceType), width: '12', type: 'number' },

                            { key: 'variation_categories', title: 'Test Categories', width: '12', type: 'multiselect', selected: 'variations_categories', formatCreateLabel: 'Add New Category', hide: !['lab_test'].includes(activeItem?.general?.serviceType), data: variations_categories },
                            { key: 'test_type', title: 'Test Type', width: '12', type: 'select', hide: !['lab_test'].includes(activeItem?.general?.serviceType), data: [{ id: 'individual', value: 'Individual Test', label: 'Individual Test' }, { id: 'package', value: 'Package', label: 'Package' }] },
                            { key: 'test_parameters', title: 'Test Parameters', width: '12', type: 'multiselect', selected: 'test_parameters', formatCreateLabel: 'Add New Parameter', hide: !['lab_test'].includes(activeItem?.general?.serviceType), data: test_parameters },
                            { key: 'tat', title: 'Time to get results (HOURS)', width: '12', hide: !['lab_test'].includes(activeItem?.general?.serviceType), type: 'number' },

                            { key: 'ingredients', title: 'Ingredients', width: '12', type: 'multiselect', selected: 'drip_ingredients', formatCreateLabel: 'Add New Ingredients', hide: !['vitamin_therapy'].includes(activeItem?.general?.serviceType), data: drip_ingredients },
                            {
                                key: 'ageGroup', title: 'Age Group', width: '6', type: 'multiselect',
                                hide: !['lab_test', 'vitamin_therapy'].includes(activeItem?.general?.serviceType),
                                data: [
                                    { id: 'Infant = 0 - 1 year', value: 'Infant = 0 - 1 year', label: 'Infant = 0 - 1 year' },
                                    { id: 'Toddler = 2-4 yrs', value: 'Toddler = 2-4 yrs', label: 'Toddler = 2-4 yrs' },
                                    { id: 'Child = 5 - 12 yrs', value: 'Child = 5 - 12 yrs', label: 'Child = 5 - 12 yrs' },
                                    { id: 'Teen = 13 - 19 yrs', value: 'Teen = 13 - 19 yrs', label: 'Teen = 13 - 19 yrs' },
                                    { id: 'Adult = 20 - 39 yrs', value: 'Adult = 20 - 39 yrs', label: 'Adult = 20 - 39 yrs' },
                                    { id: 'Middle Age Adult = 40 - 59 yrs', value: 'Middle Age Adult = 40 - 59 yrs', label: 'Middle Age Adult = 40 - 59 yrs' },
                                    { id: 'Senior Adult = 60 +', value: 'Senior Adult = 60 +', label: 'Senior Adult = 60 +' },
                                ]
                            },
                            { key: 'gender', title: 'Gender', type: 'multiselect', width: '6', hide: !['lab_test', 'vitamin_therapy'].includes(activeItem?.general?.serviceType), data: [{ id: 'male', value: 'Male', label: 'Male' }, { id: 'female', value: 'Female', label: 'Female' }] },

                            { key: 'conditions', title: 'Conditions Related', width: '6', type: 'multiselect', selected: 'conditions', formatCreateLabel: 'Add New Condition', hide: !['lab_test', 'vitamin_therapy'].includes(activeItem?.general?.serviceType), data: conditions },
                            { key: 'symptoms', title: 'Symptoms Related', width: '6', type: 'multiselect', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', hide: !['lab_test', 'vitamin_therapy'].includes(activeItem?.general?.serviceType), data: symptoms },

                            // {key: 'vatExcluded', title: 'Exclude VAT', width: '4', type: 'switch' },
                        ].map((item, index) => {
                            return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                        <div>{item.title}</div>
                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: selectedVariation?.[item.key], type: item.type })}>Translate</div>}
                                    </div>
                                    {item.type === 'picture' ?
                                        <ImageUploader
                                            className={'w-100'}
                                            withIcon={true}
                                            name={item.key}
                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                            buttonText='Choose images'
                                            onChange={(picture) => onDrop(picture, item.key)}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={15728640}
                                            withPreview={true}
                                            singleImage={true}
                                            defaultImages={selectedVariation?.pictures?.length ? [selectedVariation?.pictures?.[0]?.base64] : (selectedVariation && selectedVariation[item.key])}
                                        /> :
                                        item.type === 'switch' ? <Switch
                                            className="custom-switch custom-switch-primary"
                                            checked={selectedVariation[item.key]}
                                            onChange={switchCheckedPrimary => {
                                                setSelectedVariation({ ...selectedVariation, [item.key]: switchCheckedPrimary, updated: true })

                                            }}
                                        /> : item.type === 'quill' ? <ReactQuill
                                            theme="snow"
                                            value={selectedVariation?.[item.key]?.en || ''}
                                            onChange={(e) => {
                                                setSelectedVariation({ ...selectedVariation, [item.key]: { ...selectedVariation?.[item.key], en: e }, updated: true })
                                            }}
                                            modules={quillModules}
                                            formats={quillFormats}
                                        /> : item.type === 'multiselect' ?
                                            <Creatable
                                                isMulti
                                                style={{ width: '100%' }}
                                                placeholder={item?.title}
                                                components={{ Input: CustomSelectInput, }}
                                                className={`basic-multi-select`}
                                                value={item?.data?.map(rec => {
                                                    return (selectedVariation?.[item.key]?.includes(rec.id)) ?
                                                        ({ ...rec, value: rec?.name?.en || rec?.name || rec?.value, label: rec?.name?.en || rec?.name || rec?.label, key: rec.id }) : ''
                                                })}
                                                options={[{ value: 'All', label: 'All', key: 'all' }, ...(item?.data?.map(rec => {
                                                    return ({ ...rec, value: rec?.name?.en || rec?.name || rec?.value, label: rec?.name?.en || rec?.name || rec?.label, key: rec.id })
                                                }) || [])]}

                                                onChange={(val) => {
                                                    setSelectedVariation({ ...selectedVariation, [item.key]: val?.find(r => r.key === 'all') ? item?.data?.map(r => r.key || r.id) : val.map(rec => rec.key), updated: true })
                                                }}
                                                isValidNewOption={() => { return item?.formatCreateLabel ? true : false }}
                                                formatCreateLabel={() => item?.formatCreateLabel || undefined}
                                                onCreateOption={() => {
                                                    setNewHelper(item)
                                                }}
                                            />
                                            : item.type === 'select' ? <Select
                                                components={{ Input: CustomSelectInput }}
                                                className="basic-multi-select"
                                                placeholder={item.title}
                                                isClearable
                                                value={item?.data?.find(rec => selectedVariation?.[item.key] === (rec.key || rec.id))}
                                                options={item?.data}
                                                onChange={val => {
                                                    setSelectedVariation({ ...selectedVariation, [item.key]: val?.key || val?.id || '', updated: true })
                                                }}
                                            /> : <Input
                                                type={item.type}
                                                placeholder={"e.g. " + item.title}
                                                value={item.translateButton ? selectedVariation?.[item.key]?.en : selectedVariation?.[item.key]}
                                                className="customInput"
                                                onChange={(e) => {
                                                    if (item.translateButton) {
                                                        setSelectedVariation({ ...selectedVariation, [item.key]: { ...selectedVariation?.[item.key], en: e.target.value }, updated: true })
                                                    } else {
                                                        setSelectedVariation({ ...selectedVariation, [item.key]: e.target.value, updated: true })
                                                    }
                                                }} />}
                                </Colxx>
                            )
                        })}
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => setSelectedVariation(false)}>Cancel</Button>
                    <Button
                        disabled={checkIfDisabled(JSON.parse(JSON.stringify(selectedVariation)), ['name', 'details', 'price'])}
                        color='primary ' className='m-1' onClick={() => {
                            submit(selectedVariation.id)
                        }}>Save</Button>
                </ModalFooter>
            </Modal>}
            <Modal backdrop="static" isOpen={deleteItem}>
                <ModalHeader >
                    Delete {deleteItem?.name?.en || deleteItem?.name}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            <Label className="mb-2">Are you sure you want to delete {deleteItem?.name?.en || deleteItem?.name}?</Label>
                        </Colxx>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setDeleteItem(false)
                    }}>Dismiss</Button>
                    <Button color='danger' onClick={() => {
                        deleteItemFunc(deleteItem)
                    }}>Delete</Button>
                </ModalFooter>

            </Modal>
            {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                setSelectedVariation({ ...selectedVariation, [translateObject.key]: e, updated: true })
                showTranslatePopup(false)
            }} />
            }
            {newHelper ? <NewHelper
                title={newHelper?.formatCreateLabel}
                selected={newHelper?.selected}
                updateHelpers={(e) => {
                    let ids = selectedVariation?.[newHelper.key] || []
                    ids.push(e.id)
                    setSelectedVariation({ ...selectedVariation, [newHelper.key]: ids, updated: true })
                    setNewHelper(false)
                }}
                dismiss={() => setNewHelper(false)}
                newHelper={newHelper}
            /> : ''}
        </Card>

    );
};

export default Service_Variation;