
import {

    GET_PARTNERS,

} from '../actions';

const INIT_STATE = {
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PARTNERS:
            return { ...state, allPartners: action.payload };

        default: return { ...state };
    }
}