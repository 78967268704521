import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    CustomInput,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";


import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";

import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import Resizer from 'react-image-file-resizer';
import { firestore, functions } from '../../../../helpers/Firebase'

import Services from "../../../../components/common/services";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import { ReverseGeocode, checkIfDisabled, createNotification, uploadFile, uploadPicture } from "../../../../helpers/Utils";
import Users from "../../../../components/common/users";
import TranslatePopup from "../../../../components/common/translatePopup";


const refs = {
    map: undefined,
}
const MapWithAMarker = withScriptjs(
    withGoogleMap(props => {
        return (
            <GoogleMap
                onDragEnd={() => {
                    props.getAddressFromLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })
                }}

                ref={(ref) => refs.map = ref}
                defaultZoom={15}
                options={{ disableDefaultUI: true }}
                onCenterChanged={() => {
                    props.updateLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })
                }}
                onClick={(e) => props.updateLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
                center={props.latLng || {
                    lat: 25.1903,
                    lng: 55.2747,
                }}
                defaultCenter={props.latLng || {
                    lat: 25.1903,
                    lng: 55.2747,
                }}>
                <SearchBox
                    style={{ left: '200px' }}
                    ref={(ref) => refs.searchBox = ref}
                    controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                    onPlacesChanged={props.onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            marginTop: `27px`,
                            marginLeft: `27px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                        }}
                    />
                </SearchBox>
                <Marker position={props.latLng} />

            </GoogleMap>
        )
    }

    )
);

const Provider = ({
    authUser,
    dismiss,
}) => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const { categories } = useSelector(state => state.settings)

    const { activeItem } = useSelector(state => state.activeItem)

    const dispatch = useDispatch()
    const [providerPictures, setProviderPictures] = useState([])
    const [providerLogo, setProviderLogo] = useState([])
    const [updatedProvider, UpdatedProvider] = useState(activeItem?.general || {})
    const [translateObject, showTranslatePopup] = useState(false)

    const [tabs] = useState([
        { name: 'Facility Information', key: 1 },
        { name: 'Admins', key: 2 },
        { name: 'Services Categories', key: 3 },
        { name: 'Professionals', key: 4 },])

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedProvider }))
        // eslint-disable-next-line
    }, [updatedProvider])

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    switch (type) {
                        case 'picture':
                            setProviderPictures(pictures)
                            break;
                        case 'logo':
                            setProviderLogo(pictures)
                            break;

                        default:
                            break;
                    }
                },
                'blob'
            );

        } else {
            switch (type) {
                case 'picture':
                    setProviderPictures(pictures)
                    break;
                case 'logo':
                    setProviderLogo(pictures)
                    break;

                default:
                    break;
            }

            UpdatedProvider({ ...updatedProvider, [type]: [], updated: true })
        }
    }

    const submit = async () => {
        // let { general } = JSON.parse(JSON.stringify(activeItem));
        let { general } = activeItem;

        let id = general.id || firestore().collection('providers').doc().id;
        if (general) {
            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            delete general.password
            general.deactivated = false
            general.lastUpdate = Date.now()

            if (providerPictures && providerPictures.length > 0 && providerPictures[0].name) {
                uploadPicture(providerPictures, 'providers', id, 'picture')
            }
            if (providerLogo && providerLogo.length > 0 && providerLogo[0].name) {
                uploadPicture(providerLogo, 'providers', id, 'logo')
            }
            if (general.tradeLicense?.files) {
                uploadFile(general.tradeLicense?.files, 'providers', updatedProvider?.id, 'tradeLicense')
                delete general.tradeLicense?.files

            }
            if (general.medicalLicense?.files) {
                uploadFile(general.medicalLicense?.files, 'providers', updatedProvider?.id, 'medicalLicense')
                delete general.medicalLicense?.files

            }
            await firestore().collection('providers').doc(id).set(general, { merge: true })
        }
        ['professionals', 'users', 'variations'].map(async (item) => {
            let data = activeItem?.[item];
            if (data && Object.values(data).length) {
                let updatedData = Object.values(data).filter(r => r.updated)
                await Promise.all(updatedData.map(async rec => {
                    let Ref = firestore().collection('providers').doc(id).collection(item)
                    let documentID = rec?.id || Ref.doc().id;
                    if (rec.deleted) {
                        if ((item === 'professionals' || item === 'users') && rec?.email) {
                            const deleteUser = functions().httpsCallable('deleteAccount');
                            await deleteUser({ uid: documentID, parent_id: id })
                            await Ref.doc(documentID).set(rec, { merge: true });

                        } else {
                            await Ref.doc(documentID).delete();
                        }
                    } else {
                        if (rec.addNew && rec?.email) {
                            rec.active = true
                            rec.created = Date.now()
                            const createUser = functions().httpsCallable('createAccounts');
                            let result = await createUser({ uid: documentID, email: rec.email, pw: rec.password, role: item === 'users' ? 'admin' : 'professional', parent_id: id, phoneNumber: rec?.phoneNumber || '' })

                            if (result?.data?.errorInfo) {
                                return createNotification("error", result?.data?.errorInfo?.message, 'Error creating user ' + rec.email)
                            }
                        }
                        delete rec.addNew
                        delete rec.updated
                        delete rec.password

                        rec.lastUpdate = Date.now()

                        if (item === 'users' || item === 'professionals') {
                            rec.provider_id = id
                            rec.provider_name = general?.name
                            rec.deactivated = false
                        } else {
                            delete rec.label
                            delete rec.value
                            delete rec.key
                        }
                        if (rec.pictures && rec?.pictures?.length > 0 && rec?.pictures?.[0]?.name) {
                            uploadPicture(rec.pictures, 'providers', id, 'picture', 'professionals', documentID)
                            delete rec.pictures
                        }
                        await Ref.doc(documentID).set(rec, { merge: true });
                    }

                }))
            }
        })
        dispatch(SetActiveItem(false))
        setLoading(false)
    }

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedProvider?.addNew ? 'New Provider' : updatedProvider?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return !item.hide && (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {[{
                                                key: 'generalInfo', title: 'Facility Information',
                                                data: [
                                                    { key: 'picture', title: 'Provider Picture', width: '5', type: '' },
                                                    { key: 'logo', title: 'Provider Logo', width: '5', type: '' },
                                                    { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                                                    { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
                                                    { key: 'categories', title: 'Provider Categories', width: '10', type: '' },
                                                ]
                                            },
                                            {
                                                key: 'addressInfo', title: 'Address Information',
                                                data: [
                                                    { key: 'address', title: 'Address', width: '10', type: 'text', translateButton: true },
                                                    { key: 'area', title: 'Area', width: '10', type: 'text', translateButton: true }
                                                ]
                                            }, {
                                                key: 'documents', title: 'Documents',
                                                data: [
                                                    { key: 'medicalLicense', title: 'Medical License', width: '10', type: 'dropzone' },
                                                    { key: 'tradeLicense', title: 'Trade License', width: '10', type: 'dropzone' },
                                                ]
                                            }].map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>
                                                        {rec.key === 'addressInfo' &&
                                                            <Colxx xxs="10" className="mb-4">
                                                                {
                                                                    <MapWithAMarker

                                                                        onPlacesChanged={() => {
                                                                            let locations = refs.searchBox.getPlaces()[0]
                                                                            let address = locations?.address_components?.reduce((value, currentValue) => {
                                                                                if (currentValue.types.includes("neighborhood")) {
                                                                                    value.area = currentValue.short_name
                                                                                }
                                                                                if (!value.area && currentValue.types.includes("sublocality")) {
                                                                                    value.area = currentValue.short_name
                                                                                }
                                                                                return value
                                                                            }, {})
                                                                            let location = locations?.geometry?.location
                                                                            let latLng = { lat: location.lat(), lng: location.lng() }
                                                                            UpdatedProvider({ ...updatedProvider, address: { ...updatedProvider.address, latLng: latLng, area: { ...updatedProvider.address?.area, en: address?.area, }, address: { ...updatedProvider.address?.address, en: locations?.formatted_address } }, updated: true })

                                                                        }}
                                                                        updateLatLng={(latLng) => UpdatedProvider({ ...updatedProvider, address: { ...updatedProvider.address, latLng: latLng }, updated: true })}
                                                                        getAddressFromLatLng={async (latLng) => {
                                                                            try {
                                                                                let response = await ReverseGeocode(latLng)
                                                                                const address = response?.results?.[0]?.formatted_address;
                                                                                let area;
                                                                                for (let i = 0; i < response.results[0].address_components.length; i++) {
                                                                                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                                                                                        switch (response.results[0].address_components[i].types[j]) {
                                                                                            case "neighborhood":
                                                                                                area = response.results[0].address_components[i].short_name;
                                                                                                break;
                                                                                            case "sublocality":
                                                                                                area = response.results[0].address_components[i].short_name;
                                                                                                break;
                                                                                            default:
                                                                                                break
                                                                                        }
                                                                                    }
                                                                                }
                                                                                UpdatedProvider({ ...updatedProvider, address: { ...updatedProvider.address, latLng: latLng, area: { ...updatedProvider.address?.area, en: area }, address: { ...updatedProvider.address?.address, en: address } }, updated: true })


                                                                            } catch (error) {
                                                                                console.log('error', error);

                                                                            }

                                                                        }}
                                                                        latLng={updatedProvider?.address?.latLng}
                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o&v=3.exp&libraries=geometry,drawing,places&language=en"
                                                                        loadingElement={<div className="map-item" />}
                                                                        containerElement={<div className="map-item" />}
                                                                        mapElement={<div className="map-item" />} />
                                                                }

                                                            </Colxx>}
                                                        {rec.data.map((item, index) => {
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                        <div>{item.title}</div>
                                                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: rec.key === 'addressInfo' ? updatedProvider?.address?.[item.key] : updatedProvider?.[item.key], type: item.type })}>Translate</div>}
                                                                    </div>
                                                                    {item.key === 'picture' || item.key === 'logo' ?
                                                                        <ImageUploader
                                                                            className={'w-100'}
                                                                            withIcon={true}
                                                                            name={item.key}
                                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                            buttonText='Choose images'
                                                                            onChange={(picture) => onDrop(picture, item.key)}
                                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                            maxFileSize={15728640}
                                                                            withPreview={true}
                                                                            singleImage={true}
                                                                            defaultImages={updatedProvider && updatedProvider[item.key]}
                                                                        /> : item.key === 'categories' ?
                                                                            <Select
                                                                                isMulti
                                                                                components={{ Input: CustomSelectInput }}
                                                                                className="basic-multi-select"
                                                                                placeholder={item.title}
                                                                                value={categories.map(rec => {
                                                                                    return (updatedProvider?.[item.key]?.includes(rec.id)) ?
                                                                                        ({ ...rec, value: rec?.name?.en || rec.name, label: rec?.name?.en || rec.name, key: rec.id }) : ''
                                                                                })}
                                                                                options={categories.map(rec => {
                                                                                    return ({ ...rec, value: rec?.name?.en || rec.name, label: rec?.name?.en || rec.name, key: rec.id })
                                                                                })}
                                                                                onChange={val => {
                                                                                    UpdatedProvider({ ...updatedProvider, [item.key]: val.map(rec => rec.key), updated: true })
                                                                                }}
                                                                            />
                                                                            : item.key === 'tradeLicense' || item.key === 'medicalLicense' ?
                                                                                <InputGroup>
                                                                                    <CustomInput
                                                                                        type="file"
                                                                                        id="exampleCustomFileBrowser4"
                                                                                        name="customFile"
                                                                                        label={updatedProvider?.[item.key]?.name}
                                                                                        onChange={async ({ target: { files } }) => {
                                                                                            const [{ name }] = files;
                                                                                            UpdatedProvider({ ...updatedProvider, [item.key]: { name: name, files: files }, updated: true })
                                                                                        }}
                                                                                    />
                                                                                    <InputGroupAddon addonType="append">
                                                                                        <Button outline color="danger" onClick={() => {
                                                                                            UpdatedProvider({ ...updatedProvider, [item.key]: {}, updated: true })


                                                                                        }}>
                                                                                            <i className="glyph-icon simple-icon-trash" />
                                                                                        </Button>
                                                                                    </InputGroupAddon>
                                                                                    {updatedProvider?.[item.key]?.license ? <InputGroupAddon addonType="append">
                                                                                        <Button outline color="primary" onClick={() => {
                                                                                            window.open(updatedProvider?.[item.key]?.license, '_blank')
                                                                                        }}>
                                                                                            <i className="glyph-icon simple-icon-eye" />
                                                                                        </Button>
                                                                                    </InputGroupAddon> : ''}
                                                                                </InputGroup>
                                                                                : <Input
                                                                                    type={item.type}
                                                                                    placeholder={"e.g. " + item.title}
                                                                                    value={rec.key === 'addressInfo' ? updatedProvider?.address?.[item.key]?.en : updatedProvider?.[item.key]?.en}
                                                                                    className="customInput"
                                                                                    onChange={(e) => {
                                                                                        if (rec.key === 'addressInfo') {
                                                                                            UpdatedProvider({ ...updatedProvider, address: { ...updatedProvider.address, [item.key]: { ...updatedProvider.address?.[item.key], en: e.target.value, } }, updated: true })
                                                                                        } else {
                                                                                            UpdatedProvider({ ...updatedProvider, [item.key]: { ...updatedProvider?.[item.key], en: e.target.value }, updated: true })
                                                                                        }
                                                                                    }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Users type={'users'} title={'Users'} parent={'providers'} />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Services section="provider" />
                                </TabPane>
                                <TabPane tabId={4}>
                                    <Users type={'professionals'} title={'Professionals'} parent={'providers'} />
                                </TabPane>

                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedProvider)), ['name', 'details'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        if (translateObject.key === 'address' || translateObject.key === 'area') {
                            UpdatedProvider({ ...updatedProvider, address: { ...updatedProvider.address, [translateObject.key]: e }, updated: true })
                        } else {
                            UpdatedProvider({ ...updatedProvider, [translateObject.key]: e, updated: true })
                        }
                        showTranslatePopup(false)
                    }} />
                    }
                </Fragment >
            ))
    );
};

export default Provider;