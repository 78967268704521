import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ActiveHelper from "./activeHelper";
import Services_categories from "./services_categories";

const Users = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/helpers`} />

      {/* Health Conditions */}
      <Route path={`${match.url}/health_conditions/symptoms`} render={() => <ActiveHelper type={"symptoms"} title="Symptoms" selected='symptoms' />} />
      <Route path={`${match.url}/health_conditions/signs`} render={() => <ActiveHelper type={"signs"} title="Signs" selected='signs' />} />
      <Route path={`${match.url}/health_conditions/conditions`} render={() => <ActiveHelper type={"conditions"} title="Conditions" selected='conditions' />} />

      {/* Services */}
      <Route path={`${match.url}/services/services_categories`} component={Services_categories} />
      <Route path={`${match.url}/services/variations_categories`} render={() => <ActiveHelper type={"variations_categories"} title="Variations Categories" selected='variations_categories' />} />
      <Route path={`${match.url}/services/addons`} render={() => <ActiveHelper type={"addons"} title="Session Addons" selected='addons' />} />
      <Route path={`${match.url}/services/drip_ingredients`} render={() => <ActiveHelper type={"drip_ingredients"} title="Drip Ingredients" selected='drip_ingredients' />} />

      {/* Lab */}
      <Route path={`${match.url}/lab/test_parameters`} render={() => <ActiveHelper type={"test_parameters"} title="Test Parameters" selected='test_parameters' />} />
      <Route path={`${match.url}/lab/sample_types`} render={() => <ActiveHelper type={"sample_types"} title="Sample Types" selected='sample_types' />} />
      <Route path={`${match.url}/lab/organs`} render={() => <ActiveHelper type={"organs"} title="Organs" selected='organs' />} />


      {/* Pharmacy */}
      <Route path={`${match.url}/pharmacy/active_substances`} render={() => <ActiveHelper type={"active_substances"} title="Active Substances" selected='active_substances' />} />
      <Route path={`${match.url}/pharmacy/medicines`} render={() => <ActiveHelper type={"medicines"} title="Medicines" selected='medicines' />} />

      {/* Professionals */}
      <Route path={`${match.url}/professionals/specialities`} render={() => <ActiveHelper type={"specialities"} title="Specialities" selected='specialities' />} />
      <Route path={`${match.url}/professionals/professionals_types`} render={() => <ActiveHelper type={"types"} title="Professionals Types" selected='types' />} />
      <Route path={`${match.url}/professionals/languages`} render={() => <ActiveHelper type={"languages"} title="Languages" selected='languages' />} />
      <Route path={`${match.url}/professionals/nationalities`} render={() => <ActiveHelper type={"nationalities"} title="Nationalities" selected='nationalities' />} />


      {/* Providers */}
      <Route path={`${match.url}/providers/provider_categories`} render={() => <ActiveHelper type={"categories"} title="Categories" selected='categories' />} />
      <Route path={`${match.url}/providers/brands`} render={() => <ActiveHelper type={"brands"} title="Groups" selected='brands' />} />




      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Users;
