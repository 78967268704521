import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/app-check';

import { firebaseConfig } from '../constants/defaultValues'

firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
appCheck.activate('6LdtVtolAAAAANcSWWv9VzwzlztmUaWmkrhbhiEz', true)
const auth = firebase.auth;
const firestore = firebase.firestore;
const storage = firebase.storage;
const functions = firebase.functions;
// functions().useEmulator('localhost', 3000)

export {
   auth,
   firebase,
   firestore,
   storage,
   functions
};
