import {
    GET_PROVIDERS,
    GET_PROFISSIONALS
} from '../actions';


export const GetAllProviders = (data) => {
    return (
        {
            type: GET_PROVIDERS,
            payload: data
        }
    )
}


export const GetAllProfessionals = (data) => {
    return (
        {
            type: GET_PROFISSIONALS,
            payload: data
        }
    )
}

