
import {
    GET_REQUESTS,
    GET_APPOINTMENTS,
    GET_DELAYED_APPOINTMENTS,
    GET_ASSIGNED_REQUESTS
} from '../actions';

const INIT_STATE = {
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REQUESTS:
            return { ...state, requests: action.payload };
        case GET_ASSIGNED_REQUESTS:
            return { ...state, assignedRequests: action.payload };
        case GET_APPOINTMENTS:
            return { ...state, appointments: action.payload };
        case GET_DELAYED_APPOINTMENTS:
            return { ...state, delayedAppointments: action.payload };
        default: return { ...state };
    }
}