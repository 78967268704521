
import {
    GET_ACTIVE_ITEM,
    UPDATE_ACTIVE_ITEM,
} from '../actions';

const INIT_STATE = {
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_ACTIVE_ITEM:
            return { ...state, activeItem: action.payload };
        case UPDATE_ACTIVE_ITEM:
            let { key, val } = action.payload
            return { ...state, activeItem: { ...state.activeItem, [key]: val } };

        default: return { ...state };
    }
}