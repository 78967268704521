import moment from "moment";
import React, { Fragment } from "react";
import { getFirstCharacters } from "../../helpers/Utils";

const MessageCard = ({ sender, item, currentUserid, sameSender }) => {
  return (
    <Fragment>
      <div
        className={`d-flex mb-3  align-self-${item?.from !== 'metadoc' ? "start" : "end"
          }`}
        style={item?.from !== 'metadoc' ? { marginRight: '20%' } : { marginLeft: '20%' }}
      >
        {!sameSender ? <div
          style={{
            background: item?.from !== 'metadoc' ? '#3498db' : 'rgb(143 0 166)',
            padding: '5px',
            color: '#fff',
            width: '40px',
            height: '40px',
            minWidth: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
            borderRadius: '200px',
            letterSpacing: '.06em'
          }}
          className="mr-3"
        >{getFirstCharacters(sender?.name || 'Metadoc Team')}</div> :
          <div
            style={{
              padding: '5px',
              width: '40px',
              height: '40px',
              minWidth: '40px',
            }}
            className="mr-3"
          />}
        <div >
          <div
            style={{
              // background: item?.from !== 'metadoc' ? '#FFFFFF' : '#3498db',
              background: '#FFFFFF',
              // color: item?.from !== 'metadoc' ? '#303030' : '#FFFFFF',
              padding: '.5rem 1rem',
              width: '100%',
              borderRadius: '8px',
              borderBottomLeftRadius: !sameSender ? '0px' : '4px'
            }}
            className={`d-inline-block`}
          >
            <p className="mb-0">{item?.text?.body || item?.button?.text}</p>
          </div>
          <div className="text-extra-small mt-2" style={{ textAlign:item?.from !== 'metadoc' ? "start" : "end", color: '#8094ae', fontWeight: '500' }}>{`${sender?.name ? sender?.name + ' - ' : ''}  ${moment(item?.created).format('DD/MM/YYYY hh:mm A')} ${(item?.status) ? ' - ' + (item?.status).toUpperCase() : ''}`}</div>

        </div>
      </div>
      <div className="clearfix" />
    </Fragment>
  );
};

export default MessageCard;
