import {
    CHANGE_LOCALE,
    GET_BRANDS,
    GET_CATEGORIES,
    GET_CONDITIONS,
    GET_SERVICES,
    GET_ADDONS,
    GET_SPECIALITIES,
    SET_SEARCH,
    GET_SYMPTOMS,
    GET_SIGNS,
    GET_TYPES,
    GET_PROMOS,
    GET_VARIATIONS_CATEGORIES,
    GET_LANGUAGES,
    GET_NATIONALITIES,
    GET_DRIP_INGREDIENTS,
    GET_TEST_PARAMETERS,
    GET_SAMPLE_TYPES,
    GET_ORGANS,
    GET_ACTIVE_SUBSTANCES,
    GET_SUBSCRIPTIONS
} from '../actions';


export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const GetAllServices = (data) => {
    return (
        {
            type: GET_SERVICES,
            payload: data
        }
    )
}
export const GetAllAddons = (data) => {
    return (
        {
            type: GET_ADDONS,
            payload: data
        }
    )
}
export const GetAllVariationsCategories = (data) => {
    return (
        {
            type: GET_VARIATIONS_CATEGORIES,
            payload: data
        }
    )
}
export const GetAllSepcialities = (data) => {
    return (
        {
            type: GET_SPECIALITIES,
            payload: data
        }
    )
}

export const GetAllConditions = (data) => {
    return (
        {
            type: GET_CONDITIONS,
            payload: data
        }
    )
}
export const GetAllCategories = (data) => {
    return (
        {
            type: GET_CATEGORIES,
            payload: data
        }
    )
}
export const GetAllBrands = (data) => {
    return (
        {
            type: GET_BRANDS,
            payload: data
        }
    )
}
export const GetAllSymptoms = (data) => {
    return (
        {
            type: GET_SYMPTOMS,
            payload: data
        }
    )
}
export const GetAllSigns = (data) => {
    return (
        {
            type: GET_SIGNS,
            payload: data
        }
    )
}
export const GetAllLanguages = (data) => {
    return (
        {
            type: GET_LANGUAGES,
            payload: data
        }
    )
}
export const GetAllNationalities = (data) => {
    return (
        {
            type: GET_NATIONALITIES,
            payload: data
        }
    )
}
export const GetAllTypes = (data) => {
    return (
        {
            type: GET_TYPES,
            payload: data
        }
    )
}
export const GetAllPromos = (data) => {
    return (
        {
            type: GET_PROMOS,
            payload: data
        }
    )
}
export const GetAllSubscriptions = (data) => {
    return (
        {
            type: GET_SUBSCRIPTIONS,
            payload: data
        }
    )
}
export const setSearch = (data) => {
    return (
        {
            type: SET_SEARCH,
            payload: data
        }
    )
}

export const GetAllDripIngredients = (data) => {
    return (
        {
            type: GET_DRIP_INGREDIENTS,
            payload: data
        }
    )
}
export const GetAllTestParameters = (data) => {
    return (
        {
            type: GET_TEST_PARAMETERS,
            payload: data
        }
    )
}
export const GetAllSampleTypes = (data) => {
    return (
        {
            type: GET_SAMPLE_TYPES,
            payload: data
        }
    )
}
export const GetAllOrgans = (data) => {
    return (
        {
            type: GET_ORGANS,
            payload: data
        }
    )
}
export const GetAllActiveSubstances = (data) => {
    return (
        {
            type: GET_ACTIVE_SUBSTANCES,
            payload: data
        }
    )
}