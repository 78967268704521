import {
    CHAT_GET_CONTACTS,
    CHAT_CHANGE_CONVERSATION,
    USER_FROM_REQUESTS,
    CHAT_GET_CONVERSATIONS,
    CHAT_DELETE_MESSAGE
} from '../actions';

export const getContacts = (data) => ({
    type: CHAT_GET_CONTACTS,
    payload: data
})
export const getChatMessages = (data) => ({
    type: CHAT_GET_CONVERSATIONS,
    payload: data
})

export const deleteChatMessage = (data) => ({
    type: CHAT_DELETE_MESSAGE,
    payload: data
})


export const setSelectedUser = (data) => ({
    type: CHAT_CHANGE_CONVERSATION,
    payload: data
});

export const setUserFromRequests = (data) => ({
    type: USER_FROM_REQUESTS,
    payload: data
});

