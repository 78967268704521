import React, { Fragment, useEffect, useState } from "react";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import ReactTable from "react-table";
import Switch from "rc-switch";
import DataTablePagination from "../DatatablePagination";
import { Colxx } from "./CustomBootstrap";
import ListPageHeading from "../../containers/navs/ListPageHeading";
import { firestore } from "../../helpers/Firebase";
import moment from 'moment'
import { useSelector } from "react-redux";
import { deviceWidth } from "../../helpers/Utils";

const MainPage = ({
    title,
    selected,
    activeSwitchKey,
    data,
    setSelectedItem,

}) => {

    const search = useSelector(state => state.settings.search) || ''
    const { user } = useSelector(state => state.authUser)

    const [deleteItem, setDeleteItem] = useState(false)
    const [filteredItems, setFilteredItems] = useState({})
    const dataColumns = [
        { Header: "Status", accessor: "active", width: 100, type: 'switch', hide: title === 'Advanced Reports' },
        { Header: "", accessor: "picture", width: 100, type: 'img', hide: title === 'Subscriptions' },
        { Header: "Name", accessor: "name", type: 'label' },
        { Header: "Balance", accessor: "balance", type: 'balance', hide: title !== 'Advanced Reports' },
        { Header: "Service Provider", accessor: "provider_name", type: 'label', hide: title !== 'Professionals' },
        { Header: "", width: 80, accessor: "delete", func: (e) => setDeleteItem(e), color: "danger", title: 'Delete', type: 'button', hide: (user?.role !== 'superAdmin' && title === "Providers") || (user?.role !== 'superAdmin' && title === "Clients") || title === 'Advanced Reports' },
        { Header: "", width: 80, accessor: "edit", func: (e) => onClickItem(e), color: "primary", title: title === 'Advanced Reports' ? 'View' : 'Edit', type: 'button' },
    ]

    const offersDataColumns = [
        { Header: "Status", accessor: "active", width: 100, type: 'switch' },
        { Header: "Code", accessor: "code", type: 'label' },
        { Header: "Number of Promos", accessor: "count", type: 'label' },
        { Header: "Discount Value", accessor: "discount.value", type: 'label' },
        { Header: "Maximum Value", accessor: "cap", type: 'label' },
        { Header: "Created", accessor: "created", type: 'date' },
        { Header: "Expires", accessor: "endDate", type: 'date' },
        { Header: "Auto Applied", accessor: "global", type: 'label', hide: title === 'Affiliates' },
        { Header: "", width: 80, accessor: "delete", func: (e) => setDeleteItem(e), color: "danger", title: 'Delete', type: 'button', hide: user?.role !== 'superAdmin' },
        { Header: "", width: 70, accessor: "edit", func: (e) => onClickItem(e), color: "primary", title: 'Edit', type: 'button' },
    ]
    const dataTableColumns = () => {
        let array = (selected === 'offers' ? offersDataColumns : dataColumns).map(rec => {
            return (
                {
                    Header: rec.Header,
                    accessor: rec.accessor,
                    width: deviceWidth() === 'desktop' ? (rec.width || 'auto') : 110,
                    resizable: false,
                    show: rec.hide ? false : true,
                    Cell: props => <div className="list-item-heading" style={{ width: '100%' }}>
                        {rec.type === 'switch' ? <Switch
                            className="custom-switch custom-switch-primary"
                            checked={activeSwitchKey === 'loyalty' ? props.original?.settings?.loyalty?.active : props.value}
                            onChange={switchCheckedPrimary => {
                                if (activeSwitchKey === 'professionals') {
                                    firestore().collection('providers').doc(props.original.provider_id).collection(selected).doc(props.original.id).update({ active: switchCheckedPrimary, lastUpdate: Date.now() })
                                } else if (activeSwitchKey === 'loyalty') {
                                    firestore().collection(selected).doc(props.original.id).collection('settings').doc('loyalty').set({ active: switchCheckedPrimary, lastUpdate: Date.now() }, { merge: true }).then(() => {
                                        firestore().collection(selected).doc(props.original.id).update({ lastUpdate: Date.now() })
                                    })
                                } else {
                                    firestore().collection(selected).doc(props.original.id).update({ active: switchCheckedPrimary, lastUpdate: Date.now() })
                                }

                            }}
                        /> : rec.type === 'balance' ?
                            <div className="list-item-heading" style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                lineClamp: 2,
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}>AED {Number(props.value).toFixed(2)}</div>
                            : rec.type === 'img' ?
                                <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={(props.value && props.value[0] && props.value[0])} alt={props.value} /></div>
                                : rec.type === 'label' ?
                                    <div className="list-item-heading" style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        lineClamp: 2,
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                    }}>{props.value?.en || props.value || (rec.Header === "Assigned Professional" ? "Unassigned" : "--")}</div>
                                    : rec.type === 'payment' ?
                                        <div className="list-item-heading" style={{ color: props.value ? '#3e884f' : "#c43d4b", fontSize: '11px', fontWeight: '700' }}>{props.value ? 'Paid' : "Not Paid"}</div>
                                        : rec.type === 'date' ? (
                                            <div className="list-item-heading" >{moment(props.value).format('DD/MM/YYYY')}</div>
                                        ) : rec.type === 'button' ?
                                            <div className="providerfieldText" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                                                <Button
                                                    color={rec.color}
                                                    size="sm"
                                                    outline
                                                    className="top-right-button"
                                                    onClick={() => {
                                                        rec.func(props.original)
                                                    }}
                                                >
                                                    {rec.title}
                                                </Button>
                                            </div>
                                            : ''
                        }
                    </div>
                }
            )
        })
        return array
    }
    const deleteItemFunc = (item) => {
        if (selected === 'professionals') {
            firestore().collection('providers').doc(item.provider_id).collection(selected).doc(item.id).update({ deactivated: true, lastUpdate: Date.now(), active: false }).then(() => {
                setDeleteItem(false)
            })
        } else {
            firestore().collection(selected).doc(item.id).update({ deactivated: true, lastUpdate: Date.now(), active: false }).then(() => {
                setDeleteItem(false)
            })
        }
    }

    const toggleModal = () => {
        setSelectedItem({ addNew: true })
    };


    const onClickItem = (item) => {
        setSelectedItem(item || {})
    };


    useEffect(() => {
        if (selected !== 'offers') {
            setFilteredItems(data && data.filter(item => {
                return (item?.name?.en || item?.name) && (item?.name?.en || item?.name).toLowerCase().includes(search?.toLowerCase())
            }))
        } else if (selected === 'offers') {
            setFilteredItems(data)
        }
        // eslint-disable-next-line 
    }, [search, data, selected])


    return (
        !data && !data.length ? <div className="loading" /> :
            <Fragment>
                <div className={"disable-text-selection"}>
                    <ListPageHeading
                        heading={title}
                        toggleModal={toggleModal}
                        hide={(title === 'Loyalty') || (title === 'Advanced Reports') || ((title === 'Providers') && user.role !== 'superAdmin') || ((title === 'Clients') && user.role !== 'superAdmin')}
                    />

                    <Row className="survey-app">
                        <Colxx xxs="12">
                            {
                                filteredItems && filteredItems.length ?
                                    <ReactTable
                                        data={filteredItems}
                                        columns={dataTableColumns()}
                                        defaultPageSize={10}
                                        pageSize={filteredItems.length < 10 ? filteredItems.length : 10}
                                        showPageJump={false}
                                        showPageSizeOptions={false}
                                        PaginationComponent={DataTablePagination}
                                        className='custuomReactTable'
                                        defaultSorted={[
                                            {
                                                id: "created",
                                                desc: true
                                            }
                                        ]}
                                    />
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', width: '100%' }}>No {title}</div>
                            }
                        </Colxx>
                    </Row>

                    <Modal backdrop="static" isOpen={deleteItem}>
                        <ModalHeader >
                            Delete {deleteItem?.name?.en || deleteItem?.name}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Colxx xxs="12" className="mb-4">
                                    <Label className="mb-2">Are you sure you want to delete {deleteItem?.name?.en || deleteItem?.name}?</Label>
                                </Colxx>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {
                                setDeleteItem(false)
                            }}>Dismiss</Button>
                            <Button color='danger' onClick={() => {
                                deleteItemFunc(deleteItem)
                            }}>Delete</Button>
                        </ModalFooter>

                    </Modal>
                </div>

            </Fragment>);
};

export default MainPage;