import React from "react";
import Professional from "./components/professional";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { SetActiveItem } from "../../../redux/activeItem/actions";

const Professionals = () => {
  const dispatch = useDispatch();
  const { allProfessionals } = useSelector(state => state.providers)
  const { activeItem } = useSelector(state => state.activeItem)

  const { user } = useSelector(state => state.authUser)


  const selectProfessional = async (professionalData) => {
    let post = { general: professionalData };
    dispatch(SetActiveItem(post))
  }

  return (
    <div>
      {
        activeItem ?
          <Professional
            authUser={user}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          /> : <MainPage
            title="Professionals"
            selected='professionals'
            activeSwitchKey='professionals'
            data={allProfessionals || []}
            setSelectedItem={(e) => selectProfessional(e)}
          />

      }
    </div>
  );
};


export default Professionals;
