import React, { useEffect, useState } from "react";
import { Row, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import { firestore, functions } from '../../../../helpers/Firebase'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import Switch from "rc-switch";
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { createDynamicLink, createNotification } from "../../../../helpers/Utils";
const Promo = ({
    dismiss,
    affiliate
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { activeItem } = useSelector(state => state.activeItem)
    const { services, offers } = useSelector(state => state.settings)
    const [variations, setVariations] = useState([])
    const [affiliateType, setAffiliateType] = useState(activeItem?.general?.affiliate?.gift ? 'points' : 'discount')
    const [pointsGift] = useState(activeItem?.general?.affiliate?.gift || false)
    const { allPartners } = useSelector(state => state.partners)
    const [updatedPromo, UpdatePromo] = useState(activeItem?.general || {})

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedPromo }))
        // eslint-disable-next-line
    }, [updatedPromo])

    const submit = async () => {
        let { general } = JSON.parse(JSON.stringify(activeItem));

        let id = general.id || firestore().collection('offers').doc().id;
        if (general) {
            if (general.addNew) {
                general.active = true
                general.global = general.global || false
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            general.deactivated = false
            general.lastUpdate = Date.now()
            if (affiliateType === 'points') {
                general.limited = 1
            } else {
                delete general?.affiliate?.gift
                delete general?.affiliate?.exp
            }
            if (affiliate) {
                let createCustomer = functions().httpsCallable('CreateAffilaiate');
                let customer = await createCustomer({ phoneNumber: general?.affiliate?.phoneNumber, displayName: general?.affiliate?.name });
                if (customer?.data?.uid) {

                    general.affiliate.uid = customer?.data?.uid
                    
                    if (general?.affiliate?.gift && !general?.affiliate?.link) {
                        let link = await createDynamicLink(customer?.data?.uid, general?.affiliate?.gift)
                        general.affiliate.link = link
                    } else if (pointsGift && general?.affiliate?.link && (Number(general?.affiliate?.gift) !== Number(pointsGift))) {
                        let link = await createDynamicLink(customer?.data?.uid, general?.affiliate?.gift)
                        general.affiliate.link = link
                    }
                }
                if (customer?.data?.errorInfo) {
                    setLoading(false)

                    return createNotification("error", customer?.data?.errorInfo?.message, "Error")
                }
            }
            if (general.discount && general.cap) {
                general.discount.cap = general.cap
            }

            await firestore().collection('offers').doc(id).set(general, { merge: true })
            if (general.global) {
                offers.map(async item => {
                    return (
                        item.id !== general.id && (
                            await firestore().collection('offers').doc(item.id).update({
                                global: false,
                                lastUpdate: Date.now(),
                            }, { merge: true })
                        )
                    )
                })
            }
        }

        dispatch(SetActiveItem(false))
        setLoading(false)
    }

    useEffect(() => {
        async function fetchData() {
            let serviceList = (updatedPromo?.appliedOn?.services)?.map(r => r.service_id)
            if (serviceList && serviceList.length) {
                let vari = await Promise.all(serviceList.map(async (currentVal) => {
                    let snap = await firestore().collection('services').doc(currentVal).collection('variations').get()
                    let data = snap.docs.map(item => ({
                        ...item.data(),
                        id: item.id,
                    }))
                    return data
                }))
                setVariations(vari?.reduce((acc, val) => acc.concat(val), []) || [])
            }
        }
        fetchData();
    }, [updatedPromo.appliedOn]);
    const copy = (entryText) => {
        navigator.clipboard.writeText(entryText);
        createNotification("success", "Copied to clipboard", entryText)
    }
    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Modal wrapClassName="modal-right" backdrop="static" isOpen={activeItem} >
                    <ModalHeader >
                        <h1>{updatedPromo?.addNew ? `New ${affiliate ? 'Affiliate' : 'Promocode'}` : `Edit ${affiliate ? 'Affiliate' : 'Promocode'}`}</h1>

                    </ModalHeader>
                    <ModalBody>
                        <Colxx xxs="12">
                            {[{
                                key: 'affiliateInfo',
                                title: 'Affiliate Information',
                                hide: !affiliate,
                                data: [
                                    { key: 'name', parent: 'affiliate', title: 'Affiliate Name', width: '12', type: 'text' },
                                    { key: 'phoneNumber', parent: 'affiliate', title: 'Affiliate Phone Number', width: '12', type: 'number' },
                                    { key: 'email', parent: 'affiliate', title: 'Affiliate Email', width: '12', type: 'email' },
                                    { key: 'cashback', parent: 'affiliate', title: 'Cashback Percent (%)', width: '12', type: 'number' },
                                    { key: 'code', title: 'Affiliate Code', width: '12', type: 'text' },
                                    { key: 'link', parent: 'affiliate', title: 'Affiliate Referral Link', width: '12', type: 'text', hide: affiliateType !== 'points' || !updatedPromo?.affiliate?.link, copy: true },
                                ]
                            },
                            {
                                key: 'generalInfo',
                                title: affiliate ? 'Gift Information' : 'General Information',
                                data: [
                                    { key: 'giftType', title: 'Gift Type', width: '12', type: 'giftType', hide: !affiliate },

                                    { key: 'gift', parent: 'affiliate', title: 'Gift Points', width: '12', type: 'number', hide: !affiliate || affiliateType !== 'points' },
                                    { key: 'exp', parent: 'affiliate', title: 'Points Expiration (Days)', width: '12', type: 'number', hide: !affiliate || affiliateType !== 'points' },

                                    { key: 'global', title: 'Auto Applied', width: '12', type: 'switch', hide: affiliate },
                                    { key: 'code', title: 'Enter Promo Code', width: '12', type: 'text', hide: affiliate },
                                    { key: 'discount', title: 'Discount Value', width: '12', type: 'number', hide: affiliateType === 'points' },
                                    { key: 'cap', title: 'Maximum Value (AED)', width: '12', type: 'number', hide: affiliateType === 'points' },
                                    { key: 'count', title: 'Number Of Promos', width: '12', type: 'number' },
                                    { key: 'endDate', title: 'Expiration Date', width: '12', type: 'date', hide: affiliateType === 'points' },
                                    { key: 'limited', title: 'Limited Per User', width: '12', type: 'number', hide: affiliateType === 'points' },
                                    { key: 'services', title: 'Select Services', width: '12', type: 'select', data: [{ id: 'all', name: 'All Services' }, ...services], hide: updatedPromo?.appliedOn?.type === "All" || affiliateType === 'points' },
                                    { key: 'variations', title: 'Select Variations', width: '12', type: 'select', data: variations && variations.length ? [{ id: 'all', name: 'All Variations' }, ...variations] : [], hide: !updatedPromo?.appliedOn?.services || affiliateType === 'points' },
                                    { key: 'clients', title: 'Select Clients', width: '12', type: 'select', data: [{ id: 'all', name: 'All Clients' }, ...allPartners], hide: affiliateType === 'points' },
                                ]
                            }].map((rec, recIndex) => {
                                return !rec.hide && (
                                    <Row key={recIndex}>
                                        <Colxx xxs="12" className="mb-2">
                                            <h4>{rec.title}</h4>

                                        </Colxx>
                                        {rec.data.map((item, index) => {
                                            return !item.hide && (
                                                <Colxx key={index} xxs="12" md={item.width} className="mb-3">
                                                    {(item.type !== 'switch' && item.type !== 'giftType') && <div className="mb-2" >{item.title}</div>}

                                                    {item.key === 'discount' && <div className="mb-2" >
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            className="mr-2"
                                                            outline={updatedPromo?.discount?.unit !== 'percent'}
                                                            onClick={() => {
                                                                let discount = { ...updatedPromo.discount, unit: 'percent' }
                                                                UpdatePromo({ ...updatedPromo, discount, updated: true })

                                                            }}>
                                                            Percent (%)
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            outline={updatedPromo?.discount?.unit !== 'fixed'}
                                                            onClick={() => {
                                                                let discount = { ...updatedPromo.discount, unit: 'fixed' }
                                                                UpdatePromo({ ...updatedPromo, discount, updated: true })
                                                            }}>
                                                            Fixed Value (AED)
                                                        </Button>
                                                    </div>}
                                                    {item.type === 'giftType' ? <div className="mb-2 justify-content-between d-flex" >
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            className="mr-2"
                                                            style={{ flex: '1' }}
                                                            outline={affiliateType !== 'discount'}
                                                            onClick={() => {
                                                                setAffiliateType('discount')

                                                            }}>
                                                            Discount
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            size="xs"
                                                            style={{ flex: '1' }}
                                                            outline={affiliateType !== 'points'}
                                                            onClick={() => {
                                                                setAffiliateType('points')
                                                            }}>
                                                            Wallet Points
                                                        </Button>
                                                    </div> : item.type === 'switch' ?
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div style={{ width: '65%' }}>
                                                                <div style={{ marginBottom: '0' }}>Auto Applied:</div>
                                                                <p style={{ fontSize: '10px', color: 'gray', lineHeight: '14px', marginBottom: '0' }}>
                                                                    * Enabling this will disable it in all other promo codes
                                                                </p>
                                                            </div>
                                                            <Switch
                                                                className="custom-switch custom-switch-primary"
                                                                checked={updatedPromo?.[item.key]}
                                                                onChange={switchCheckedPrimary => {
                                                                    UpdatePromo({ ...updatedPromo, [item.key]: switchCheckedPrimary, updated: true })
                                                                }}
                                                            />
                                                        </div> : item.type === 'select' ?
                                                            <Select
                                                                isMulti
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select"
                                                                placeholder={item.title}
                                                                value={
                                                                    (item.key === 'services') ?
                                                                        item?.data?.map(rec => {
                                                                            return (updatedPromo?.appliedOn?.[item.key]?.map(r => r.service_id)?.includes(rec.id)) ?
                                                                                ({ ...rec, value: rec?.name?.en || rec?.name || rec, label: rec?.name?.en || rec?.name || rec, key: rec?.id || rec }) : ''
                                                                        })
                                                                        : (item.key === 'variations') ?
                                                                            item?.data?.map(rec => {
                                                                                return (updatedPromo?.appliedOn?.services?.find(r => r.service_id === rec.service_id)?.variations?.includes(rec.id)) ?
                                                                                    ({ ...rec, value: rec?.name?.en || rec?.name || rec, label: rec?.name?.en || rec?.name || rec, key: rec?.id || rec }) : ''
                                                                            })
                                                                            :
                                                                            item?.data?.map(rec => {
                                                                                return (updatedPromo?.appliedOn?.[item.key]?.includes(rec.id)) ?
                                                                                    ({ ...rec, value: rec?.name?.en || rec?.name || rec, label: rec?.name?.en || rec?.name || rec, key: rec?.id || rec }) : ''
                                                                            })
                                                                }
                                                                options={item?.data?.map(rec => {
                                                                    return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec?.id })
                                                                })}
                                                                onChange={val => {
                                                                    let allSelected = val.find(r => r.id === 'all')
                                                                    switch (item.key) {
                                                                        case 'services':
                                                                            {
                                                                                let appliedOn = { ...updatedPromo.appliedOn, [item.key]: (allSelected ? services : val)?.map(rec => ({ service_id: rec.key || rec.id, variations: updatedPromo?.appliedOn?.services?.find(r => r.service_id === (rec.key || rec.id))?.variations || [] })) }
                                                                                UpdatePromo({ ...updatedPromo, appliedOn, updated: true })
                                                                            }
                                                                            break;
                                                                        case 'variations':
                                                                            {
                                                                                let serv = updatedPromo.appliedOn?.services?.reduce((value, currentVal) => {
                                                                                    value.push({
                                                                                        service_id: currentVal.service_id,
                                                                                        variations: (allSelected ? variations : val)?.map(r => {
                                                                                            return (r.service_id === currentVal.service_id) ? r.id : false
                                                                                        }).filter(r => r)
                                                                                    })
                                                                                    return value
                                                                                }, [])

                                                                                let appliedOn = { ...updatedPromo.appliedOn, services: serv }
                                                                                UpdatePromo({ ...updatedPromo, appliedOn, updated: true })
                                                                            }
                                                                            break;

                                                                        case 'clients':
                                                                            {
                                                                                let appliedOn = { ...updatedPromo.appliedOn, [item.key]: (allSelected ? allPartners : val)?.map(rec => rec.key || rec.id) }
                                                                                UpdatePromo({ ...updatedPromo, appliedOn, updated: true })
                                                                            }
                                                                            break;

                                                                        default:
                                                                            let appliedOn = { ...updatedPromo.appliedOn, [item.key]: val?.map(rec => rec.key) }
                                                                            UpdatePromo({ ...updatedPromo, appliedOn, updated: true })
                                                                            break;
                                                                    }
                                                                }}
                                                            /> : item.key === 'phoneNumber' ?
                                                                <PhoneInput
                                                                    countryCodeEditable={false}
                                                                    regions={['middle-east', 'north-africa']}
                                                                    inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                                    className="customInput"
                                                                    country={'ae'}
                                                                    placeholder="e.g. +971xxxxxx"
                                                                    value={updatedPromo?.affiliate?.[item.key]}
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                        let affiliate = { ...updatedPromo.affiliate, [item.key]: formattedValue }
                                                                        UpdatePromo({ ...updatedPromo, affiliate, updated: true })
                                                                    }
                                                                    }
                                                                />
                                                                :
                                                                <InputGroup>
                                                                    <Input
                                                                        type={item.type}
                                                                        disabled={item.key === 'link'}
                                                                        placeholder={"e.g. " + item.title}
                                                                        value={(item.parent === 'affiliate') ? updatedPromo?.affiliate?.[item.key]
                                                                            : (item.type === 'date' ?
                                                                                moment(updatedPromo?.[item.key]).format('YYYY-MM-DD') :
                                                                                (item.key === 'discount' ?
                                                                                    updatedPromo?.[item.key]?.value :
                                                                                    updatedPromo?.[item.key]))}
                                                                        className="customInput"
                                                                        onChange={(e) => {
                                                                            if (item.parent === 'affiliate') {
                                                                                let affiliate = { ...updatedPromo.affiliate, [item.key]: e.target.value }
                                                                                UpdatePromo({ ...updatedPromo, affiliate, updated: true })

                                                                            } else {
                                                                                if (item.key === 'discount') {
                                                                                    let discount = { ...updatedPromo.discount, value: e.target.value }
                                                                                    UpdatePromo({ ...updatedPromo, discount, updated: true })

                                                                                } else {
                                                                                    UpdatePromo({ ...updatedPromo, [item.key]: item.type === 'date' ? moment(e.target.value).valueOf() : (e.target.value).toLowerCase(), updated: true })
                                                                                }
                                                                            }

                                                                        }} />
                                                                    {item.copy ? <InputGroupAddon addonType="append">
                                                                        <InputGroupText>
                                                                            <i style={{ fontSize: '18px', color: '#3498db', cursor: 'pointer' }} className="iconsminds-file-clipboard" onClick={() => copy(updatedPromo?.affiliate?.[item.key])} />
                                                                        </InputGroupText>

                                                                    </InputGroupAddon> : ''}
                                                                </InputGroup>
                                                    }
                                                </Colxx>
                                            )
                                        })}
                                    </Row>
                                )
                            })}

                        </Colxx>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'space-between' }}>

                        <Button
                            color="danger"
                            outline
                            size="lg"
                            onClick={() => dismiss()}>
                            Dismiss
                        </Button>
                        <Button
                            color="primary"
                            // disabled={!updatedPromo?.affiliate?.name || !updatedPromo?.affiliate?.phoneNumber}
                            size="lg"
                            onClick={() => {
                                setLoading(true)
                                submit()
                            }}>
                            Save Changes
                        </Button>
                    </ModalFooter>

                </Modal>
            ))
    );
};

export default Promo;