import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";

// import Dashboard from "./dashboard";
import Providers from './providers';
import Professionals from './professionals';
import ReferralPartners from './referral_partners';
import Requests from './bookings';
import Appointments from './bookings';
import CRM from './CRM'
import Marketing from './marketing'
import Helpers from './helpers'
import { SetActiveItem } from "../../redux/activeItem/actions";
import Chat from "./chat";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidUpdate = () => {
    const { activeItem } = this.props;

    if (activeItem && activeItem?.general && activeItem?.general?.updated) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  render() {
    const { match, authUser } = this.props;

    return (
      <AppLayout>
        <Switch>
          {authUser?.role === 'accountant' ?
            <Redirect exact from={`${match.url}/`} to={`${match.url}/operations/advanced_reports`} />
            :
            <Redirect exact from={`${match.url}/`} to={`${match.url}/bookings/requests`} />


          }
          {/* <Route path={`${match.url}/dashboard`} component={Dashboard} /> */}
          <Route path={`${match.url}/providers`} component={Providers} />
          <Route path={`${match.url}/professionals`} component={Professionals} />
          {(authUser?.role === 'superAdmin' || authUser?.role === 'concierge') && <Route path={`${match.url}/referral_partners`} component={ReferralPartners} />}
          <Route path={`${match.url}/bookings`} component={Requests} />
          <Route path={`${match.url}/bookings`} component={Appointments} />
          <Route path={`${match.url}/chat`} component={Chat} />

          <Route path={`${match.url}/operations`} component={CRM} />
          {authUser?.role === 'superAdmin' && <Route path={`${match.url}/marketing`} component={Marketing} />}
          {authUser?.role === 'superAdmin' && <Route path={`${match.url}/helpers`} component={Helpers} />}
          <Redirect to="/error" />
        </Switch>


      </AppLayout>
    );
  }
}

const mapStateToProps = ({ menu, authUser, activeItem }) => {
  const { containerClassnames } = menu;
  return {
    containerClassnames,
    authUser: authUser?.user,
    activeItem: activeItem?.activeItem
  };
};
const mapActionsToProps = (dispatch) => ({
  SetActiveItem: (data) => dispatch(SetActiveItem(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps

  )(App)
);
