import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { setSearch } from "../redux/actions";
import { SetActiveItem } from "../redux/activeItem/actions";

const ConfirmBox = ({
    visible,
    onCancel,
    onConfirm,
    activeItem,
    SetActiveItem
}) => {
    const confirm = () => {
        onCancel();
        onConfirm();
    };
    return (
        <>
            <Modal backdrop="static" isOpen={visible}>
                <ModalHeader >
                    Do you want to save the changes you made to {activeItem?.general?.name?.en || activeItem?.general?.name}?
                </ModalHeader>
                <ModalBody>
                    <Label className="mb-2">Your changes will be lost if you don't save them.</Label>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => {
                        onCancel()
                    }}>Stay On Page</Button>
                    <Button color='danger' onClick={() => {
                        SetActiveItem(false)
                        setSearch('')
                        confirm()
                    }}>Don't Save</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ activeItem }) => {
    return {
        activeItem: activeItem?.activeItem
    };
};
const mapActionsToProps = (dispatch) => ({
    SetActiveItem: (data) => dispatch(SetActiveItem(data)),
    setSearch: (data) => dispatch(setSearch(data)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps

    )(ConfirmBox)
);

