import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    CustomInput,
} from "reactstrap";
import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import Resizer from 'react-image-file-resizer';
import { firestore } from '../../../../helpers/Firebase'
import ServiceVariation from "./service_variation";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import { checkIfDisabled, uploadPicture } from "../../../../helpers/Utils";
import TranslatePopup from "../../../../components/common/translatePopup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
import { quillFormats, quillModules } from "../../../../constants/defaultValues";

const tabs = [{ name: 'General Information', key: 1 }, { name: 'Service Variations', key: 2 }, { name: 'Service Fees', key: 3 }]

const Service_category = ({
    dismiss,
}) => {
    const { specialities, services } = useSelector(state => state.settings)

    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [servicePictures, setServicePictures] = useState([])
    const [serviceIcon, setServiceIcon] = useState([])
    const { activeItem } = useSelector(state => state.activeItem)
    const [translateObject, showTranslatePopup] = useState(false)

    const dispatch = useDispatch()
    const [updatedService, UpdatedService] = useState(activeItem?.general || {})

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                (type === 'icon' ? 'png' : 'jpeg'),
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    switch (type) {
                        case 'picture':
                            setServicePictures(pictures)
                            break;
                        case 'icon':
                            setServiceIcon(pictures)
                            break;

                        default:
                            break;
                    }
                    UpdatedService({ ...updatedService, updated: true })

                },
                'blob'
            );

        } else {
            switch (type) {

                case 'picture':
                    setServicePictures(pictures)
                    break;
                case 'icon':
                    setServiceIcon(pictures)
                    break;

                default:
                    break;
            }
            UpdatedService({ ...updatedService, [type]: [], updated: true })
        }
    }

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedService }))
        // eslint-disable-next-line
    }, [updatedService])

    const submit = async () => {
        let { general, variations } = activeItem;

        let id = general.id || firestore().collection('services').doc().id;

        if (general) {
            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            delete general.allVariations
            delete general.variations
            general.deactivated = false
            general.lastUpdate = Date.now()

            if (servicePictures && servicePictures.length > 0 && servicePictures[0].name) {
                uploadPicture(servicePictures, 'services', id, 'picture')
            }

            if (serviceIcon && serviceIcon.length > 0 && serviceIcon[0].name) {
                uploadPicture(serviceIcon, 'services', id, 'icon')
            }

            await firestore().collection('services').doc(id).set(general, { merge: true })
        }

        if (variations && Object.values(variations).length) {
            let updatedVariations = Object.values(variations).filter(r => r.updated)
            await Promise.all(updatedVariations.map(async rec => {
                let Ref = firestore().collection('services').doc(id).collection('variations')
                let documentID = rec?.id || Ref.doc().id;
                if (rec.deleted) {
                    await Ref.doc(documentID).delete();
                } else {
                    delete rec.addNew
                    delete rec.updated
                    rec.lastUpdate = Date.now()
                    rec.service_id = id
                    rec.deactivated = false
                    if (rec.pictures && rec?.pictures?.length > 0 && rec?.pictures?.[0]?.name) {
                        uploadPicture(rec.pictures, 'services', id, 'picture', 'variations', documentID)
                        delete rec.pictures
                    }
                    await Ref.doc(documentID).set(rec, { merge: true });
                }

            }))
        }
        dispatch(SetActiveItem(false))
        setLoading(false)
    }
    console.log('updatedServiceupdatedService', updatedService);
    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedService?.addNew ? 'New Service' : updatedService?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.key === 2 ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item.name}
                                                    {Object.values(activeItem?.variations || {})?.filter(r => !r.deleted)?.length ?
                                                        <div style={{
                                                            fontSize: '10px',
                                                            color: '#3498db',
                                                            border: '1px solid #3498db',
                                                            borderRadius: '10px',
                                                            width: '18px',
                                                            height: '18px',
                                                            marginLeft: '8px',
                                                            fontWeight: '700',
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                        }}>{Object.values(activeItem?.variations || {})?.filter(r => !r.deleted)?.length}</div> : ''}
                                                </div> :
                                                    item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card className="rounded">
                                        <CardBody>
                                            <Row>
                                                <Colxx xxs="12" className="mb-2">
                                                    <h4>General Information</h4>
                                                </Colxx>
                                            </Row>
                                            <Row>
                                                {[{ key: 'picture', title: 'Service Cover' }, { key: 'icon', title: 'Service Icon' }].map((item, index) => {
                                                    return (
                                                        <Colxx key={index} xxs="12" md="4" className="mb-4">
                                                            <div className="mb-2" >{item.title}</div>
                                                            <ImageUploader
                                                                className={'w-100'}
                                                                withIcon={true}
                                                                name={item.key}
                                                                label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                buttonText='Choose images'
                                                                onChange={(picture) => onDrop(picture, item.key)}
                                                                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                maxFileSize={15728640}
                                                                withPreview={true}
                                                                singleImage={true}
                                                                defaultImages={updatedService && updatedService[item.key]}
                                                            />
                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>

                                            <Row>
                                                {[
                                                    { key: 'name', title: 'Name', width: '8', type: 'text', translateButton: true },
                                                    {
                                                        key: 'serviceType', title: 'Service Type', width: '8', data: [
                                                            { value: 'Consultation', label: 'Consultation', key: 'consultation', id: 'consultation' },
                                                            { value: 'Lab test', label: 'Lab test', key: 'lab_test', id: 'lab_test' },
                                                            { value: 'Vitamin therapy', label: 'Vitamin therapy', key: 'vitamin_therapy', id: 'vitamin_therapy' },
                                                            { value: 'Procedures', label: 'Procedures', key: 'procedures', id: 'procedures' },
                                                        ], type: 'select'
                                                    },
                                                    { key: 'details', title: 'Details', width: '8', type: 'quill', translateButton: true },
                                                    { key: 'actionBtnTitle', title: 'Action Botton Title', width: '8', type: 'text', translateButton: true },
                                                    { key: 'buffer', title: 'Buffer Time (minutes)', placeholder: '120 minutes', width: '8', type: 'number' },
                                                    { key: 'menuType', title: 'Menu Type', width: '8', data: [{ value: 'Searchable', label: 'Searchable', key: 'searchable', id: 'searchable' }], type: 'select' },

                                                ].map((item, index) => {
                                                    return (
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                <div>{item.title}</div>
                                                                {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: updatedService?.[item.key], type: item.type })}>Translate</div>}
                                                            </div>
                                                            {item.type === 'quill' ? <ReactQuill
                                                                theme="snow"
                                                                value={updatedService?.[item.key]?.en || ''}
                                                                onChange={(e) => {
                                                                    UpdatedService({ ...updatedService, [item.key]: { ...updatedService?.[item.key], en: e }, updated: true })
                                                                }}
                                                                modules={quillModules}
                                                                formats={quillFormats}
                                                            /> : item.type === 'select' ? <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select"
                                                                placeholder={item.title}
                                                                isClearable
                                                                value={item?.data?.find(rec => updatedService?.[item.key] === rec.id)}
                                                                options={item?.data}
                                                                onChange={val => {
                                                                    UpdatedService({ ...updatedService, [item.key]: val?.key || '', updated: true })
                                                                }}
                                                            /> : <Input
                                                                type={item.type}
                                                                placeholder={"e.g. " + (item?.placeholder || item?.title)}
                                                                value={updatedService?.[item.key]?.en || updatedService?.[item.key]}
                                                                className="customInput"
                                                                onChange={(e) => {
                                                                    if (item.translateButton) {
                                                                        UpdatedService({ ...updatedService, [item.key]: { ...updatedService?.[item.key], en: e.target.value }, updated: true })
                                                                    } else {
                                                                        UpdatedService({ ...updatedService, [item.key]: e.target.value, updated: true })

                                                                    }
                                                                }} />}
                                                            {item.key === 'actionBtnTitle' ?
                                                                <CustomInput
                                                                    className="custom-checkbox mb-0 mt-1 d-inline-block"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    checked={updatedService?.comingSoon}
                                                                    onChange={(e) => {
                                                                        UpdatedService({ ...updatedService, comingSoon: e.target.checked, updated: true })
                                                                    }}
                                                                    label={"Disable Button"}
                                                                />
                                                                : ''}

                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>

                                            <Row>
                                                {[
                                                    { key: 'related_services', title: 'Related Services', data: services?.filter(rec => rec.active) },
                                                    { key: 'related_specialities', title: 'Related Specialities', data: specialities }
                                                ].map((item, index) => {
                                                    return (
                                                        <Colxx key={index} xxs="12" md="8" className="mb-4">
                                                            <div className="mb-2" >{item.title}</div>
                                                            <Select
                                                                isMulti
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select"
                                                                placeholder={item.title}
                                                                value={item?.data?.map(rec => {
                                                                    return (updatedService?.[item.key]?.includes(rec.id)) ?
                                                                        ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id }) : ''
                                                                })}
                                                                options={item?.data?.map(rec => {
                                                                    return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                })}
                                                                onChange={val => {
                                                                    UpdatedService({ ...updatedService, [item.key]: val.map(rec => rec.key), updated: true })
                                                                }}
                                                            />
                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <ServiceVariation saveChanges={() =>
                                        UpdatedService({ ...updatedService, updated: true })
                                    } />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Card className="rounded">
                                        <CardBody>
                                            <Row>
                                                <Colxx xxs="12" className="mb-2">
                                                    <h4>Service Fees</h4>
                                                </Colxx>
                                            </Row>
                                            <Row>
                                                {[
                                                    { key: 'title', title: 'Service Title', width: '8', type: 'text', translateButton: true },
                                                    { key: 'details', title: 'Service Details', width: '8', type: 'text', translateButton: true },
                                                    { key: 'value', title: 'Service Fees', width: '8', type: 'number' },

                                                ].map((item, index) => {
                                                    return (
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                <div>{item.title}</div>
                                                                {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ parent: 'serviceFees', key: [item.key], value: updatedService?.serviceFees?.[item.key], type: item.type })}>Translate</div>}
                                                            </div>
                                                            <Input
                                                                type={item.type}
                                                                placeholder={"e.g. " + item.title}
                                                                value={item.key === 'value' ? updatedService?.serviceFees?.[item.key] : updatedService?.serviceFees?.[item.key]?.en}
                                                                className="customInput"
                                                                onChange={(e) => {
                                                                    let serviceFees = { ...updatedService.serviceFees, [item.key]: item.key === 'title' || item.key === 'details' ? { ...updatedService?.serviceFees?.[item.key], en: e.target.value } : e.target.value }
                                                                    UpdatedService({ ...updatedService, serviceFees, updated: true })
                                                                }} />

                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedService)), ['name', 'details'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        if (translateObject?.parent === 'serviceFees') {
                            let serviceFees = { ...updatedService.serviceFees, [translateObject?.key]: e }
                            UpdatedService({ ...updatedService, serviceFees, updated: true })
                            showTranslatePopup(false)

                        } else {
                            UpdatedService({ ...updatedService, [translateObject.key]: e, updated: true })
                            showTranslatePopup(false)
                        }

                    }} />
                    }
                </Fragment >
            ))
    );
};

export default Service_category;