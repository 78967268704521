import React from "react";
import AdvancedReports from "./components/advancedReports";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { SetActiveItem } from "../../../redux/activeItem/actions";

const Providers = () => {
    const dispatch = useDispatch();
    const { allProviders } = useSelector(state => state.providers)
    const { activeItem } = useSelector(state => state.activeItem)

    const { user } = useSelector(state => state.authUser)

   
   
    const selectPartner = async (partnerData) => {
        let post = { general: partnerData, settings: partnerData?.settings || {} };
        dispatch(SetActiveItem(post))
    }

    return (
        <div>
            {
                activeItem ?
                    <AdvancedReports
                        authUser={user}
                        selectedItem={activeItem}
                        dismiss={() => {
                            dispatch(SetActiveItem(false))
                        }}

                    /> : <MainPage
                        title="Advanced Reports"
                        selected='providers'
                        data={allProviders || []}
                        setSelectedItem={(e) => selectPartner(e)}
                    />


            }
        </div>
    );
};


export default Providers;
