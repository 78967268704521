import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Card,
    CardBody,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput,
    Table,
    InputGroup,
    InputGroupAddon,
    Alert,
    InputGroupText,
} from "reactstrap";

import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Select, { components } from "react-select";
import { Creatable } from 'react-select';
import PhoneInput from "react-phone-input-2";

import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { firestore, functions } from '../../../../helpers/Firebase'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { calcTotalCost, CheckLocationIsServed, formatAddress, getAvailableTimings, GetTimingSlots, makeid, SearchUsers, UpdateUser } from "../../../../helpers/Utils";
import AddressDetails from "../../../../components/common/AddressDetails";
import Customers from "../../../../components/common/customers";
import Addons from "../../../../components/common/addons";
import { debounce } from 'lodash'
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { MenuItem } from "@trendmicro/react-dropdown";

const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
);
const Booking = ({
    authUser,
    dismiss,
}) => {
    const { user } = useSelector(state => state.authUser)
    const { activeItem } = useSelector(state => state.activeItem)
    const [specialDiscount, setSpecialDiscount] = useState({ type: 'percent', val: '' })
    const [booking, updateBooking] = useState(activeItem?.general || {})
    const [bookingType, setBookingType] = useState('custom')
    const [loading, setLoading] = useState(false)
    const [filterTimings, setFilterTimings] = useState(user.role === 'superAdmin' ? false : true)

    const [loadingTimings, setLoadingTimings] = useState(false)
    const [loadingCustomers, setLoadingCustomers] = useState(false)
    const { services, addons } = useSelector(state => state.settings)
    const { allProviders } = useSelector(state => state.providers)

    const { allProfessionals } = useSelector(state => state.providers)
    const { allPartners } = useSelector(state => state.partners)
    const [timings, setTimings] = useState([])
    const [orderDate, setOrderDate] = useState(null)
    const [orderNumber] = useState(makeid())

    const [servicesObject, setServicesObject] = useState([])
    const [serviceChanged, setServiceChanged] = useState(false)
    const [addresDetails, setAddressDetails] = useState(false)
    const [existingUser, setExistingUser] = useState(false)
    const [customerLocations, setCustomerLocations] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState(false)
    const [editLocation, setEditLocation] = useState(false)
    const [servingAreas, setServingAreas] = useState([])
    const [locationVariations, setLocationVariations] = useState(false)
    const [editCustomer, setEditCustomer] = useState(false)
    const [editAddon, setCreateAddons] = useState(false)
    const [customers, setCustomers] = useState([])
    const [providerAddons, setProviderAddons] = useState([])

    const [medicines, setMedicines] = useState([])
    const [loadingMedicines, setLoadingMedicines] = useState(false)
    const [showConfirmationModal, SetShowConfirmationModal] = useState(false)

    const statusArray = {
        new: { sort: 0, label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept' },
        pending: { sort: 1, label: "Pending Assigment", status: 'pending', color: "#3498db", action: 'Accept' },
        accepted: { sort: 2, label: "Accepted", status: 'accepted', color: "#145388", action: 'On the way' },
        ontheway: { sort: 3, label: "On The Way", status: 'ontheway', color: "#3e884f", action: 'Arrive' },
        active: { sort: 4, label: "Session Started", status: 'active', color: "#3195a5", action: 'Complete' },
        completed: { sort: 5, label: "Completed", status: 'completed', color: "#3e884f", action: '' },
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: booking }))
        // eslint-disable-next-line
    }, [booking])

    useEffect(() => {
        async function fetchData() {
            // let partnerKey = booking?.brand?.url || 'app'
            let partnerKey = bookingType === 'existing' ? (booking?.brand?.url) : (user?.parent_id || 'app')

            if (partnerKey) {
                let partnerSnap = await firestore().collection('partners').where('url', '==', partnerKey.toLowerCase()).get()
                let data = { ...partnerSnap.docs?.[0]?.data(), id: partnerSnap.docs?.[0]?.id };
                firestore().collection('partners').doc(data?.id).collection('locations').onSnapshot(snap => {
                    let locationsData = snap?.docs?.map(rec => {
                        return ({ ...rec.data(), id: rec.id })
                    })
                    setServingAreas(locationsData)
                });
            }
            if (bookingType === 'existing') {
                let clientServ = services?.filter(rec => booking?.brand?.services?.includes(rec.id))
                let snap = await firestore().collection('partners').doc(booking?.brand?.id).collection('variations').get()
                let varis = snap.docs.reduce((val, currentVal) => {
                    val[currentVal.id] = {
                        ...currentVal.data(), id: currentVal.id, key: currentVal.id,
                        value: currentVal.data()?.name?.en || currentVal.data()?.name,
                        label: currentVal.data()?.name?.en || currentVal.data()?.name,
                    }
                    return val
                }, {})
                let servicesObject = clientServ?.reduce((value, currentVal) => {
                    value.push({
                        ...currentVal,
                        options: Object.values(varis)?.filter(rec => rec.service_id === currentVal?.id) || [],
                    })

                    return value
                }, [])
                return setServicesObject(servicesObject)


            } else {
                let client = allPartners?.find(r => r.id === (user?.brand_id || 'IlDSRL3CAgKT7XDF5fXp'))
                if (client.url !== 'app') {
                    let clientServ = client.url === 'app' ? services : services?.filter(rec => client?.services?.includes(rec.id))
                    let snap = await firestore().collection('partners').doc(user?.brand_id || 'IlDSRL3CAgKT7XDF5fXp').collection('variations').get()
                    let varis = snap.docs.reduce((val, currentVal) => {
                        val[currentVal.id] = {
                            ...currentVal.data(),
                            id: currentVal.id,
                            key: currentVal.id,
                            value: currentVal.data()?.name?.en || currentVal.data()?.name,
                            label: currentVal.data()?.name?.en || currentVal.data()?.name,
                            // pricing: {
                            //     topUp: "0",
                            //     total: locationVariations?.[currentVal.id] || currentVal.data().price,
                            //     val: locationVariations?.[currentVal.id] || currentVal.data().price,
                            // }
                        }
                        return val
                    }, {})

                    let servicesObject = clientServ?.reduce((value, currentVal) => {
                        value.push({
                            ...currentVal,
                            options: Object.values(varis)?.filter(rec => rec.service_id === currentVal?.id) || [],
                        })

                        return value
                    }, [])
                    return setServicesObject(servicesObject)

                } else {
                    let servicesObject = await services?.reduce(async (value, currentValue) => {

                        let snap = await firestore().collection('services').doc(currentValue?.id).collection('variations').get()
                        let varis = snap.docs.reduce((val, currentVal) => {
                            val[currentVal.id] = {
                                ...currentVal.data(),
                                id: currentVal.id,
                                key: currentVal.id,
                                value: currentVal.data()?.name?.en || currentVal.data()?.name,
                                label: currentVal.data()?.name?.en || currentVal.data()?.name,
                                pricing: {
                                    topUp: "0",
                                    total: locationVariations?.[currentVal.id] || currentVal.data().price,
                                    val: locationVariations?.[currentVal.id] || currentVal.data().price,
                                }
                            }
                            return val
                        }, {})

                        const accum = await value;

                        accum.push({
                            ...currentValue,
                            options: Object.values(varis)?.filter(rec => rec.service_id === currentValue?.id) || [],
                        })

                        return accum
                    }, [])

                    return setServicesObject(servicesObject)

                }



            }
        }
        fetchData();
    }, [booking.service, bookingType, locationVariations, booking.brand, services, user, allPartners]);

    const getLocationVariations = async (location) => {

        let partnerKey = bookingType === 'existing' ? (booking?.brand?.id) : (user.brand_id || 'IlDSRL3CAgKT7XDF5fXp')
        let locationVariations = false
        let clusterId;
        if (location?.latLng) {
            let latLng = location?.latLng
            let { cluster } = await CheckLocationIsServed(servingAreas, { latitude: latLng?.lat, longitude: latLng?.lng })
            clusterId = cluster
        }

        let locationSnap = await firestore().collection('partners').doc(partnerKey).collection('locations').doc(clusterId).get()
        locationVariations = locationSnap?.exists ? (locationSnap.data()?.variations || false) : false
        setLocationVariations(locationVariations)
        updateBooking({ ...booking, location, updated: true, items: {} })

    }
    useEffect(() => {
        async function fetchData() {
            let snap = await firestore().collection('providers').doc(booking?.provider?.id).collection('addons').get()
            let adds = snap.docs.reduce((val, currentVal) => {
                val[currentVal.id] = {
                    ...currentVal.data(),
                    id: currentVal.id,
                    key: currentVal.id,
                    value: currentVal.data()?.name?.en || currentVal.data()?.name,
                    label: currentVal.data()?.name?.en || currentVal.data()?.name,
                }
                return val
            }, {})
            setProviderAddons(adds)

            if (booking?.provider) {

                let variationSnap = await firestore().collection('providers').doc(booking?.provider?.id).collection('variations').where('deactivated', '!=', true).get()
                variationSnap.docs.filter(rec => Object.keys(booking?.items).includes(rec.id)).map(currentVal => {
                    let item = booking?.items

                    if (locationVariations && locationVariations?.[currentVal.id]) {
                        item[currentVal.id].price.providerFees = locationVariations?.[currentVal.id]
                    } else {
                        item[currentVal.id].price.providerFees = currentVal.data()?.providerPrice || 0
                    }
                    return item
                })

                let providerFees = Object.values(booking?.items).reduce((val, currentVal) => {
                    val = val + (Number(currentVal?.price?.providerFees || 0) * currentVal.count)
                    return val
                }, 0)
                let metadocCommission = Number(booking.receipt?.sub) - (Number(booking.receipt?.totalTopup || 0) || 0) - Number(providerFees || 0)

                let newReceipt = { ...booking?.receipt, metadocCommission: metadocCommission, providerFees: providerFees }
                updateBooking({
                    ...booking, updated: true, receipt: newReceipt
                })
            }
        }
        fetchData();

        // eslint-disable-next-line
    }, [booking?.provider?.id])
    useEffect(() => {
        async function fetchData() {

        }
        fetchData();
        // eslint-disable-next-line
    }, [booking?.provider])

    const getTimings = async (items) => {
        let result = await getAvailableTimings({ variationsKeys: Object.keys(items), activeProvidersSnap: allProviders })
        let data = JSON.parse(result);
        setTimings(data?.dates)
        setLoadingTimings(false)

    }
    const submit = async () => {
        let { general } = JSON.parse(JSON.stringify(activeItem));

        let id = firestore().collection('orders').doc().id;

        if (id) {
            general.created = Date.now()
            general.orderNumber = orderNumber
            general.type = 'offline'
            general.status = general?.status || (general?.provider?.id ? 'pending' : 'new')
            general[general.status] = Date.now()

            general.id = id
            general.candidates = general?.provider?.id ? [general?.provider?.id] : general.candidates
            delete general?.brand?.services
            delete general?.brand?.customLocations
            delete general.addNew
            if (user.role === 'concierge') {
                let client = allPartners?.find(r => r.id === (user?.brand_id || 'IlDSRL3CAgKT7XDF5fXp'))

                general.brand = {
                    id: client?.id,
                    name: client?.name?.en,
                    parent_id: client?.parent_id,
                    url: client?.url,
                }
                if (!client?.customLocations) {
                    general.location = client.address
                }

            }
            if (general?.addons && user.role === 'superAdmin') {
                let adns = JSON.parse(JSON.stringify(general?.addons))
                Object.keys(adns).map(async (val) => {
                    let addon = adns?.[val]
                    addon.active = true
                    addon.created = Date.now()
                    addon.lastUpdate = Date.now()
                    addon.deactivated = false
                    addon.id = val

                    if (addon.addNew) {
                        delete addon.addNew
                        delete addon.count
                        await firestore().collection('addons').doc(val).set(addon, { merge: true })
                    }
                    if (general?.provider?.id && !providerAddons[val]) {
                        await firestore().collection('providers').doc(general?.provider?.id).collection('addons').doc(val).set(addon, { merge: true })
                    }
                })
            }
            let createPaymentIntent = functions().httpsCallable('createPaymentIntentClient');
            let post = {
                id: id,
                amount: (general?.receipt?.total || 2) * 100,
                description: general?.service?.name?.en,
                ...general,
                host: `${bookingType === 'existing' ? (general?.brand?.url) : (user?.parent_id || 'app')}.metadocapp.com`
            }
            if (general?.receipt?.discount) {
                post.discount = {
                    code: general?.receipt?.code
                }
            }

            let paymentIntent = await createPaymentIntent(post);
            if (paymentIntent?.data?.status === 'error') {
                // eslint-disable-next-line 
                throw (paymentIntent?.data)
            }
            let results = paymentIntent?.data;
            general.paymentIntent = results?.id;
            general.provider_order_url = results?.provider_order_url || '--';
            general.orderUrl = results?.orderUrl;
            general.location.link = results?.mapUrl;
            general.customer_id = results?.userUID

            general.updatedBy = user.uid
            if (general?.receipt) {
                general.paymentStatus = "notPaid"
                general.receipt.outstanding = (general?.receipt?.total * 1)
                general.receipt.paid = 0
            }
            await firestore().collection('orders').doc(id).set(general, { merge: true })
            try {
                let post = {};
                // eslint-disable-next-line 
                ["email", 'phoneNumber'].map(r => {
                    if (general?.contact[r]) {
                        post[r] = general?.contact[r]
                    }
                })
                if (general?.contact?.name) {
                    post.displayName = general?.contact?.name
                }
                if (customerLocations && customerLocations?.length) {
                    post.locations = customerLocations
                }
                post.id = post.uid = results?.userUID
                let { error } = await UpdateUser({ ...existingUser, ...post })

                console.log('resultsresultsresults', error);

            } catch (error) {

            }

            dispatch(SetActiveItem(false))
            setLoading(false)
            SetShowConfirmationModal(false)

        }

    }
    const _filterPassedTime = () => {
        const availableTime = GetTimingSlots(timings?.find(rec => rec.weekDay === moment(orderDate).format('ddd')))
        const slots = [];
        availableTime.map(item => {
            let slot = item.start.valueOf();
            while (moment(slot).isBefore(item.end)) {
                slots.push(slot)
                slot = moment(slot).add(60, 'minutes').valueOf()
            }
            return item
        })
        return slots
    };
    const isInTimeRange = () => {
        const availableTime = GetTimingSlots(timings?.find(rec => rec.weekDay === moment(orderDate).format('ddd')))

        let inRange = availableTime.some(item => {

            let start = moment(item.start).set('year', moment(orderDate).year())
                .set('month', moment(orderDate).month())
                .set('date', moment(orderDate).date());
            let end = moment(item.end).set('year', moment(orderDate).year())
                .set('month', moment(orderDate).month())
                .set('date', moment(orderDate).date());

            end = (end.valueOf() < start.valueOf()) ? end.add(1, 'day') : end
            return moment(orderDate).isBetween(start, end) || moment(orderDate).isSame(start)

        })
        return inRange
    };

    useEffect(() => {
        if (booking?.items) {
            let receipt = calcTotalCost(booking?.items, false, booking?.addons, booking?.receipt)
            updateBooking({ ...booking, updated: true, receipt })
        }
        // eslint-disable-next-line
    }, [booking?.items, booking?.addons])
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span style={{ fontSize: '14px' }}>{data?.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    var debounce_fun = debounce(async function (e) {
        let customers = await SearchUsers(e)
        setCustomers(customers)
        setExistingUser(false)
        setCustomerLocations(false)
        let contact = {}
        updateBooking({ ...booking, contact, updated: true })
        setLoadingCustomers(false)

    }, 1000);
    var searchMedicines = debounce(async function (search) {
        const firstSnap = await firestore().collection('medicines').where('name', '>=', search.toLowerCase()).where('name', '<=', search.toLowerCase() + "\uf8ff").orderBy('name').limit(25).get();
        let medicines = firstSnap.docs.map(async res => {
            return {
                ...res.data(),
                key: res.id,
            }

        })
        Promise.all(medicines).then((ress) => {
            ress = ress?.filter(o => !Object.keys(booking?.items || {})?.includes(o?.sku))
            setMedicines(ress)
            setLoadingMedicines(false)
        })
    }, 1000);

    useEffect(() => {
        if (booking?.service?.id === 'pharmacy') {
            async function fetchData() {
                const firstSnap = await firestore().collection('medicines').orderBy('name').limit(15).get();
                let medicines = firstSnap.docs.map(async res => {
                    return {
                        ...res.data(),
                        key: res.id,
                    }
                })
                Promise.all(medicines).then((ress) => {
                    ress = ress?.filter(o => !Object.keys(booking?.items || {})?.includes(o?.sku))
                    setMedicines(ress)
                })
            }
            fetchData();
        }
        // eslint-disable-next-line
    }, [booking?.service])

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{booking?.addNew ? 'New Request' : booking.name}</h1>

                            <Card className="rounded mb-4">
                                <CardBody>

                                    <Row>
                                        <Colxx xxs="12" md="8">
                                            {
                                                booking?.addNew && user.role === 'superAdmin' && <Row>
                                                    <Colxx >
                                                        <Button
                                                            color="primary"
                                                            size="md"
                                                            className="mr-2"
                                                            outline={bookingType !== 'custom'}
                                                            onClick={() => {
                                                                setBookingType('custom')
                                                                updateBooking({ addNew: true })
                                                            }}>Custom Client</Button>
                                                        {/* </Colxx>
                                                    <Colxx xxs="4" md="3"> */}
                                                        <Button
                                                            color="primary"
                                                            size="md"
                                                            outline={bookingType !== 'existing'}
                                                            onClick={() => {
                                                                setBookingType('existing')
                                                                updateBooking({ addNew: true })
                                                            }}>Existing Client</Button>
                                                    </Colxx>
                                                </Row>
                                            }
                                            {[{
                                                key: 'clientInfo', title: 'Client Information',
                                                hide: bookingType !== 'existing',
                                                data: [
                                                    { key: 'brand', title: 'Select Client', width: '10', type: 'select', data: allPartners || [], value: booking?.brand, show: bookingType === 'existing' },
                                                ]
                                            },
                                            {
                                                key: 'customerInfo', title: 'Contact Information',
                                                hide: user.role === 'superAdmin',
                                                data: [
                                                    { key: 'name', parent: 'contact', title: 'Name', width: '10', type: 'text', show: true },
                                                    { key: 'phoneNumber', parent: 'contact', title: 'Number', width: '10', type: 'phoneNumber', show: true },
                                                    { key: 'email', parent: 'contact', title: 'Email', width: '10', type: 'email', show: true, optional: true },
                                                    { key: 'room', parent: 'contact', title: 'Flat / Villa / Room Number', width: '10', type: 'text', show: true },

                                                ]
                                            },
                                            {
                                                key: 'customerInfo', title: 'Contact Information',
                                                hide: user.role !== 'superAdmin',
                                                data: [
                                                    { key: 'customer', title: '', width: '10', type: 'creatable', placeholder: 'Select Customer', value: existingUser || '', formatCreateLabel: 'Add New Customer', data: customers, show: true },
                                                    { key: 'location', title: 'Select Location', width: '10', type: 'creatable', value: selectedLocation || '', formatCreateLabel: 'Add New Location', data: customerLocations, show: ((bookingType === 'custom' && allPartners?.find(r => r.id === (user?.brand_id || 'IlDSRL3CAgKT7XDF5fXp'))?.customLocations) || (bookingType === 'existing' && booking?.brand?.customLocations)) && existingUser },
                                                ]
                                            },
                                            {
                                                key: 'generalInfo', title: 'General Information',
                                                data: [
                                                    { key: 'room', parent: 'contact', title: 'Flat / Villa / Room Number', width: '10', type: 'text', show: bookingType === 'existing' && !booking?.brand?.customLocations },
                                                    { key: 'items', title: 'Services', width: '10', type: 'groupselect', data: servicesObject || [], show: true },
                                                    { key: 'addons', title: 'Addons', width: '10', type: 'creatable', value: '', formatCreateLabel: 'Add New Addon', data: addons?.filter(a => !Object.keys(booking?.addons || {}).includes(a.id)), show: user.role === 'superAdmin', },
                                                ]
                                            },
                                            {
                                                key: 'dateInfo', title: 'Date Information',
                                                data: [
                                                    { key: 'bookingDate', title: 'Select perferred Date and Time', width: '10', type: 'date', show: true },
                                                    { key: 'notes', title: 'Do you have any specific instructions?', width: '10', type: 'textarea', show: true, optional: true },
                                                ]
                                            },
                                            {
                                                key: 'providerInfo', title: 'Provider Information',
                                                hide: user.role !== 'superAdmin',

                                                data: [{ key: 'provider', title: 'Provider', width: '5', type: 'select', data: allProviders?.filter(rec => filterTimings ? booking?.candidates?.includes(rec.id) : true), value: booking?.provider, show: true, optional: filterTimings },
                                                { key: 'professional', title: 'Professional', width: '5', type: 'select', data: allProfessionals?.filter(rec => rec.provider_id === booking?.provider?.id) || [], value: booking?.professional, show: true, optional: true }]
                                            }].map((rec, recIndex) => {
                                                return !rec.hide && (
                                                    <Row key={recIndex}>
                                                        <Colxx xxs="12" className="mt-4 mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>

                                                        {rec.data.map((item, index) => {

                                                            let bufferTime = Number(services?.find(r => r.id === booking?.service?.id)?.buffer) || 60
                                                            const today = moment()

                                                            const bufferDateTime = today.add(bufferTime, 'minutes');
                                                            const isBefore = moment(orderDate).isBefore(bufferDateTime, 'day');
                                                            const isToday = moment(orderDate).isSame(bufferDateTime, 'day');
                                                            let minDate = bufferDateTime.toDate()
                                                            let minTime = isBefore ? moment(orderDate).endOf('day').toDate() : isToday ? bufferDateTime.toDate() : moment(orderDate).startOf('day').toDate();


                                                            return item.show && (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }} >
                                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                                                                            <div>{item.title}</div>
                                                                            {item.optional && <span
                                                                                style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                                                                className={`ml-1 badge badge-pill badge-light badge-top-left`}
                                                                            >
                                                                                Optional
                                                                            </span>}
                                                                        </div>
                                                                        {item.key === 'bookingDate' && user.role === 'superAdmin' ?
                                                                            <CustomInput
                                                                                className="custom-checkbox mb-0 mt-1 d-inline-block"
                                                                                type="checkbox"
                                                                                id="checkAll"
                                                                                checked={filterTimings}
                                                                                onChange={(e) => {
                                                                                    setFilterTimings(e.target.checked)
                                                                                    delete booking?.bookingDate
                                                                                    delete booking?.provider
                                                                                    delete booking?.professional
                                                                                    updateBooking({ ...booking, updated: true })
                                                                                }}
                                                                                label={<div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }}>Filter based on SP availability</div>}
                                                                            />
                                                                            : ''}
                                                                    </div>
                                                                    {item.type === 'select' ?
                                                                        <>
                                                                            <Select
                                                                                components={{
                                                                                    Input: CustomSelectInput,
                                                                                    Option: (props) => (
                                                                                        <components.Option {...props} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                                            <div>{props?.data?.label}</div>
                                                                                            {props?.data?.available && <span
                                                                                                style={{ fontWeight: '300', backgroundColor: '#3984db10', color: '#3984db' }}
                                                                                                className={`ml-1 badge badge-pill  badge-top-left`}
                                                                                            >
                                                                                                Active
                                                                                            </span>}
                                                                                        </components.Option>
                                                                                    )

                                                                                }}
                                                                                className="basic-multi-select"
                                                                                placeholder={item.title}
                                                                                value={item?.value ? { value: item?.value?.name?.en || item?.value?.name, label: item?.value?.name?.en || item?.value?.name, key: item?.value?.id } : ''}
                                                                                options={item.key === 'provider' ?
                                                                                    item?.data?.sort((a, b) => booking?.candidates?.includes(a.id) ? -1 : 1)?.map(rec => {
                                                                                        return !filterTimings && booking?.candidates?.includes(rec.id) && isInTimeRange() ?
                                                                                            ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, available: true, key: rec.id }) :
                                                                                            ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                                    }) :
                                                                                    item?.data?.map(rec => {
                                                                                        return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                                    })}
                                                                                styles={
                                                                                    {
                                                                                        option: (provided, state) => ({
                                                                                            ...provided,
                                                                                            justifyContent: state?.data?.available && 'space-between',
                                                                                            fontWeight: state?.data?.available && '700',
                                                                                        }),
                                                                                    }
                                                                                }
                                                                                onChange={val => {
                                                                                    let post = {}
                                                                                    if (item.key === 'brand') {
                                                                                        post.name = val.label
                                                                                        post.id = val.key
                                                                                        post.parent_id = val?.parent_id
                                                                                        post.services = val.services
                                                                                        post.url = val.url
                                                                                        post.customLocations = val?.customLocations || false
                                                                                        if (post.customLocations) {
                                                                                            updateBooking({ ...booking, updated: true, [item.key]: post })

                                                                                        } else {
                                                                                            updateBooking({ ...booking, updated: true, [item.key]: post, location: val.address })

                                                                                        }

                                                                                    } else {
                                                                                        post.name = val.label
                                                                                        post.id = val.key
                                                                                        updateBooking({ ...booking, [item.key]: post, updated: true })

                                                                                    }
                                                                                }}

                                                                            />

                                                                        </> : item.type === 'groupselect' ?
                                                                            <>
                                                                                <Select
                                                                                    formatGroupLabel={formatGroupLabel}
                                                                                    components={{
                                                                                        Input: CustomSelectInput,
                                                                                    }}
                                                                                    className="basic-multi-select"
                                                                                    placeholder={item.title}
                                                                                    value={booking?.service && { ...booking?.service, value: booking?.service?.name?.en || booking?.service?.name, label: booking?.service?.name?.en || booking?.service?.name, key: booking?.service.id }}
                                                                                    options={
                                                                                        item?.data?.map(rec => {
                                                                                            return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id, options: rec?.options?.filter(o => !Object.keys(booking?.items || {})?.includes(o?.id)) })
                                                                                        })}
                                                                                    onChange={val => {
                                                                                        let selectedService = services?.find(r => r?.id === val?.service_id)
                                                                                        const action = () => {
                                                                                            let post = {
                                                                                                ...booking.items,
                                                                                                [val.key]: {
                                                                                                    count: 1,
                                                                                                    name: val.name,
                                                                                                    price: val.pricing,
                                                                                                    service_id: val.service_id
                                                                                                },
                                                                                            }


                                                                                            getTimings(post)
                                                                                            delete booking?.bookingDate
                                                                                            setLoadingTimings(true)

                                                                                            let bookingPost = {
                                                                                                ...booking,
                                                                                                updated: true,
                                                                                                // [item.key]: post,

                                                                                                service: {
                                                                                                    name: selectedService.name,
                                                                                                    desc: selectedService.details,
                                                                                                    id: selectedService.id,
                                                                                                }
                                                                                            }
                                                                                            if (selectedService?.id !== 'pharmacy') {
                                                                                                bookingPost[item.key] = post
                                                                                            }
                                                                                            if (selectedService?.serviceFees) {
                                                                                                bookingPost.receipt = {
                                                                                                    ...booking?.receipt,
                                                                                                    serviceFees: selectedService?.serviceFees
                                                                                                }
                                                                                            }
                                                                                            updateBooking(bookingPost)
                                                                                        }
                                                                                        if (booking?.service?.id && (selectedService?.id !== booking?.service?.id)) {
                                                                                            setServiceChanged({
                                                                                                open: true,
                                                                                                action: action
                                                                                            })
                                                                                        } else {
                                                                                            action()
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {booking?.service?.id === 'pharmacy' &&
                                                                                    <div className={`mt-3`}>
                                                                                        <div className="mb-2">{'Pharmacy Items'}</div>
                                                                                        <Creatable
                                                                                            style={{ width: '100%' }}
                                                                                            isLoading={loadingMedicines}
                                                                                            placeholder={'Search Pharmacy Items'}
                                                                                            components={{
                                                                                                Input: CustomSelectInput,
                                                                                            }}
                                                                                            className={`basic-multi-select`}
                                                                                            value=''
                                                                                            options={
                                                                                                medicines?.filter(o => { return !Object.keys(booking?.items || {}).includes(o.key) })?.map(rec => {
                                                                                                    return ({ ...rec, value: rec?.name + (rec?.unit && ` (${rec?.unit})`), label: rec?.name + (rec?.unit && ` (${rec?.unit})`), key: rec.sku })
                                                                                                })}
                                                                                            onChange={val => {
                                                                                                let post = {
                                                                                                    ...booking.items,
                                                                                                    [val.sku]: {
                                                                                                        count: 1,
                                                                                                        name: val.label,
                                                                                                        price: {
                                                                                                            topUp: "0",
                                                                                                            total: val.price,
                                                                                                            val: val.price,
                                                                                                        },
                                                                                                        service_id: 'pharmacy'
                                                                                                    },
                                                                                                }
                                                                                                updateBooking({
                                                                                                    ...booking,
                                                                                                    updated: true,
                                                                                                    [item.key]: post,
                                                                                                })

                                                                                            }}
                                                                                            onInputChange={(e) => {
                                                                                                if (e.length > 2) {
                                                                                                    searchMedicines(e)
                                                                                                    setLoadingMedicines(true)
                                                                                                }
                                                                                            }}
                                                                                            isValidNewOption={() => { return item?.formatCreateLabel ? true : false }}
                                                                                            formatCreateLabel={() => item?.formatCreateLabel || undefined}

                                                                                        />
                                                                                    </div>}
                                                                                {item.key === 'items' && Object.keys(booking?.items || {})?.length ? <div className="mt-3">
                                                                                    <Table striped>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Name</th>
                                                                                                <th>Price</th>
                                                                                                <th style={{ textAlign: 'end' }}>Count</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {Object.keys(booking.items).map((r, ind) => {
                                                                                                let variations = servicesObject?.find(s => s?.id === booking?.service?.id)?.options
                                                                                                let clientVariations = variations?.reduce((val, currentVal) => {
                                                                                                    val[currentVal.id] = currentVal
                                                                                                    return val
                                                                                                }, {})
                                                                                                let selectedItem = booking?.items?.[r]
                                                                                                return (<tr key={ind} className="mt-1">
                                                                                                    <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{selectedItem?.name?.en || selectedItem?.name}</td>
                                                                                                    <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', width: '50%' }} >
                                                                                                        <InputGroup>
                                                                                                            <InputGroupAddon addonType='prepend'>
                                                                                                                <InputGroupText>AED</InputGroupText>
                                                                                                            </InputGroupAddon>
                                                                                                            <Input className="customInput"
                                                                                                                value={selectedItem?.price?.total || selectedItem?.price?.total === '' ? selectedItem?.price?.total : clientVariations?.[r]?.pricing?.total}
                                                                                                                placeholder={"e.g. 200 AED"}
                                                                                                                type="number"
                                                                                                                onChange={(e) => {
                                                                                                                    let newItems = JSON.parse(JSON.stringify(booking.items))

                                                                                                                    newItems[r].price.total = e.target.value
                                                                                                                    newItems[r].price.val = e.target.value

                                                                                                                    updateBooking({
                                                                                                                        ...booking, updated: true, items: newItems,
                                                                                                                    })
                                                                                                                }}
                                                                                                            />
                                                                                                            {booking?.service?.id !== 'pharmacy' && clientVariations?.[r]?.pricing?.total !== selectedItem?.price.total && (selectedItem?.price.total || selectedItem?.price.total === '') ? <InputGroupAddon addonType="append">
                                                                                                                <Button
                                                                                                                    onClick={() => {

                                                                                                                        let newItems = JSON.parse(JSON.stringify(booking.items))

                                                                                                                        newItems[r].price.total = clientVariations?.[r]?.pricing?.total
                                                                                                                        newItems[r].price.val = clientVariations?.[r]?.pricing?.total

                                                                                                                        updateBooking({
                                                                                                                            ...booking, updated: true, items: newItems,
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    outline style={{ background: '#fff', zIndex: '0' }} color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>

                                                                                                            </InputGroupAddon>
                                                                                                                : ''}
                                                                                                        </InputGroup>
                                                                                                        {/* {selectedItem?.price?.total} AED */}
                                                                                                    </td>
                                                                                                    <td className="d-flex align-items-center" style={{ justifyContent: 'flex-end', padding: '21px 0.75rem' }}>
                                                                                                        <i className="simple-icon-minus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                            let newItems = JSON.parse(JSON.stringify(booking.items))
                                                                                                            if (newItems[r].count > 1) {
                                                                                                                newItems[r].count = newItems[r].count - 1
                                                                                                            } else {
                                                                                                                delete newItems[r]
                                                                                                            }
                                                                                                            updateBooking({
                                                                                                                ...booking, updated: true, items: newItems,
                                                                                                            })

                                                                                                        }} />
                                                                                                        <div className="ml-2 mr-2" style={{ fontSize: '16px', fontWeight: '700' }}>{selectedItem.count}</div>
                                                                                                        <i className="simple-icon-plus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                            let newItems = JSON.parse(JSON.stringify(booking.items))
                                                                                                            newItems[r].count = newItems[r].count + 1
                                                                                                            updateBooking({
                                                                                                                ...booking,
                                                                                                                updated: true,
                                                                                                                items: newItems,
                                                                                                            })
                                                                                                        }} />
                                                                                                    </td>
                                                                                                </tr>)
                                                                                            })}

                                                                                        </tbody>
                                                                                    </Table>

                                                                                </div> : ''}
                                                                            </> :
                                                                            item.type === 'creatable' ?
                                                                                <div >
                                                                                    {item.key === 'customer' && existingUser ?
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                            <div>
                                                                                                <div style={{ fontWeight: '700' }}>{existingUser.name}</div>
                                                                                                <div className="text-muted" >{existingUser.phoneNumber} - {existingUser.email}</div>
                                                                                            </div>
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle btnStyle="link" noCaret>
                                                                                                    <i className="simple-icon-options" />
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu style={{ left: '-140px', width: '150px' }}>

                                                                                                    <MenuItem
                                                                                                        onSelect={() => {
                                                                                                            setEditCustomer({ ...existingUser, displayName: existingUser?.name, edit: true })
                                                                                                        }}
                                                                                                    >
                                                                                                        Edit
                                                                                                    </MenuItem>
                                                                                                    <MenuItem
                                                                                                        onSelect={() => {
                                                                                                            setExistingUser(false)
                                                                                                            setCustomerLocations(false)
                                                                                                            let contact = {}
                                                                                                            updateBooking({ ...booking, contact, updated: true })
                                                                                                            setLoadingCustomers(false)
                                                                                                        }}
                                                                                                    >
                                                                                                        Remove
                                                                                                    </MenuItem>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div> :
                                                                                        <Creatable
                                                                                            style={{ width: '100%' }}
                                                                                            isLoading={loadingCustomers && item.key === 'customer'}
                                                                                            placeholder={item?.placeholder}
                                                                                            components={{
                                                                                                Input: CustomSelectInput,
                                                                                                Option: (props) => (
                                                                                                    <components.Option {...props} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                                                        <div style={{ flex: '1' }}>
                                                                                                            <div style={{ flex: '1' }}>{props?.data?.label}</div>
                                                                                                            {props?.data?.phoneNumber && <div style={{ flex: '1', fontSize: '10px' }}>{props?.data?.phoneNumber}</div>}
                                                                                                            {props?.data?.email && <div style={{ flex: '1', fontSize: '10px' }}>{props?.data?.email}</div>}
                                                                                                        </div>
                                                                                                        {props?.data?.label !== (item.formatCreateLabel) && item.key !== 'addons' &&
                                                                                                            < i className="simple-icon-pencil" style={{ color: props?.isSelected ? "#fff" : '#3498db', cursor: 'pointer', fontSize: '16px' }} onClick={() => {
                                                                                                                switch (item.key) {
                                                                                                                    case 'customer':
                                                                                                                        setEditCustomer({ ...props?.data, name: props?.data?.displayName, edit: true })
                                                                                                                        break;
                                                                                                                    case 'location':
                                                                                                                        setAddressDetails({ ...props?.data, edit: true })
                                                                                                                        break;
                                                                                                                    default:
                                                                                                                        break;
                                                                                                                }
                                                                                                            }} />
                                                                                                        }
                                                                                                    </components.Option>
                                                                                                )
                                                                                            }}
                                                                                            className={`basic-multi-select`}

                                                                                            options={(item?.data || [])?.map(rec => {
                                                                                                return ({
                                                                                                    ...rec,
                                                                                                    value: item.key === 'addons' ? rec?.name?.en : item.key === 'customer' ? `${rec?.displayName} - ${rec?.phoneNumber} - ${rec?.email}` : formatAddress(rec),
                                                                                                    label: item.key === 'addons' ? rec?.name?.en : item.key === 'customer' ? `${rec?.displayName}` : formatAddress(rec),
                                                                                                    key: rec.id
                                                                                                })
                                                                                            })}
                                                                                            value={item.value ? (item.key === 'customer' ? {
                                                                                                value: `${item?.value?.name} - ${item?.value?.phoneNumber}`,
                                                                                                label: `${item?.value?.name}`,
                                                                                                key: item?.value?.id
                                                                                            } : item.key === 'location' ? {
                                                                                                value: formatAddress(item?.value),
                                                                                                label: formatAddress(item?.value),
                                                                                                key: rec.id
                                                                                            } : '') : ''}
                                                                                            onInputChange={(e) => {
                                                                                                if (e.length > 4 && item.key === 'customer') {
                                                                                                    debounce_fun(e)
                                                                                                    setLoadingCustomers(true)
                                                                                                }
                                                                                            }}
                                                                                            onChange={(newValue) => {
                                                                                                switch (item.key) {
                                                                                                    case 'customer':
                                                                                                        let contact = { name: newValue?.displayName || '', phoneNumber: newValue?.phoneNumber || '', email: newValue?.email || '' }
                                                                                                        setCustomerLocations(newValue?.locations || [])
                                                                                                        setExistingUser({ ...newValue, name: newValue?.displayName, toggle: false })
                                                                                                        updateBooking({ ...booking, contact, updated: true })

                                                                                                        break;
                                                                                                    case 'location':
                                                                                                        let location = { ...booking.location, ...newValue }
                                                                                                        location.latLng = location?.latLng || { lat: location?.geometry?.location?.lat || location?.geometry?.lat || '--', lng: location?.geometry?.location?.lng || location?.geometry?.lng || '--' }
                                                                                                        updateBooking({ ...booking, location, updated: true })
                                                                                                        setSelectedLocation(location)
                                                                                                        getLocationVariations(location)

                                                                                                        break;
                                                                                                    case 'addons':
                                                                                                        let post = {
                                                                                                            ...booking.addons,
                                                                                                            [newValue.key]: {
                                                                                                                count: 1,
                                                                                                                name: newValue.name,
                                                                                                                price: newValue.price,
                                                                                                            },
                                                                                                        }
                                                                                                        updateBooking({
                                                                                                            ...booking,
                                                                                                            updated: true,
                                                                                                            [item.key]: post,

                                                                                                        })
                                                                                                        break;

                                                                                                    default:
                                                                                                        break;
                                                                                                }


                                                                                            }}
                                                                                            isValidNewOption={() => { return true; }}
                                                                                            formatCreateLabel={() => item.formatCreateLabel}
                                                                                            onCreateOption={() => {
                                                                                                switch (item.key) {
                                                                                                    case 'customer':
                                                                                                        setEditCustomer({ addNew: true })
                                                                                                        setExistingUser(false)
                                                                                                        setCustomerLocations(false)
                                                                                                        let contact = {}
                                                                                                        updateBooking({ ...booking, contact, updated: true })

                                                                                                        break;
                                                                                                    case 'location':
                                                                                                        setAddressDetails(true)
                                                                                                        break;

                                                                                                    case 'addons':
                                                                                                        setCreateAddons(true)
                                                                                                        break;

                                                                                                    default:
                                                                                                        break;
                                                                                                }

                                                                                            }}
                                                                                        />}
                                                                                    {item.key === 'addons' && Object.keys(booking?.addons || {})?.length ? <div className="mt-3">
                                                                                        <Table striped>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Name</th>
                                                                                                    <th>Price</th>
                                                                                                    <th style={{ textAlign: 'end' }}>Count</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {Object.keys(booking.addons).map((r, ind) => {
                                                                                                    let clientAddons = addons?.reduce((val, currentVal) => {
                                                                                                        val[currentVal.id] = currentVal
                                                                                                        return val
                                                                                                    }, {})
                                                                                                    let selectedItem = booking.addons[r]
                                                                                                    return (<tr key={ind} className="mt-1">
                                                                                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{selectedItem?.name?.en}</td>
                                                                                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', width: '50%' }} >
                                                                                                            <InputGroup>
                                                                                                                <InputGroupAddon addonType='prepend'>
                                                                                                                    <InputGroupText>AED</InputGroupText>
                                                                                                                </InputGroupAddon>
                                                                                                                <Input className="customInput"
                                                                                                                    value={selectedItem?.price || selectedItem?.price === '' ? selectedItem?.price : clientAddons?.[r]?.price}
                                                                                                                    placeholder={"e.g. 200 AED"}
                                                                                                                    type="number"
                                                                                                                    onChange={(e) => {
                                                                                                                        let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                                        newItems[r].price = e.target.value
                                                                                                                        updateBooking({
                                                                                                                            ...booking, updated: true, addons: newItems,
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                                {clientAddons?.[r]?.price !== selectedItem?.price && (selectedItem?.price || selectedItem?.price === '') && !selectedItem?.addNew ? <InputGroupAddon addonType="append">
                                                                                                                    <Button
                                                                                                                        onClick={() => {

                                                                                                                            let newItems = JSON.parse(JSON.stringify(booking.addons))

                                                                                                                            newItems[r].price = clientAddons?.[r]?.price

                                                                                                                            updateBooking({
                                                                                                                                ...booking, updated: true, addons: newItems,
                                                                                                                            })
                                                                                                                        }}
                                                                                                                        outline style={{ background: '#fff', zIndex: '0' }} color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>

                                                                                                                </InputGroupAddon>
                                                                                                                    : ''}
                                                                                                            </InputGroup>
                                                                                                            {/* {selectedItem?.price?.total} AED */}
                                                                                                        </td>
                                                                                                        <td className="d-flex align-items-center" style={{ justifyContent: 'flex-end', padding: '21px 0.75rem' }}>
                                                                                                            <i className="simple-icon-minus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                                let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                                if (newItems[r].count > 1) {
                                                                                                                    newItems[r].count = newItems[r].count - 1
                                                                                                                } else {
                                                                                                                    delete newItems[r]
                                                                                                                }
                                                                                                                updateBooking({
                                                                                                                    ...booking, updated: true, addons: newItems,
                                                                                                                })

                                                                                                            }} />
                                                                                                            <div className="ml-2 mr-2" style={{ fontSize: '16px', fontWeight: '700' }}>{selectedItem.count}</div>
                                                                                                            <i className="simple-icon-plus" style={{ color: '#3498db', fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                                                                                                                let newItems = JSON.parse(JSON.stringify(booking.addons))
                                                                                                                newItems[r].count = newItems[r].count + 1
                                                                                                                updateBooking({
                                                                                                                    ...booking,
                                                                                                                    updated: true,
                                                                                                                    addons: newItems,
                                                                                                                })
                                                                                                            }} />
                                                                                                        </td>
                                                                                                    </tr>)
                                                                                                })}

                                                                                            </tbody>
                                                                                        </Table>

                                                                                    </div> : ''}
                                                                                </div> :
                                                                                item.type === 'date' ?
                                                                                    <> <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                        {loadingTimings && <div className="loading" style={{ position: 'absolute', left: '14px', top: '33px' }} />}
                                                                                        <DatePicker
                                                                                            placeholderText="Select Order Date & Time"
                                                                                            value={booking?.bookingDate?.date ? moment(booking?.bookingDate?.date) : ''}
                                                                                            disabled={!timings?.length && filterTimings}
                                                                                            popperClassName="redatepicker"
                                                                                            wrapperClassName={loadingTimings && "marginLeft"}
                                                                                            customInput={<DatepickerInput />}
                                                                                            selected={orderDate && (moment(orderDate).toDate())}
                                                                                            minDate={user.role !== 'superAdmin' && minDate}
                                                                                            minTime={user.role !== 'superAdmin' && minTime}
                                                                                            maxTime={user.role !== 'superAdmin' && moment().endOf('year').toDate()}
                                                                                            // minDate={minDate}
                                                                                            // minTime={minTime}
                                                                                            // maxTime={moment().endOf('year').toDate()}
                                                                                            onChange={date => {
                                                                                                setOrderDate(date)
                                                                                                if (filterTimings) {
                                                                                                    let selectedDate = moment(date).format('hh:mm A') + ' - ' + moment(date).add(1, 'hours').format('hh:mm A')
                                                                                                    let selectedTime = GetTimingSlots(timings?.find(rec => rec.weekDay === moment(date).format('ddd')))?.find(rec => rec.id === selectedDate)
                                                                                                    let candidates = timings?.find(rec => rec.weekDay === moment(date).format('ddd'))?.candidates
                                                                                                    updateBooking({ ...booking, updated: true, candidates: candidates || [], [item.key]: { ...booking.bookingDate, date: moment(date).valueOf(), time: selectedTime } })

                                                                                                } else {
                                                                                                    let selectedTime = {
                                                                                                        start: moment(date).set('year', 1955).set('month', 0).set('date', 5).valueOf(),
                                                                                                        end: moment(date).set('year', 1955).set('month', 0).set('date', 5).valueOf() + 3600000,
                                                                                                        id: `${moment(date).format('hh:mm A')} - ${moment(date).add(60, 'minutes').format('hh:mm A')}`
                                                                                                    }
                                                                                                    let candidates = timings?.find(rec => rec.weekDay === moment(date).format('ddd'))?.candidates
                                                                                                    updateBooking({ ...booking, updated: true, candidates: candidates || [], [item.key]: { ...booking.bookingDate, date: moment(date).valueOf(), time: selectedTime } })

                                                                                                }
                                                                                            }}
                                                                                            // excludeTimes={GetTimingSlots(timings?.find(rec => rec.weekDay === moment(orderDate).format('dddd')))?.map(rec => {
                                                                                            //     return rec.start
                                                                                            // })}
                                                                                            includeTimes={filterTimings && _filterPassedTime().map(rec => {
                                                                                                return rec
                                                                                            })}
                                                                                            showTimeSelect
                                                                                            timeFormat="hh:mm a"
                                                                                            timeIntervals={30}
                                                                                            timeCaption="time"
                                                                                            dateFormat="dd/MM/yyyy hh:mm a"
                                                                                        />

                                                                                    </div>
                                                                                        {!isInTimeRange() && booking?.bookingDate &&
                                                                                            <Alert color="warning" className="rounded mt-2">
                                                                                                No active SP is available at the selected time, kindly pick another time or assign an SP directly
                                                                                            </Alert>
                                                                                        }
                                                                                    </> : item.type === 'phoneNumber' ?
                                                                                        <PhoneInput
                                                                                            // countryCodeEditable={false}
                                                                                            inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                                                            className="customInput"
                                                                                            country={'ae'}
                                                                                            placeholder="e.g. +971xxxxxx"
                                                                                            value={item.parent ? booking?.[item.parent]?.[item.key] : booking?.[item.key]}
                                                                                            onChange={async (value, data, event, formattedValue) => {
                                                                                                let number = `+${formattedValue.replace(/\D/g, '')}`;
                                                                                                let contact = { ...booking.contact, [item.key]: number }
                                                                                                updateBooking({ ...booking, contact, updated: true })

                                                                                            }}
                                                                                        /> : <Input
                                                                                            type={item.type}
                                                                                            placeholder={"e.g. " + item.title}
                                                                                            value={item.parent ? booking?.[item.parent]?.[item.key] : booking?.[item.key]}
                                                                                            className="customInput"
                                                                                            onChange={(e) => {
                                                                                                if (item.parent === 'contact') {
                                                                                                    let contact = { ...booking.contact, [item.key]: e.target.value }
                                                                                                    updateBooking({ ...booking, contact, updated: true })
                                                                                                }
                                                                                                //  else if (item.key === 'building' || item.key === 'area') {
                                                                                                //     let location = { ...booking.location, [item.key]: e.target.value }
                                                                                                //     updateBooking({ ...booking, location, updated: true })
                                                                                                // }
                                                                                                else {
                                                                                                    updateBooking({ ...booking, [item.key]: e.target.value, updated: true })
                                                                                                }
                                                                                            }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </Colxx>
                                        <Colxx xxs="12" md="4" className="pl-4" style={{ background: '#d7d7d715', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div>
                                                {[{
                                                    key: 'details', title: 'Details',
                                                    data: [
                                                        { key: 'orderNumber', title: 'Order ID:', width: '12', type: 'title', data: orderNumber },
                                                        { key: 'service', title: 'Service:', width: '12', type: 'title', data: booking?.service?.name?.en },
                                                        {
                                                            key: 'serviceDetails', title: 'Service Details:', width: '12', type: 'title',
                                                            data: booking?.items && Object.values(booking?.items).length && Object.values(booking?.items)?.map(rec => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <div style={{ flex: '1', textAlign: 'start' }}>{`${rec.count}X ${rec?.name?.en || rec?.name}`}</div>
                                                                <div>{`${Number(Number(rec?.count || 1) * Number(rec?.price?.total || 0)).toFixed(2)} AED`}</div>
                                                                <br /></div>), column: true
                                                        },
                                                        {
                                                            key: 'addonsDetails', title: 'Addons:', width: '12', type: 'title', data: booking?.addons && Object.values(booking?.addons)?.map(rec =>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <div style={{ flex: '1', textAlign: 'start' }}>{`${rec.count}X ${rec?.name?.en || rec?.name}`}</div>
                                                                    <div>{`${Number(Number(rec?.count || 1) * Number(rec?.price || 0)).toFixed(2)} AED`}</div>
                                                                    <br /></div>
                                                            ), column: true
                                                        },
                                                        { key: 'insturction', title: 'Instructions:', width: '12', type: 'title', data: booking?.notes },]
                                                },
                                                { key: 'date&time', title: 'Date & Time', data: [{ key: 'date', title: 'Date', width: '12', type: 'title', data: booking?.bookingDate?.date && moment(booking?.bookingDate?.date)?.format("DD/MM/YYYY hh:mm A ( dddd )") }, { key: 'slot', title: 'Time slot', width: '12', type: 'title', data: booking?.bookingDate?.time?.id },] },
                                                { key: 'address', title: 'Address', data: [{ key: 'client', title: 'Client', width: '12', type: 'title', data: booking?.brand?.name, hide: bookingType === 'custom' }, { key: 'area', title: 'Area', width: '12', type: 'title', data: booking?.location?.area, hide: bookingType === 'existing' }, { key: 'building', title: 'Building', width: '12', type: 'title', data: booking?.location?.building, hide: bookingType === 'existing' }, { key: 'room', title: 'Room', width: '12', type: 'title', data: booking?.contact?.room },] },
                                                {
                                                    key: 'paymentDetails', title: 'Payment Details',
                                                    data: [
                                                        { key: 'subtotal', title: 'Subtotal', width: '12', type: 'price', data: booking?.receipt?.sub && Number(Number(booking?.receipt?.sub || 0)?.toFixed(2) - Number(booking?.receipt?.totalTopup || 0)?.toFixed(2)).toFixed(2) },
                                                        { key: 'serviceFees', title: booking?.receipt?.serviceFees?.title?.en, width: '12', type: 'price', data: booking?.receipt?.serviceFees && Number((booking?.receipt?.serviceFees?.value) || 0)?.toFixed(2), hide: !booking?.receipt?.serviceFees },
                                                        { key: 'topup', title: 'Client Topup', width: '12', type: 'price', data: booking?.receipt?.totalTopup && Number((booking?.receipt?.totalTopup) || 0)?.toFixed(2) },
                                                        { key: 'discount', title: 'Discount', width: '12', type: 'price', data: Number(booking?.receipt?.discount || 0)?.toFixed(2), hide: !booking?.receipt?.discount },
                                                        { key: 'total', title: 'Total', width: '12', type: 'price', data: Number(booking?.receipt?.total || 0)?.toFixed(2) }]
                                                },
                                                { key: 'special_discount', hide: user.role !== 'superAdmin', title: 'Apply Special Discount' },
                                                ].map((rec, recIndex) => {
                                                    return !rec.hide && (
                                                        <>
                                                            <Row key={recIndex}>
                                                                <Colxx xxs="12" className="mt-4 mb-2">
                                                                    <h6>{rec.title}</h6>
                                                                </Colxx>
                                                                {rec.key !== 'special_discount' ?
                                                                    rec.data?.map((item, index) => {
                                                                        return !item.hide && (
                                                                            <Colxx key={index} xxs="12" md={item.width} className={`mb-2 d-flex ${item.column && item.data ? 'flex-column' : ''} align-items-baseline`} style={{ justifyContent: 'space-between' }}>
                                                                                <div className="mb-2 text-muted" style={{ fontSize: '12px' }}>{item.title}</div>
                                                                                <Label style={{ fontWeight: '700', textAlign: item.key === 'insturction' && item.data ? 'start' : 'end', width: item.column && item.data ? '100%' : '70%' }}>{item.data || '--'} {item.type === 'price' && item.data ? "AED" : ''}</Label>
                                                                            </Colxx>
                                                                        )
                                                                    }) :
                                                                    <Colxx xxs="12"  >

                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSpecialDiscount({ ...specialDiscount, type: 'percent' })
                                                                                    }}
                                                                                    outline={specialDiscount?.type !== 'percent'}
                                                                                    color={specialDiscount?.type !== 'percent' ? 'light' : "primary"}
                                                                                    size="sm">%</Button>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSpecialDiscount({ ...specialDiscount, type: '' })
                                                                                    }}
                                                                                    outline={specialDiscount?.type === 'percent'}
                                                                                    color={specialDiscount?.type === 'percent' ? 'light' : "primary"} size="sm">AED</Button>


                                                                            </InputGroupAddon>
                                                                            <Input className="customInput"
                                                                                value={specialDiscount?.val}
                                                                                placeholder={"e.g. 200 AED"}
                                                                                type="number"
                                                                                onChange={(e) => {
                                                                                    setSpecialDiscount({ ...specialDiscount, val: e.target.value })

                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button
                                                                                    disabled={!booking?.receipt?.total}
                                                                                    onClick={() => {
                                                                                        let receipt = calcTotalCost(booking?.items, (!booking?.receipt?.discount ? specialDiscount : { type: '', val: '' }), booking?.addons, booking?.receipt)
                                                                                        updateBooking({
                                                                                            ...booking, updated: true, receipt
                                                                                        })
                                                                                    }}
                                                                                    outline color="primary" size="sm">{booking?.receipt?.discount ? 'Reset' : 'Apply'}</Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </Colxx>
                                                                }
                                                            </Row>
                                                            {rec.key !== 'special_discount' && <Separator />}
                                                        </>

                                                    )
                                                })}
                                            </div>

                                            <div className="d-flex align-items-center mt-4" style={{ justifyContent: 'space-between' }}>
                                                <Button
                                                    color="danger"
                                                    className="btn-shadow"
                                                    size="lg"
                                                    onClick={() => dismiss()}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    disabled={(bookingType === 'existing' ? !booking?.brand : (user.role === 'superAdmin' ? (!booking?.location?.building && !booking?.location?.area) : !booking?.contact?.room))
                                                        || !booking?.service
                                                        || !booking?.items
                                                        || !Object.keys(booking?.items || {}).length
                                                        || !booking?.bookingDate?.date
                                                        || !booking?.bookingDate?.time?.start
                                                        || !booking?.bookingDate?.time?.end
                                                        || !booking?.candidates
                                                        || !booking?.contact?.name
                                                        || !booking?.contact?.phoneNumber
                                                        || (!filterTimings ? !booking?.provider?.id : false)}
                                                    color="primary"
                                                    className="btn-shadow"
                                                    size="lg"
                                                    onClick={() => {
                                                        SetShowConfirmationModal(true)
                                                    }}>
                                                    Create Request
                                                </Button>

                                            </div>

                                        </Colxx>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Colxx>
                    </Row>
                    {addresDetails && <AddressDetails booking={JSON.parse(JSON.stringify(booking))}
                        dismiss={() => {
                            setAddressDetails(false)
                            setSelectedLocation(false)
                            getLocationVariations(false)
                        }}
                        servingAreas={servingAreas}
                        editAddress={addresDetails}
                        saveAddressDetails={async (details) => {

                            let contact = { ...booking.contact, ...details?.contact }
                            let location = { ...booking.location, ...details?.location }
                            if (location?.edit) {
                                return setEditLocation(details)
                            }

                            let locations = customerLocations || []

                            delete location?.value
                            delete location?.edit
                            locations.push(location)

                            setSelectedLocation(location)
                            getLocationVariations(location)

                            setCustomerLocations(locations)
                            if (existingUser?.id) {
                                try {
                                    let post = {};
                                    if (locations && locations?.length) {
                                        post.locations = locations
                                    }
                                    post.id = post.uid = existingUser?.id
                                    let { error } = await UpdateUser({ ...existingUser, ...post })
                                    console.log('error', error);

                                } catch (error) {

                                }
                            }

                            setAddressDetails(false)
                            updateBooking({ ...booking, contact, location, updated: true })

                        }} />}

                    {editLocation && <Modal
                        isOpen={true}
                        // toggle={dismiss}
                        backdrop="static"
                        size="md">
                        <ModalHeader>
                            Edit Customer Location
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you want to edit this location?
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="danger"
                                className="btn-shadow"
                                size="md"
                                onClick={() => {
                                    setAddressDetails(false)
                                    setEditLocation(false)
                                    setSelectedLocation(false)
                                    getLocationVariations(false)

                                }}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="md"
                                onClick={async () => {

                                    let contact = { ...booking.contact, ...editLocation?.contact }
                                    let location = { ...booking.location, ...editLocation?.location }

                                    let locations = customerLocations || []

                                    if (location?.edit) {
                                        setEditLocation(location)
                                        locations = locations.filter(rec => rec.id !== location?.id)
                                    }
                                    delete location?.value
                                    delete location?.edit
                                    locations.push(location)
                                    if (existingUser?.id) {
                                        try {
                                            let post = {};
                                            if (locations && locations?.length) {
                                                post.locations = locations
                                            }
                                            post.id = post.uid = existingUser?.id
                                            let { error } = await UpdateUser({ ...existingUser, ...post })
                                            console.log('error', error);
                                        } catch (error) {

                                        }
                                    }
                                    setSelectedLocation(location)
                                    getLocationVariations(location)
                                    setCustomerLocations(locations)
                                    setAddressDetails(false)
                                    setEditLocation(false)
                                    updateBooking({ ...booking, contact, location, updated: true })

                                }}>
                                Edit
                            </Button>
                        </ModalFooter>
                    </Modal>}

                    {serviceChanged?.open && <Modal
                        isOpen={true}
                        // toggle={dismiss}
                        backdrop="static"
                        size="md">
                        <ModalHeader>
                            Caution
                        </ModalHeader>
                        <ModalBody>
                            By adding this item to cart ,Other Selected services will be removed
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="danger"
                                className="btn-shadow"
                                size="md"
                                onClick={() => {
                                    setServiceChanged(false)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="md"
                                onClick={() => {
                                    delete booking.items
                                    delete booking?.candidates
                                    delete booking?.bookingDate
                                    delete booking?.receipt?.serviceFees
                                    setTimings([])
                                    updateBooking({ ...booking, updated: true })

                                    serviceChanged.action()
                                    setServiceChanged(false)
                                }}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </Modal>}
                    {showConfirmationModal && <Modal
                        isOpen={true}
                        // toggle={dismiss}
                        backdrop="static"
                        size="md">
                        <ModalHeader>
                            Confirm Creating Appointment
                        </ModalHeader>
                        {user.role === 'superAdmin' ? <ModalBody>
                            <div>{'Status'}</div>
                            <Select
                                components={{
                                    Input: CustomSelectInput,
                                }}
                                className="basic-multi-select mt-2"
                                value={{
                                    value: statusArray?.[booking.status || (booking?.provider?.id ? 'pending' : 'new')]?.label,
                                    label: statusArray?.[booking?.status || (booking?.provider?.id ? 'pending' : 'new')]?.label,
                                    key: booking?.status
                                }}
                                options={Object.values(statusArray)?.map(rec => {
                                    return ({ ...rec, value: rec?.name?.en || rec?.name || rec?.label, label: rec?.name?.en || rec?.name || rec?.label, key: rec.id || rec?.status })
                                })}
                                onChange={val => {
                                    updateBooking({ ...booking, status: val.key, updated: true })
                                }}

                            />
                            <div className="mt-4">{'Notification Settings'}</div>
                            <CustomInput
                                className="custom-checkbox mb-0 mt-2 d-inline-block"
                                type="checkbox"
                                id="disableNotification"
                                checked={booking?.disableNotification}
                                onChange={(e) => {
                                    updateBooking({ ...booking, disableNotification: e.target.checked, updated: true })
                                }}
                                label={<div style={{ cursor: 'pointer', fontWeight: '700' }}>Disable notifications for this appointment</div>}
                            />
                        </ModalBody> : <ModalBody>
                            Click confirm and our team will review your request and serve you soon
                        </ModalBody>}
                        <ModalFooter>
                            <Button
                                color="danger"
                                className="btn-shadow"
                                size="md"
                                onClick={() => {
                                    SetShowConfirmationModal(false)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="btn-shadow"
                                size="md"
                                onClick={() => {
                                    submit()
                                    setLoading(true)

                                }}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </Modal>}
                    {editCustomer ? <Customers
                        updateCustomer={(e) => {
                            let contact = { name: e?.displayName || '', phoneNumber: e?.phoneNumber || '', email: e?.email || '' }
                            updateBooking({ ...booking, contact, updated: true })
                            setEditCustomer(contact)
                            setExistingUser({ ...existingUser, ...contact })
                            let newCustomers = customers?.reduce((val, currentVal) => {
                                if (currentVal.id === existingUser.id) {
                                    currentVal.displayName = e.displayName
                                    currentVal.phoneNumber = e.phoneNumber
                                    currentVal.email = e.email
                                }
                                val.push(currentVal)
                                return val
                            }, [])
                            if (e.addNew) {
                                delete e.addNew
                                newCustomers.push(e)
                            }
                            setCustomers(newCustomers)
                        }}
                        dismiss={() => setEditCustomer(false)}
                        editCustomer={editCustomer}
                    /> : ''}
                    {editAddon ? <Addons
                        addNewAddon={(e) => {
                            let key = firestore().collection('addons').doc().id
                            let post = {
                                ...booking.addons,
                                [key]: {
                                    count: Number(e.count),
                                    name: { en: e.name },
                                    price: e.price,
                                    addNew: true
                                },
                            }
                            updateBooking({
                                ...booking,
                                updated: true,
                                addons: post,

                            })
                        }}
                        dismiss={() => setCreateAddons(false)}
                        editAddon={editAddon}
                    /> : ''}
                </Fragment >
            ))
    );
};

export default Booking;