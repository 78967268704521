import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
} from "reactstrap";
import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { firestore } from '../../../../helpers/Firebase'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import { checkIfDisabled, uploadPicture } from "../../../../helpers/Utils";

import { Creatable } from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import TranslatePopup from "../../../../components/common/translatePopup";
import NewHelper from "../../../../components/common/newHelper";

const tabs = [{ name: 'General Information', key: 1 }]

const Helper = ({
    title,
    selected,
    elements,
    dismiss,
}) => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [pictures, setPictures] = useState([])
    // eslint-disable-next-line
    const [requiredKeys, setRequiredKeys] = useState([])
    const { activeItem } = useSelector(state => state.activeItem)
    const [translateObject, showTranslatePopup] = useState(false)
    const [newHelper, setNewHelper] = useState(false)

    const dispatch = useDispatch()
    const [updatedHelper, UpdateHelper] = useState(activeItem?.general || {})

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    setPictures(pictures)
                },
                'blob'
            );

        } else {

            setPictures(pictures)
            UpdateHelper({ ...updatedHelper, [type]: [], updated: true })
        }
    }

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedHelper }))
        // eslint-disable-next-line
    }, [updatedHelper])

    const submit = async () => {
        let { general } = JSON.parse(JSON.stringify(activeItem));

        let id = general.id || firestore().collection(selected).doc().id;
        if (general) {
            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            general.deactivated = false
            general.lastUpdate = Date.now()
            if (pictures && pictures.length > 0 && pictures[0].name) {
                uploadPicture(pictures, selected, id, 'picture')
            }
            await firestore().collection(selected).doc(id).set(general, { merge: true })
        }

        dispatch(SetActiveItem(false))
        setLoading(false)
    }

    useEffect(() => {
        if (elements?.length) {
            let required = elements?.reduce((value, currentValue) => {
                value = value?.concat(currentValue.data?.map(rec => rec.key)?.filter(rec => rec !== 'picture'))
                return value
            }, [])
            setRequiredKeys(required)
        }
    }, [elements])
    console.log('updatedHelperupdatedHelperupdatedHelper', updatedHelper);
    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedHelper?.addNew ? 'New ' + title : updatedHelper?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {elements.map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>

                                                        {rec.data.map((item, index) => {
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    {/* <div className="mb-2" >{item.title}</div> */}
                                                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                        <div>{item.title}</div>
                                                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: updatedHelper?.[item.key], type: item.type })}>Translate</div>}
                                                                    </div>
                                                                    {item.type === 'picture' ?
                                                                        <ImageUploader
                                                                            className={'w-100'}
                                                                            withIcon={true}
                                                                            name={item.key}
                                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                            buttonText='Choose images'
                                                                            onChange={(picture) => onDrop(picture, item.key)}
                                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                            maxFileSize={15728640}
                                                                            withPreview={true}
                                                                            singleImage={true}
                                                                            defaultImages={updatedHelper && updatedHelper[item.key]}
                                                                        /> : item.type === 'select' ?
                                                                            <Creatable
                                                                                isMulti
                                                                                style={{ width: '100%' }}
                                                                                placeholder={item?.title}
                                                                                components={{ Input: CustomSelectInput, }}
                                                                                className={`basic-multi-select`}
                                                                                value={item?.data?.length ? item?.data?.map(rec => {
                                                                                    return (updatedHelper?.[item.key]?.includes(rec.id)) ?
                                                                                        ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id }) : ''
                                                                                }) : updatedHelper?.[item.key]?.map(rec => {
                                                                                    return ({ ...rec, value: rec?.en, label: rec?.en, key: rec.id })
                                                                                })}
                                                                                options={item?.data?.map(rec => {
                                                                                    return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                                })}

                                                                                onChange={(val) => {
                                                                                    UpdateHelper({ ...updatedHelper, [item.key]: val.map(rec => rec.key), updated: true })
                                                                                }}
                                                                                isValidNewOption={() => { return item?.formatCreateLabel ? true : false }}
                                                                                formatCreateLabel={() => item?.formatCreateLabel || undefined}
                                                                                onCreateOption={() => {
                                                                                    setNewHelper(item)
                                                                                }}
                                                                            />
                                                                            : <Input
                                                                                type={item.type}
                                                                                placeholder={"e.g. " + item.title}
                                                                                value={item.translateButton ? updatedHelper?.[item.key]?.en : updatedHelper?.[item.key]}
                                                                                className="customInput"
                                                                                onChange={(e) => {
                                                                                    if (item.translateButton) {
                                                                                        UpdateHelper({ ...updatedHelper, [item.key]: { ...updatedHelper?.[item.key], en: e.target.value }, updated: true })
                                                                                    } else {
                                                                                        UpdateHelper({ ...updatedHelper, [item.key]: e.target.value, updated: true })
                                                                                    }
                                                                                }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>

                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedHelper)), ['name'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        UpdateHelper({ ...updatedHelper, [translateObject.key]: e, updated: true })
                        showTranslatePopup(false)
                    }} />
                    }
                    {newHelper ? <NewHelper
                        title={newHelper?.formatCreateLabel}
                        selected={newHelper?.selected}
                        updateHelpers={(e) => {
                            let ids = updatedHelper?.[newHelper.key] || []
                            ids.push(newHelper?.selected ? e.id : e?.name)
                            UpdateHelper({ ...updatedHelper, [newHelper.key]: ids, updated: true })
                            setNewHelper(false)
                        }}
                        dismiss={() => setNewHelper(false)}
                        newHelper={newHelper}
                    /> : ''}
                </Fragment >
            ))
    );
};

export default Helper;