import React, { useState } from "react";
import { Row, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Colxx, Separator } from "./CustomBootstrap";
import PhoneInput from "react-phone-input-2";
import { checkIfDisabled } from "../../helpers/Utils";
const Customers = ({
  dismiss,
  editCustomer,
  updateCustomer
}) => {
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState(editCustomer || {})

  const submit = () => {
    updateCustomer(customer)
    setLoading(false)
    dismiss()
  }
  return (
    (
      loading ? (
        <div className="loading" />
      ) : (
        <Modal wrapClassName="modal-right" backdrop="static" isOpen={editCustomer} >
          <ModalHeader>
            <h3>{'Customer Information'}</h3>
          </ModalHeader>
          <ModalBody>
            <Colxx xxs="12 p-0">
              {[{
                key: 'customerInfo', title: 'Contact Information',
                data: [
                  { key: 'displayName', parent: 'contact', title: 'Name', value: customer.displayName, width: '12', type: 'text', show: true },
                  { key: 'phoneNumber', parent: 'contact', title: 'Number', value: customer.phoneNumber, width: '12', type: 'phoneNumber', show: true },
                  { key: 'email', parent: 'contact', title: 'Email', value: customer.email, width: '12', type: 'email', show: true, optional: true },
                ]
              },
              ].map((rec, recIndex) => {
                return (
                  <Row key={recIndex}>
                    <Separator />
                    {rec.data.map((item, index) => {
                      return item.show && (
                        <Colxx key={index} xxs="12" md={item.width} className="mb-4 ">
                          <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }} >
                            <div className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                              <div>{item.title}</div>
                              {item.optional && <span
                                style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                className={`ml-1 badge badge-pill badge-light badge-top-left`}
                              >
                                Optional
                              </span>}
                            </div>
                          </div>
                          {
                            item.type === 'phoneNumber' ?
                              <PhoneInput
                                // countryCodeEditable={false}
                                inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                className="customInput"
                                country={'ae'}
                                placeholder="e.g. +971xxxxxx"
                                value={item.value}
                                onChange={async (value, data, event, formattedValue) => {
                                  let number = `+${formattedValue.replace(/\D/g, '')}`;
                                  setCustomer({ ...customer, [item.key]: number, updated: true })
                                }}
                              /> : <Input
                                type={item.type}
                                placeholder={"e.g. " + item.title}
                                value={item.value}
                                className="customInput"
                                onChange={(e) => {
                                  setCustomer({ ...customer, [item.key]: e.target.value, updated: true })

                                }} />}
                        </Colxx>
                      )
                    })}
                  </Row>
                )
              })}


            </Colxx>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'space-between' }}>

            <Button
              color="danger"
              outline
              className="btn"
              size="lg"
              onClick={() => dismiss()}>
              Dismiss
            </Button>
            <Button
              disabled={checkIfDisabled(JSON.parse(JSON.stringify(customer)), ['displayName', 'phoneNumber'])}
              color="primary"
              className="btn"
              size="lg"
              onClick={() => {
                setLoading(true)
                submit()
              }}>
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      ))
  );
};

export default Customers;