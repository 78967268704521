import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import moment from "moment";

const NotificationItem = ({ img, contact, bookingDate, service }) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">
      {/* <a href="/app/pages/details">
        <img
          src={img}
          alt={title}
          className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
        />
      </a> */}
      <div className="pl-3 pr-2">
        <a href="/app/bookings">
          <p className="font-weight-medium mb-1">{contact?.name + ' - ' + service?.name?.en}</p>
          <p className="text-muted mb-0 text-small">{moment(bookingDate.date).format('DD/MM/YYYY hh:mm A')}</p>
        </a>
      </div>
    </div>
  );
};

const TopnavNotifications = ({ requests }) => {
  let filteredBookings = requests?.filter(rec => rec.status === 'new' || rec.status === 'pending')

  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
        >
          <i className="simple-icon-bell" />
          {filteredBookings?.length ? <span className="count">{filteredBookings?.length}</span> : ''
          }
        </DropdownToggle>
        <DropdownMenu
          className="position-absolute mt-3 scroll"
          right
          id="notificationDropdown"
        >
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {filteredBookings?.length ? filteredBookings?.map((notification, index) => {
              return <NotificationItem key={index} {...notification} />;
            }) : 'No notifications'}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopnavNotifications;
