import React, { Component } from "react";
import {
  Row, Card, CardTitle, Form, Label, Input, Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from '../../helpers/Firebase'
import { loginUserSuccess } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import metadocLogo from "../../assets/img/metadocLogo.png"

import { NotificationManager } from "../../components/common/react-notifications";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      resetPassword: false
    };
  }
  createNotification = (className, error) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        error,
        "Error",
        4000,
        null,
        cName
      )
    )
  };
  createSuccessNotification = (className, success) => {
    let cName = className || "";
    return (
      NotificationManager.success(
        success,
        "Alert",
        5000,
        null,
        cName
      )
    )
  };
  onUserLogin() {
    let { email, password } = this.state
    let _this = this
    if (email !== "" && password !== "") {
      auth().signInWithEmailAndPassword(email, password).then((user) => {
        if (user) {
          localStorage.setItem('user_id', user.uid);
          localStorage.setItem('role', user.role);
        }
      }).catch(function (error) {
        _this.createNotification("error", error.message)
      });
    }
  }

  render() {
    let { email, password, resetPassword } = this.state
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div style={{
              width: '35%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
            }>
              <img alt="" src={metadocLogo} style={{ objectFit: 'contain', width: '100%' }} />
            </div>
            <div className="form-side">
              <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.login-title" />
              </CardTitle>
              <Form>
                <Label className="form-group has-float-label mb-4">
                  <Input style={{ fontSize: '16px' }} type="email" defaultValue={this.state.email} value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                  <IntlMessages id="user.email" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input style={{ fontSize: '16px' }} type="password" autocomplete={'on'} defaultValue={this.state.password} value={password} onChange={(e) => this.setState({ password: e.target.value })} />
                  <IntlMessages
                    id="user.password"
                    defaultValue={this.state.password}
                  />
                </Label>
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    style={{ color: "#2383c4", fontWeight: '500', cursor: 'pointer' }}
                    onClick={() => this.setState({ resetPassword: true })}
                  >
                    <IntlMessages id="user.forgot-password-question" />
                  </div>

                  <Button
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    onClick={() => this.onUserLogin()}
                  >
                    <IntlMessages id="user.login-button" />
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
        <Modal
          isOpen={resetPassword}
          backdrop="static">
          <ModalHeader >
            Kindly enter your email to send you reset password link
          </ModalHeader>
          <ModalBody>
            <Row>
              <Colxx xxs="12" >
                <Label >
                  Email
                </Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Colxx>
            </Row>
          </ModalBody>
          <ModalFooter >
            <Button color="danger" outline onClick={() => this.setState({ resetPassword: false })}>
              Cancel
            </Button>
            <Button color="primary" className="top-right-button" onClick={() => this.changePassword()}>
              Reset
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
  changePassword() {
    let { email } = this.state
    let _this = this
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${window.location.origin}/verify_email_login`,
      // This must be true.
      handleCodeInApp: true,
    };
    auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      _this.createSuccessNotification("Alert", 'Please check your email and follow link sent from Metadoc team')
      _this.setState({ resetPassword: false })
    }).catch(function (error) {
      _this.createNotification("error", error.message)

      // An error happened.
    });
    // auth().sendPasswordResetEmail(email).then(function () {
    //   // Email sent.
    //   _this.createSuccessNotification("Alert", 'Reset Password Email Has Been Sent Successfully')
    //   _this.setState({ resetPassword: false })

    // }).catch(function (error) {
    //   _this.createNotification("error", error.message)

    //   // An error happened.
    // });


  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};
const mapActionsToProps = (dispatch) => ({
  loginUserSuccess: (user) => dispatch(loginUserSuccess(user)),
});
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Login);
