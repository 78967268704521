import React, { useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    Label,
    ButtonGroup,
    CustomInput,
} from "reactstrap";

import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from "react-redux";
import { Colxx, Separator } from "./CustomBootstrap";
import { firestore } from "../../helpers/Firebase";
import { UpdateItem } from "../../redux/activeItem/actions";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { checkIfDisabled } from "../../helpers/Utils";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { List, arrayMove } from 'react-movable';

const Banners = ({
    parent,
    title,
    type
}) => {
    const dispatch = useDispatch()
    const { activeItem } = useSelector(state => state.activeItem)
    const { services } = useSelector(state => state.settings)
    const [banners, setBanners] = useState(activeItem?.[type] || [])
    const [variations] = useState(activeItem?.variations || [])
    const [sortedBanners, setSortedBanners] = useState(activeItem?.general?.banners?.map(r => { return ({ id: r }) }) || [])
    const [deleteItem, setDeleteItem] = useState(false)
    const [selectedBanner, setSelectedBanner] = useState(false)

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                1080,
                720,
                'png',
                80,
                0,
                async uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600,
                        base64: await getBase64(uri600)

                    });
                    setSelectedBanner({ ...selectedBanner, pictures: pictures, updated: true })
                },
                'blob'
            );

        } else {
            setSelectedBanner({ ...selectedBanner, [type]: [], pictures: [], updated: true })
        }
    }
    const deleteItemFunc = (item) => {
        setDeleteItem(false)
        if (item.id) {
            banners[item.id].deleted = true
            banners[item.id].updated = true
            let ban = sortedBanners.filter(rec => rec.id !== item.id)
            setSortedBanners(ban || [])
            setBanners(banners)
        } else {
            delete banners[item.id]
            dispatch(UpdateItem({ key: type, val: banners }))

        }

        setBanners(banners)
    }
    const submit = (id) => {
        let Ref = firestore().collection(parent).doc(activeItem?.general?.id).collection(type)
        let documentID = id || Ref.doc().id;
        let newBanners = JSON.parse(JSON.stringify(banners))
        newBanners[documentID] = { ...selectedBanner, id: documentID }

        let sort = [...sortedBanners, { id: documentID }]
        const arrUniq = [...new Map(sort.map(v => [v.id, v])).values()]
        setSortedBanners(arrUniq)

        setBanners(newBanners)
        setSelectedBanner(false)

    }
    useEffect(() => {
        activeItem.general.banners = sortedBanners.map(r => r.id)
        activeItem.general.updated = true

        dispatch(UpdateItem({ key: 'general', val: activeItem.general }))
        // eslint-disable-next-line
    }, [sortedBanners, activeItem.general])

    useEffect(() => {
        dispatch(UpdateItem({ key: type, val: banners }))
        // eslint-disable-next-line
    }, [banners])
    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                resolve(e.target.result)
            }
        })
    };
    return (
        <Card className="rounded">
            <CardBody>
                <Row>

                    <Colxx xxs="12" className="mb-2">
                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                            <h4 className="mb-0">{title}</h4>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => setSelectedBanner({ addNew: true, type: 'url', active: true })}>Add new
                                </Button>
                            </div>

                        </div>
                    </Colxx>
                </Row>
                <Separator className="mb-5" />

                <Row>
                    <Colxx xxs="12" className="mb-2">
                        <>
                            <Row className="d-flex align-items-center mt-4">

                                <Colxx xxs="1" />
                                <Colxx xxs="1" style={{ fontWeight: '700' }}></Colxx>
                                <Colxx xxs="1" style={{ fontWeight: '700' }}></Colxx>
                                <Colxx xxs="2" style={{ fontWeight: '700' }}>Type</Colxx>
                                <Colxx xxs="2" style={{ fontWeight: '700' }}>Main Banner</Colxx>
                                <Colxx xxs="3" style={{ fontWeight: '700' }}>Category Banner</Colxx>
                                <Colxx xxs="3" />
                            </Row>
                            <List
                                values={sortedBanners}
                                onChange={({ oldIndex, newIndex }) => {
                                    setSortedBanners(arrayMove(sortedBanners, oldIndex, newIndex))
                                }}
                                renderList={({ children, props }) => <ul className="p-0" {...props}>{children}</ul>}

                                renderItem={({ value, props }) => {
                                    let item = Object.values(banners).find(rec => rec.id === value?.id)
                                    props.style = { ...props.style, borderBottom: '1px solid #f3f3f3', listStyle: 'none' }
                                    return (
                                        <li  {...props} className="mt-3 pb-3" >
                                            <Row className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                <Colxx xxs="1">
                                                    <div>
                                                        <i className="simple-icon-cursor-move" />
                                                    </div>
                                                </Colxx>
                                                <Colxx xxs="1">
                                                    <Switch
                                                        className="custom-switch custom-switch-primary"
                                                        checked={item?.active}
                                                        onChange={switchCheckedPrimary => {
                                                            let newBanners = JSON.parse(JSON.stringify(banners))
                                                            newBanners[item.id] = { ...item, active: switchCheckedPrimary, updated: true }
                                                            setBanners(newBanners)
                                                        }}
                                                    />
                                                </Colxx>
                                                <Colxx xxs="1">
                                                    <img style={{
                                                        width: '60px',
                                                        height: '60px',
                                                        borderRadius: '8px',
                                                        border: '1px solid lightgrey',
                                                        padding: '2px',
                                                        objectFit: 'contain'
                                                        // defaultImages={selectedVariation?.pictures?.length ? [selectedVariation?.pictures?.[0]?.base64] : (selectedVariation && selectedVariation[item.key])}

                                                    }} src={item?.pictures?.length ? item?.pictures?.[0]?.base64 : item?.picture} alt={item?.picture || ''} />
                                                </Colxx>
                                                <Colxx xxs="2">
                                                    <span style={{ textTransform: 'capitalize' }}>{item?.type}</span>
                                                </Colxx>
                                                <Colxx xxs="2">
                                                    <span>{item?.mainBanner ? "True" : "False"}</span>

                                                </Colxx>
                                                <Colxx xxs="2">
                                                    <span>{item?.categoryBanner ? (services?.find(rec => item?.selectedCategory === rec.id)?.name?.en) : "False"}</span>
                                                </Colxx>

                                                <Colxx xxs="3" >
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        outline
                                                        className="float-right"
                                                        // className="top-right-button"
                                                        onClick={() => setSelectedBanner(item)}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        className="float-right mr-2"
                                                        outline
                                                        onClick={() => {
                                                            setDeleteItem(item)
                                                        }}>
                                                        Remove
                                                    </Button>

                                                </Colxx>
                                            </Row>
                                        </li>)
                                }}
                            />
                        </>
                    </Colxx>
                </Row>
            </CardBody>
            {selectedBanner && <Modal
                size='lg'
                isOpen={selectedBanner}>
                <ModalHeader>{selectedBanner?.addNew ? 'New Banner' : 'Edit Banner'}</ModalHeader>
                <ModalBody>
                    {
                        [{
                            key: 'generalInfo', title: 'General Information',
                            data: [
                                { key: 'picture', title: 'Banner Image', width: '12', type: 'picture', show: true },
                                { key: 'buttonGroup', title: 'Select Banner Type', width: '12', type: 'buttonGroup', show: true },
                                { key: 'url', title: 'URL', width: '12', type: 'text', show: selectedBanner.type === 'url' },
                                { key: 'page', title: 'Page Key', width: '12', type: 'text', show: selectedBanner.type === 'page' },
                                { key: 'service', title: 'Service', width: '12', type: 'select', data: services?.filter(r => (activeItem?.general?.services)?.includes(r.id || r.key)), show: selectedBanner.type === 'service' },
                                { key: 'variation', title: 'Select Variation', width: '12', type: 'select', data: Object.values(variations || {}).filter(r => r.service_id === selectedBanner?.service), show: selectedBanner.service && selectedBanner.type === 'service' },
                                { key: 'mainBanner', title: 'Show In Main Banners', width: '12', type: 'checkBox', show: true },
                                { key: 'categoryBanner', title: 'Category Banner', width: '12', type: 'checkBox', show: true },
                                { key: 'selectedCategory', title: 'Select Category', width: '12', type: 'select', data: services?.filter(r => (activeItem?.general?.services)?.includes(r.id || r.key)), show: selectedBanner?.categoryBanner },

                            ]
                        },
                        ].map((rec, recIndex) => {
                            return (
                                <Row key={recIndex} >
                                    <Colxx xxs="12" className="mb-2">
                                        <h4>{rec.title}</h4>
                                    </Colxx>
                                    {rec.data.map((item, index) => {
                                        let selected = (item.data)?.find(rec => selectedBanner?.[item?.key] === rec.id) || false

                                        return item.show && (
                                            <Colxx key={index} xxs="12" md={item.width} className={item.type !== 'checkBox' ? "mb-4" : 'mb-2'}>
                                                {item.type !== 'checkBox' && <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                    <div>{item.title}</div>
                                                    {/* {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: selectedBanner?.[item.key], type: item.type })}>Translate</div>} */}
                                                </div>}
                                                {item.type === 'picture' ?
                                                    <ImageUploader
                                                        className={'w-100'}
                                                        withIcon={true}
                                                        name={item.key}
                                                        label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                        buttonText='Choose images'
                                                        onChange={(picture) => onDrop(picture, item.key)}
                                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                        maxFileSize={15728640}
                                                        withPreview={true}
                                                        singleImage={true}
                                                        defaultImages={selectedBanner && selectedBanner?.[item.key]}
                                                    /> : (item.type === 'buttonGroup') ?
                                                        <ButtonGroup>
                                                            {[{ key: 'url', title: 'URL' }, { key: 'service', title: 'Service' }, { key: 'page', title: 'Page' }].map(r => {
                                                                return (
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            switch (r.key) {
                                                                                case 'url':
                                                                                    delete selectedBanner.page
                                                                                    delete selectedBanner.service
                                                                                    delete selectedBanner.variation
                                                                                    break;
                                                                                case 'service':
                                                                                    delete selectedBanner.page
                                                                                    delete selectedBanner.url
                                                                                    break;
                                                                                case 'page':
                                                                                    delete selectedBanner.service
                                                                                    delete selectedBanner.variation
                                                                                    delete selectedBanner.url
                                                                                    break;
                                                                                default:
                                                                                    break;
                                                                            }

                                                                            setSelectedBanner({ ...selectedBanner, type: r.key, updated: true })
                                                                        }}
                                                                        active={selectedBanner?.type === r.key}
                                                                        outline={selectedBanner.type !== r.key}
                                                                    >
                                                                        {r.title}
                                                                    </Button>
                                                                )
                                                            })}
                                                        </ButtonGroup>
                                                        : (item.type === 'select') ?
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select"
                                                                placeholder={item.title}
                                                                value={(item.data) ? {
                                                                    ...selected,
                                                                    value: selected?.name?.en || selected?.name,
                                                                    label: selected?.name?.en || selected?.name,
                                                                    key: selected.id
                                                                } : {}}

                                                                options={(item.data).map(rec => {
                                                                    return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                })}
                                                                onChange={val => {
                                                                    setSelectedBanner({ ...selectedBanner, [item.key]: val.key, updated: true })
                                                                }}
                                                            /> : (item.type === 'checkBox') ? <CustomInput
                                                                className="custom-checkbox mb-0 d-inline-block"
                                                                type="checkbox"
                                                                id="checkAll"
                                                                checked={selectedBanner?.[item.key]}
                                                                onChange={(e) => {
                                                                    if (item.key === 'categoryBanner' && !e.target.checked) {
                                                                        delete selectedBanner?.selectedCategory
                                                                    }
                                                                    setSelectedBanner({ ...selectedBanner, [item.key]: e.target.checked, updated: true })
                                                                }}
                                                                label={<div style={{ cursor: 'pointer', fontWeight: '700' }}>{item.title}</div>}
                                                            /> :
                                                                <Input
                                                                    type={item.type}
                                                                    placeholder={"e.g. " + item.title}
                                                                    value={selectedBanner?.[item.key]}
                                                                    className="customInput"
                                                                    onChange={(e) => {

                                                                        setSelectedBanner({ ...selectedBanner, [item.key]: e.target.value, updated: true })

                                                                    }} />}
                                            </Colxx>
                                        )
                                    })}
                                </Row>
                            )
                        })}
                </ModalBody>
                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => setSelectedBanner(false)}>Cancel</Button>
                    <Button
                        color='primary'
                        className='m-1'
                        disabled={checkIfDisabled(JSON.parse(JSON.stringify(selectedBanner)), ['pictures', 'type']) && checkIfDisabled(JSON.parse(JSON.stringify(selectedBanner)), ['picture', 'type'])}
                        onClick={() => {
                            submit(selectedBanner.id)
                        }}>Save</Button>
                </ModalFooter>
            </Modal>}
            <Modal backdrop="static" isOpen={deleteItem}>
                <ModalHeader >
                    Delete Banner
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            <Label className="mb-2">Are you sure you want to delete this banner?</Label>
                        </Colxx>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setDeleteItem(false)
                    }}>Dismiss</Button>
                    <Button color='danger' onClick={() => {
                        deleteItemFunc(deleteItem)
                    }}>Delete</Button>
                </ModalFooter>

            </Modal>

        </Card>

    );
};

export default Banners;