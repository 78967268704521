import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    InputGroup,
    CustomInput,
    InputGroupAddon,
    Collapse,
} from "reactstrap";
import classnames from "classnames";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { firestore, functions } from '../../../../helpers/Firebase'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import Select, { Creatable } from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { checkIfDisabled, createNotification, uploadFile, uploadPicture } from "../../../../helpers/Utils";
import TranslatePopup from "../../../../components/common/translatePopup";
import NewHelper from "../../../../components/common/newHelper";
import NewRichProfile from "../../../../components/common/newRichProfile";
import moment from "moment";
import UpdateCredentials from "../../../../components/common/UpdateCredentials";


const Professional = ({
    dismiss,
}) => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const { types, specialities, conditions, services, languages, nationalities } = useSelector(state => state.settings)
    const { activeItem } = useSelector(state => state.activeItem)
    const dispatch = useDispatch()
    const { allProviders } = useSelector(state => state.providers)
    const [newHelper, setNewHelper] = useState(false)
    const [newRichProfile, setNewRichProfile] = useState(false)
    const [selectedRichProfile, setSelectedRichProfile] = useState(false)

    const [professionalPictures, setProfessionalPictures] = useState([])
    const [updatedProfessional, UpdateProfessional] = useState(activeItem.general || {})
    const [translateObject, showTranslatePopup] = useState(false)
    const [updateUser, setUpdateUser] = useState(false)


    const tabs = [
        {
            name: 'General Information',
            key: 1,
            data: [
                { key: 'picture', title: 'Professional Picture', width: '10', type: 'img' },
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Bio', width: '10', type: 'textarea', translateButton: true },
                { key: 'languages', title: 'Language spoken', width: '10', type: 'multi-select', data: languages, selected: 'languages', formatCreateLabel: 'Add New Language', },
                { key: 'gender', title: 'Gender', width: '10', type: 'select', data: [{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }] },
                { key: 'nationality', title: 'Nationality', width: '10', type: 'multi-select', data: nationalities, selected: 'nationalities', formatCreateLabel: 'Add New Nationality', },

            ]
        },
        {
            name: 'Medical Information', key: 2,
            data: [
                { key: 'type', title: 'Professional Type', width: '10', type: 'select', data: types },
                { key: 'speciality', title: 'Speciality', width: '10', type: 'select', data: specialities, hide: updatedProfessional?.type !== 'pg0QcbtGUHEeN5CxsXWt' },
                { key: 'title', title: 'Title', width: '10', type: 'select', data: [{ id: 'specialist', name: 'Specialist' }, { id: 'consultant', name: 'Consultant' }], hide: updatedProfessional?.type !== 'pg0QcbtGUHEeN5CxsXWt' },
                { key: 'areas_of_interest', title: 'Areas of interest', width: '10', type: 'multi-select', data: conditions, selected: 'conditions', formatCreateLabel: 'Add New Condition', hide: updatedProfessional?.type !== 'pg0QcbtGUHEeN5CxsXWt' },
                { key: 'services_of_interest', title: 'Services of interest', width: '10', type: 'multi-select', data: services, hide: updatedProfessional?.type !== 'pg0QcbtGUHEeN5CxsXWt' },
                { key: 'started_practice ', title: 'Year started medical practice', width: '10', type: 'date', hide: updatedProfessional?.type !== 'pg0QcbtGUHEeN5CxsXWt' },

            ]
        },
        {
            name: 'Provider', key: 3,
            data: [
                { key: 'provider_id', title: 'Select Provider', width: '10', type: 'select', data: allProviders },
                { key: 'services', title: 'Services Provided', width: '10', type: 'multi-select', data: services },
            ]
        },
        {
            name: 'Medical License', key: 4,
            data: [
                { key: 'license_number', title: 'License Number', width: '10', type: 'number' },
                { key: 'expiry_date', title: 'Expiry Date ', width: '10', type: 'date' },
                { key: 'medicalLicenseFront', title: 'Upload Front', width: '10', type: 'dropzone' },
                { key: 'medicalLicenseBack', title: 'Upload Back', width: '10', type: 'dropzone' },

            ]
        },
        {
            name: 'Account Details',
            key: 5,
            data: [
                { key: 'email', title: 'Email', width: '10', type: 'email', updateCredentials: true },
                { key: 'password', title: 'Password', width: '10', type: 'password', hide: !updatedProfessional.addNew, autocomplete: 'on' },
                { key: 'phoneNumber', title: 'Number', width: '10', type: 'phoneNumber', updateCredentials: true }
            ]
        },



        {
            name: 'Rich Profile', key: 6,
            width: 100,
            data: [
                {
                    key: 'medical_school', title: 'Medical School', width: '12', type: 'rich_profile',
                    elements: [
                        { key: 'degree', title: 'Degree', type: 'text' },
                        { key: 'facility', title: 'Facility', type: 'text' },
                        { key: 'country', title: 'Country', type: 'text' },
                        { key: 'completion_date', title: 'Completion date', type: 'date' },
                    ]
                },
                {
                    key: 'residency', title: 'Residency', width: '12', type: 'rich_profile',
                    elements: [
                        { key: 'specialty', title: 'Specialty', type: 'text' },
                        { key: 'facility', title: 'Facility', type: 'text' },
                        { key: 'country', title: 'Country', type: 'text' },
                        { key: 'completion_date', title: 'Completion date', type: 'date' },
                    ]
                },
                {
                    key: 'fellowship', title: 'Fellowship', width: '12', type: 'rich_profile',
                    elements: [
                        { key: 'specialty', title: 'Specialty', type: 'text' },
                        { key: 'facility', title: 'Facility', type: 'text' },
                        { key: 'country', title: 'Country', type: 'text' },
                        { key: 'completion_date', title: 'Completion date', type: 'date' },
                    ]
                },
                {
                    key: 'experience', title: 'Experience', width: '12', type: 'rich_profile',
                    elements: [
                        { key: 'Title', title: 'Title', type: 'text' },
                        { key: 'Specialty', title: 'Specialty', type: 'text' },
                        { key: 'Facility', title: 'Facility', type: 'text' },
                        { key: 'Country', title: 'Country', type: 'text' },
                        { key: 'City', title: 'City', type: 'text' },
                        { key: 'From', title: 'From', type: 'date' },
                        { key: 'to', title: 'To', type: 'date' },
                    ]
                },
                {
                    key: 'awards', title: 'Awards', width: '12', type: 'rich_profile',
                    elements: [
                        { key: 'name', title: 'Award name', type: 'text', width: 2 },
                        { key: 'organization_name', title: 'Awarding organization name', type: 'text', width: 2 },
                        { key: 'country', title: 'Country', type: 'text', width: 2 },
                        { key: 'city', title: 'City', type: 'text', width: 2 },
                        { key: 'date', title: 'Award date', type: 'date', width: 2 },
                    ]
                },
            ]
        },

    ]


    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    setProfessionalPictures(pictures)

                },
                'blob'
            );

        } else {
            setProfessionalPictures(pictures)

            UpdateProfessional({ ...updatedProfessional, [type]: [], updated: true })
        }
    }
    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedProfessional }))
        // eslint-disable-next-line
    }, [updatedProfessional])

    const submit = async () => {
        // let { general } = JSON.parse(JSON.stringify(activeItem));
        let { general } = activeItem;

        let provider_id = updatedProfessional.provider_id
        let id = general.id || firestore().collection('providers').doc(provider_id).collection("professionals").doc().id
        if (general) {
            if (general.addNew && general?.email) {
                general.active = true
                general.created = Date.now()
                const createUser = functions().httpsCallable('createAccounts');
                let result = await createUser({ uid: id, email: general?.email || '', pw: general?.password || '', role: 'professional', parent_id: provider_id, phoneNumber: general?.phoneNumber || '' })

                if (result?.data?.errorInfo) {
                    setLoading(false)
                    return createNotification("error", result?.data?.errorInfo?.message, 'Error creating user ' + general.email)
                }
            }
            delete general.addNew
            delete general.updated
            delete general.password
            general.deactivated = false
            general.lastUpdate = Date.now()
            if (professionalPictures && professionalPictures.length > 0 && professionalPictures[0]?.name) {
                uploadPicture(professionalPictures, 'providers', provider_id, 'picture', 'professionals', id)
            }
            if (general?.medicalLicenseFront?.files) {
                uploadFile(general?.medicalLicenseFront?.files, 'providers', provider_id, 'medicalLicenseFront', 'professionals', id)
                delete general?.medicalLicenseFront?.files
            }
            if (general?.medicalLicenseBack?.files) {
                uploadFile(general?.medicalLicenseBack?.files, 'providers', provider_id, 'medicalLicenseBack', 'professionals', id)
                delete general?.medicalLicenseBack?.files

            }
            await firestore().collection('providers').doc(provider_id).collection("professionals").doc(id).set(general, { merge: true })
        }

        dispatch(SetActiveItem(false))
        setLoading(false)
    }

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedProfessional?.addNew ? 'New Professional' : updatedProfessional?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                {tabs.map((rec, recIndex) => {
                                    return (
                                        <TabPane tabId={rec.key} key={recIndex}>
                                            <Card className="rounded">
                                                <CardBody>

                                                    <Row className={`w-${rec.width || 80}`}>
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.name}</h4>
                                                        </Colxx>

                                                        {rec.data.map((item, index) => {
                                                            let selected = (item.data)?.find(rec => updatedProfessional?.[item?.key] === rec.id) || false
                                                            return !item.hide && (
                                                                <Colxx key={index} xxs="12" md={item.width} style={item.type === 'rich_profile' ? { border: '1px solid #00000030', borderRadius: '8px', marginRight: '1rem', marginLeft: '1rem' } : {}} className="mb-4">
                                                                    {item.type !== 'rich_profile' ? <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>

                                                                        <div>{item.title}</div>
                                                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: updatedProfessional?.[item.key], type: item.type })}>Translate</div>}
                                                                        {item.updateCredentials && !updatedProfessional?.addNew && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => setUpdateUser({ ...item, uid: updatedProfessional?.id, selectedType: 'professionals', parent_id: updatedProfessional.provider_id, parent: 'providers' })}>{`Update ${item.title}`}</div>}

                                                                    </div> : <div className="d-flex align-items-center" style={{
                                                                        justifyContent: 'space-between', cursor: 'pointer', padding: '16px 0',
                                                                    }}
                                                                        onClick={() => {
                                                                            setSelectedRichProfile(item)
                                                                        }}>
                                                                        <div style={{ fontWeight: '700' }} ><i className={item.title === selectedRichProfile?.title ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} style={{ marginRight: '12px' }} /> {item.title}</div>
                                                                        <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => setNewRichProfile(item)}>Add New</div>
                                                                    </div>}
                                                                    {item.type === 'img' ?
                                                                        <ImageUploader
                                                                            className={'w-100'}
                                                                            withIcon={true}
                                                                            name={item.key}
                                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                            buttonText='Choose images'
                                                                            onChange={(picture) => onDrop(picture, item.key)}
                                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                            maxFileSize={15728640}
                                                                            withPreview={true}
                                                                            singleImage={true}
                                                                            defaultImages={updatedProfessional && updatedProfessional[item.key]}
                                                                        /> : item.type === 'phoneNumber' ?
                                                                            <PhoneInput
                                                                                regions={['middle-east', 'north-africa']}
                                                                                inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                                                className="customInput"
                                                                                country={'ae'}
                                                                                disabled={!updatedProfessional?.addNew}
                                                                                placeholder="e.g. +971xxxxxx"
                                                                                value={updatedProfessional?.[item.key]}
                                                                                onChange={(value, data, event, formattedValue) =>
                                                                                    UpdateProfessional({ ...updatedProfessional, [item.key]: formattedValue, country_code: data.dialCode, number: value.slice(data.dialCode.length), updated: true })
                                                                                }
                                                                            /> : item.type === 'multi-select' ?
                                                                                <Creatable
                                                                                    isMulti
                                                                                    style={{ width: '100%' }}
                                                                                    placeholder={item?.title}
                                                                                    components={{ Input: CustomSelectInput, }}
                                                                                    className={`basic-multi-select`}
                                                                                    value={(item?.data)?.map(rec => {
                                                                                        return (updatedProfessional?.[item.key]?.includes(rec.id)) ?
                                                                                            ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id }) : ''
                                                                                    })}
                                                                                    options={(item?.data)?.map(rec => {
                                                                                        return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                                    })}

                                                                                    onChange={(val) => {
                                                                                        UpdateProfessional({ ...updatedProfessional, [item.key]: val.map(rec => rec.key), updated: true })
                                                                                    }}
                                                                                    isValidNewOption={() => { return item?.formatCreateLabel ? true : false }}
                                                                                    formatCreateLabel={() => item?.formatCreateLabel || undefined}
                                                                                    onCreateOption={() => {
                                                                                        setNewHelper(item)
                                                                                    }}
                                                                                />
                                                                                :
                                                                                (item.type === 'select') ?
                                                                                    <Select
                                                                                        components={{ Input: CustomSelectInput }}
                                                                                        className="basic-multi-select"
                                                                                        placeholder={item.title}
                                                                                        value={(item.data) && selected ? {
                                                                                            ...selected,
                                                                                            value: selected?.name?.en || selected?.name,
                                                                                            label: selected?.name?.en || selected?.name,
                                                                                            key: selected.id
                                                                                        } : ''}

                                                                                        options={(item.data).map(rec => {
                                                                                            return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                                        })}
                                                                                        onChange={val => {
                                                                                            if (item.key === 'provider_id') {
                                                                                                UpdateProfessional({ ...updatedProfessional, provider_id: val.key, provider_name: val?.name?.en || val?.name, updated: true })
                                                                                            } else {
                                                                                                UpdateProfessional({ ...updatedProfessional, [item.key]: val.key, updated: true })
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    : item.type === 'dropzone' ?
                                                                                        <InputGroup>
                                                                                            <CustomInput
                                                                                                type="file"
                                                                                                id="exampleCustomFileBrowser4"
                                                                                                name="customFile"
                                                                                                label={updatedProfessional?.[item.key]?.name}
                                                                                                onChange={async ({ target: { files } }) => {
                                                                                                    const [{ name }] = files;
                                                                                                    UpdateProfessional({ ...updatedProfessional, [item.key]: { name: name, files: files }, updated: true })
                                                                                                }}
                                                                                            />
                                                                                            {updatedProfessional?.[item.key] ? <InputGroupAddon addonType="append">
                                                                                                <Button outline color="danger" onClick={() => {
                                                                                                    delete updatedProfessional?.[item.key]
                                                                                                    UpdateProfessional({ ...updatedProfessional, updated: true })
                                                                                                }}>
                                                                                                    <i className="glyph-icon simple-icon-trash" />
                                                                                                </Button>
                                                                                            </InputGroupAddon> : ''}
                                                                                            {updatedProfessional?.[item.key]?.license ? <InputGroupAddon addonType="append">
                                                                                                <Button outline color="primary" onClick={() => {
                                                                                                    window.open(updatedProfessional?.[item.key]?.license, '_blank')
                                                                                                }}>
                                                                                                    <i className="glyph-icon simple-icon-eye" />
                                                                                                </Button>
                                                                                            </InputGroupAddon> : ''}
                                                                                        </InputGroup>
                                                                                        : item.type === 'rich_profile' ?

                                                                                            <Collapse isOpen={item.title === selectedRichProfile.title}>
                                                                                                {(updatedProfessional?.[item.key]?.length ?
                                                                                                    <>
                                                                                                        <Row className="d-flex align-items-center ml-4 mr-4 mb-3 mt-2">
                                                                                                            {
                                                                                                                item.elements?.map(a => {
                                                                                                                    return (
                                                                                                                        <Colxx xxs={a.width} className='font-weight-bold'>
                                                                                                                            {a.title}
                                                                                                                        </Colxx>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            <Colxx className='font-weight-bold'>
                                                                                                                Created
                                                                                                            </Colxx>
                                                                                                        </Row>
                                                                                                        {updatedProfessional?.[item.key]?.map((r, ind) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <Row key={r} className="d-flex align-items-center ml-4 mr-4 mt-3 mb-3">

                                                                                                                        {
                                                                                                                            item.elements?.map(a => {
                                                                                                                                return (
                                                                                                                                    <Colxx xxs={a.width} className='font-weight-bold'>
                                                                                                                                        {r?.[a.key]}
                                                                                                                                    </Colxx>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                        <Colxx className='font-weight-bold'>
                                                                                                                            {moment(r.created).format('DD-MM-YYYY')}
                                                                                                                        </Colxx>
                                                                                                                    </Row>
                                                                                                                    {(ind !== updatedProfessional?.[item.key]?.length - 1) && <Separator className="ml-4" />}
                                                                                                                </>
                                                                                                            )
                                                                                                        })}
                                                                                                    </>
                                                                                                    : <div style={{
                                                                                                        height: '100px',
                                                                                                        display: 'flex',
                                                                                                        fontSize: '14px',
                                                                                                        fontWeight: '600',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'center',
                                                                                                    }}>No Records</div>)}
                                                                                            </Collapse>
                                                                                            : <Input
                                                                                                type={item.type}
                                                                                                disabled={!updatedProfessional?.addNew && item.key === 'email'}
                                                                                                autocomplete={item.autocomplete}
                                                                                                placeholder={"e.g. " + item.title}
                                                                                                value={updatedProfessional?.[item.key]?.en || updatedProfessional?.[item.key]}
                                                                                                className="customInput"
                                                                                                onChange={(e) => {
                                                                                                    if (item.parent) {
                                                                                                        UpdateProfessional({ ...updatedProfessional, [item.parent]: { ...updatedProfessional?.[item.parent], [item.key]: e.target.value, }, updated: true })
                                                                                                    } else {
                                                                                                        if (item?.translateButton) {
                                                                                                            UpdateProfessional({ ...updatedProfessional, [item.key]: { ...updatedProfessional?.[item.key], en: e.target.value }, updated: true })
                                                                                                        } else {
                                                                                                            UpdateProfessional({ ...updatedProfessional, [item.key]: e.target.value, updated: true })
                                                                                                        }
                                                                                                    }

                                                                                                }} />
                                                                    }
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    )
                                })}
                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedProfessional)), ['provider_id', 'name', 'phoneNumber'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        UpdateProfessional({ ...updatedProfessional, [translateObject.key]: e, updated: true })

                        showTranslatePopup(false)
                    }} />
                    }
                    {newHelper ? <NewHelper
                        title={newHelper?.formatCreateLabel}
                        selected={newHelper?.selected}
                        updateHelpers={(e) => {
                            let ids = updatedProfessional?.[newHelper.key] || []
                            ids.push(e.id)
                            UpdateProfessional({ ...updatedProfessional, [newHelper.key]: ids, updated: true })
                            setNewHelper(false)
                        }}
                        dismiss={() => setNewHelper(false)}
                        newHelper={newHelper}
                    /> : ''}
                    {newRichProfile ? <NewRichProfile
                        title={`New ${newRichProfile?.title}`}
                        selected={newRichProfile?.key}
                        elements={newRichProfile?.elements}
                        updateHelpers={(e) => {
                            let data = updatedProfessional?.[newRichProfile.key] || []
                            delete e.addNew
                            delete e.updated
                            e.created = Date.now()
                            data.push(e)
                            UpdateProfessional({ ...updatedProfessional, [newRichProfile.key]: data, updated: true })
                            setNewRichProfile(false)
                        }}
                        dismiss={() => setNewRichProfile(false)}
                        newRichProfile={newRichProfile}
                    /> : ''}
                    {updateUser ? <UpdateCredentials
                        title={`Update ${updateUser?.title}`}
                        selected={updateUser?.key}
                        elements={[updateUser]}
                        updateCredentials={(e) => {

                            UpdateProfessional({ ...updatedProfessional, [updateUser.key]: e?.[updateUser?.key], updated: true })
                            setUpdateUser(false)
                        }}
                        dismiss={() => setUpdateUser(false)}
                        updateUser={updateUser}
                    /> : ''}
                </Fragment >
            ))
    );
};

export default Professional;