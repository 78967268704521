import React, { useEffect, useState } from 'react';
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { firestore } from '../../../../helpers/Firebase';
import { generateMsg } from '../../../../helpers/Utils';
import { useSelector } from 'react-redux';
const colors = {
  new: "#c43d4b",
  pending: "#c43d4b",
  accepted: "#145388",
  ontheway: "#3e884f",
  active: "#3195a5",
  completed: "#3e884f",
  rated: "#b69329",
  canceled: "#000",
  rejected: "#000",
}
const Logs = ({ dismiss, logsOpen, selectedBooking }) => {
  const [logs, setLogs] = useState([])
  const { user } = useSelector(state => state.authUser)


  useEffect(() => {
    (async () => {
      let logsData = await firestore().collection('orders').doc(selectedBooking.id).collection('log').orderBy('created').get()
      if (!logsData.empty) {
        setLogs(logsData.docs.map(rec => rec.data()).filter((r => user.role !== 'superAdmin' ? r.title !== 'viewRequest' : r)))
      }
    })()


  }, [selectedBooking, user])
  return (
    <Modal
      isOpen={logsOpen}
      style={{ boxShadow: 'none', maxWidth: '600px', top: '30px', overflow: 'hidden', borderRadius: '14px' }}
      toggle={dismiss}
      backdrop="static"
      size="lg">
      <ModalHeader toggle={dismiss}>Logs</ModalHeader>
      <ModalBody >
        <div className="dashboard-logs">
          {logs?.length ? <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}>
            <table className="table table-sm table-borderless">
              <tbody>
                {logs?.map((log, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span style={{ borderColor: colors[log.currentStatus] }}
                          className={`log-indicator align-middle ${log.color
                            }`}
                        />
                      </td>
                      <td>
                        <span className="font-weight-medium">
                          {generateMsg(log, selectedBooking)}
                        </span>
                      </td>
                      <td className="text-right" style={{ width: '29%' }}>
                        <span className="text-muted">{moment(log.created).format("DD/MM/YYYY hh:mm A")}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </PerfectScrollbar> :
            <div style={{
              width: '100%',
              fontSize: '18px',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '600'
            }}>No data to show</div>
          }
        </div>
      </ModalBody >
    </Modal >
  );
};

export default Logs;