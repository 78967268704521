import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import providers from './providers/reducer';
import bookings from './bookings/reducer';
import partners from './partners/reducer';
import activeItem from './activeItem/reducer';
import chatApp from './chat/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  providers,
  bookings,
  chatApp,
  partners,
  activeItem
});

export default reducers;