import React, { Fragment, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Button,
} from "reactstrap";
import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import Payments from "./payments";
import Invoices from "./Invoices";
import SOA from "./SOA";
import { useSelector } from "react-redux";


const AdvancedReports = ({
    dismiss,
}) => {
    const [activeTab, setActiveTab] = useState(1)

    const { activeItem } = useSelector(state => state.activeItem)
    const [provider] = useState(activeItem?.general || {})

    const [tabs] = useState([
        { name: 'Payments', key: 1 },
        { name: 'Invoices', key: 2 },
        { name: 'Statment Of Account', key: 3 },
    ])
    return (
        <Fragment>
            <Row>
                <Colxx xxs="12">
                    <h1>{provider?.name?.en}</h1>
                    <Nav tabs className="separator-tabs ml-0 mb-5">
                        {
                            tabs.map(item => {
                                return !item.hide && (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        key={item.key}
                                        className={classnames({
                                            active: activeTab === item.key,
                                            "nav-link": true
                                        })}
                                        onClick={() => {
                                            setActiveTab(item.key);
                                        }}>
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </Nav>
                    <TabContent activeTab={activeTab} className="mt-4">
                        <TabPane tabId={1}>
                            <Payments provider={provider} />
                        </TabPane>
                        <TabPane tabId={2}>
                            <Invoices provider={provider} />
                        </TabPane>
                        <TabPane tabId={3}>
                            <SOA provider={provider} />
                        </TabPane>
                    </TabContent>
                    <Button
                        color="danger"
                        className="btn-shadow float-right m-4"
                        size="lg"
                        onClick={() => dismiss()}>
                        Dismiss
                    </Button>
                </Colxx>
            </Row>
        </Fragment >
    );
};

export default AdvancedReports;