import React, { useEffect, useState } from "react";
import { Row, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import { firestore } from '../../../../helpers/Firebase'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
const Loyalty = ({
    dismiss,
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { activeItem } = useSelector(state => state.activeItem)
    const [updatedLoyalty, UpdateLoyalty] = useState(activeItem?.settings?.loyalty || {})

    useEffect(() => {
        dispatch(UpdateItem({ key: 'settings', val: updatedLoyalty }))
        // eslint-disable-next-line
    }, [updatedLoyalty])

    const submit = async () => {
        let { general, settings } = JSON.parse(JSON.stringify(activeItem));
        let id = general.id
        if (general) {
            delete general.updated
            general.deactivated = false
            general.lastUpdate = Date.now()

            await firestore().collection('partners').doc(id).collection("settings").doc('loyalty').set(settings, { merge: true }).then(() => {
                firestore().collection('partners').doc(id).update({ lastUpdate: Date.now() })
            })
        }
        dispatch(SetActiveItem(false))
        setLoading(false)
    }


    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Modal wrapClassName="modal-right" backdrop="static" isOpen={activeItem} >
                    <ModalHeader >
                        <h1>{"Edit Loyalty"}</h1>

                    </ModalHeader>
                    <ModalBody>
                        <Colxx xxs="12">
                            {[{
                                key: 'generalInfo',
                                title: 'General Information',
                                data: [
                                    { key: 'gift', title: 'Referral Gift', desc: 'Credit added to referee on signup', width: '12', type: 'number' },
                                    { key: 'signupReward', title: 'Signup Reward', desc: 'Credit added to referrer on signup of the referee', width: '12', type: 'number' },
                                    { key: 'orderReward', title: '1st Order Reward', desc: 'Credit added to referrer on first order of the referee', width: '12', type: 'number' },
                                    { key: 'expiration', title: 'Points Expiration (Days)', desc: 'Number of days after which user credit will expire', width: '12', type: 'number' },
                                    { key: 'capPercent', title: 'Maximum Redeemed Percent (%)', width: '12', type: 'number' },
                                    { key: 'capValue', title: 'Maximum Redeemed Value (AED)', width: '12', type: 'number' },
                                    // { key: 'percent', title: 'Loyalty Percent', width: '12', type: 'number' },
                                ]
                            }].map((rec, recIndex) => {
                                return (
                                    <Row key={recIndex}>
                                        <Colxx xxs="12" className="mb-2">
                                            <h4>{rec.title}</h4>
                                        </Colxx>
                                        {rec.data.map((item, index) => {
                                            return !item.hide && (
                                                <Colxx key={index} xxs="12" md={item.width} className="mb-3">
                                                    <div className="mb-2" >
                                                        <div>{item.title}</div>
                                                        {item.desc && <div style={{
                                                            fontSize: '10px',
                                                            color: '#a7a0a0'
                                                        }}>{item.desc}</div>}
                                                    </div>

                                                    <Input
                                                        type={item.type}
                                                        placeholder={"e.g. " + item.title}
                                                        value={updatedLoyalty?.[item.key]}
                                                        className="customInput"
                                                        onChange={(e) => {
                                                            UpdateLoyalty({ ...updatedLoyalty, [item.key]: (e.target.value), updated: true })
                                                        }} />
                                                </Colxx>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                            <ModalFooter>

                                <Button
                                    color="danger"
                                    className="btn-shadow float-right "
                                    size="lg"
                                    onClick={() => dismiss()}>
                                    Dismiss
                                </Button>
                                <Button
                                    color="primary"
                                    className="btn-shadow float-right "
                                    size="lg"
                                    onClick={() => {
                                        setLoading(true)
                                        submit()
                                    }}>
                                    Save Changes
                                </Button>
                            </ModalFooter>

                        </Colxx>
                    </ModalBody>
                </Modal>
            ))
    );
};

export default Loyalty;