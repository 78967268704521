import React from "react";
import { Separator } from "./CustomBootstrap";
import metadocLogo from '../../assets/img/metadocLogo.png'
import moment from "moment";
import { ListServiceVariations } from "../../helpers/Utils";

const PDFComponent = ({ order, id }) => {

    return (
        <div style={{ width: '100vw', height: '1000px', position: 'absolute', left: '-5000px' }} id={id}  >
            {/* <div style={{ width: '100%', position: 'relative' }} id="customerRecepdf"  > */}
            <div style={{ backgroundColor: '#f6f9fc', padding: '50px 0' }} className={'d-flex flex-column justify-content-between align-items-center'}>
                <img src={metadocLogo} alt="" width={'100px'} height={'100px'} />
                <div style={{
                    verticalAlign: 'middle',
                    color: '#32325d',
                    fontSize: '24px',
                    lineHeight: '32px',
                    textAlign: 'center'

                }}>Receipt from Metadoc</div>
                <div className="mt-3 mb-4" style={{
                    verticalAlign: 'middle',
                    color: '#8898aa',
                    fontSize: '15px',
                    lineHeight: '18px',
                    textAlign: 'center'

                }}>{`Receipt ${order?.invoiceNumber}`}</div>
            </div>
            <div style={{ padding: '60px' }} id="customerRecepdf"  >

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {[
                        { title: 'Customer Name', data: `${order?.contact?.name}` },
                        { title: 'Customer Number', data: `${order?.contact?.phoneNumber}` },
                        { title: 'AMOUNT PAID', data: `AED ${order?.receipt?.total}` },
                        { title: 'DATE PAID', data: moment(order?.completed).format("MMM DD, YYYY, hh:mm:ss A") },
                        { title: 'PAYMENT METHOD', data: order.paymentType === 'cash' ? 'CASH PAYMENT' : 'CARD PAYMENT' }
                    ].map((item, index) => {
                        return (
                            <div key={index}>
                                <div style={{
                                    verticalAlign: 'middle',
                                    color: '#8898aa',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}>{item.title}</div>
                                <div style={{
                                    verticalAlign: 'middle',
                                    color: '#525f7f',
                                    fontSize: '15px',
                                    lineHeight: '24px',
                                    whiteSpace: 'nowrap'
                                }}>{item.data}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="mt-5" style={{
                    color: '#687385',
                    fontSize: '12px',
                    lineHeight: '16px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'

                }}>SUMMARY</div>

                <table className="mt-3" style={{
                    borderRadius: '8px',
                    background: ' rgb(246, 249, 252)',

                }} width="100%">
                    <tbody >
                        {[...ListServiceVariations(order), { name: 'Discount', price: order?.receipt?.discount, hide: !order?.receipt?.discount }].map((item => {
                            return !item.hide && (
                                <>
                                    <tr>
                                        <td class="Table-description Font Font--body"
                                            style={{
                                                border: 0,
                                                verticalAlign: 'middle',
                                                color: '#525f7f',
                                                fontSize: '15px',
                                                lineHeight: '24px',
                                                padding: '25px',

                                            }}>
                                            {`${item?.qty ? item.qty + 'X' : ''} ${item.name}`}
                                        </td>
                                        <td class="Table-amount Font Font--body" align="right" valign="top"
                                            style={{
                                                verticalAlign: 'middle', color: '#525f7f', fontSize: '15px', lineHeight: '24px',
                                                padding: '25px',
                                            }}
                                        >
                                            AED {item.price}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="Spacer" bgcolor="e6ebf1" colspan="3" height="1" style={{}}> </td>
                                    </tr>
                                </>


                            )
                        }))}

                        <tr>
                            <td class="Table-description Font Font--body" style={{
                                border: 0,
                                verticalAlign: 'middle',
                                color: '#525f7f',
                                fontSize: '15px',
                                lineHeight: '24px',
                                padding: '25px',

                            }}>
                                <strong>Amount charged</strong>
                            </td>
                            <td class="Table-amount Font Font--body" align="right" valign="top"
                                style={{
                                    verticalAlign: 'middle', color: '#525f7f', fontSize: '15px', lineHeight: '24px',
                                    padding: '25px',
                                }}>
                                <strong>{`AED ${order?.receipt?.total}`}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Separator className='mt-5 mb-4' />
                <div style={{
                    color: '#414552',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px',

                }}>If you have any questions, contact us at <a style={{
                    color: '#625afa',
                    fontWeight: 'bold',
                }} href="mailto:letstalk@metadocapp.com">letstalk@metadocapp.com</a></div>

            </div>
        </div >
    );
};

export default PDFComponent;


