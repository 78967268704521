import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./helpers/Firebase";
import AppLocale from "./lang";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import main from "./views";
import app from "./views/app";
import user from "./views/user";
import error from "./views/error";
import { createNotification, getDirection } from "./helpers/Utils";
import { auth, firestore } from "./helpers/Firebase";
import {
  logoutUser,
  loginUserSuccess,
  GetAllServices,
  GetAllAddons,
  GetAllCategories,
  GetAllSepcialities,
  GetAllConditions,
  GetAllBrands,
  GetAllSymptoms,
  GetAllSigns,
  GetAllTypes,
  GetAllPromos,
  GetAllVariationsCategories,
  GetAllLanguages,
  GetAllNationalities,
  GetAllDripIngredients,
  GetAllOrgans,
  GetAllSampleTypes,
  GetAllTestParameters,
  GetAllActiveSubstances,
  GetAllSubscriptions
} from "./redux/actions";
import { GetAllRequests, GetAssignedRequests, GetDelayedAppointments } from "./redux/bookings/actions"
import moment from "moment";
import verify_email_login from "./views/user/verify_email_login";
import { GetAllPartners } from "./redux/partners/actions";
import { GetAllProfessionals, GetAllProviders } from "./redux/providers/actions";

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser.uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
};

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentWillMount() {
    let unsubscribe = [];
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }

    auth().onAuthStateChanged(user => {
      // let _this = this
      if (user) {
        this.setState({ loading: true }, () => {
          user.getIdTokenResult(true).then(async result => {
            let { role, parent_id, passwordRequired, brand_id } = result.claims
            let post = {
              email: user.email,
              uid: user?.uid,
              displayName: user.displayName,
              parent_id: parent_id,
              role: role,
              phoneNumber: user.phoneNumber,
              passwordRequired,
              brand_id: brand_id
            };
            if (!["admin", "superAdmin", "concierge", "accountant"].includes(role)) {
              await auth().signOut()
              localStorage.removeItem('user_id');
              localStorage.removeItem('role');
              this.props.loginUserSuccess({})
              this.setState({ loading: false })
              return createNotification("error", 'This user is not authorized to login to dashboard', "Error")
            }
            [
              { action: 'GetAllPartners', ref: firestore().collection('partners'), id: "partners" },
              { action: 'GetAllProfessionals', ref: firestore().collectionGroup('professionals'), id: "professionals" },
              { action: 'GetAllProviders', ref: firestore().collection('providers'), id: "providers" },
            ].map(rec => {
              let ref = rec.ref.where('deactivated', '!=', true)
              if (role !== 'superAdmin' && role !== 'concierge' && parent_id) {
                ref = ref.where(rec.id === "professionals" ? 'provider_id' : 'id', '==', parent_id)
              } else if (role !== 'superAdmin' && rec.id === "partners" && parent_id) {
                ref = ref.where(rec.id === "partners" ? 'url' : 'id', '==', parent_id)

              }
              let listner = ref.onSnapshot(snap => {
                let data = snap.docs.map(rec => {
                  return ({ ...rec.data(), id: rec.id })
                })
                this.props[rec.action](data);

              })
              return unsubscribe.push(listner)
            })

            let requestsRef = firestore().collection('orders')
            switch (role) {
              case 'concierge':
                requestsRef = requestsRef.where('status', 'in', ['pending']).where("brand.id", '==', brand_id)
                break;
              case 'admin':
                requestsRef = requestsRef.where('status', 'in', ['pending']).where("candidates", 'array-contains', parent_id)

                let assignedRef = firestore().collection('orders').where('status', '==', 'accepted').where("candidates", 'array-contains', parent_id)
                let assignedSub = assignedRef.onSnapshot(snap => {
                  let data = snap.docs.map(rec => {
                    return ({ ...rec.data(), id: rec.id, alreadyAssigned: true })
                  })?.filter(r => r?.provider?.id !== parent_id && r?.created >= moment().subtract(15, 'minutes').valueOf())
                  this.props.GetAssignedRequests(data)
                });
                unsubscribe.push(assignedSub);

                break;
              case 'superAdmin':
                requestsRef = requestsRef.where('status', 'in', ['new', 'pending'])
                break;
              default:
                requestsRef = requestsRef.where('status', 'in', ['new', 'pending'])
                break;
            }


            let sub = requestsRef.onSnapshot(snap => {
              let data = snap.docs.map(rec => {
                return ({ ...rec.data(), id: rec.id })
              })
              this.props.GetAllRequests(data)

            });
            unsubscribe.push(sub);

            let ref2 = firestore().collection('orders').where('bookingDate.date', '<=', moment().add(20, 'minutes').valueOf()).where('status', 'in', ['accepted', 'ontheway', 'active'])
            switch (role) {
              case 'concierge':
                ref2 = ref2.where("brand.id", '==', brand_id)
                break;
              case 'admin':
                ref2 = ref2.where('provider.id', '==', parent_id)
                break;

              default:
                break;
            }
            let unsub2 = ref2.onSnapshot(snap => {
              let data = snap.docs.map(rec => {
                return ({ ...rec.data(), id: rec.id })
              })
              data = data?.reduce((val, currentVal) => {
                let now = moment()
                let start = moment(currentVal?.bookingDate?.date)
                let diffStart = start.diff(now, 'minutes')
                if (diffStart < 20 && currentVal?.status === 'accepted') {
                  currentVal.delayedOntheWay = true
                } else if (diffStart < -10 && currentVal?.status === 'ontheway') {
                  currentVal.delayedActive = true
                } else if (diffStart < -60 && currentVal?.status === 'active') {
                  currentVal.delayedCompleted = true
                }

                val.push(currentVal)
                return val
              }, [])?.filter(r => r.delayedOntheWay || r.delayedActive || r.delayedCompleted)
              this.props.GetDelayedAppointments(data)

            });
            unsubscribe.push(unsub2);

            [
              { key: 'services', action: 'GetAllServices' },
              { key: 'addons', action: 'GetAllAddons' },
              { key: 'variations_categories', action: 'GetAllVariationsCategories' },
              { key: 'categories', action: 'GetAllCategories' },
              { key: 'brands', action: 'GetAllBrands' },
              { key: 'conditions', action: 'GetAllConditions' },
              { key: 'specialities', action: 'GetAllSepcialities' },
              { key: 'symptoms', action: 'GetAllSymptoms' },
              { key: 'signs', action: 'GetAllSigns' },
              { key: 'languages', action: 'GetAllLanguages' },
              { key: 'nationalities', action: 'GetAllNationalities' },
              { key: 'types', action: 'GetAllTypes' },
              { key: 'drip_ingredients', action: 'GetAllDripIngredients' },
              { key: 'test_parameters', action: 'GetAllTestParameters' },
              { key: 'sample_types', action: 'GetAllSampleTypes' },
              { key: 'organs', action: 'GetAllOrgans' },
              { key: 'active_substances', action: 'GetAllActiveSubstances' },
              { key: 'offers', action: 'GetAllPromos' },
              { key: 'subscriptions', action: 'GetAllSubscriptions' }
            ].map(rec => {

              let listner = firestore().collection(rec.key).where('deactivated', '!=', true).onSnapshot(snap => {
                let data = snap.docs.map(r => {
                  return ({ ...r.data(), id: r.id })
                })
                this.props[rec.action](data);
              })
              return unsubscribe.push(listner)
            })


            this.props.loginUserSuccess(post)
            setTimeout(() => {
              this.setState({ loading: false })
            }, 1000);

          })
        })

      } else {
        unsubscribe.forEach(r => {
          r()
        })
        localStorage.removeItem('user_id');
        localStorage.removeItem('role');
        this.props.loginUserSuccess({})
        this.setState({ loading: false })
      }
    })
  }
  render() {
    const { locale, loginUser } = this.props;
    let { loading } = this.state
    const currentAppLocale = AppLocale[locale];

    if (loading) {
      return <div className="loading" />
    }

    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <NotificationContainer />
            {/* {isMultiColorActive && <ColorSwitcher />} */}
            <Router>
              <Switch>
                <Route path="/verify_email_login" component={verify_email_login} />

                <AuthRoute path="/app" authUser={loginUser} component={app} />
                {
                  loginUser && loginUser?.uid && <Redirect to='/app' />
                }
                <Route path="/user" component={user} />

                <Route path="/error" exact component={error} />
                <Route path="/" exact component={main} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { loginUser: user, locale };
};
const mapActionsToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  loginUserSuccess: (user) => dispatch(loginUserSuccess(user)),
  GetAllPartners: (data) => dispatch(GetAllPartners(data)),
  GetAllProviders: (data) => dispatch(GetAllProviders(data)),
  GetAllProfessionals: (data) => dispatch(GetAllProfessionals(data)),
  GetAllServices: (data) => dispatch(GetAllServices(data)),
  GetAllAddons: (data) => dispatch(GetAllAddons(data)),
  GetAllVariationsCategories: (data) => dispatch(GetAllVariationsCategories(data)),
  GetAllCategories: (data) => dispatch(GetAllCategories(data)),
  GetAllSepcialities: (data) => dispatch(GetAllSepcialities(data)),
  GetAllConditions: (data) => dispatch(GetAllConditions(data)),
  GetAllBrands: (data) => dispatch(GetAllBrands(data)),
  GetAllSymptoms: (data) => dispatch(GetAllSymptoms(data)),
  GetAllSigns: (data) => dispatch(GetAllSigns(data)),
  GetAllLanguages: (data) => dispatch(GetAllLanguages(data)),
  GetAllNationalities: (data) => dispatch(GetAllNationalities(data)),
  GetAllTypes: (data) => dispatch(GetAllTypes(data)),
  GetAllDripIngredients: (data) => dispatch(GetAllDripIngredients(data)),
  GetAllTestParameters: (data) => dispatch(GetAllTestParameters(data)),
  GetAllSampleTypes: (data) => dispatch(GetAllSampleTypes(data)),
  GetAllOrgans: (data) => dispatch(GetAllOrgans(data)),
  GetAllActiveSubstances: (data) => dispatch(GetAllActiveSubstances(data)),
  GetAllPromos: (data) => dispatch(GetAllPromos(data)),
  GetAllSubscriptions: (data) => dispatch(GetAllSubscriptions(data)),
  GetAllRequests: (data) => dispatch(GetAllRequests(data)),
  GetAssignedRequests: (data) => dispatch(GetAssignedRequests(data)),
  GetDelayedAppointments: (data) => dispatch(GetDelayedAppointments(data)),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
