import React, { useState } from "react";
import { Row, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Colxx } from "./CustomBootstrap";
import { checkIfDisabled } from "../../helpers/Utils";
import { firestore } from "../../helpers/Firebase";
const NewHelper = ({
    title,
    selected,
    dismiss,
    newHelper,
    updateHelpers
}) => {
    const [loading, setLoading] = useState(false)
    const [updatedHelper, UpdateHelper] = useState({ addNew: true })

    const submit = async () => {
        let general = JSON.parse(JSON.stringify(updatedHelper));


        if (general && selected) {
            let id = general.id || firestore().collection(selected).doc().id;

            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            general.deactivated = false
            general.id = id
            general.lastUpdate = Date.now()

            await firestore().collection(selected).doc(id).set(general, { merge: true })
        }
        updateHelpers(general)
        setLoading(false)
    }

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Modal wrapClassName="modal-right" backdrop="static" isOpen={newHelper} >
                    <ModalHeader>
                        <h3>{title}</h3>
                    </ModalHeader>
                    <ModalBody>
                        <Colxx xxs="12 p-0">
                            {[{
                                key: 'generalInfo', title: '',
                                data: [
                                    { key: 'name', title: 'Name', width: '12', type: 'text' },
                                    // { key: 'details', title: 'Details', width: '12', type: 'textarea' },
                                ]
                            },
                            ].map((rec, recIndex) => {
                                return (
                                    <Row key={recIndex} >
                                        <Colxx xxs="12" className="mb-2">
                                            <h4>{rec.title}</h4>
                                        </Colxx>

                                        {rec.data.map((item, index) => {
                                            return (
                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                        <div>{item.title}</div>
                                                    </div>
                                                    <Input
                                                        type={item.type}
                                                        placeholder={"e.g. " + item.title}
                                                        value={updatedHelper?.[item.key]?.en}
                                                        className="customInput"
                                                        onChange={(e) => {
                                                            UpdateHelper({ ...updatedHelper, [item.key]: { ...updatedHelper?.[item.key], en: e.target.value }, updated: true })
                                                        }} />
                                                </Colxx>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </Colxx>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'space-between' }}>

                        <Button
                            color="danger"
                            outline
                            className="btn"
                            size="lg"
                            onClick={() => dismiss()}>
                            Dismiss
                        </Button>
                        <Button
                            disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedHelper)), ['name'])}
                            color="primary"
                            className="btn"
                            size="lg"
                            onClick={() => {
                                setLoading(true)
                                submit()
                            }}>
                            Add New
                        </Button>
                    </ModalFooter>
                </Modal>
            ))
    );
};

export default NewHelper;