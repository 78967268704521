import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from "../../../helpers/Firebase";
import InfiniteScroll from 'react-infinite-scroll-component';

class Medicines extends Component {
  state = {
    isInfiniteLoading: true,
    lastVisible: false
  };
  componentDidMount() {
    this.getMedicines()
  }
  onClickItem = (item) => {
    this.props.setSelectedItem(item || {})
  };
  onInfiniteLoad = async () => {
    const { search } = this.props
    this.setState({
      isInfiniteLoading: true
    });
    let ref = firestore().collection('medicines').startAfter(this.state.lastVisible)
    if (search) {
      ref = ref.where('name', '>=', search.toLowerCase()).where('name', '<=', search.toLowerCase() + "\uf8ff")
    }
    const last = await ref.limit(25).get();

    const lastVisible = last.docs[last.docs.length - 1];
    let medicines = last.docs.map(async res => {
      return {
        ...res.data(),
        key: res.id,
      }
    })
    Promise.all(medicines).then((ress) => {
      const data = [...this.state.data, ...ress];
      this.setState({
        data: data,
        isInfiniteLoading: ress?.length ? true : false,

      });
    })
    this.setState({
      lastVisible
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.search !== nextProps.search) {
      this.getMedicines(nextProps.search)
    }
  }
  getMedicines = async (search) => {
    let ref = firestore().collection('medicines')
    if (search) {
      ref = ref.where('name', '>=', search.toLowerCase()).where('name', '<=', search.toLowerCase() + "\uf8ff")
    }
    const firstSnap = await ref.orderBy('name').limit(25).get();

    let medicines = firstSnap.docs.map(async res => {
      return {
        ...res.data(),
        key: res.id,
      }

    })

    Promise.all(medicines).then((ress) => {
      this.setState({ data: ress })
    })

    const lastVisible = firstSnap.docs[firstSnap.docs.length - 1];
    this.setState({ lastVisible, isInfiniteLoading: true })
  }

  render() {
    const { title } = this.props;
    const { data, isInfiniteLoading } = this.state;

    return (
      <Fragment>
        <ListPageHeading
          heading={title}
          hide={true}
        />

        <Row>
          <Colxx xxs="12" className="mb-2" >
            <Row className="d-flex align-items-center mt-4 ml-0 mr-0" style={{
              padding: '0 1.5rem',
            }}>
              <Colxx xxs="1" />
              <Colxx xxs="3" style={{ fontWeight: '700' }}>Name</Colxx>
              <Colxx xxs="2" style={{ fontWeight: '700' }}>Price</Colxx>
              <Colxx xxs="2" style={{ fontWeight: '700' }}>SKU</Colxx>
              <Colxx xxs="2" style={{ fontWeight: '700' }}>Available Stock</Colxx>
              <Colxx />
            </Row>
            <InfiniteScroll
              dataLength={data?.length || 0}
              next={this.onInfiniteLoad}
              hasMore={isInfiniteLoading}
              loader={<h4>Loading...</h4>}
            >
              {data?.map((i, index) => (
                <Row key={index} className="d-flex align-items-center mt-3 ml-0 mr-0" style={{
                  justifyContent: 'flex-start',
                  padding: '1.5rem',
                  background: 'white',
                  borderRadius: '0.75rem',
                }}>
                  <Colxx xxs="1">
                    <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={(i?.image)} alt='' /></div>
                  </Colxx>
                  <Colxx xxs="3">
                    <span>{i?.name}</span> {i?.unit && <span style={{ color: 'gray',fontSize:'12px',fontWeight:'700' }}>{`(${i?.unit})`}</span>}
                  </Colxx>
                  <Colxx xxs="2">
                    <span>{i?.price}</span>
                  </Colxx>
                  <Colxx xxs="2">
                    <span>{i?.sku}</span>
                  </Colxx>
                  <Colxx xxs="2" >
                    <span>{i?.available_stock}</span>
                  </Colxx>
                  <Colxx >
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      className="float-right"
                      onClick={() => this.onClickItem(i)}>
                      Edit
                    </Button>
                  </Colxx>
                </Row>

              ))}
            </InfiniteScroll>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { search } = settings;
  return {
    search,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
  )(Medicines)
);
