import React, { useState } from "react";
import { Row, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Colxx } from "./CustomBootstrap";
import { checkIfDisabled, createNotification } from "../../helpers/Utils";
import { firestore, functions } from "../../helpers/Firebase";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const UpdateCredentials = ({
    title,
    selected,
    elements,
    dismiss,
    updateCredentials,
    updateUser
}) => {
    const [loading, setLoading] = useState(false)
    const [user, UpdateUser] = useState({})
    const submit = async () => {
        let general = JSON.parse(JSON.stringify(user));

        const updateUserFunc = functions().httpsCallable('updateUserCredentials');
        let result = await updateUserFunc({ uid: updateUser?.uid, [updateUser?.key]: general?.[updateUser?.key] || '' })

        if (result?.data?.error) {
            if (result?.data?.code === "auth/user-not-found") {
                const createUser = functions().httpsCallable('createAccounts');
                let createUserResult = await createUser({ uid: updateUser?.uid, [updateUser?.key]: general?.[updateUser?.key] || '', role: 'professional', parent_id: updateUser?.parent_id })
                if (createUserResult?.data?.errorInfo) {
                    createNotification("error", createUserResult?.data?.errorInfo?.message, 'Error updating user ')
                } else {
                    firestore().collection(updateUser?.parent).doc(updateUser?.parent_id).collection(updateUser?.selectedType).doc(updateUser?.uid).update({
                        [updateUser?.key]: general?.[updateUser?.key],
                        lastUpdate: Date.now()
                    }).then(() => {
                        updateCredentials(general)
                    })
                }
            }
        } else {
            firestore().collection(updateUser?.parent).doc(updateUser?.parent_id).collection(updateUser?.selectedType).doc(updateUser?.uid).update({
                [updateUser?.key]: general?.[updateUser?.key],
                lastUpdate: Date.now()
            }).then(() => {
                updateCredentials(general)
            })
        }
        setLoading(false)
    }

    return (

        <Modal backdrop="static" isOpen={updateUser} >
            {
                loading ? (
                    <div className="loading" />
                ) : ''}
            <ModalHeader>
                <h3>{title}</h3>
            </ModalHeader>
            <ModalBody>
                <Colxx xxs="12 p-0">
                    {[{
                        key: 'generalInfo',
                        data: elements
                    },
                    ].map((rec, recIndex) => {
                        return (
                            <Row key={recIndex} >
                                {rec.data.map((item, index) => {
                                    return (
                                        <Colxx key={index} xxs="12">
                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                <div>{item.title}</div>
                                            </div>
                                            {item.type === 'phoneNumber' ?
                                                <PhoneInput
                                                    regions={['middle-east', 'north-africa']}
                                                    inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                    className="customInput"
                                                    country={'ae'}
                                                    placeholder="e.g. +971xxxxxx"
                                                    value={user?.[item.key]}
                                                    onChange={(value, data, event, formattedValue) =>
                                                        UpdateUser({ ...user, [item.key]: formattedValue, country_code: data.dialCode, number: value.slice(data.dialCode.length), updated: true })
                                                    }
                                                /> : <Input
                                                    type={item.type}
                                                    placeholder={"e.g. " + item.title}
                                                    value={user?.[item.key]}
                                                    className="customInput"
                                                    onChange={(e) => {
                                                        UpdateUser({ ...user, [item.key]: e.target.value, updated: true })
                                                    }} />}
                                        </Colxx>
                                    )
                                })}
                            </Row>
                        )
                    })}
                </Colxx>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'space-between' }}>

                <Button
                    color="danger"
                    outline
                    className="btn"
                    size="lg"
                    onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button
                    disabled={checkIfDisabled(JSON.parse(JSON.stringify(user)), [selected])}
                    color="primary"
                    className="btn"
                    size="lg"
                    onClick={() => {
                        setLoading(true)
                        submit()
                    }}>
                    Update
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UpdateCredentials;