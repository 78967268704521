import React, { useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    Label,
} from "reactstrap";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Colxx, Separator } from "./CustomBootstrap";
import DataTablePagination from "../DatatablePagination";
import { firestore } from "../../helpers/Firebase";
import { UpdateItem } from "../../redux/activeItem/actions";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { checkIfDisabled } from "../../helpers/Utils";
import UpdateCredentials from "./UpdateCredentials";
const Users = ({
    parent,
    title,
    type
}) => {
    const dispatch = useDispatch()
    const { activeItem } = useSelector(state => state.activeItem)
    const { types, specialities } = useSelector(state => state.settings)
    const [users, setUsers] = useState(activeItem?.[type] || [])
    const [updateUser, setUpdateUser] = useState(false)

    const [deleteItem, setDeleteItem] = useState(false)

    const [selectedUser, setSelectedUser] = useState(false)

    const dataTableColumns = [
        {
            Header: "Name",
            accessor: type === 'professionals' ? "name.en" : 'name',
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Email",
            accessor: "email",
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Number",
            accessor: "phoneNumber",
            show: type === 'professionals',
            Cell: props => <p className="list-item-heading">{props.value}</p>
        }, {
            Header: "Speciality",
            accessor: "speciality",
            show: type === 'professionals',
            Cell: props => <p className="list-item-heading">{specialities?.find(r => r.id === props.value)?.name?.en || specialities?.find(r => r.id === props.value)?.name}</p>
        },
        {
            Header: "Gender",
            accessor: "gender",
            show: type === 'professionals',
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },

        {
            sortable: false,
            width: 80,
            accessor: "delete",
            Cell: props => <div>
                <Button
                    color="danger"
                    size="sm"
                    outline
                    onClick={() => setDeleteItem(props.original)}>
                    Delete
                </Button>
            </div>
        },
        {
            sortable: false,
            width: 70,
            accessor: "edit",
            Cell: props => <div>
                <Button
                    color="primary"
                    size="sm"
                    outline
                    className="top-right-button"
                    onClick={() => setSelectedUser(props.original)}
                >
                    Edit
                </Button>
            </div>
        }
    ];

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                720,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    setSelectedUser({ ...selectedUser, pictures: pictures, updated: true })
                },
                'blob'
            );

        } else {
            setSelectedUser({ ...selectedUser, [type]: [], pictures: [], updated: true })
        }
    }
    const deleteItemFunc = (item) => {
        setDeleteItem(false)
        if (item.id && !item.addNew) {
            users[item.id].deleted = true
            users[item.id].updated = true
        } else {
            delete users[item.id]
            dispatch(UpdateItem({ key: type, val: users }))
        }

        setUsers(users)
    }
    const submit = (id) => {
        let Ref = firestore().collection(parent).doc(activeItem?.general?.id).collection(type)
        let documentID = id || Ref.doc().id;
        users[documentID] = selectedUser
        setUsers(users)
        setSelectedUser(false)

    }
    useEffect(() => {
        dispatch(UpdateItem({ key: type, val: users }))
        // eslint-disable-next-line
    }, [users])
    return (
        <Card className="rounded">
            <CardBody>
                <Row>

                    <Colxx xxs="12" className="mb-2">
                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                            <h4 className="mb-0">{title}</h4>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => setSelectedUser({ addNew: true, id: firestore().collection(parent).doc(activeItem?.general?.id).collection(type).doc().id })}>Add new
                                </Button>
                            </div>

                        </div>
                    </Colxx>
                </Row>
                <Separator className="mb-5" />

                <Row>
                    <Colxx xxs="12" className="mb-2">
                        <ReactTable
                            data={Object.values(users).filter(rec => !rec.deleted)}
                            columns={dataTableColumns}
                            defaultPageSize={10}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            PaginationComponent={DataTablePagination}
                        />
                    </Colxx>
                </Row>
            </CardBody>
            {selectedUser && <Modal
                size='lg'
                isOpen={selectedUser}>
                <ModalHeader>{selectedUser?.addNew ? (type === "professionals" ? 'New Professional' : 'New User') : 'Edit ' + selectedUser?.name?.en}</ModalHeader>
                <ModalBody>
                    {
                        [{
                            key: 'generalInfo', title: 'General Information', hide: type !== 'professionals',
                            data: [
                                { key: 'picture', title: 'Professional Picture', width: '12', type: '' },
                                { key: 'name', title: 'Name', width: '6', type: 'text', translateButton: true },
                                { key: 'type', title: 'Professional Type', width: '6', type: 'select', data: types },
                                { key: 'speciality', title: 'Speciality', width: '6', type: 'select', data: specialities },
                                { key: 'gender', title: 'Gender', width: '6', type: 'select', data: [{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }] },
                            ]
                        },
                        {
                            key: 'accountInfo', title: 'Account Information', data: [
                                { key: 'name', title: 'Person Name', width: '6', type: 'text', hide: type === 'professionals' },
                                { key: 'gender', title: 'Gender', width: '6', type: 'select', hide: type === 'professionals', data: [{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }] },
                                { key: 'email', title: 'Email', width: '6', type: 'email', updateCredentials: true },
                                { key: 'password', title: 'Password', width: '6', type: 'password', hide: !selectedUser?.addNew, autocomplete: 'on' },
                                { key: 'phoneNumber', title: 'Number', width: '6', type: 'phoneNumber', hide: type !== 'professionals', updateCredentials: true }
                            ]
                        },].map((rec, recIndex) => {
                            return !rec.hide && (
                                <Row key={recIndex} >
                                    <Colxx xxs="12" className="mb-2">
                                        <h4>{rec.title}</h4>
                                    </Colxx>
                                    {rec.data.map((item, index) => {
                                        let selected = (item.data)?.find(rec => selectedUser?.[item?.key] === rec.id) || false

                                        return !item.hide && (
                                            <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                    <div>{item.title}</div>
                                                    {item.updateCredentials && !selectedUser?.addNew && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => setUpdateUser({ ...item, uid: selectedUser?.id, selectedType: type, parent_id: activeItem?.general?.id, parent: parent })}>{`Update ${item.title}`}</div>}
                                                </div>
                                                {item.key === 'phoneNumber' ?
                                                    <PhoneInput
                                                        regions={['middle-east', 'north-africa']}
                                                        inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                        className="customInput"
                                                        country={'ae'}
                                                        disabled={!selectedUser?.addNew}
                                                        placeholder="e.g. +971xxxxxx"
                                                        value={selectedUser?.[item.key]}
                                                        onChange={(value, data, event, formattedValue) =>
                                                            setSelectedUser({ ...selectedUser, [item.key]: formattedValue, country_code: data.dialCode, number: value.slice(data.dialCode.length) })
                                                        }
                                                    /> : item.key === 'picture' ?
                                                        <ImageUploader
                                                            className={'w-100'}
                                                            withIcon={true}
                                                            name={item.key}
                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                            buttonText='Choose images'
                                                            onChange={(picture) => onDrop(picture, item.key)}
                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                            maxFileSize={15728640}
                                                            withPreview={true}
                                                            singleImage={true}
                                                            defaultImages={selectedUser && selectedUser?.[item.key]}
                                                        /> : (item.key === 'type' || item.key === 'gender' || item.key === 'speciality') ?
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select"
                                                                placeholder={item.title}
                                                                value={(item.data) ? {
                                                                    ...selected,
                                                                    value: selected?.name?.en || selected?.name,
                                                                    label: selected?.name?.en || selected?.name,
                                                                    key: selected.id
                                                                } : {}}

                                                                options={(item.data).map(rec => {
                                                                    return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                })}
                                                                onChange={val => {
                                                                    setSelectedUser({ ...selectedUser, [item.key]: val.key, updated: true })
                                                                }}
                                                            /> : <Input
                                                                type={item.type}
                                                                placeholder={"e.g. " + item.title}
                                                                autocomplete={item.autocomplete}
                                                                value={item.translateButton ? selectedUser?.[item.key]?.en : selectedUser?.[item.key]}
                                                                className="customInput"
                                                                disabled={item.key === 'email' && !selectedUser?.addNew}
                                                                onChange={(e) => {
                                                                    if (item.translateButton) {
                                                                        setSelectedUser({ ...selectedUser, [item.key]: { ...selectedUser?.[item.key], en: e.target.value }, updated: true })
                                                                    } else {
                                                                        setSelectedUser({ ...selectedUser, [item.key]: e.target.value, updated: true })
                                                                    }
                                                                }} />}
                                            </Colxx>
                                        )
                                    })}
                                </Row>
                            )
                        })}
                </ModalBody>
                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => setSelectedUser(false)}>Cancel</Button>
                    <Button color='primary ' className='m-1'
                        disabled={checkIfDisabled(JSON.parse(JSON.stringify(selectedUser)), type === 'professionals' ? ['name', 'phoneNumber'] : ['name', 'email'])}
                        onClick={() => {
                            submit(selectedUser.id)
                        }}>Save</Button>
                </ModalFooter>
            </Modal>}
            <Modal backdrop="static" isOpen={deleteItem}>
                <ModalHeader >
                    Delete {deleteItem?.name?.en || deleteItem?.name}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            <Label className="mb-2">Are you sure you want to delete {deleteItem?.name?.en || deleteItem?.name}?</Label>
                        </Colxx>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setDeleteItem(false)
                    }}>Dismiss</Button>
                    <Button color='danger' onClick={() => {
                        deleteItemFunc(deleteItem)
                    }}>Delete</Button>
                </ModalFooter>

            </Modal>
            {updateUser ? <UpdateCredentials
                title={`Update ${updateUser?.title}`}
                selected={updateUser?.key}
                elements={[updateUser]}
                updateCredentials={(e) => {
                    setSelectedUser({ ...selectedUser, [updateUser.key]: e?.[updateUser?.key], updated: true })
                    setUpdateUser(false)
                }}
                dismiss={() => setUpdateUser(false)}
                updateUser={updateUser}
            /> : ''}
        </Card>

    );
};

export default Users;