const data = [


  // {
  //   id: "dashboard",
  //   icon: "iconsminds-monitor-analytics",
  //   label: "Dashboard",
  //   role: ["admin", "superAdmin"],
  //   to: "/app/dashboard"
  // },
  {
    id: "requests",
    icon: "iconsminds-calendar-4",
    label: "Requests",
    role: ["admin", "superAdmin", "concierge"],

    to: "/app/bookings/requests"
  },
  {
    id: "appointments",
    icon: "iconsminds-calendar-4",
    label: "Appointments",
    role: ["admin", "superAdmin", "concierge"],

    to: "/app/bookings/appointments"
  },

  {
    id: "providers",
    icon: "iconsminds-shop",
    label: "Providers",
    role: ["admin", "superAdmin"],
    to: "/app/providers"
  },
  {
    id: "professionals",
    icon: "iconsminds-doctor",
    label: "Professionals",
    role: ["admin", "superAdmin"],
    to: "/app/professionals"
  },
  {
    id: "referral_partners",
    icon: "iconsminds-hotel",
    label: "Clients",
    role: ["superAdmin", "concierge"],
    to: "/app/referral_partners"
  },
  {
    id: "chat",
    icon: "simple-icon-speech",
    label: "Chat",
    role: ["superAdmin", "admin"],

    to: "/app/chat"
  },
  {
    id: "operations",
    icon: "iconsminds-monitor-analytics",
    label: "Operations",
    to: "/app/operations",
    role: ["admin", "superAdmin", "accountant", "concierge"],
    subs: [
      {
        icon: "simple-icon-arrow-right",
        label: "Customers",
        to: "/app/operations/customers",
        role: ["superAdmin"],

      },
      {
        icon: "simple-icon-arrow-right",
        label: "Order History",
        to: "/app/operations/order_history",
        role: ["admin", "superAdmin", "accountant", "concierge"],

      },
      {
        icon: "simple-icon-arrow-right",
        label: "Advanced Reports",
        to: "/app/operations/advanced_reports",
        role: ["superAdmin", "accountant"],
        // role: ["admin", "superAdmin"],
      },
    ]
  },
  {
    id: "marketing",
    icon: "iconsminds-loudspeaker",
    label: "Marketing",
    to: "/app/marketing",
    role: ["superAdmin"],
    subs: [
      {
        icon: "simple-icon-arrow-right",
        label: "Promocodes",
        to: "/app/marketing/promocodes",
        role: ["superAdmin"],
      }, {
        icon: "simple-icon-arrow-right",
        label: "Affiliates",
        to: "/app/marketing/affiliates",
        role: ["superAdmin"],
      },
      {
        icon: "simple-icon-arrow-right",
        label: "Loyalty",
        to: "/app/marketing/loyalty",
        role: ["superAdmin"],
      },
      {
        icon: "simple-icon-arrow-right",
        label: "Subscriptions",
        to: "/app/marketing/subscriptions",
        role: ["superAdmin"],
      }
    ]
  },
  {
    id: "helpers",
    icon: "simple-icon-settings",
    label: "Helpers",
    to: "/app/helpers",
    role: ["superAdmin"],

    subs: [

      {
        icon: "simple-icon-arrow-right",
        label: "Health Conditions",
        to: "/app/helpers/health_conditions",
        role: ["superAdmin"],
        subs: [
          {
            icon: "simple-icon-arrow-right",
            label: "Symptoms",
            to: "/app/helpers/health_conditions/symptoms",
            role: ["superAdmin"],
          },
          {
            icon: "simple-icon-arrow-right",
            label: "Signs",
            to: "/app/helpers/health_conditions/signs",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Conditions",
            to: "/app/helpers/health_conditions/conditions",
            role: ["superAdmin"],

          },
        ]
      },
      {
        icon: "simple-icon-arrow-right",
        label: "Services",
        to: "/app/helpers/services",
        role: ["superAdmin"],
        subs: [
          {
            id: "services_categories",
            icon: "simple-icon-arrow-right",
            label: "Services Categories",
            to: "/app/helpers/services/services_categories",
            role: ["superAdmin"],

          },
          {
            id: "variations_categories",
            icon: "simple-icon-arrow-right",
            label: "Variations Categories",
            to: "/app/helpers/services/variations_categories",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Session Addons",
            to: "/app/helpers/services/addons",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Drip Ingredients",
            to: "/app/helpers/services/drip_ingredients",
            role: ["superAdmin"],

          },
        ]
      },


      {
        icon: "simple-icon-arrow-right",
        label: "Lab",
        to: "/app/helpers/lab",
        role: ["superAdmin"],
        subs: [
          {
            icon: "simple-icon-arrow-right",
            label: "Test Parameters",
            to: "/app/helpers/lab/test_parameters",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Sample Types",
            to: "/app/helpers/lab/sample_types",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Organs",
            to: "/app/helpers/lab/organs",
            role: ["superAdmin"],

          },
        ]
      },

      {
        icon: "simple-icon-arrow-right",
        label: "Pharmacy",
        to: "/app/helpers/pharmacy",
        role: ["superAdmin"],
        subs: [
          {
            icon: "simple-icon-arrow-right",
            label: "Active Substances",
            to: "/app/helpers/pharmacy/active_substances",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Medicines",
            to: "/app/helpers/pharmacy/medicines",
            role: ["superAdmin"],
          },
        ]
      },

      {
        icon: "simple-icon-arrow-right",
        label: "Professionals",
        to: "/app/helpers/professionals",
        role: ["superAdmin"],
        subs: [
          {
            icon: "simple-icon-arrow-right",
            label: "Specialities",
            to: "/app/helpers/professionals/specialities",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Professionals Types",
            to: "/app/helpers/professionals/professionals_types",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Languages",
            to: "/app/helpers/professionals/languages",
            role: ["superAdmin"],

          },
          {
            icon: "simple-icon-arrow-right",
            label: "Nationalities",
            to: "/app/helpers/professionals/nationalities",
            role: ["superAdmin"],

          },

        ]
      },

      {
        icon: "simple-icon-arrow-right",
        label: "Providers",
        to: "/app/helpers/providers",
        role: ["superAdmin"],
        subs: [
          {
            icon: "simple-icon-arrow-right",
            label: "Provider Categories",
            to: "/app/helpers/providers/provider_categories",
            role: ["superAdmin"],

          },

          {
            icon: "simple-icon-arrow-right",
            label: "Groups",
            to: "/app/helpers/providers/brands",
            role: ["superAdmin"],

          },
        ]
      },




      // {
      //   icon: "simple-icon-paper-plane",
      //   label: "Products",
      //   to: "/app/helpers/products"
      // },
    ]
  }
];
export default data;
