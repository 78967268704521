import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,

} from "reactstrap";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  changeLocale,
  setSearch,
  loginUserSuccess
} from "../../redux/actions";

import { menuHiddenBreakpoint } from "../../constants/defaultValues";
import audioFile from '../../Smartphone Open.m4a'

import { MobileMenuIcon, MenuIcon } from "../../components/svg";
import TopnavNotifications from "./Topnav.Notifications";
import metadocLogo from '../../assets/img/metadocLogo.png'
import profileImage from '../../assets/img/profile.png'
// import TopnavDarkSwitch from "./Topnav.DarkSwitch";
import { auth, functions } from '../../helpers/Firebase'
import { checkPasswordValidity, createNotification } from "../../helpers/Utils";
const beep = new Audio(audioFile)

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInFullScreen: false,
    };
  }


  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };
  componentWillReceiveProps(nextProps) {
    const { requests } = nextProps;
    let { loaded } = this.state
    let _this = this
    if (this.props.requests !== requests) {
      if (requests && Object.values(requests).length) {
        let status = Object.values(requests).map(rec => rec.status).filter(rec => rec === 'new' || rec === 'pending')

        if (status.length > 0) {
          beep.loop = true;
          beep.play().catch(() => {
            _this.setState({ activateSound: status.length, loaded: true })
          })
        } else {
          beep.pause()
          if (!loaded) {
            beep.loop = false;
            beep.play().catch(() => {
              _this.setState({ activateSound: 0, loaded: true })
            })
          }
        }
      } else {
        beep.pause()
        if (!loaded) {
          beep.loop = false;
          beep.play().catch(() => {
            _this.setState({ activateSound: 0, loaded: true })
          })
        }
      }
    }
  }
  componentWillMount() {
    const { requests } = this.props;
    let { loaded } = this.state

    let _this = this

    if (requests && Object.values(requests).length) {
      let status = Object.values(requests).map(rec => rec.status).filter(rec => rec === 'new' || rec === 'pending')

      if (status.length > 0) {
        beep.loop = true;
        beep.play().catch(() => {
          _this.setState({ activateSound: status.length, loaded: true })
        })
      } else {
        beep.pause()
        if (!loaded) {
          beep.loop = false;
          beep.play().catch(() => {
            _this.setState({ activateSound: 0, loaded: true })
          })
        }
      }
    }
  }



  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  handleLogout = () => {
    auth().signOut().then(() => {
      window.location.reload()
    })
  };
  handlePasswordChange = async () => {
    const { authUser } = this.props;

    let { updatedPassword, updatedPasswordConfrim } = this.state
    if (!updatedPassword || !updatedPasswordConfrim) {
      return this.setState({ passwordChangeError: 'Please fill all fields' })
    }
    const error = checkPasswordValidity(updatedPassword)
    if (error) {
      return this.setState({ passwordChangeError: error })
    }
    await auth().currentUser.updatePassword(updatedPassword).then(async response => {
      this.setState({ updatedPassword: false, updatedPasswordConfrim: false, updatePassword: false })
      const passwordUpdated = functions().httpsCallable('resetAdminP');
      await passwordUpdated({})
      this.props.loginUserSuccess({ ...authUser, passwordRequired: false })
      createNotification("success", "Password Updated", 'Your password has been updated successfully')
    }).catch(async err => {
      console.log("errerrerr", err);
      if (err.code === 'auth/requires-recent-login') {
        let password = window.prompt('Enter Old password')
        var credential = auth.EmailAuthProvider.credential(authUser?.email, password);
        try {
          await auth().currentUser.reauthenticateWithCredential(credential)
          this.handlePasswordChange()
        } catch (error) {
          if (error.code === 'auth/wrong-password') {
            return this.setState({ passwordChangeError: err?.message })
          }
          console.log("errorerrorerrorerror", error);
        }

      }
      this.setState({ passwordChangeError: err?.message })
    });
    // auth().signOut().then(() => {
    //   window.location.reload()
    // })
  };
  handleSearchIconClick = e => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains("search")) {
        if (e.target.parentElement.classList.contains("search")) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains("search")
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains("mobile-view")) {
        this.props.setSearch('')
        elem.classList.remove("mobile-view");
        this.removeEventsSearch();
      } else {
        elem.classList.add("mobile-view");
        this.addEventsSearch();
      }
    } else {
      this.props.setSearch('')
    }
  };
  addEventsSearch = () => {
    document.addEventListener("click", this.handleDocumentClickSearch, true);
  };
  removeEventsSearch = () => {
    document.removeEventListener("click", this.handleDocumentClickSearch, true);
  };
  handleDocumentClickSearch = e => {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("navbar") ||
        e.target.classList.contains("simple-icon-magnifier"))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains("simple-icon-magnifier")) {
        // this.props.setSearch('')
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains("search")
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector(".mobile-view");
      if (input && input.classList) input.classList.remove("mobile-view");
      this.removeEventsSearch();
      this.setState({
        searchKeyword: ""
      });
    }
  };
  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.props.selectedMenuHasSubItems
    );
  };
  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  render() {
    const { containerClassnames, menuClickCount, requests, delayedAppointments, authUser, provider } = this.props;
    let { loaded, activateSound, updatePassword, passwordChangeError } = this.state
    return (
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <NavLink
            to="#"
            className="menu-button d-none d-md-block"
            onClick={e =>
              this.menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>
          <div className="search" data-search-path="/app/pages/search">
            <Input
              name="searchKeyword"
              id="searchKeyword"
              placeholder={"Search"}
              value={this.props.search}
              onChange={e => this.props.setSearch(e.target.value)}
            // onKeyPress={e => this.handleSearchInputKeyPress(e)}
            />
            <span
              className="search-icon"
              onClick={e => this.handleSearchIconClick(e)}
            >
              <i className="simple-icon-magnifier" />
            </span>
          </div>
        </div>
        <a className="navbar-logo" href="/">
          <span className="logo d-none d-xs-block" style={{ backgroundImage: metadocLogo }} />
          <span className="logo-mobile d-block d-xs-none" />
        </a>
        <div className="navbar-right">
          {/* {isDarkSwitchActive && <TopnavDarkSwitch />} */}

          <div className="header-icons d-inline-block align-middle">
            {delayedAppointments?.length ?
              <a href="/app/bookings/appointments">
                <Button color='danger' size='sm' style={{ verticalAlign: 'super', fontWeight: '700' }}>
                  {`${delayedAppointments?.length} Order(s) Needs Your Attention`}
                </Button>
              </a>
              : ''}
            <TopnavNotifications requests={requests} />
            <button
              className="header-icon btn btn-empty d-none d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <span className="name mr-1">{authUser?.role === "superAdmin" ? "Super Admin" : authUser?.role === "accountant" ? "Accountant" : authUser?.role === "concierge" ? "Partner" : (provider?.name?.en || 'Provider')}</span>
                <span>
                  <img style={{ height: '40px', width: '40px' }} alt="Profile" src={['superAdmin', 'accountant']?.includes(authUser?.role) ? profileImage : provider?.picture?.[0] || profileImage} />
                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {/* <DropdownItem>Account</DropdownItem>
                <DropdownItem>Features</DropdownItem>
                <DropdownItem>History</DropdownItem>
                <DropdownItem>Support</DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem onClick={() => this.setState({ updatePassword: true })}>
                  Change Password
                </DropdownItem>
                <DropdownItem onClick={() => this.handleLogout()}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {
          loaded && <Modal isOpen={true}>
            <ModalBody>
              {"You have " + activateSound + " new orders"}
            </ModalBody>

            <ModalFooter >
              <Button color='primary' onClick={() => { beep.play(); this.setState({ activateSound: false, loaded: false }) }}>Dismiss</Button>
            </ModalFooter>
          </Modal>
        }
        {
          (updatePassword || authUser?.passwordRequired) && <Modal isOpen={updatePassword || authUser?.passwordRequired}>
            <ModalHeader>
              Update Password
              {
                authUser?.passwordRequired && <div style={{ fontSize: '12px' }}>It's required to Update your password on first login </div>

              }
              <div style={{ fontSize: '10px' }}>*Please don't share this credentials with any one</div>

            </ModalHeader>
            <ModalBody >
              <div>
                {"Please enter new password"}
                <Input className='mt-2' type='password' onChange={(e) => this.setState({ updatedPassword: e.target.value, passwordChangeError: false })} />
              </div>
              <div className="mt-4">
                {"Please re-enter new password"}
                <Input className='mt-2' type='password' onChange={(e) => this.setState({ updatedPasswordConfrim: e.target.value, passwordChangeError: false })} />
              </div>
            </ModalBody>
            <div className="p-4">
              {
                Array.isArray(passwordChangeError) ?
                  passwordChangeError.map(r => <div style={{ color: 'red' }} key={r}>
                    {r}
                  </div>)
                  :
                  passwordChangeError && <div style={{ textAlign: 'center', color: 'red' }}>
                    {passwordChangeError}
                  </div>
              }
            </div>
            <ModalFooter >
              {
                !authUser?.passwordRequired && <Button color='danger' onClick={() => { this.setState({ updatePassword: false }) }}>Dismiss</Button>
              }
              <Button color='primary' onClick={() => this.handlePasswordChange()}>Update</Button>
            </ModalFooter>
          </Modal>
        }

      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, authUser, providers, bookings }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale, search } = settings;
  const { allProviders } = providers;
  let provider = !['superAdmin', 'accountant'].includes(authUser.role) ? allProviders?.[0] : {}

  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
    search,
    authUser: authUser?.user,
    provider: provider || {},
    requests: bookings?.requests || [],
    delayedAppointments: bookings?.delayedAppointments || [],
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    { setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale, setSearch, loginUserSuccess }
  )(TopNav)
);
