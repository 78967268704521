import React, { useState } from 'react';
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Button,
} from 'reactstrap';
import { Colxx } from './CustomBootstrap';

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '../../helpers/Firebase';
import { UpdateItem } from '../../redux/activeItem/actions';
import { sortTimings } from '../../helpers/Utils';
const Timings = ({ selectedVariation, updateTimings, dismiss }) => {
    const [defaultTimings, setDefaultTimings] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const { activeItem } = useSelector(state => state.activeItem)
    let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);
    const [timings, setTimings] = useState(selectedVariation?.timings || {
        Saturday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Sunday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Monday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Tuesday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Wednesday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Thursday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
        Friday: { timings: [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }], isOpen: false },
    })
    const CustomInput = React.forwardRef((props, ref) => {
        return (
            <input
                onClick={props.onClick}
                value={props.value}
                type="text"
                className="customDatePicker"
                readOnly={true}
                ref={ref}
            />
        )
    })
    const handleDateChange = (date, selectedDay, index, type) => {
        let editedTimings = JSON.parse(JSON.stringify(timings))
        let optimizedMoment = moment(date).set('year', 1955).set('month', 0).set('date', 5);
        switch (type) {
            case 'start':
                editedTimings[selectedDay].timings[index].startTime = optimizedMoment.toDate().getTime()
                setTimings(editedTimings);
                break;
            case 'end':
                editedTimings[selectedDay].timings[index].endTime = optimizedMoment.toDate().getTime()
                setTimings(editedTimings);
                break;

            default:
                break;
        }
    };

    const handleSwitchChange = (selectedDay, switchState) => {
        let editedTimings = JSON.parse(JSON.stringify(timings))
        editedTimings[selectedDay].isOpen = switchState
        setTimings(editedTimings);
    };

    const addTimings = (day) => {
        let editedTimings = JSON.parse(JSON.stringify(timings))
        let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);

        editedTimings[day].timings.push({ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() });
        setTimings(editedTimings);
    };

    const removeTimings = (day, timeIndex) => {
        let editedTimings = JSON.parse(JSON.stringify(timings))
        editedTimings[day].timings.splice(timeIndex, 1);

        setTimings(editedTimings);
    };

    const applyForAllTimings = (item) => {
        setTimings({
            Saturday: item,
            Sunday: item,
            Monday: item,
            Tuesday: item,
            Wednesday: item,
            Thursday: item,
            Friday: item,
        })
        setDefaultTimings(false)
        setLoading(false)

    };

    const saveAsDefaultTimings = () => {
        let { general, defaultSettings } = activeItem
        firestore().collection('providers').doc(general.id).collection('defaultSettings').doc('timings').set(timings).then(() => {
            setDefaultTimings(false)
            setLoading(false)

            dispatch(UpdateItem({ key: 'defaultSettings', val: { ...defaultSettings, timings: timings } }))

        })
    };
    const fillWithDefaultTimings = () => {
        let { defaultSettings } = activeItem
        setTimings(defaultSettings?.timings)
        setDefaultTimings(false)
        setLoading(false)

    };


    return (
        <Modal
            size='lg'
            isOpen={selectedVariation}>
            <div className='modal-header'>
                <div className='modal-title' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>{selectedVariation?.name?.en}</h5>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {activeItem?.defaultSettings?.timings && <div
                            style={{
                                fontWeight: '700',
                                color: '#28a745',
                                fontSize: '14px',
                                cursor: 'pointer'
                            }}
                            className="mr-4"
                            onClick={() => {
                                setDefaultTimings({ key: 'fillDefault', value: timings })

                            }}>Fill with Default Timings</div>}
                        <div style={{
                            fontWeight: '700',
                            color: '#3498db',
                            fontSize: '14px',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setDefaultTimings({ key: 'saveDefault', value: timings })
                            }}>Save as Default Timings</div>
                    </div>
                </div>
            </div>
            <ModalBody>
                <Row>
                    <Colxx xxs="12" >
                        {

                            Object.keys(sortTimings(timings)).map((item, index) => {
                                return (
                                    <div key={index} className="timingsParent" style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                        <div className="timingsParent" style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                                            <div className="dayParent">
                                                <div className="day">{item}</div>
                                                <Switch
                                                    className="custom-switch custom-switch-primary"
                                                    checked={timings[item].isOpen}
                                                    onChange={switchCheckedPrimary => {
                                                        handleSwitchChange(item, switchCheckedPrimary)
                                                    }}
                                                />
                                            </div>
                                            {
                                                timings[item].isOpen &&
                                                <div className="dateContainer">
                                                    {timings[item].timings.map((timing, index2) => {
                                                        return (
                                                            <div key={index2} className="date2Container" style={{ margin: '0 0 10px 10px' }}>
                                                                <DatePicker
                                                                    customInput={<CustomInput />}
                                                                    selected={(timing.startTime ? moment(timing.startTime).toDate() : moment().endOf('day').toDate())}
                                                                    className="customDatePicker"
                                                                    showTimeSelectOnly
                                                                    onChange={date => handleDateChange(date, item, index2, 'start')}
                                                                    showTimeSelect
                                                                    dateFormat="hh:mm a"
                                                                    timeFormat="hh:mm a"
                                                                    timeIntervals={30}
                                                                    timeCaption="Time"
                                                                    placeholderText={"Start"}
                                                                    minTime={index2 > 0 ? moment().hours(moment(timings[item].timings[index2 - 1].endTime).hours()).minutes(moment(timings[item].timings[index2 - 1].endTime).minutes()).toDate() : moment().hours(moment().startOf('day').hours()).minutes(moment().startOf('day').minutes()).toDate()}
                                                                    maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes()).toDate()}
                                                                />
                                                                <DatePicker
                                                                    customInput={<CustomInput />}
                                                                    selected={(timing.endTime ? moment(timing.endTime).toDate() : moment().endOf('day').toDate())}
                                                                    className="customDatePicker"
                                                                    showTimeSelectOnly
                                                                    onChange={date => { handleDateChange(date, item, index2, 'end') }}
                                                                    showTimeSelect
                                                                    timeIntervals={30}
                                                                    timeCaption="Time"
                                                                    dateFormat="hh:mm a"
                                                                    timeFormat="hh:mm a"
                                                                    placeholderText={"End"}
                                                                    minTime={timing.startTime ? moment().hours(moment(timing.startTime).hours()).minutes(moment(timing.startTime).minutes()).toDate() : moment().startOf('day').toDate()}
                                                                    maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes()).toDate()} />
                                                                {index2 === 0 ? <div className="addTimings" onClick={() => { addTimings(item) }}><i className="simple-icon-plus" /> Add New Timings</div>
                                                                    : <div className="removeTimings" onClick={() => { removeTimings(item, index2) }}><i className="simple-icon-close" /></div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        {index === 0 &&
                                            <Button Button
                                                color='primary '
                                                size='sm'
                                                style={{ marginTop: '8px' }}
                                                onClick={() => {
                                                    setDefaultTimings({ key: 'local', value: timings[item] })
                                                }}>Apply For All</Button>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Colxx>

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color='danger' className='m-1' onClick={() => dismiss()}>Cancel</Button>
                <Button color='primary ' className='m-1' onClick={() => {
                    updateTimings(timings)
                }}>Save</Button>
            </ModalFooter>


            <Modal
                // size='sm'
                isOpen={defaultTimings}>
                <ModalHeader>
                    {defaultTimings.key === 'local' ?
                        'Apply For All Timings' :
                        defaultTimings.key === 'saveDefault' ?
                            'Save Default Timings' :
                            defaultTimings.key === 'fillDefault' ?
                                'Fill Default Timings' :
                                ''
                    }
                </ModalHeader>
                <ModalBody>
                    {loading && (
                        <div className="loading" />
                    )}
                    {defaultTimings.key === 'local' ?
                        'Are you sure you want to apply for all timings?' :
                        defaultTimings.key === 'saveDefault' ?
                            'Are you sure you want to save to default timings?' :
                            defaultTimings.key === 'fillDefault' ?
                                'Are you sure you want to fill from default timings?' :
                                ''
                    }

                </ModalBody>
                <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => {
                        setDefaultTimings(false)
                        setLoading(false)
                    }}>Cancel</Button>
                    <Button color='primary ' className='m-1' onClick={() => {
                        setLoading(true)
                        switch (defaultTimings.key) {
                            case 'local':
                                return applyForAllTimings(defaultTimings?.value);
                            case 'saveDefault':
                                return saveAsDefaultTimings();
                            case 'fillDefault':
                                return fillWithDefaultTimings(defaultTimings);
                            default:
                                break;
                        }
                    }}> {defaultTimings.key === 'local' ?
                        'Apply' :
                        defaultTimings.key === 'saveDefault' ?
                            'Save' :
                            defaultTimings.key === 'fillDefault' ?
                                'Fill' :
                                ''
                        }</Button>
                </ModalFooter>
            </Modal>
        </Modal >
    );
};

export default Timings;