
import {
    GET_PROVIDERS,
    GET_PROFISSIONALS,

} from '../actions';

const INIT_STATE = {
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROVIDERS:
            return { ...state, allProviders: action.payload };
        case GET_PROFISSIONALS:
            return { ...state, allProfessionals: action.payload };

        default: return { ...state };
    }
}