import React, { useEffect } from "react";
import Loyalty from "./components/loyalty";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { firestore } from "../../../helpers/Firebase";
import { GetAllPartners } from "../../../redux/partners/actions";
import { SetActiveItem } from "../../../redux/activeItem/actions";

const Partners = () => {
  const dispatch = useDispatch();
  const { allPartners } = useSelector(state => state.partners)
  const { activeItem } = useSelector(state => state.activeItem)

  const authUser = useSelector(state => state.authUser)

  useEffect(() => {
    let tapsData = ["settings"]
    let unsub = firestore().collection('partners').where('deactivated', '!=', true).onSnapshot(async snap => {
      let data = await Promise.all(snap.docs.map(async rec => {
        let post = rec.data()
        await Promise.all(tapsData.map(async tap => {
          let snap = await firestore().collection('partners').doc(rec.id).collection(tap).get()
          let tapdata = snap.docs.reduce((val, currentVal) => {
            val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }
            return val
          }, {})
          post[tap] = tapdata
          return tapdata
        }))
        return ({ ...post, id: rec.id })
      }))
      dispatch(GetAllPartners(data))
    })
    return () => {
      if (unsub) {
        unsub()
      }
    }
    // eslint-disable-next-line
  }, [])

  const selectPartner = async (partnerData) => {
    let post = { general: partnerData, settings: partnerData?.settings || {} };
    dispatch(SetActiveItem(post))
  }

  return (
    <div>

      {activeItem && <Loyalty
        authUser={authUser}
        selectedItem={activeItem}
        dismiss={() => {
          dispatch(SetActiveItem(false))
        }}

      />}
      <MainPage
        title="Loyalty"
        selected='partners'
        activeSwitchKey='loyalty'
        data={allPartners || []}
        setSelectedItem={(e) => selectPartner(e)}
      />


    </div>
  );
};


export default Partners;
