import {
    GET_PARTNERS,
  
} from '../actions';


export const GetAllPartners = (data) => {
    return (
        {
            type: GET_PARTNERS,
            payload: data
        }
    )
}

