import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import Providers from "./providers";

const Applications = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Route path={`${match.url}/`} component={Providers} />
      <Redirect to="/error" />
    </Switch>
  </Suspense>
);
export default Applications;
