import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalFooter,
    Input,
    Row, Card, CardBody, ModalHeader
} from "reactstrap";
import { Colxx } from "./CustomBootstrap";
import { firebase } from '../../helpers/Firebase'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
import { quillFormats, quillModules } from "../../constants/defaultValues";

const TranslatePopup = ({ dismiss, submit, translateObject }) => {
    const [translation, updateTranslation] = useState(translateObject?.value || {})
    const [loading, setLoading] = useState(false)

    const AutoTranslateFunc = async (text, lang) => {
        let getTranslation = firebase.app().functions('europe-west1').httpsCallable('TranslateText');
        let result = await getTranslation({ text, lang })
        updateTranslation({ ...translation, [lang]: result?.data || '' })
        setLoading(false)

    }
    return (
        <Modal
            isOpen={true}
            // toggle={dismiss}
            backdrop="static"
            size="lg">
            <div>
                {loading && (
                    <div className="loading" />
                )}
                <Row className="invoice-react">
                    <Colxx xxs="12" >
                        <ModalHeader toggle={dismiss}>
                            <div className="d-flex flex-column justify-flex-start" style={{ alignItems: 'flex-start' }} >
                                <h6 className="font-weight-bold m-0 mb-2">Edit Translations</h6>
                            </div>
                        </ModalHeader>
                        <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                            <CardBody className="d-flex flex-column justify-content-between">
                                <div className="d-flex flex-column">
                                    {[{
                                        key: 'generalInfo', title: 'General Information', data: [
                                            { key: 'en', title: 'English', value: '', width: '12', type: translateObject.type },
                                            { key: 'ar', title: 'Arabic', value: '', width: '12', type: translateObject.type, autoTranslate: true },
                                            { key: 'rus', title: 'Russian', value: '', width: '12', type: translateObject.type, autoTranslate: true },
                                            { key: 'zh-CN', title: 'Chinese', value: '', width: '12', type: translateObject.type, autoTranslate: true },
                                        ]
                                    },
                                    ].map((rec, recIndex) => {
                                        return (
                                            <Row key={recIndex} >
                                                {/* <Colxx xxs="12" className="mb-2">
                                                    <h4>{rec.title}</h4>
                                                </Colxx> */}
                                                {rec.data.map((item, index) => {
                                                    return (
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                <div>{item.title}</div>
                                                                {item.autoTranslate && <div style={{
                                                                    color: '#3498db',
                                                                    cursor: 'pointer',
                                                                    fontWeight: '700'
                                                                }} onClick={() => {
                                                                    setLoading(true)
                                                                    AutoTranslateFunc(translation?.en, item.key)
                                                                }}>Auto Translate</div>}
                                                            </div>
                                                            {item.type === 'quill' ? <ReactQuill
                                                                theme="snow"
                                                                value={translation?.[item.key] || ''}
                                                                onChange={(e) => {
                                                                    updateTranslation({ ...translation, [item.key]: e })

                                                                }}
                                                                modules={quillModules}
                                                                formats={quillFormats}
                                                            /> : <Input
                                                                type={item.type}
                                                                placeholder={"e.g. " + item.title}
                                                                value={translation?.[item.key] || ''}
                                                                className="customInput"
                                                                onChange={(e) => {
                                                                    updateTranslation({ ...translation, [item.key]: e.target.value })

                                                                }} />}
                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>
                                        )
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </div>

            <ModalFooter>
                <Button color="danger" outline onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button color="primary" outline onClick={() => {
                    submit(translation)
                }}>
                    Submit
                </Button>{" "}
            </ModalFooter>
        </Modal >
    )
};

export default TranslatePopup;