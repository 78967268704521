import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import moment from "moment";
import { connect } from "react-redux";
import { firestore } from '../../../helpers/Firebase'
import { checkTimeRange, SearchUsers } from "../../../helpers/Utils";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { debounce } from 'lodash'
import CustomerHistory from "../../../components/common/CustomerHistory";


const dataTableColumns = (authUser) => [
  {
    Header: "Customer",
    accessor: "displayName",
    Cell: props => <p className="list-item-heading">{props.value}<br />{authUser.role === 'superAdmin' ? props.original.number : (checkTimeRange() && props.original.number)}</p>
  },
  {
    Header: "Orders Placed",
    accessor: "ordersPlaced",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: "First Order At",
    accessor: "firstOrder",
    Cell: props => <div className="list-item-heading" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
  },
  {
    Header: "Last Order At",
    accessor: "lastOrder",
    Cell: props => <div className="list-item-heading" >{moment(props?.value || '').format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props?.value || '').format("hh:mmA")}</div></div>
  },
  {
    Header: "Total Revenue",
    accessor: "revenue",
    Cell: props => <p className="list-item-heading">{"AED " + (props?.value)?.toFixed(2)}</p>
  },

];

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameSearch: '',
      allUsers: [],
      filteredUsers: null,
      customer: false
    };
  }

  filterFunc() {
    let { nameSearch, filteredUsers } = this.state

    if (nameSearch) {
      filteredUsers = filteredUsers.filter((item) => {
        return (item.name).toLowerCase().includes(nameSearch)
      })
    }


    this.setState({ filteredUsers })

  }

  handleChange(event) {
    let { allUsers } = this.state
    switch (event.target.name) {
      case 'name':
        this.setState({ nameSearch: event.target.value, filteredUsers: allUsers }, () => {
          this.filterFunc()
        })
        break;
      default:
        break;
    }
  }
  searchFunction = (customers) => {


    console.log('onlineResultonlineResult', customers);
    let asyncOptionsOnline = customers?.map(async res => {
      let ref = firestore().collection('orders')
      let userNumber = res?.phoneNumber

      let userBookingsSnap = await ref.where('contact.phoneNumber', '==', userNumber).get()

      let userBookings = [...userBookingsSnap.docs].map(r => { return { ...r.data(), key: r.id } }) || []
      let createdArray = userBookings.map(r => r.created)

      let totalRevenue = userBookings?.map(r => (r?.receipt?.total && r?.status !== 'canceled' && r?.status !== 'rejected' ? (r?.receipt?.total * 1) : 0))?.reduce((a, b) => a + b, 0)
      if (res && !userBookingsSnap.empty) {
        return {
          ...res,
          key: res.id,
          ordersPlaced: createdArray.length,
          firstOrder: createdArray.length ? Math.min.apply(null, createdArray) : new Date().getTime(),
          lastOrder: createdArray.length ? Math.max.apply(null, createdArray) : new Date().getTime(),
          revenue: totalRevenue,
          number: res.phoneNumber,
          orders: userBookings
        }
      }
      return false
    })

    Promise.all(asyncOptionsOnline).then((ress) => {
      this.setState({ allUsers: ress.filter(rec => rec), loading: false })
    })

  }
  debounce_fun = debounce(async function (e) {
    if (String(e).length > 3) {
      this.setState({ loading: true })

      let customers = await SearchUsers(e)
      this.searchFunction(customers)
    } else {
      this.setState({ allUsers: [], loading: false })
    }

  }, 1000);
  // exportCSV = (data, user) => {

  //   let prefix = "data:text/csv;charset=utf-8,";
  //   let header = ""
  //   header = "ID., Name of Customer, Customer Number, Customer Email, Number of Orders,revenue";

  //   let csvContent = header + "\r\n";
  //   data.map(item => {

  //     return csvContent += `${item?.uid || item?.id || '--'}, ${item?.name || item?.displayName || '--'},${item?.phoneNumber || item?.number || '--'},${item?.email || '--'},${item?.orders?.length || '--'},${item?.revenue || '--'}\r\n`;
  //   })
  //   var encodedUri = prefix + encodeURIComponent(csvContent);
  //   var link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "file.csv");
  //   document.body.appendChild(link);
  //   link.click();
  // }

  render() {
    let { authUser } = this.props
    let { allUsers, filteredUsers, loading, customer } = this.state

    return (
      <Fragment>
        {
          loading ? (
            <div className="loading" />
          ) : ''
        }
        <Row>
          <Colxx xxs="12">
            <div className="mb-2"><h1>Customers</h1></div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <Row>
                  <Colxx xxs="12" md="4" className='mt-1'>
                    <PhoneInput
                      countryCodeEditable={false}
                      inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                      className="customInput"
                      country={'ae'}
                      placeholder="e.g. +971xxxxxx"
                      // value={phoneNumber}
                      onChange={async (value, data, event, formattedValue) => {
                        let number = formattedValue.replaceAll(" ", "")
                        this.debounce_fun(number)
                      }}
                    />
                  </Colxx>
                  <Colxx xxs="12" md="4" className='mt-1'>
                    <Input type="text" style={{ borderRadius: '4px' }} placeholder="Customer Name" name="name" onChange={(e) => this.debounce_fun(e.target.value)} />
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                {filteredUsers || allUsers ? <ReactTable
                  data={filteredUsers ? filteredUsers : allUsers}
                  showPageJump={false}
                  defaultPageSize={10}
                  columns={dataTableColumns(authUser)}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={false}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: e => {
                        if (authUser?.role === 'superAdmin') {
                          this.setState({ customer: rowInfo.original })
                        }
                      },
                    }
                  }}
                /> : <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
                  <div className='font-weight-bold'>No Customers yet!</div>
                </div>}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        {customer && <CustomerHistory customer={customer} dismiss={() => this.setState({ customer: false })} />}
      </Fragment>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  return {
    authUser: authUser?.user,
  };
};

export default connect(mapStateToProps)(Customers);

