import React, { useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
} from "reactstrap";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import { Colxx } from "./CustomBootstrap";
import { checkAddressUrl, CheckLocationIsServed, getAddressDetails, getAddressParams, makeid, ReverseGeocode } from "../../helpers/Utils";
import { NotificationManager } from "./react-notifications";

const refs = {
    map: undefined,
}
const MapWithAMarker = withScriptjs(
    withGoogleMap(props => {
        return (
            <GoogleMap
                onDragEnd={() => {
                    props.getAddressFromLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })
                }}

                ref={(ref) => refs.map = ref}
                defaultZoom={15}
                options={{
                    disableDefaultUI: true,
                }}
                onCenterChanged={() => {
                    props.updateLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })
                }}
                onClick={(e) => {
                    props.updateLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                }}
                center={
                    props.latLng || {
                        lat: 25.1903,
                        lng: 55.2747,
                    }
                }

                defaultCenter={
                    props.latLng || {
                        lat: 25.1903,
                        lng: 55.2747,
                    }
                } >
                <SearchBox
                    style={{ left: '200px' }}
                    ref={(ref) => refs.searchBox = ref}
                    controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                    onPlacesChanged={props.onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `50%`,
                            height: `32px`,
                            marginTop: `20px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                        }}
                    />
                </SearchBox>
                <Marker position={props.latLng} />
            </GoogleMap >
        )
    }
    )
);

const AddressDetails = ({
    booking,
    dismiss,
    saveAddressDetails,
    editAddress,
    servingAreas
}) => {
    const [uniqeId] = useState(makeid())
    const [requiredInfo, setRequriedInfo] = useState(false)

    const [addressObject, UpdateAddress] = useState(editAddress?.edit ? { location: { ...editAddress, type: editAddress?.type || 'Apartment' } } : { location: { type: 'Apartment', id: uniqeId, key: uniqeId } })
    console.log('addressObjectaddressObjectaddressObject', addressObject);
    return (
        <Modal
            isOpen={true}
            backdrop="static"
            size="lg">
            <div>
                <Row className="invoice-react">
                    <Colxx xxs="12" >
                        <ModalHeader toggle={dismiss}>
                            <h6 className="font-weight-bold m-0 mb-2">Address Information</h6>
                        </ModalHeader>
                        <ModalBody>
                            {[
                                {
                                    key: 'addressInfo', title: 'Address Information',
                                    data: [
                                        { key: 'type', parent: 'location', title: 'Label', width: '2', type: 'text' },
                                        { key: 'linkAddress', parent: 'location', title: 'Enter Location Link', placeholder: 'Enter Location Link', width: '12', type: 'text', optional: true },
                                        { key: 'area', parent: 'location', title: 'Area', width: '6', placeholder: 'eg. Downtown Dubai', type: 'text' },
                                        { key: 'building', parent: 'location', title: 'Building Name', width: '6', placeholder: 'eg. The Prime Tower', type: 'text', hide: addressObject?.location?.type !== 'Apartment' },
                                        { key: 'flat', parent: 'location', title: 'Flat Number', width: '6', placeholder: 'eg. 1302', type: 'text', hide: addressObject?.location?.type !== 'Apartment' },
                                        { key: 'villa', parent: 'location', title: 'Villa', width: '6', placeholder: 'eg. Villa 36', type: 'text', hide: addressObject?.location?.type !== 'Villa' },
                                        { key: 'street', parent: 'location', title: 'Street', width: '6', placeholder: 'eg. Sheikh Mohammed bin Rashid Blvd', type: 'text', optional: true },
                                        { key: 'label', parent: 'location', title: 'Address Nick Name', width: '6', placeholder: 'Nick Name', type: 'text', optional: true },
                                        { key: 'additional_directions', parent: 'location', title: 'Additional Directions', width: '6', placeholder: 'Additional Directions', type: 'textarea', optional: true },
                                    ]
                                }].map((rec, recIndex) => {
                                    return (
                                        <Row key={recIndex} >
                                            <Colxx xxs="12" className="mb-4">
                                                <Card className='rounded' style={{ overflow: 'hidden', boxShadow: 'none' }}>
                                                    <CardBody style={{ padding: '0' }}>
                                                        {
                                                            <MapWithAMarker
                                                                onPlacesChanged={async () => {
                                                                    let locations = refs.searchBox.getPlaces()[0]
                                                                    let location = locations?.geometry?.location
                                                                    let latLng = { lat: location?.lat(), lng: location?.lng() }
                                                                    let address = getAddressDetails(locations, latLng)

                                                                    let { place_id, cluster } = await CheckLocationIsServed(servingAreas, { latitude: location?.lat(), longitude: location?.lng() })

                                                                    UpdateAddress({ ...addressObject, location: { ...addressObject.location, ...address, place_id, cluster }, updated: true })
                                                                }}
                                                                updateLatLng={(latLng) => UpdateAddress({ ...addressObject, location: { ...addressObject.location, latLng: latLng }, updated: true })}
                                                                getAddressFromLatLng={async (latLng) => {
                                                                    try {
                                                                        let response = await ReverseGeocode(latLng)
                                                                        let address = getAddressDetails(response?.results?.[0], latLng)
                                                                        UpdateAddress({ ...addressObject, location: { ...addressObject.location, ...address }, updated: true })

                                                                    } catch (error) {
                                                                        console.log('error', error);

                                                                    }
                                                                }}

                                                                latLng={addressObject?.location?.latLng}
                                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o&v=3.exp&libraries=geometry,drawing,places&language=en"
                                                                loadingElement={<div className="address-item " />}
                                                                containerElement={<div className="address-item" />}
                                                                mapElement={<div className="address-item" />} />
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Colxx>
                                            {rec.data.map((item, index) => {
                                                return !item.hide && (
                                                    item.title === 'Label' ?

                                                        <Colxx xxs="12" md='12' className="mb-4">
                                                            {['Apartment', 'Villa'].map((r, i) => {
                                                                return (<Button
                                                                    key={i}
                                                                    color="primary"
                                                                    size="sm"
                                                                    className="mr-2"
                                                                    outline={r !== addressObject?.[item.parent]?.[item.key]}
                                                                    onClick={() => {
                                                                        UpdateAddress({ ...addressObject, [item.parent]: { ...addressObject?.[item.parent], [item.key]: r }, updated: true })
                                                                    }}>{r}</Button>
                                                                )
                                                            })}
                                                        </Colxx>
                                                        :
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-3">
                                                            <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'flex-start' }}>
                                                                <div>{item.title}</div>
                                                                {item.optional && <span
                                                                    style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                                                    className={`ml-1 badge badge-pill badge-light badge-top-left`}
                                                                >
                                                                    Optional
                                                                </span>}
                                                            </div>
                                                            <Input
                                                                type={item.type}
                                                                placeholder={item.placeholder}
                                                                value={addressObject?.[item.parent]?.[item.key]}
                                                                className="customInput"
                                                                onChange={async (e) => {
                                                                    if (item.key === 'linkAddress') {
                                                                        let { url, query } = checkAddressUrl(e.target.value);

                                                                        if (url && query) {
                                                                            let getAddress = await getAddressParams({ area: query })
                                                                            let { results } = getAddress

                                                                            if (results && results.length > 0) {
                                                                                let { geometry } = results[0]
                                                                                let latLng = {
                                                                                    lat: geometry.location.lat,
                                                                                    lng: geometry.location.lng
                                                                                }
                                                                                let address = getAddressDetails(results?.[0], latLng)
                                                                                UpdateAddress({ ...addressObject, location: { ...addressObject.location, ...address }, updated: true })
                                                                            } else {
                                                                                NotificationManager.error(
                                                                                    'Cannot get location from URL',
                                                                                    "Error",
                                                                                    2000,
                                                                                    null,
                                                                                )
                                                                            }
                                                                        }
                                                                        // else {
                                                                        //     UpdateAddress({ ...addressObject, [item.parent]: { ...addressObject?.[item.parent], [item.key]: e?.target?.value }, updated: true })
                                                                        // }
                                                                    } else {
                                                                        UpdateAddress({ ...addressObject, [item.parent]: { ...addressObject?.[item.parent], [item.key]: e?.target?.value }, updated: true })

                                                                    }

                                                                }} />
                                                        </Colxx>

                                                )
                                            })}
                                        </Row>
                                    )
                                })}
                        </ModalBody>
                    </Colxx>
                </Row>
            </div>
            <ModalFooter>
                <Button color="danger" outline onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button
                    disabled={
                        !addressObject?.location?.type
                        || !addressObject?.location?.area
                        || (addressObject?.location?.type === 'Apartment' ? !addressObject?.location?.building : false)
                        || (addressObject?.location?.type === 'Apartment' ? !addressObject?.location?.flat : false)
                        || (addressObject?.location?.type === 'Villa' ? !addressObject?.location?.villa : false)
                    }
                    color="primary" outline onClick={() => {
                        if (!addressObject?.location?.latLng) {
                            setRequriedInfo(true)
                        } else {
                            saveAddressDetails(addressObject)
                        }
                    }}>
                    Save
                </Button>{" "}
            </ModalFooter>
            <Modal
                isOpen={requiredInfo}
                backdrop="static"
                size="md">
                <ModalHeader >
                    <h6 className="font-weight-bold m-0 mb-2">Missing Required Information</h6>
                </ModalHeader>
                <ModalBody>
                    Sorry, we couldn't retrieve the latitude and longitude information for the selected location. Please ensure that the location is accurate and try again
                </ModalBody>

                <ModalFooter>
                    <Button color="danger" outline onClick={() => setRequriedInfo(false)}>
                        Dismiss
                    </Button>
                </ModalFooter>

            </Modal >
        </Modal >
    )

};

export default AddressDetails;