import React, { Fragment, useEffect, useState } from "react";
import { Row, Card, CardBody, Input, Button, Table } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Booking from "../../app/bookings/components/booking"

import './crmstyles.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../../helpers/Firebase";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import { calculateSOA, checkTimeRange, exportCSV } from "../../../helpers/Utils";
const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            className="customDatePicker"
            readOnly={true}
            ref={ref}
        />
    )
})

const startDate = new Date();
startDate.setDate(startDate.getDate() - 14);
const OrderHistory = () => {
    const dispatch = useDispatch();
    const { activeItem } = useSelector(state => state.activeItem)

    const { user } = useSelector(state => state.authUser)
    const { services } = useSelector(state => state.settings)
    const { allProviders } = useSelector(state => state.providers)
    const { allProfessionals } = useSelector(state => state.providers)
    const [selectedOrder, setSelectedOrder] = useState(false)
    const [data, FilterOrders] = useState({})
    const [search, setSearch] = useState({ dateRange: [startDate, new Date()] })

    const status = {
        new: { label: "Pending Payment", value: "Pending Payment", id: 'new', color: "#c43d4b", action: 'Accept', hide: !['superAdmin', 'accountant'].includes(user.role) },
        pending: { label: "Pending Assigment", value: "Pending Assigment", id: 'pending', color: "#3498db", action: 'Accept', hide: !['superAdmin', 'accountant'].includes(user.role) },
        accepted: { label: "Accepted", value: "Accepted", id: 'accepted', color: "#145388", action: 'On the way' },
        ontheway: { label: "On The Way", value: "On The Way", id: 'ontheway', color: "#3e884f", action: 'Arrive' },
        active: { label: "Session Started", value: "Session Started", id: 'active', color: "#3195a5", action: 'Complete' },
        completed: { label: "Completed", value: "Completed", id: 'completed', color: "#3e884f", action: '' },
        rated: { label: "Rated", value: "Rated", id: 'rated', color: "#b69329", action: '' },
        canceled: { label: "Canceled", value: "Canceled", id: 'canceled', color: "#c43d4b", action: 'Cancel' },
        rejected: { label: "Rejected", value: "Rejected", id: 'rejected', color: "#c43d4b", action: 'Reject' },
    }

    useEffect(() => {
        async function fetchData() {
            let { dateRange } = search;
            let ref = firestore().collection('orders')
            console.log('useruseruser', user);
            if (!['superAdmin', 'accountant'].includes(user.role)) {
                if (user.role === 'admin') {
                    ref = ref.where("provider.id", "==", user?.parent_id)
                } else if (user.role === 'concierge') {
                    ref = ref.where("brand.id", "==", user?.brand_id)

                }
            }
            let userBookingsSnap = await ref.where('bookingDate.date', '>=', moment(dateRange[0]).startOf('day').toDate().getTime()).where('bookingDate.date', '<=', moment(dateRange[1]).endOf('day').toDate().getTime()).get()
            let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {
                return {
                    ...res.data(),
                    key: res.id,
                    bookingId: res.id,
                }

            })
            Promise.all(asyncOptionsOnline).then((ress) => {
                let sales = 0
                let providerFees = 0
                let avgSales = 0
                let metadocCommission = 0

                ress = ress?.filter(item => {
                    return (
                        (search?.services?.length ? search.services.includes(item?.service?.id) : true) &&
                        (search?.partners?.length ? search.partners.includes(item?.brand?.id) : true) &&
                        (search?.providers?.length ? search.providers.includes(item?.provider?.id) : true) &&
                        (search?.professionals?.length ? search.professionals.includes(item?.professional?.id) : true) &&
                        (search?.status?.length ? search.status.includes(item.status) : true) &&
                        (search?.type?.length ? search.type.includes(item.type || 'online') : true) &&
                        (search?.phoneNumber?.length ? String(item?.contact?.phoneNumber).includes(search?.phoneNumber) : true) &&
                        (search?.orderNumber?.length ? String(item.orderNumber).includes(search?.orderNumber) : true)
                    )
                })
                ress.map(item => {
                    return (
                        (!['canceled', 'rejected', 'test'].includes(item.status)) && (
                            sales += calculateSOA(item).total,
                            providerFees += calculateSOA(item).providerFees,
                            metadocCommission += calculateSOA(item).metadocCommission,
                            avgSales = (sales / ress?.filter(r => !['canceled', 'rejected', 'test'].includes(r.status)).length).toFixed(2)
                        ))
                })
                FilterOrders({ orders: ress, filteredBookings: ress, avgSales, metadocCommission, sales, providerFees })
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [search])

    return (
        <Fragment>
            <Row>
                <Colxx xxs="12">
                    <div className='d-flex justify-content-between'>
                        <div className="mb-2"><h1>Orders History</h1></div>
                        <Button className='btn-sm' style={{ height: '35px' }} onClick={() => exportCSV(data?.filteredBookings, user)}>Export</Button>
                    </div>
                    <Separator className="mb-5" />
                </Colxx>
            </Row>
            <Row>
                <Colxx xxs="12">
                    <Card className="mb-4 rounded">
                        <CardBody>
                            {[
                                {
                                    key: 'searchFields',
                                    title: 'Search Fields',
                                    data: [
                                        { key: 'dateRange', title: 'Select Date', width: '3', type: 'date' },
                                        { key: 'orderNumber', title: 'Order Number', width: '3', type: 'text' },
                                        { key: 'phoneNumber', title: 'Mobile Number', width: '3', type: 'text' },
                                        { key: 'status', title: 'Status', width: '3', type: 'select', data: Object.values(status).filter(r => !r.hide) },
                                        { key: 'providers', title: 'Select Providers', width: '3', type: 'select', data: allProviders, hide: !['superAdmin', 'accountant'].includes(user.role) },
                                        { key: 'professionals', title: 'Select Professionals', width: '3', type: 'select', data: allProfessionals, hide: !['superAdmin', 'accountant', 'admin'].includes(user.role) },
                                        // { key: 'partners', title: 'Select Partners', width: '3', type: 'select', data: allPartners },
                                        { key: 'services', title: 'Services', width: '3', type: 'select', data: services },
                                        {
                                            key: 'type', title: 'Order Type', width: '3', type: 'select', data: [
                                                { name: 'Online Orders', id: 'online' },
                                                { name: 'Offline Orders', id: 'offline' },

                                            ]
                                        },
                                    ]
                                },
                            ].map((rec, recIndex) => {
                                return (
                                    <Row key={recIndex}>

                                        {rec.data.map((item, index) => {
                                            let itemOptions = (item?.data)?.map(rec => {
                                                return ({ ...rec, value: rec?.name?.en || rec?.name || rec?.value, label: rec?.name?.en || rec?.name || rec?.label, key: rec?.id })
                                            })
                                            return !item.hide && (
                                                <Colxx key={index} xxs="12" md={item.width} className="mb-2">
                                                    {item.type === 'date' ?
                                                        <DatePicker
                                                            customInput={<CustomInput />}
                                                            onChange={(dates) => {
                                                                setSearch({ ...search, [item.key]: dates })
                                                            }}
                                                            selectsRange={true}
                                                            startDate={search?.[item.key]?.[0]}
                                                            endDate={search?.[item.key]?.[1]}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                        : item.type === 'select' ?
                                                            <Select
                                                                isMulti
                                                                components={{ Input: CustomSelectInput }}
                                                                className="basic-multi-select custoumSelectCRM"
                                                                placeholder={item.title}
                                                                value={search?.[item.key] ? search?.[item.key]?.map(rec => {
                                                                    let i = item.data?.find(r => r.id === rec)
                                                                    return ({ ...i, value: i?.name?.en || i?.name || i?.value, label: i?.name?.en || i?.name || i?.label, key: i?.id })
                                                                }) : []}
                                                                options={[{ value: 'All', label: 'All', key: 'all' }, ...(itemOptions || [])]}
                                                                onChange={val => {
                                                                    setSearch({ ...search, [item.key]: val?.find(r => r.key === 'all') ? itemOptions?.map(r => r.key) : val?.map(r => r.key) })
                                                                }}
                                                            />
                                                            : <Input
                                                                type={item.type}
                                                                placeholder={item.title}
                                                                value={search?.[item.key]}
                                                                className="customInput"
                                                                onChange={(e) => {
                                                                    setSearch({ ...search, [item.key]: e.target.value })
                                                                }} />}
                                                </Colxx>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

            <Row>
                <Colxx xxs="12">
                    <Card className="mb-4 rounded">
                        <Row className="mt-4 pl-4 pr-4 justify-content-between align-items-center">
                            {['superAdmin', 'accountant', 'concierge'].includes(user.role) && <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Revenue</div>
                                <div className="salesValue">{'AED ' + Number(data?.sales || 0).toFixed(2)}</div>
                            </Colxx>}
                            {['superAdmin', 'accountant', 'admin'].includes(user.role) && <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Service Providers Revenue</div>
                                <div className="salesValue">{'AED ' + Number(data?.providerFees || 0).toFixed(2)}</div>
                            </Colxx>}
                            {['superAdmin', 'accountant'].includes(user.role) && <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Metadoc Commission</div>
                                <div className="salesValue">{'AED ' + Number(data?.metadocCommission || 0).toFixed(2)}</div>
                            </Colxx>}
                            {['superAdmin', 'accountant'].includes(user.role) && <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Average Metadoc Commission</div>
                                <div className="salesValue">{'AED ' + (Number(data?.metadocCommission || 0) / data?.filteredBookings?.length).toFixed(2)}</div>
                            </Colxx>}

                            <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Average order value</div>
                                <div className="salesValue">{'AED ' + Number(data?.avgSales || 0).toFixed(2)}</div>
                            </Colxx>
                            <Colxx xxs="2" className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="salesTitle">Total orders</div>
                                <div className="salesValue">{data?.filteredBookings?.length || '0'}</div>
                            </Colxx>
                        </Row>
                        {data?.filteredBookings?.length ? <Table striped className={'mt-4'}>
                            <thead>
                                <tr >
                                    <th style={{ paddingLeft: '34px' }}>#</th>
                                    <th>Order Number</th>
                                    <th>Customer</th>
                                    {['superAdmin', 'accountant'].includes(user.role) && <th>Provider</th>}
                                    <th>Area</th>
                                    <th>Total</th>
                                    {['superAdmin', 'accountant'].includes(user.role) && <th>Provider Fees</th>}
                                    {['superAdmin', 'accountant'].includes(user.role) && <th>Metadoc Fees</th>}
                                    {['superAdmin', 'accountant'].includes(user.role) && <th>Discount</th>}
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Appointment Date</th>
                                    <th style={{ textAlign: 'end', paddingRight: '34px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.filteredBookings?.map((r, ind) => {
                                    let selectedItem = r
                                    return (<tr key={selectedItem.orderNumber} className="mt-1 ">
                                        <th style={{ verticalAlign: 'middle', paddingLeft: '34px' }} scope="row">{ind + 1}</th>
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{selectedItem?.orderNumber}</td>
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }}>{selectedItem?.contact?.name}<br />{['superAdmin', 'accountant'].includes(user.role) ? selectedItem?.contact?.phoneNumber : (checkTimeRange() && selectedItem?.contact?.phoneNumber)}</td>
                                        {['superAdmin', 'accountant'].includes(user.role) && <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{selectedItem?.provider?.name?.en || selectedItem?.provider?.name}</td>}
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{selectedItem?.location?.area?.en || selectedItem?.location?.area}</td>
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{calculateSOA(selectedItem)?.total?.toFixed(2)}</td>
                                        {['superAdmin', 'accountant'].includes(user.role) && <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{calculateSOA(selectedItem)?.providerFees?.toFixed(2)}</td>}
                                        {['superAdmin', 'accountant'].includes(user.role) && <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{calculateSOA(selectedItem)?.metadocCommission?.toFixed(2)}</td>}
                                        {/* {['superAdmin', 'accountant'].includes(user.role) && <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{calculateSOA(selectedItem)?.discount?.toFixed(2)}</td>} */}
                                        {['superAdmin', 'accountant'].includes(user.role) && <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{Number(selectedItem?.receipt?.discount || 0)?.toFixed(2)}</td>}
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', color: status?.[selectedItem?.status]?.color }}>{status?.[selectedItem?.status]?.label || selectedItem?.status}</td>
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{selectedItem?.type === 'offline' ? 'Offline' : 'Online'}</td>
                                        <td style={{ fontSize: '12px', fontWeight: '700', verticalAlign: 'middle' }} >{moment(selectedItem?.bookingDate?.date).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(selectedItem?.bookingDate?.date).format("hh:mmA")}</div></td>
                                        <td style={{ paddingRight: '34px', verticalAlign: 'middle' }} >
                                            <Button
                                                style={{ padding: '0.2rem 1rem' }}
                                                color="primary"
                                                size="sm"
                                                outline
                                                // className="top-right-button"
                                                onClick={() => {
                                                    dispatch(SetActiveItem({ general: selectedItem }))
                                                    setSelectedOrder(selectedItem)
                                                }}
                                            >View
                                            </Button>
                                        </td>
                                    </tr>)
                                })}

                            </tbody>
                        </Table> : <div style={{
                            height: '500px',
                            display: 'flex',
                            fontSize: '16px',
                            fontWeight: '600',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>No Records</div>}
                        {
                            selectedOrder && activeItem &&
                            <Booking
                                authUser={user}
                                selectedItem={activeItem}
                                dismiss={() => {
                                    dispatch(SetActiveItem(false))
                                }}
                            />
                        }

                    </Card>
                </Colxx>
            </Row>
        </Fragment>
    );
};

export default OrderHistory;