import {

    GET_ACTIVE_ITEM,
    UPDATE_ACTIVE_ITEM
} from '../actions';



export const SetActiveItem = (data) => {
    return (
        {
            type: GET_ACTIVE_ITEM,
            payload: data
        }
    )
}
export const UpdateItem = (data) => {
    return (
        {
            type: UPDATE_ACTIVE_ITEM,
            payload: data
        }
    )
}

