// const minOffset = 0;
// const maxOffset = 60; 

// class YearsDatePicker extends React.Component {
//   constructor() {
//     super();

//     const thisYear = (new Date()).getFullYear();

//     this.state = {
//       thisYear: thisYear,
//       selectedYear: thisYear
//     }
//   }

//   onHandleChange = (evt) => {
//     // Handle Change Here
//     // alert(evt.target.value);
//     this.setState({ selectedYear: evt.target.value });
//   };

//   render() {
//     const { thisYear, selectedYear } = this.state;
//     const options = [];

//     for (let i = minOffset; i <= maxOffset; i++) {
//       const year = thisYear - i;
//       options.push(<option value={year}>{year}</option>);
//     }
//     return (
//       <div>
//         <select value={this.selectedYear} onChange={this.onHandleChange}> 
//           {options}
//         </select>
//       </div>
//     );
//   }
// }

// /*
//  * Render the above component into the div#app
//  */
// ReactDOM.render(<YearsDatePicker />, document.getElementById('app'));

import React, { Component } from 'react';
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Separator } from './CustomBootstrap';
const minOffset = 0;
const maxOffset = 60;

class YearsDatePicker extends Component {
    constructor() {
        super();

        const thisYear = (new Date()).getFullYear();
        this.state = {
            nestingDropdownOpen: false,
            thisYear: thisYear,
            selectedYear: thisYear
        }
    }
    onHandleChange = (evt) => {
        let value = evt.target.value
        this.setState({ selectedYear: value }, () => {
            this.props.updateYear(value)
        });
    };
    nestingToggle = () => {
        this.setState({
            nestingDropdownOpen: !this.state.nestingDropdownOpen
        });
    };
    render() {
        const { thisYear, selectedYear } = this.state;
        const options = [];
        for (let i = minOffset; i <= maxOffset; i++) {
            const year = thisYear - i;
            options.push(year);
        }
        return (
            <div>
                <ButtonDropdown
                    className="top-right-button  ml-2"
                    size="md"
                    isOpen={this.state.nestingDropdownOpen}
                    toggle={this.nestingToggle}
                >
                    <DropdownToggle color="primary" caret>
                        {selectedYear}
                    </DropdownToggle>
                    <DropdownMenu style={{ height: '250px', overflow: 'auto' }}>
                        {options.map(rec => {
                            return (
                                <div key={rec}>
                                    <DropdownItem  onClick={() => {
                                        this.setState({ selectedYear: rec }, () => {
                                            this.props.updateYear(rec)
                                        });
                                    }}>
                                        {rec}
                                    </DropdownItem>
                                    <Separator />
                                </div>
                            )
                        })}

                    </DropdownMenu>
                </ButtonDropdown>
                {/* <select value={this.selectedYear} onChange={this.onHandleChange} style={{ padding: '10px 15px' }}>
                    {options}
                </select> */}
            </div>
        );
    }
}

export default YearsDatePicker;