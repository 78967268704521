import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Label,
    Row, Card, CardBody, Table, ModalHeader
} from "reactstrap";
import { firestore } from '../../../../helpers/Firebase'

import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import { checkTimeRange, createNotification, formatAddress, getCityFromAddress, submitOrder } from "../../../../helpers/Utils";
import { NavLink } from "react-router-dom";
import Logs from "./Logs";
import AcceptOrderModal from "../../../../components/common/AcceptOrderModal";
import WhatsappIcon from "../../../../assets/img/whatsapp.png";
import { saveAs } from "file-saver";
import { setUserFromRequests } from "../../../../redux/chat/actions";

const Booking = ({ dismiss }) => {
    const { user } = useSelector(state => state.authUser)
    const { allProviders } = useSelector(state => state.providers)
    const [loading, setLoading] = useState(false)

    const { activeItem } = useSelector(state => state.activeItem)
    const [booking, setBooking] = useState(activeItem?.general || {})
    const [rejectModal, setReject] = useState(false)
    const [acceptOrderModal, setAcceptOrderModal] = useState(false)
    const [showLogs, setShowLogs] = useState(false)
    const [city, setCity] = useState('')

    const [chatExists, setChatExists] = useState(false)

    const dispatch = useDispatch()


    const status = {
        new: { label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept', nextStatus: acceptOrderModal?.provider?.id ? 'accepted' : 'pending', },
        pending: { label: "Pending Assigment", status: 'pending', nextStatus: 'accepted', color: "#3498db", action: 'Accept' },
        accepted: { label: "Accepted", status: 'accepted', nextStatus: 'ontheway', color: "#145388", action: 'On the way' },
        ontheway: { label: "On The Way", status: 'ontheway', nextStatus: 'active', color: "#3e884f", action: 'Arrive' },
        active: { label: "Session Started", status: 'active', nextStatus: 'completed', color: "#3195a5", action: 'Complete' },
        completed: { label: "Completed", status: 'completed', nextStatus: 'completed', color: "#3e884f", action: '' },
        rated: { label: "Booking Rated", status: 'rated', color: "#b69329", action: '' },
        canceled: { label: "Booking Canceled", status: 'canceled', color: "#c43d4b", action: 'Cancel' },
        rejected: { label: "Booking Rejected", status: 'rejected', color: "#c43d4b", action: 'Reject' },
    }
    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: booking }))
        // eslint-disable-next-line
    }, [booking])
    useEffect(() => {
        let id = firestore().collection('orders').doc().id;
        firestore().collection('orders').doc(activeItem?.general?.id).collection('log').doc(id).set({
            created: Date.now(),
            updatedBy: user.uid,
            createdBy: {
                email: user.email,
                name: user.displayName,
                role: user.role
            },
            title: 'viewRequest',

        }, { merge: true })
        // eslint-disable-next-line
    }, [user])

    const ignoreFunc = async (booking, parent_id) => {
        let { general } = JSON.parse(JSON.stringify(activeItem));
        let id = general.id
        await firestore().collection('orders').doc(id).update({
            candidates: firestore.FieldValue.arrayRemove(parent_id),
            ignoredCandidates: firestore.FieldValue.arrayUnion(parent_id),
            updated: Date.now(),
            updatedBy: user.uid

        }).then(() => {
            if (booking.rescadual) {
                createNotification("warning", "Success", "Order Released Successfully")
            } else {
                createNotification("warning", "Success", 'Order Ignored Successfully')
            }
        })

        setLoading(false)
        setBooking(booking)
        setReject(false)
        setAcceptOrderModal(false)
        dispatch(SetActiveItem(false))

    }
    const releaseFunc = async (booking, newStatus) => {
        let { general } = JSON.parse(JSON.stringify(activeItem));
        let id = general.id

        booking.updated = Date.now()
        booking.status = newStatus
        booking[newStatus] = Date.now()
        booking.updatedBy = user.uid

        delete booking.viewOrder
        delete booking.delayedOntheWay
        delete booking.delayedActive
        delete booking.delayedCompleted
        delete booking?.receipt?.providerFees
        delete booking?.professional

        let now = moment()
        let accepted = moment(booking?.accepted)
        let start = moment(booking?.bookingDate?.date)
        let diffAccepted = now.diff(accepted, 'minutes')
        let diffStart = start.diff(now, 'minutes')

        let totalPrice = Number(booking?.receipt?.total)
        let post = {
            created: Date.now(),
            orderId: id,
            receipt: booking?.receipt,
            penalty: (diffAccepted > 15 && diffStart > 120) ? (totalPrice / 2) : (diffStart < 120) ? totalPrice : 0
        }
        if (post?.penalty) {
            await firestore().collection('providers').doc(booking?.provider?.id).collection('penalties').doc(id).set(post, { merge: true }).then(async () => {
                booking.provider = {}
                await firestore().collection('orders').doc(id).update(booking).then(async () => {
                    createNotification("warning", "Success", "Order Released Successfully")
                })
            })
        } else {
            booking.provider = {}
            await firestore().collection('orders').doc(id).update(booking).then(async () => {
                createNotification("warning", "Success", "Order Released Successfully")
            })

        }

        setLoading(false)
        setBooking(booking)
        setReject(false)
        setAcceptOrderModal(false)
        dispatch(SetActiveItem(false))
    }
    const copy = (entryText) => {
        navigator.clipboard.writeText(entryText);
        createNotification("success", "Copied to clipboard", entryText)
    }
    useEffect(() => {
        if (!booking?.location?.emirate) {
            getCityFromAddress(booking?.location?.latLng).then(city => {
                setCity(city)
            })
        }
        // eslint-disable-next-line
    }, [booking?.location])
    useEffect(() => {
        async function fetchData() {
            if (booking?.contact?.phoneNumber) {
                let customerNumber = `${(booking?.contact?.phoneNumber).replace(/\D/g, '')}`;
                let ref = await firestore().collection('chats').doc(customerNumber).collection('messages').where('delivered', '!=', null).get()
                setChatExists(!ref.empty)
            }
        }
        fetchData();

    }, [booking.contact.phoneNumber])

    return (


        <Modal
            isOpen={true}
            // toggle={dismiss}
            backdrop="static"
            size="lg">
            {loading && (
                <div className="loading" />
            )}
            <div>

                <Row className="invoice-react">
                    <Colxx xxs="12" >
                        <ModalHeader toggle={dismiss}>
                            <div className="d-flex flex-column justify-flex-start" style={{ alignItems: 'flex-start' }} >
                                <div className='badge badge-pill mb-2' style={{ backgroundColor: status[booking.status]?.color, color: 'white', borderRadius: '4px' }} >{status[booking.status]?.label}</div>
                                <h6 className="font-weight-bold m-0 mb-2">Order ID: {booking?.orderNumber}</h6>
                                <h6 color="primary" style={{ color: '#3498db', cursor: 'pointer' }} className="font-weight-bold m-0" size="sm" outline onClick={() => setShowLogs(true)}>
                                    Show Logs
                                </h6>
                            </div>

                        </ModalHeader>
                        <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                            <CardBody className="d-flex flex-column justify-content-between">
                                <div className="d-flex flex-column">
                                    {[{
                                        key: 'contactInfo',
                                        title: 'Contact Information',
                                        chatWithPatient: true,
                                        data: [
                                            { title: 'Customer Name', width: '4', data: booking?.contact?.name },
                                            { title: 'Phone Number', width: '4', data: booking?.contact?.phoneNumber, hide: user.role !== 'superAdmin' && (['new', 'pending'].includes(booking?.status) || !checkTimeRange()) },
                                            { title: 'Email', width: '4', data: booking?.contact?.email, hide: user.role !== 'superAdmin' && ['new', 'pending'].includes(booking?.status) },
                                            { title: 'Villa Number', width: '4', data: booking?.location?.villa, hide: !booking?.location?.villa },
                                            { title: 'Flat / Room Number', width: '4', data: booking?.contact?.room || booking?.location?.flat, hide: booking?.location?.villa },
                                            { title: 'Building', width: '4', data: booking?.location?.building, hide: booking?.location?.villa },
                                            { title: 'Street', width: '4', data: booking?.location?.street?.en || booking?.location?.street },
                                            { title: 'Area', width: '4', data: booking?.location?.area?.en || booking?.location?.area },
                                            { title: 'City', width: '4', data: booking?.location?.emirate?.en || booking?.location?.emirate || city },
                                            { title: 'Additional Directions', width: '4', data: booking?.location?.additional_directions },
                                            // { title: 'Address', width: '6', data: booking?.location?.address?.en || booking?.location?.address },
                                            { title: 'Address', width: '4', data: formatAddress({ ...booking?.location, flat: booking?.location?.flat ? `Flat ${booking?.location?.flat}` : '', villa: booking?.location?.villa ? `Villa ${booking?.location?.villa}` : '', street: booking?.location?.street ? `Street ${booking?.location?.street?.en || booking?.location?.street}` : '', emirate: booking?.location?.emirate?.en || booking?.location?.emirate || city }) },
                                            { title: 'Map location', width: '4', data: 'Click on the map to navigate to the customer location', }

                                        ]
                                    },
                                    {
                                        key: 'addressInfo', title: 'Booking Details',

                                        data: [
                                            { title: 'Order ID', width: '4', data: booking?.orderNumber },
                                            { title: 'Booking Status', width: '4', data: status[booking.status]?.label },
                                            { title: 'Client', width: '4', data: booking?.brand?.name },
                                            { title: 'Start at', width: '4', data: booking?.bookingDate ? moment(booking?.bookingDate?.date).format('DD/MM/YYYY') + " " + moment(booking?.bookingDate?.time?.start).format('hh:mm A') : '' },
                                            { title: 'Provider', width: '4', data: booking?.provider?.name },
                                            { title: 'Professional', width: '4', data: booking?.professional?.name || booking?.professional?.name },
                                            { title: 'Notes', width: '6', data: booking.notes }
                                        ]
                                    },
                                    {
                                        key: 'extraInfo', title: 'Other Information',
                                        data: [
                                            { title: 'SP Status Link', width: '6', id: 'sp_status_link', data: booking?.provider_order_url, copy: true },
                                            { title: 'Customer Status Link', width: '6', id: 'customer_status_link', hide: user.role !== 'superAdmin', data: booking.orderUrl, copy: true },
                                            { title: 'Payment Link', width: '6', id: 'payment_link', hide: booking?.paid || ['canceled', 'rejected', 'completed', 'rated'].includes(booking?.status), data: `https://app.metadocapp.com/Payment/order/${booking?.id}`, copy: true }
                                        ]
                                    },

                                    ].map((item, recIndex) => {
                                        return (
                                            <div key={recIndex}>
                                                <Row>
                                                    <Colxx xxs="12" className="mb-2">
                                                        <h6 style={{ fontWeight: 'bold', marginRight: '16px' }} className="d-flex align-items-center">{item.title} {
                                                            item.chatWithPatient && chatExists && (user.role !== 'superAdmin' ? ['accepted', 'ontheway', 'active'].includes(booking?.status) : true) ?
                                                                <NavLink
                                                                    to={'/app/chat'}
                                                                    onClick={e => dispatch(setUserFromRequests(String(Number(booking?.contact?.phoneNumber))))}
                                                                >
                                                                    <Button color="success" className='d-flex align-items-center' style={{ marginLeft: '8px' }} size='xs' >
                                                                        Chat With Patient <img style={{ width: '15px', marginLeft: '8px' }} alt="" src={WhatsappIcon} />
                                                                    </Button>
                                                                </NavLink>
                                                                : ''
                                                        }</h6>

                                                    </Colxx>
                                                    {item.data.map((rec, index) => {
                                                        return !rec.hide && (
                                                            <Colxx xxs="12" md={rec.width} key={index}>
                                                                <Label className="mb-0  text-semi-muted">{rec.title}:</Label>
                                                                {(rec.copy && rec.data) ?
                                                                    <p href={rec.data} className="font-weight-bold" id={rec.id}>
                                                                        <a href={rec.data} target={'_blank'} rel="noopener noreferrer" className="font-weight-bold" id={rec.id}>{rec.data ? rec.data : '--'} </a>
                                                                        <i style={{ fontSize: '18px', color: '#3498db', cursor: 'pointer' }} className="iconsminds-file-clipboard" onClick={() => copy(rec.data)} />
                                                                    </p>
                                                                    : (rec.title === 'Map location' && booking?.location?.link && booking?.location?.latLng?.lat && booking?.location?.latLng?.lng) ?
                                                                        <p className="font-weight-bold d-flex align-items-center" id={rec.id}>{rec.data ? rec.data : '--'} {
                                                                            <NavLink
                                                                                key={booking?.location?.latLng?.lat}
                                                                                to={{ pathname: booking?.location?.link }}
                                                                                target="_blank">
                                                                                <img alt=""
                                                                                    key={booking?.location?.latLng?.lat}
                                                                                    // eslint-disable-next-line
                                                                                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${booking?.location?.latLng?.lat},${booking?.location?.latLng?.lng}&zoom=18&size=150x150&markers=color:red%7C${booking?.location?.latLng?.lat},${booking?.location?.latLng?.lng}&key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o`}
                                                                                    style={{ cursor: 'pointer', width: '70px', borderRadius: '14px', marginLeft: '8px', boxShadow: '0px 0px 14px -7px #000' }} />
                                                                            </NavLink>}</p>
                                                                        : <p className="font-weight-bold" id={rec.id} style={{ color: rec.title === 'Start at' && '#3498db' }}>{rec.data ? (rec?.data?.en || rec?.data) : '--'}</p>

                                                                }
                                                            </Colxx>
                                                        )
                                                    })}
                                                </Row>
                                                {item.key !== 'extraInfo' && <Separator className="pt-1 mb-5" />
                                                }
                                            </div>

                                        )
                                    })}
                                    {[

                                        { title: 'Service Details', width: '12', id: 'services', data: Object.values(booking?.items || {}) },
                                        { title: 'Addons Details', width: '12', id: 'addons', data: Object.values(booking?.addons || {}), hide: !Object.values(booking?.addons || {}).length, attachment: booking?.attachment || '' }
                                    ].map((rec, recIndex) => {
                                        return !rec.hide && (
                                            <div key={recIndex}>
                                                <Separator className="pt-3 mb-5" />
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <h6 style={{ fontWeight: 'bold' }}>{rec.title}</h6>
                                                    {rec?.attachment ? <div
                                                        className="mb-2 font-weight-bold"
                                                        style={{ color: '#3498db', cursor: 'pointer' }}
                                                        onClick={() => saveAs(rec?.attachment?.[0], 'image.jpg')}
                                                    >
                                                        <i className="fa fa-download" />
                                                        View Attachment
                                                    </div> : ''}
                                                </div>
                                                <Table borderless>

                                                    <thead>
                                                        <tr>
                                                            <th className="text-semi-muted mb-2" style={{ width: '45%', paddingLeft: '0' }}>
                                                                Item name
                                                            </th>
                                                            <th className="text-semi-muted mb-2">
                                                                Count
                                                            </th>
                                                            <th className="text-right text-semi-muted mb-2">
                                                                Price
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (rec?.data)?.map((item, itemIndex) => {
                                                                return (
                                                                    <tr key={itemIndex}>
                                                                        <td style={{ paddingLeft: '0' }}>

                                                                            <div style={{ fontSize: '14px' }}>
                                                                                {item?.name?.en || item?.name}
                                                                            </div>
                                                                            {
                                                                                item.extra && item.extra.map((extraIndex, index) => {
                                                                                    return (
                                                                                        Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
                                                                                            let duplicates = []
                                                                                            return (
                                                                                                <div key={categoryIndex} style={{ padding: '2px 8px' }}>
                                                                                                    <div style={{ color: 'gray', fontSize: '11px' }}>
                                                                                                        {categoryKey + ":"}
                                                                                                    </div>
                                                                                                    <div style={{ color: 'black', fontSize: '12px', fontWeight: '500' }}>
                                                                                                        {
                                                                                                            extraIndex[categoryKey].map((innerItem, pos) => {
                                                                                                                let extraCount = extraIndex[categoryKey].filter(rec => rec.name === innerItem.name).length
                                                                                                                if (!duplicates.includes(innerItem.name)) {
                                                                                                                    duplicates.push(innerItem.name)
                                                                                                                    return (
                                                                                                                        <div><span style={{ fontWeight: '700' }}>{extraCount + 'x '}</span>{innerItem.name}</div>
                                                                                                                    )
                                                                                                                }
                                                                                                                return false
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>

                                                                                            )
                                                                                        })
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                        <td>{item.count}</td>
                                                                        <td className="text-right">
                                                                            <div className="text-right">{((item?.price?.total || item?.price) * item?.count).toFixed(2) + " AED"}</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>

                                        )
                                    })}



                                </div>
                                <div className="d-flex flex-column">
                                    <Separator className="pt-3 mb-5" />
                                    <Table borderless className="d-flex" style={{ justifyContent: 'space-between' }}>
                                        <tbody>
                                            {[
                                                // { title: 'Payment Status', data: booking?.paid ? "Paid" : "Not Paid (To be collected)", color: booking?.paid ? '#3e884f' : "#c43d4b", },
                                                { key: 'paymentType', title: 'Payment Type', data: {} },
                                            ].map((rec, index) => {
                                                return (
                                                    rec.data ?
                                                        <tr key={index}>
                                                            <Label className="mb-0  text-semi-muted">{rec.title}:</Label>
                                                            {rec.key === 'paymentType' ?
                                                                <>
                                                                    {booking?.receipt?.outstanding ? <p className="font-weight-bold mb-0" id={rec.id} style={{ color: rec.color }}>{"Cash Payment: AED " + Number(booking?.receipt?.outstanding).toFixed(2)}</p> : ''}
                                                                    {booking?.receipt?.paid ? <p className="font-weight-bold" id={rec.id} style={{ color: rec.color }}>{"Online Payment: AED " + Number(booking?.receipt?.paid).toFixed(2)}</p> : ''}
                                                                    {!booking?.receipt?.paid && !booking?.receipt?.outstanding && <p className="font-weight-bold" id={rec.id} style={{ color: rec.color }}>
                                                                        {booking?.paymentStatus ? (booking?.paymentStatus === 'paid' ? 'Online Payment' : booking?.paymentStatus === 'partiallyPaid' ? 'OnlinePayment / Cash Payment' : 'Cash Payment') : (booking?.paid ? 'Online Payment' : "Cash Payment")}
                                                                    </p>}
                                                                </> :
                                                                <p className="font-weight-bold" id={rec.id} style={{ color: rec.color }}>{rec.data ? rec.data : '--'}</p>
                                                            }
                                                        </tr> : ''
                                                )
                                            })}

                                        </tbody>
                                        <tbody>
                                            {[
                                                { key: 'itemsPrice', title: 'Service Price', width: '12', type: 'price', data: booking?.receipt?.itemsPrice && Number((booking?.receipt?.itemsPrice) || 0)?.toFixed(2) },
                                                { key: 'addonsPrice', title: 'Addons Price', width: '12', type: 'price', data: booking?.receipt?.addonsPrice && Number((booking?.receipt?.addonsPrice) || 0)?.toFixed(2), hide: !booking?.receipt?.addonsPrice },
                                                { key: 'serviceFees', title: booking?.receipt?.serviceFees?.title?.en, width: '12', type: 'price', data: booking?.receipt?.serviceFees && Number((booking?.receipt?.serviceFees?.value) || 0)?.toFixed(2), hide: !booking?.receipt?.serviceFees },
                                                { title: 'Subtotal', data: (booking?.receipt?.sub) },
                                                { title: 'Discount', data: booking?.receipt?.discount },
                                                { title: 'Total', data: booking?.receipt?.total }].map((rec, index) => {
                                                    return (
                                                        rec.data || rec.title === 'Total' ?
                                                            <tr key={index}>
                                                                <td className="text-semi-muted">{rec.title}: <span style={{ fontWeight: '600', color: '#000', fontSize: '12px' }}>{rec.title === 'Discount' ? `"${booking?.receipt?.code}"` : ""}</span></td>
                                                                <td className="text-right">{(Number(rec?.data))?.toFixed(2) + " AED"}</td>
                                                            </tr> : ''
                                                    )
                                                })}

                                        </tbody>
                                    </Table>

                                    {['new', 'pending', 'accepted', 'ontheway', 'active'].includes(booking?.status) && user?.role !== 'concierge' ?
                                        <Separator className="pt-3 mb-5" />
                                        : ''
                                    }
                                    {['new', 'pending', 'accepted', 'ontheway', 'active'].includes(booking?.status) && user?.role !== 'concierge' ? <div className="text-muted text-small text-center no-print">

                                        <Button className='m-1 float-right'
                                            // disabled={booking?.status === 'new'}
                                            color="primary"
                                            onClick={() => {
                                                if (booking.status === 'pending' && user.role !== 'superAdmin') {
                                                    let provider = allProviders.find(rec => rec.id === user?.parent_id)
                                                    setAcceptOrderModal({ ...booking, provider: { id: provider.id, name: provider.name } })
                                                } else {
                                                    setAcceptOrderModal(booking)

                                                }
                                            }}
                                        >
                                            {
                                                status[booking?.status].action
                                            }
                                        </Button>
                                        {['new', 'pending'].includes(booking.status) && <Button className='m-1 float-left' color="danger" outline onClick={() => {
                                            if (user.role === 'superAdmin') {
                                                setReject({ title: 'Reject Order', msg: 'Are you sure you want to reject this order?', actionButton: 'Reject Order', type: 'reject' })
                                            } else {
                                                if (booking.rescadual) {
                                                    setReject({ title: 'Release Order', msg: 'Are you sure you want to release this order?', actionButton: 'Release Order', type: 'ignore' })
                                                } else {
                                                    setReject({ title: 'Ignore Order', msg: 'Are you sure you want to ignore this order?', actionButton: 'Ignore Order', type: 'ignore' })
                                                }
                                            }
                                        }
                                        }>
                                            {(user.role === 'superAdmin') ?
                                                'Reject Order' : (booking.rescadual ? 'Release Order' : 'Ignore Order')
                                            }

                                        </Button>}
                                        {!['new', 'pending'].includes(booking.status) && <Button className='m-1 float-left' color="danger" outline onClick={() => {

                                            if (user.role === 'superAdmin') {
                                                setReject({ title: 'Cancel Order', msg: 'Are you sure you want to cancel this order?', actionButton: 'Cancel Order', type: 'cancel' })
                                            } else {
                                                let now = moment()
                                                let accepted = moment(booking?.accepted)
                                                let start = moment(booking?.bookingDate?.date)
                                                let diffAccepted = now.diff(accepted, 'minutes')
                                                let diffStart = start.diff(now, 'minutes')

                                                let msg = 'Are you sure you want to release this order?'
                                                if (diffAccepted > 15 && diffStart > 120) {
                                                    msg = 'By releaseing this order you will be charged 50% of total amount, Are you sure you want to release this order?'
                                                } else if (diffStart < 120) {
                                                    msg = 'By releaseing this order you will be charged 100% of total amount, Are you sure you want to release this order?'
                                                }
                                                setReject({ title: 'Release Order', msg: msg, actionButton: 'Release Order', type: 'release' })
                                            }

                                        }
                                        }>

                                            {(user.role === 'superAdmin') ?
                                                'Cancel Order' : 'Release Order'
                                            }
                                        </Button>}

                                    </div> : ''}
                                </div>

                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>

            </div>
            <Modal
                isOpen={rejectModal}
                backdrop="static">
                <ModalHeader >{<div><span style={{
                    fontWeight: '700',
                    color: '#dc3545'
                }}>{rejectModal?.title}</span> </div>}</ModalHeader>
                <ModalBody>
                    {rejectModal?.msg}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => setReject(false)}>
                        Dismiss
                    </Button>
                    <Button color="danger" outline onClick={async () => {
                        setLoading(true)
                        switch (rejectModal?.type) {
                            case 'reject':
                                let result = await submitOrder(JSON.parse(JSON.stringify(booking)), 'rejected', activeItem?.general?.id, user, status?.['rejected']?.label)
                                if (result) {
                                    setLoading(false)
                                    setBooking(result?.booking)
                                    setReject(false)
                                    setAcceptOrderModal(false)
                                }
                                break;
                            case 'cancel':
                                let result2 = await submitOrder(JSON.parse(JSON.stringify(booking)), 'canceled', activeItem?.general?.id, user, status?.['canceled']?.label)
                                if (result2) {
                                    setLoading(false)
                                    setBooking(result2?.booking)
                                    setReject(false)
                                    setAcceptOrderModal(false)
                                }
                                break;
                            case 'ignore':
                                ignoreFunc(JSON.parse(JSON.stringify(booking)), user?.parent_id)

                                break;
                            case 'release':
                                releaseFunc(JSON.parse(JSON.stringify(booking)), 'pending')
                                break;
                            default:
                                break;
                        }

                    }} >{rejectModal?.actionButton}</Button>
                </ModalFooter>
            </Modal>
            {acceptOrderModal && <AcceptOrderModal booking={acceptOrderModal} orderID={activeItem?.general?.id} status={status} success={(e) => {
                setLoading(false)
                setBooking(e?.booking)
                setReject(false)
                setAcceptOrderModal(false)
            }}
                dismiss={() => {
                    setAcceptOrderModal(false)
                }} />}

            {
                showLogs && <Logs dismiss={() => setShowLogs(false)} logsOpen={showLogs} selectedBooking={booking} />
            }
        </Modal >
    );
};

export default Booking;
