import React, { useEffect, useState } from "react";
import { NavItem } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import ApplicationMenu from "../../components/common/ApplicationMenu";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { useSelector } from "react-redux";

const OrdersFilter = ({ todoItems, bookingType, filter, setFilterOpen }) => {
  const { user } = useSelector(state => state.authUser)
  const { services } = useSelector(state => state.settings)
  const { allProviders } = useSelector(state => state.providers)
  const { allProfessionals } = useSelector(state => state.providers)
  const { allPartners } = useSelector(state => state.partners)

  const [selectedFilter, setFilter] = useState({ status: bookingType === "Appointments" ? ['accepted', 'ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected'] : ['pending'], selected: bookingType?.toLowerCase() })
  useEffect(() => {
    setFilter({ status: bookingType === "Appointments" ? ['accepted', 'ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected'] : ['pending'], selected: bookingType?.toLowerCase() })
    // eslint-disable-next-line
  }, [user])
  useEffect(() => {
    filter(selectedFilter)
    // eslint-disable-next-line
  }, [selectedFilter])
  return (
    <ApplicationMenu setFilterOpen={setFilterOpen} todoItems={todoItems}>
      <PerfectScrollbar>
        <div className="p-4">
          {[
          {
            title: 'Appointments',
            key: 'appointments',
            show: bookingType === 'Appointments',
            data: [
              { key: 'appointments', type: 'status', title: 'All Appointments', status: ['accepted', 'ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected'] },
              { key: 'accepted', type: 'status', title: "Accepted", status: ['accepted'] },
              { key: 'ontheway', type: 'status', title: "On The Way", status: ['ontheway'] },
              { key: 'delayed', type: 'status', title: "Delayed Orders", delayed: true, status: ['ontheway'] },
              { key: 'active', type: 'status', title: "Session Started", status: ['active'] },
              { key: 'completed', type: 'status', title: "Completed", status: ['completed'] },
              { key: 'rated', type: 'status', title: "Rated", status: ['rated'] },
              { key: 'canceled', type: 'status', title: "Canceled", status: ['canceled'] },
              { key: 'rejected', type: 'status', title: "Rejected", status: ['rejected'] },
            ]
          },
          ].map((rec, recIndex) => {
            return rec.show && (
              <div key={recIndex}>
                <p className="text-muted text-small mb-2">{rec.title}</p>
                <ul className="list-unstyled mb-4">
                  {rec.data.map((c, index) => {
                    return (
                      <NavItem key={index}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                      >
                        <div onClick={() => {
                          setFilter({
                            ...selectedFilter,
                            // [c.type]: selectedFilter?.selected === c.key ? [] : c.status,
                            // selected: selectedFilter?.selected === c.key ? '' : c.key,
                            [c.type]: c.status,
                            selected: c.key,
                            delayed: c.delayed,
                            notes: c.notes,
                          })
                        }}>
                          <div className="custom-control custom-radio">
                            <input
                              style={{ cursor: 'pointer' }}
                              type="radio"
                              className="custom-control-input"
                              checked={selectedFilter?.selected === c.key}
                            />
                            <label style={{ cursor: 'pointer' }} className="custom-control-label">{c.title}</label>
                          </div>
                        </div>
                        <span className="float-right">
                          {todoItems && todoItems.filter(x => c.status.includes(x.status) && (c.notes ? (c.key === 'notes' ? x.notes : true) : true) && (c.delayed ? (c.key === 'delayed' ? x.delayed : true) : true)).length}
                        </span>
                      </NavItem>
                    );
                  })}
                </ul>
              </div>
            )
          })}
          {
            [{
              title: 'Services',
              key: 'service',
              data: services,
            }, {
              title: 'Providers',
              key: 'provider',
              data: allProviders,
            },
            {
              title: 'Professionals',
              key: 'professional',
              data: allProfessionals,
            },
            {
              title: 'Clients',
              key: 'partners',
              data: allPartners,
            }].map((rec, index) => {
              return (
                <div key={index}>
                  <p className="text-muted text-small mb-1">{rec.title}</p>
                  <div className="mb-4">
                    <Select
                      isMulti
                      components={{ Input: CustomSelectInput }}
                      className="react-select"
                      classNamePrefix="react-select"
                      name="form-field-name"
                      options={rec.data && Object.values(rec.data)?.map(rec => { return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id }) })}
                      // value={this.state.selectedWasher}
                      isClearable={true}
                      onChange={val => {
                        setFilter({ ...selectedFilter, [rec.key]: val.map(v => v.key) })
                      }}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </PerfectScrollbar>
    </ApplicationMenu >
  );
};

export default OrdersFilter;