import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Collapse,
    Card,
    CardBody,
    CardTitle,
    Table,
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import YearsDatePicker from "../../../../components/common/YearsDatePicker";
import { firestore } from "../../../../helpers/Firebase";
import { exportCSV } from "../../../../helpers/Utils";
const months = [
    { title: 'Jan', count: '01' },
    { title: 'Feb', count: '02' },
    { title: 'Mar', count: '03' },
    { title: 'Apr', count: '04' },
    { title: 'May', count: '05' },
    { title: 'June', count: '06' },
    { title: 'July', count: '07' },
    { title: 'Aug', count: '08' },
    { title: 'Sept', count: '09' },
    { title: 'Oct', count: '10' },
    { title: 'Nov', count: '11' },
    { title: 'Dec', count: '12' }
]

const Invoices = ({ provider }) => {
    const { user } = useSelector(state => state.authUser)
    const [month, setSelectedMonth] = useState(false)
    const [year, setSelectedYear] = useState(moment().format('YYYY'))
    const [data, FilterOrders] = useState({})
    const [dateRange, setDateRange] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (month) {
            let day = moment(`01/${month?.title}/${year}`).valueOf()
            let startDate = moment(day).startOf('months').startOf('day').valueOf()
            let endDate = moment(day).endOf('months').endOf('day').valueOf()
            setDateRange({ start: startDate, end: endDate })
        }
    }, [month, year])


    useEffect(() => {

        async function fetchData() {
            let ref = firestore().collection('orders').where("provider.id", "==", provider.id)

            let userBookingsSnap = await ref.where('completed', '<=', moment(dateRange.end).valueOf()).where('status', 'in', ['completed', 'rated']).get()
            let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {
                return {
                    ...res.data(),
                    key: res.id,
                    bookingId: res.id,
                }

            })
            Promise.all(asyncOptionsOnline).then((ress) => {
                FilterOrders({ orders: ress, filteredBookings: ress, })
                setLoading(false)

            })
        }
        fetchData();
    }, [provider, dateRange])
    return (
        <Colxx xxs="12" className="mb-4">
            <Card className={'rounded'}>
                <CardBody>
                    <CardTitle className={'d-flex justify-content-between align-items-center mb-2'}>
                        <div>Invoices</div>
                        <YearsDatePicker updateYear={(e) => { setSelectedYear(e) }} />
                    </CardTitle>
                    <Fragment>
                        {months.sort((a, b) => b.count - a.count).map((m) => {
                            return (
                                <div key={m.title} className="mt-2" style={{ border: '1px solid #00000030', borderRadius: '8px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px'
                                        }}
                                        onClick={() => {
                                            setSelectedMonth(m)
                                            if (m.title !== month?.title) {
                                                setLoading(true)
                                            }
                                        }}
                                        aria-expanded={m.title === month?.title}
                                    >
                                        <div style={{ fontSize: '16px', fontWeight: '700', }}><i className={m.title === month?.title ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} style={{ marginRight: '12px' }} /> {m.title + ' ' + year}</div>
                                    </div>
                                    <Collapse isOpen={m.title === month?.title}>
                                        {loading ? <div className="loading" style={{ position: 'relative', margin: '50px 0' }} />
                                            : <Table style={{ borderTop: 'none' }} className={'mt-2 mb-0'}>
                                                <tbody>
                                                    {[
                                                        { title: 'Detailed Report', key: 'detailed' },
                                                        // { title: 'Summary Report', key: 'summary' }
                                                    ]?.map((r, ind) => {
                                                        let selectedItem = r
                                                        return (<tr key={selectedItem.key} className="justigy-content-between">
                                                            <td style={{ paddingLeft: '34px', fontSize: '14px', fontWeight: '700', verticalAlign: 'middle' }} >{selectedItem?.title}</td>
                                                            <td style={{ paddingRight: '34px', verticalAlign: 'middle', textAlign: 'end' }} onClick={() => exportCSV(data.filteredBookings, user)} ><i className="iconsminds-download-1" style={{ fontSize: '20px', cursor: 'pointer' }} /></td>

                                                        </tr>)
                                                    })}

                                                </tbody>
                                            </Table>}

                                    </Collapse>
                                </div>
                            )
                        })}

                    </Fragment>
                </CardBody>
            </Card>
        </Colxx>
    );

};

export default Invoices;