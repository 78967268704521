import React, { Component } from "react";
import {
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { injectIntl } from "react-intl";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { deviceWidth } from "../../helpers/Utils";
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      className="customDatePicker"
      readOnly={true}
      ref={ref}
    />
  )
})
class ListPageHeading extends Component {
  constructor(props) {
    super();
    this.state = {
      nestingDropdownOpen: false,
      dropdownSplitOpen: false,
      selectedDate: 'Today'
    };
  }

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen
    });
  };
  render() {
    const {
      toggleModal,
      heading,
      fromModifiers,
      hide,
      startDate,
      endDate,
      onChangeStart,
      onChangeEnd
    } = this.props;
    const { selectedDate } = this.state
    return (
      <Row>

        <Colxx xxs="4">

          <div className="align-items-start" style={{ flexDirection: 'row', alignItems: 'center' }}>
            <h1 style={{ paddingBottom: '0' }}>
              {heading}
            </h1>
          </div>
        </Colxx>
        {!hide ? <Colxx xxs="8">
          <div className="text-zero d-flex  justify-content-end" style={{ height: '100%', alignItems: 'flex-end' }}>
            <Button
              color="primary"
              size="lg"
              className="top-right-button"
              onClick={() => toggleModal()}
            >
              Create New
            </Button>
            {"  "}
          </div>
        </Colxx> : heading === "Appointments" &&
        <Colxx xxs="12" md="8">
          <div className="text-zero d-flex  justify-content-end float-right align-items-end">

            <div className="mr-2">
              <div>From</div>
              <DatePicker
                customInput={<CustomInput />}
                selected={startDate}
                onChange={(e) => {
                  this.setState({ selectedDate: 'Select Filter' })
                  onChangeStart(e)
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showTimeSelect
                timeFormat="hh:mm a"
                timeIntervals={30}
                timeCaption="time"
                dateFormat="dd/MM/yyyy hh:mm a"
                withPortal={deviceWidth() !== 'desktop'}

              />
            </div>
            <div>
              <div>To</div>
              <DatePicker
                customInput={<CustomInput />}
                selected={endDate}
                onChange={(e) => {
                  this.setState({ selectedDate: 'Select Filter' })
                  onChangeEnd(e)
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                showTimeSelect
                timeFormat="hh:mm a"
                timeIntervals={30}
                timeCaption="time"
                dateFormat="dd/MM/yyyy hh:mm a"
                withPortal={deviceWidth() !== 'desktop'}
              />
            </div>
            <ButtonDropdown

              className="top-right-button mr-2 ml-2"
              size="md"
              isOpen={this.state.nestingDropdownOpen}
              toggle={this.nestingToggle}
            >
              <DropdownToggle color="primary" outline={selectedDate === 'Select Filter'} caret>
                {selectedDate}
              </DropdownToggle>
              <DropdownMenu>
                {[{ key: 'Today', start: moment().startOf('day').valueOf(), end: moment().endOf('day').valueOf() },
                { key: 'Tomorrow', start: moment().add(1, 'days').startOf('day').valueOf(), end: moment().add(1, 'days').endOf('day').valueOf() },
                { key: 'Last 7 Days', start: moment().subtract(7, 'days').startOf('day').valueOf(), end: moment().endOf('day').valueOf() },
                { key: 'Last 30 Days', start: moment().subtract(30, 'days').startOf('day').valueOf(), end: moment().endOf('day').valueOf() }].map(rec => {
                  return (
                    <DropdownItem key={rec.key} onClick={() => {
                      onChangeStart(rec.start)
                      onChangeEnd(rec.end)
                      this.setState({ selectedDate: rec.key })
                    }}>
                      {rec.key}
                    </DropdownItem>
                  )
                })}

              </DropdownMenu>
            </ButtonDropdown>
          </div></Colxx>}
        {!fromModifiers && <Colxx xxs="12">
          <Separator className="mb-2 mt-2" />
        </Colxx>}

      </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
