import React, { Fragment, useEffect, useState } from "react";
import Appointment from "./components/booking";
import ManageOrder from "./components/ManageOrder";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import moment from "moment";
import { Button, Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import ReactTable from "react-table";
import AcceptOrderModal from "../../../components/common/AcceptOrderModal";
import OrdersFilter from "../../../components/common/ordersFilter";
import DataTablePagination from "../../../components/DatatablePagination";
import { deviceWidth, getPaymentType } from "../../../helpers/Utils";
import { firestore } from "../../../helpers/Firebase";
import { GetAllAppointments } from '../../../redux/bookings/actions'
import Dropdown from "../../../components/Dropdown/Dropdown";
import { MenuItem } from "@trendmicro/react-dropdown";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PDFComponent from "../../../components/common/CustomerReceipt";

const Bookings = () => {
  const dispatch = useDispatch();
  const { appointments, delayedAppointments } = useSelector(state => state.bookings)
  const { activeItem } = useSelector(state => state.activeItem)
  const [acceptOrderModal, setAcceptOrderModal] = useState(false)
  const [allAppointment, setAppointments] = useState([]);

  const status = {
    new: { label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept', nextStatus: acceptOrderModal?.provider?.id ? 'accepted' : 'pending' },
    pending: { label: "Pending Assigment", status: 'pending', nextStatus: 'accepted', color: "#3498db", action: 'Accept' },
    accepted: { label: "Accepted", status: 'accepted', nextStatus: 'ontheway', color: "#145388", action: 'On the way' },
    ontheway: { label: "On The Way", status: 'ontheway', nextStatus: 'active', color: "#3e884f", action: 'Arrive' },
    active: { label: "Session Started", status: 'active', nextStatus: 'completed', color: "#3195a5", action: 'Complete' },
    completed: { label: "Completed", status: 'completed', nextStatus: 'completed', color: "#3e884f", action: '' },
    rated: { label: "Rated", status: 'rated', color: "#b69329", action: '' },
    canceled: { label: "Canceled", status: 'canceled', color: "#c43d4b", action: 'Cancel' },
    rejected: { label: "Rejected", status: 'rejected', color: "#c43d4b", action: 'Reject' },
  }
  const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
  const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());
  const [selectedFilter, setFilter] = useState({})
  const [filterOpen, setFilterOpen] = useState(false)
  const search = useSelector(state => state.settings.search) || ''
  const { user } = useSelector(state => state.authUser)
  const { allProviders } = useSelector(state => state.providers)

  const [filteredItems, setFilteredItems] = useState({})

  const onClickItem = (item) => {
    dispatch(SetActiveItem({ general: item }))
  };
  const filter = (selectedFilter) => {
    let filteredBookings = []
    filteredBookings = allAppointment?.filter(item => {
      return (
        (String(item?.orderNumber)?.includes(search?.toLowerCase()) || (item?.contact?.name)?.toLowerCase()?.includes(search?.toLowerCase())) &&
        (selectedFilter?.service?.length ? selectedFilter.service.includes(item?.service?.id) : true) &&
        (selectedFilter?.partners?.length ? selectedFilter.partners.includes(item?.brand?.id) : true) &&
        (selectedFilter?.provider?.length ? selectedFilter.provider.includes(item?.provider?.id) : true) &&
        (selectedFilter?.professional?.length ? selectedFilter.professional.includes(item?.professional?.id) : true) &&
        (selectedFilter?.status?.length ? selectedFilter.status.includes(item.status) &&
          (selectedFilter.notes ? (selectedFilter.selected === 'notes' ? item.notes : true) : true) &&
          (selectedFilter.delayed ? (selectedFilter.selected === 'delayed' ? item.delayed : true) : true) : true)
      )
    })
    setFilteredItems(filteredBookings)
  }
  useEffect(() => {
    let allAppointment = appointments?.concat(delayedAppointments || [])?.sort((a, b) => b?.bookingDate?.date - a?.bookingDate?.date)?.sort((a) => a?.delayedOntheWay || a?.delayedActive || a?.delayedCompleted ? -1 : 1)
    setAppointments(allAppointment)
  }, [appointments, delayedAppointments])


  useEffect(() => {
    if (user) {
      let ref = firestore().collection('orders').where('bookingDate.date', '>=', moment(startDate).valueOf()).where('bookingDate.date', '<=', moment(endDate).valueOf()).where('status', 'in', ['accepted', 'ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected'])
      switch (user?.role) {
        case 'concierge':
          ref = ref.where("brand.id", '==', user?.brand_id)
          break;
        case 'admin':
          ref = ref.where('provider.id', '==', user?.parent_id)
          break;
        default:
          break;
      }
      let unsub = ref.onSnapshot(snap => {
        let data = snap.docs.map(rec => {
          return ({ ...rec.data(), id: rec.id })
        })
        data = data?.reduce((val, currentVal) => {
          let now = moment()
          let start = moment(currentVal?.bookingDate?.date)
          let diffStart = start.diff(now, 'minutes')
          if (diffStart < 20 && currentVal?.status === 'accepted') {
            currentVal.delayedOntheWay = true
          } else if (diffStart < -10 && currentVal?.status === 'ontheway') {
            currentVal.delayedActive = true
          } else if (diffStart < -60 && currentVal?.status === 'active') {
            currentVal.delayedCompleted = true
          }

          val.push(currentVal)
          return val
        }, [])?.filter(r => !r.delayedOntheWay && !r.delayedActive && !r.delayedCompleted)
        dispatch(GetAllAppointments(data))
      });

      return () => {
        if (unsub) {
          unsub()
        }

      }
    }
    // eslint-disable-next-line
  }, [startDate, endDate, user])

  useEffect(() => {
    if (selectedFilter.status) {
      filter(selectedFilter)
    }
    // eslint-disable-next-line 
  }, [selectedFilter, allAppointment, search])
  const bookingDataColumns = [
    { Header: "Order ID", accessor: "orderNumber", type: 'label' },
    { Header: "Created", accessor: "created", type: 'date' },
    { Header: "Start at", accessor: "bookingDate.date", type: 'date' },
    { Header: "Contact", accessor: "contact.name", type: 'label' },
    { Header: "Assigned Provider", accessor: "provider.name", type: 'label', hide: (user.role !== 'superAdmin' ? true : false) },
    { Header: "Assigned Professional", accessor: "professional.name", type: 'label', hide: false },
    { Header: "Area", accessor: "location.area", type: 'label' },
    { Header: "Selected Service", accessor: "service.name", type: 'label' },
    { Header: "Status", accessor: "status", type: 'status' },
    { Header: "Payment", accessor: "paid", type: 'payment', hide: (user.role !== 'superAdmin' ? true : false) || selectedFilter.selected === 'notPaid' },
    { Header: "Payment Type", accessor: "paymentStatus", type: 'paymentStatus', },
    { Header: "", accessor: "delayed", color: "primary", title: 'View', type: 'delayed', hide: (user.role === 'concierge' ? true : false) },
    { Header: "", width: 20, accessor: "manage", func: (e) => onClickItem(e), color: "dark", title: <i className="simple-icon-options-vertical" />, type: 'button' },
  ]
  const dataTableColumns = () => {
    let array = (bookingDataColumns).map(rec => {
      return (
        {
          Header: rec.Header,
          accessor: rec.accessor,
          width: deviceWidth() === 'desktop' ? (rec.width || 'auto') : 110,
          resizable: false,
          show: rec.hide ? false : true,
          Cell: props => <div className="list-item-heading" style={{ width: '100%' }}>
            {rec.type === 'label' ?
              <div className="list-item-heading" style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineClamp: 2,
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>{props.value?.en || props.value || (rec.Header === "Assigned Professional" ? "Unassigned" : "--")}</div>
              : rec.type === 'payment' ?
                <div className="list-item-heading" style={{ color: props.value ? '#3e884f' : "#c43d4b", fontSize: '11px', fontWeight: '700' }}>{props.value ? 'Paid' : "Not Paid"}</div>
                : rec.type === 'paymentStatus' ?
                  <div className="list-item-heading" >{props.value ? (props.value === 'paid' ? 'Online' : props.value === 'partiallyPaid' ? 'Online / Cash' : 'Cash') : (props?.original?.paid ? 'Online' : "Cash")}</div>
                  : rec.type === 'status' ?
                    <div className="list-item-heading" style={{ color: status[props.value].color }}>{status[props.value].label}</div>
                    : rec.type === 'date' ?
                      <div className="list-item-heading" style={{ color: rec.accessor !== 'created' && '#87cefa', fontWeight: rec.accessor !== 'created' && 'bold' }}>{moment(props.value).format('DD/MM/YYYY')}<br />{moment(props.value).format('hh:mm A')}</div>
                      : rec.type === 'delayed' ?
                        <div className="providerfieldText" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          {(props.original.delayedOntheWay || props.original.delayedActive || props.original.delayedCompleted) && <Button
                            color={rec.color}
                            size="sm"
                            outline
                            className="top-right-button mr-2"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              if (props.original.status === 'pending' && user.role !== 'superAdmin') {
                                let provider = allProviders.find(rec => rec.id === user?.parent_id)
                                setAcceptOrderModal({ ...props.original, provider: { id: provider.id, name: provider.name } })
                              } else {
                                setAcceptOrderModal(props.original)
                              }
                            }}
                          >
                            {props.original.delayedOntheWay ? 'Ontheway' :
                              props.original.delayedActive ? 'Arrive' :
                                props.original.delayedCompleted ? 'Complete' :
                                  rec.title}
                          </Button>}
                        </div>
                        : rec.type === 'button' ?
                          <div className="providerfieldText" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                            {user?.role !== 'concierge' ? <div className="cursor__pointer" style={{ position: 'absolute' }} onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}>
                              <Dropdown>
                                <Dropdown.Toggle btnStyle="link" noCaret>
                                  <i className="simple-icon-options" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ left: '-140px', width: '150px' }}>
                                  {/* <MenuItem header>Manage Order</MenuItem> */}
                                  {user?.role === 'superAdmin' ? <MenuItem
                                    disabled={['ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'reschedule' })
                                    }}
                                  >
                                    Reschedule
                                  </MenuItem> : ''}

                                  {user?.role === 'superAdmin' ? <MenuItem
                                    disabled={['ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'reassign' })
                                    }}
                                  >
                                    Reassign
                                  </MenuItem> : ''}
                                  <MenuItem
                                    disabled={['canceled', 'rejected']?.includes(props.original.status) || getPaymentType(props.original) !== 'Cash Payment'}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'services' })
                                    }}
                                  >
                                    Services
                                  </MenuItem>
                                  <MenuItem
                                    disabled={['canceled', 'rejected']?.includes(props.original.status)}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'addons' })
                                    }}
                                  >
                                    Addons
                                  </MenuItem>
                                  {user?.role === 'superAdmin' ? <MenuItem
                                    disabled={['completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'status' })
                                    }}
                                  >
                                    Status
                                  </MenuItem> : ''}
                                  {user?.role === 'superAdmin' ? <MenuItem
                                    disabled={!['completed', 'rated']?.includes(props.original.status)}
                                    onSelect={() => {
                                      rec.func({ ...props.original, manageOrder: 'receipt' })
                                    }}
                                  >
                                    Receipt
                                  </MenuItem> : ''}
                                  {user?.role === 'superAdmin' && ['completed', 'rated']?.includes(props.original.status) ? <MenuItem
                                    onSelect={() => {
                                      createPDF(props.original)
                                      // rec.func({ ...props.original, manageOrder: 'status' })
                                    }}
                                  >
                                    Export Receipt
                                  </MenuItem> : ''}
                                  <PDFComponent order={props.original} id={props.original.orderNumber} />

                                </Dropdown.Menu>
                              </Dropdown>
                            </div> : ''}
                          </div>
                          : ''
            }
          </div>
        }
      )
    })
    return array
  }
  const createPDF = async (booking) => {
    const pdf = new jsPDF("portrait", "pt", "a5");
    const data = await html2canvas(document.getElementById(`${booking.orderNumber}`));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${booking?.invoiceNumber}.pdf`);


  };
  return (
    <Fragment>
      {
        activeItem && (activeItem?.general?.manageOrder) ?

          <ManageOrder
            authUser={user}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          /> : activeItem && <Appointment
            authUser={user}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          />

      }
      <div className={`app-row survey-app  ${filterOpen ? '' : "shown"}`}>
        <ListPageHeading
          heading={'Appointments'}
          hide={true}
          startDate={startDate}
          endDate={endDate}
          onChangeStart={setStartDate}
          onChangeEnd={setEndDate}
        />

        <Row className="survey-app">
          <Colxx xxs="12">
            {
              filteredItems && filteredItems.length ?
                <ReactTable
                  data={filteredItems}
                  columns={dataTableColumns()}
                  defaultPageSize={10}
                  pageSize={filteredItems.length < 10 ? filteredItems.length : 10}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className={"bookingsReactTable"}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: e => {
                        dispatch(SetActiveItem({ general: { ...rowInfo.original, viewOrder: true } }))
                      },
                      style: {
                        border: rowInfo?.original?.delayedOntheWay || rowInfo?.original?.delayedActive || rowInfo?.original?.delayedCompleted ? `1px solid red` : '',
                        borderRadius: rowInfo?.original?.delayedOntheWay || rowInfo?.original?.delayedActive || rowInfo?.original?.delayedCompleted ? `16px` : '',
                        background: rowInfo?.original?.delayedOntheWay || rowInfo?.original?.delayedActive || rowInfo?.original?.delayedCompleted ? `rgba(255, 0, 0, 0.02)` : '',
                      }
                    }
                  }}

                />
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', width: '100%' }}>No Requests</div>
            }
          </Colxx>
        </Row>

      </div>
      {acceptOrderModal && <AcceptOrderModal booking={acceptOrderModal} orderID={acceptOrderModal?.id} status={status} success={(e) => {
        setAcceptOrderModal(false)
      }}
        dismiss={() => {
          setAcceptOrderModal(false)
        }} />}

      <OrdersFilter
        setFilterOpen={(e) => setFilterOpen(e)}
        activeServices={[]}
        washers={[]}
        todoItems={allAppointment || []}
        filter={(selectedFilter) => {
          setFilter(selectedFilter)
        }}
        bookingType={'Appointments'}
      />
    </Fragment>
  );
};


export default Bookings;
