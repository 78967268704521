import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import promocodes from "./promocodes";
import affiliates from "./affiliates";
import loyalty from "./loyalty";
import subscriptions from "./subscriptions";

const Gogo = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/marketing`} />
      <Route path={`${match.url}/promocodes`} component={promocodes} />
      <Route path={`${match.url}/affiliates`} component={affiliates} />
      <Route path={`${match.url}/loyalty`} component={loyalty} />
      <Route path={`${match.url}/subscriptions`} component={subscriptions} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Gogo;
