import React from "react";
import Partner from "./components/partner";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { firestore } from "../../../helpers/Firebase";
import { SetActiveItem } from "../../../redux/activeItem/actions";

const Partners = () => {
  const dispatch = useDispatch();
  const { allPartners } = useSelector(state => state.partners)
  const { activeItem } = useSelector(state => state.activeItem)
  const authUser = useSelector(state => state.authUser)

  const selectPartner = async (partnerData) => {
    let post = { general: partnerData };
    let { addNew, id } = partnerData;
    let tapsData = ["variations", "users", "banners", 'locations', 'settings']
    if (!addNew) {
      await Promise.all(tapsData.map(async rec => {
        let snap = await firestore().collection('partners').doc(id).collection(rec).get()
        let data = snap.docs.reduce((val, currentVal) => {
          val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }
          return val
        }, {})
        post[rec] = data
        return data

      }))
    }
    dispatch(SetActiveItem(post))
  }

  return (
    <div>
      {
        activeItem ?
          <Partner
            authUser={authUser}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          /> : <MainPage
            title="Clients"
            selected='partners'
            data={allPartners || []}
            setSelectedItem={(e) => selectPartner(e)}
          />

      }
    </div>
  );
};


export default Partners;
