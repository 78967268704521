import React, { useEffect, useState } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from "react-select";
import CustomSelectInput from './CustomSelectInput';

import { firestore } from '../../helpers/Firebase';
import { useSelector } from 'react-redux';
import { submitOrder } from '../../helpers/Utils';
const AcceptOrderModal = ({ booking, success, orderID, dismiss }) => {
    const { allProviders } = useSelector(state => state.providers)
    const { user } = useSelector(state => state.authUser)
    const [acceptOrderModal, setAcceptOrderModal] = useState(booking || false)
    const [professionals, SetProfessionals] = useState({})
    const [loading, setLoading] = useState(false)
    const status = {
        new: { label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept', nextStatus: acceptOrderModal?.provider?.id ? 'accepted' : 'pending', },
        pending: { label: "Pending Assigment", status: 'pending', nextStatus: 'accepted', color: "#3498db", action: 'Accept' },
        accepted: { label: "Accepted", status: 'accepted', nextStatus: 'ontheway', color: "#145388", action: 'On the way' },
        ontheway: { label: "On The Way", status: 'ontheway', nextStatus: 'active', color: "#3e884f", action: 'Arrive' },
        active: { label: "Session Started", status: 'active', nextStatus: 'completed', color: "#3195a5", action: 'Complete' },
        completed: { label: "Completed", status: 'completed', nextStatus: 'completed', color: "#3e884f", action: '' },
        rated: { label: "Booking Rated", status: 'rated', color: "#b69329", action: '' },
        canceled: { label: "Booking Canceled", status: 'canceled', color: "#c43d4b", action: 'Cancel' },
        rejected: { label: "Booking Rejected", status: 'rejected', color: "#c43d4b", action: 'Reject' },
    }
    useEffect(() => {
        async function fetchData() {
            if (acceptOrderModal?.provider) {

                let snap = await firestore().collection('providers').doc(acceptOrderModal?.provider?.id).collection('professionals').where('deactivated', '!=', true).get()
                let data = snap?.docs?.reduce((val, currentVal) => {
                    val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }
                    return val
                }, {})
                SetProfessionals(data)

                let locationVariations = false
                let locationSnap = await firestore().collection('partners').doc(acceptOrderModal?.brand?.id).collection('locations').doc(acceptOrderModal?.location?.cluster).get()
                locationVariations = locationSnap?.exists ? (locationSnap.data()?.providers?.find(r => r.id === acceptOrderModal?.provider?.id)?.variations || false) : false

                let variationSnap = await firestore().collection('providers').doc(acceptOrderModal?.provider?.id).collection('variations').where('deactivated', '!=', true).get()
                variationSnap.docs.filter(rec => Object.keys(acceptOrderModal?.items).includes(rec.id)).map(currentVal => {
                    let item = acceptOrderModal?.items

                    if (locationVariations && locationVariations?.[currentVal.id]) {
                        item[currentVal.id].price.providerFees = locationVariations?.[currentVal.id]
                    } else {
                        item[currentVal.id].price.providerFees = currentVal.data()?.providerPrice || 0
                    }

                    return setAcceptOrderModal({ ...acceptOrderModal, items: item })

                })

                let providerFees = Object.values(acceptOrderModal?.items).reduce((val, currentVal) => {
                    val = val + (Number(currentVal?.price?.providerFees || 0) * currentVal.count)
                    return val
                }, 0)
                let metadocCommission = Number(acceptOrderModal.receipt?.total) - (Number(acceptOrderModal.receipt?.totalTopup || 0) || 0) - Number(providerFees || 0)
                if (acceptOrderModal?.service?.id === 'pharmacy') {
                    providerFees = Number(acceptOrderModal.receipt?.total) * 0.9
                    metadocCommission = Number(acceptOrderModal.receipt?.total) * 0.1
                }
                let newReceipt = { ...acceptOrderModal?.receipt, metadocCommission: metadocCommission, providerFees: providerFees }
                setAcceptOrderModal({ ...acceptOrderModal, receipt: newReceipt })
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [acceptOrderModal?.provider])

    return (
        <Modal
            isOpen={acceptOrderModal ? true : false}
            backdrop="static">
            {loading && (
                <div className="loading" />
            )}
            <ModalHeader >{<div><span style={{
                fontWeight: '700',
            }}>{status?.[booking?.status]?.action} Order</span> </div>}</ModalHeader>
            <ModalBody>
                {[{ key: 'provider', title: 'Select Provider:', data: allProviders?.filter(rec => booking?.candidates?.includes(rec.id)), mt: 'mt-0', show: !booking?.provider?.id && user.role === 'superAdmin' },
                { key: 'professional', title: 'Select Professional:', data: Object.values(professionals), mt: !booking?.provider?.id && user.role === 'superAdmin' ? 'mt-3' : 'mt-0', show: acceptOrderModal?.provider?.id && !booking?.professional?.id }]?.map((item, index) => {
                    return item.show && (
                        <div key={index} className={item.mt}>
                            <Label className="text-semi-muted ">{item.title}</Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="basic-multi-select"
                                placeholder={'Select Provider'}
                                value={acceptOrderModal?.[item.key] ? { value: acceptOrderModal?.[item.key]?.name, label: acceptOrderModal?.[item.key]?.name, key: acceptOrderModal?.[item.key]?.id } : {}}
                                options={item.data?.map(rec => {
                                    return ({ ...rec, value: rec.name?.en || rec.name, label: rec.name?.en || rec.name, key: rec.id })
                                })}
                                onChange={val => {
                                    setAcceptOrderModal({
                                        ...acceptOrderModal,
                                        [item.key]: {
                                            id: val.key,
                                            name: val.label
                                        }
                                    })
                                }}
                            />
                        </div>
                    )
                })}

                {booking?.provider?.id && booking?.professional?.id ?
                    <div>Are you sure you want to change status to {status[booking?.status].action} this order?</div> : ''
                }

            </ModalBody>
            <ModalFooter>
                <Button color="danger" outline onClick={() => {
                    setAcceptOrderModal(false)
                    dismiss()
                }}>
                    Dismiss
                </Button>
                <Button color="primary"
                    // disabled={(!acceptOrderModal?.provider?.id && booking.status !== 'new')}
                    disabled={(!acceptOrderModal?.provider?.id && booking.status !== 'new') || (user?.role !== 'superAdmin' ? (!acceptOrderModal?.professional?.id && booking.status === 'accepted') : false)}
                    outline
                    onClick={async () => {
                        setLoading(true)
                        let result = await submitOrder(acceptOrderModal, status[booking?.status].nextStatus, orderID, user, status?.[status[booking?.status].nextStatus]?.label)
                        if (result) {
                            success(result)
                            setLoading(false)
                        }
                    }}>
                    Submit
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
};

export default AcceptOrderModal;