import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { getContacts, setSelectedUser, getChatMessages, deleteChatMessage, setUserFromRequests } from "../../../redux/chat/actions";
import ChatApplicationMenu from "../../../containers/applications/ChatApplicationMenu";
import ChatHeading from "../../../components/applications/ChatHeading";
import MessageCard from "../../../components/applications/MessageCard";
import SaySomething from "../../../components/applications/SaySomething";
import { useDispatch, useSelector } from "react-redux";
import { firestore, functions } from "../../../helpers/Firebase";
import InfiniteScroll from 'react-infinite-scroll-component';
import { createNotification, deviceWidth } from "../../../helpers/Utils";
import moment from "moment";

const Chat = () => {
  const { user } = useSelector(state => state.authUser)
  const { allContacts, selectedUser, messages, userFromRequests } = useSelector(state => state.chatApp)
  const [messageInput, setMessageInput] = useState('')
  const [disableSendButton, setDisableSendButton] = useState(false)
  const dispatch = useDispatch()

  const compareContacts = (a, b) => {
    // Sort the messages arrays for each contact by created timestamp
    a.chatMessages.sort((msg1, msg2) => msg1?.created - msg2?.created);
    b.chatMessages.sort((msg1, msg2) => msg1?.created - msg2?.created);

    // Get the last message in each contact's message array
    const lastMessageA = a?.chatMessages?.[a?.chatMessages?.length - 1];
    const lastMessageB = b?.chatMessages?.[b?.chatMessages?.length - 1];

    const itemExpirationA = a?.exp;
    const itemExpirationB = b?.exp;

    if (itemExpirationA > itemExpirationB) {
      return 1;
    } else if (itemExpirationA < itemExpirationB) {
      return -1;
    } else {
      // If the item expiration dates are the same, compare the last message's created property
      if (lastMessageA?.created < lastMessageB?.created) {
        return 1;
      } else if (lastMessageA?.created > lastMessageB?.created) {
        return -1;
      } else {
        return 0;
      }
    }
  };
  const getInitialData = useMemo(() => {
    return async (snap) => {
      if (!snap?.docs?.length) {
        dispatch(setSelectedUser(undefined))
        dispatch(setUserFromRequests(undefined))
        return dispatch(getContacts([]))
      }
      let data = await Promise.all(snap?.docs?.map(async rec => {
        let post = rec.data()
        let ref = firestore().collection('chats').doc(rec.id).collection('messages')
        if (user?.role === 'admin') {
          ref = ref.where('provider_id', '==', user?.parent_id)
        }
        let snap = await ref.orderBy("created", "desc").limit(10).get()
        let chatMessages = snap.docs.map((d) => {
          let parent_id = d.ref.parent.parent.id
          let chatData = { ...d.data(), parent_id }
          dispatch(getChatMessages(chatData))
          return chatData
        })
        const lastVisible = snap.docs[snap.docs.length - 1];
        let exp = post?.exp && moment(post?.exp).isAfter(moment())
        // if (chatMessages.length) {
        return ({ ...post, lastVisible, id: rec.id, isInfiniteLoading: chatMessages.length, exp: !exp, chatMessages })
        // }
      }))
      if (!selectedUser || userFromRequests) {
        dispatch(setSelectedUser(userFromRequests ? data?.find(r => r?.id === userFromRequests) : data?.filter(r => r)?.sort(compareContacts)?.[0]))
        dispatch(setUserFromRequests(undefined))
      }
      dispatch(getContacts(data?.filter(r => r)?.sort(compareContacts)))

    }
  }, [dispatch, user, selectedUser, userFromRequests])

  useEffect(() => {
    let collectionGroupRef = firestore().collectionGroup('messages')
    if (user?.role === 'admin') {
      collectionGroupRef = collectionGroupRef.where('provider_id', '==', user?.parent_id)
    }
    collectionGroupRef
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot(snap => {
        if (!snap.empty) {
          let parent_id = snap.docs?.[0].ref.parent.parent.id
          dispatch(getChatMessages({ ...snap.docs[0].data(), parent_id }))
        }
      })
    let ref = firestore().collection('chats')
    if (user?.role === 'admin') {
      ref = ref.where('provider_id', '==', user?.parent_id)
    }
    let unsub = ref.onSnapshot(async snap => {
      getInitialData(snap)
    })

    return () => {
      if (unsub) {
        unsub()
      }
    }
  }, [getInitialData, dispatch, user])

  const onInfiniteLoad = useCallback(async () => {
    if (selectedUser?.id) {
      let ref = firestore().collection('chats').doc(selectedUser.id).collection('messages')
      if (user?.role === 'admin') {
        ref = ref.where('provider_id', '==', user?.parent_id)
      }

      let snap = await ref
        .orderBy("created", "desc")
        .startAfter(selectedUser?.lastVisible)
        .limit(10).get()
      snap.docs.map((d) => {
        let parent_id = d.ref.parent.parent.id
        return dispatch(getChatMessages({ ...d.data(), parent_id }))
      })

      const lastVisible = snap.docs[snap.docs.length - 1];

      let updateUser = { ...selectedUser, lastVisible: lastVisible, isInfiniteLoading: snap.docs.length ? true : false }
      let updateContacts = allContacts?.reduce((val, currentVal) => {
        if (currentVal.id === selectedUser.id) {
          currentVal = updateUser
        }
        val.push(currentVal)
        return val
      }, [])
      dispatch(setSelectedUser(updateUser))

      dispatch(getContacts(updateContacts?.sort(compareContacts)))
    }
  }, [allContacts, dispatch, selectedUser, user])

  const handleChatInputPress = useCallback(async e => {
    if (e.key === "Enter") {
      setDisableSendButton(true)
      let message = JSON.parse(JSON.stringify(messageInput))
      if (message.length > 0 && !disableSendButton && selectedUser?.id) {
        dispatch(getChatMessages({
          created: Date.now(),
          from: "metadoc",
          sender: user.uid,
          id: '===spam',
          status: "Created",
          text: {
            body: message,
          },
          type:
            "text",
          parent_id: selectedUser.id
        }))
        setMessageInput("")


        let addMessage = functions().httpsCallable("SendWhatsappMessage");
        addMessage({ text: message, phoneNumber: selectedUser.id }).then(() => {
          dispatch(deleteChatMessage({ id: '===spam', parent_id: selectedUser.id }))
          setDisableSendButton(false)
        }).catch(error => {
          console.log(error)
          dispatch(deleteChatMessage({ id: '===spam', parent_id: selectedUser.id }))
          createNotification("error", error.message)
          setDisableSendButton(false)

        })
      }
    }
  }, [messageInput, selectedUser, disableSendButton, dispatch, user.uid])
  useEffect(() => {
    if (selectedUser) {
      let chatMessages = Object.values(messages?.[selectedUser?.id] || {})?.sort((msg1, msg2) => msg1?.created - msg2?.created);
      const lastMessage = chatMessages?.[chatMessages?.length - 1];
      if (lastMessage?.template_name === 'create_new_session') {
        setDisableSendButton({ text: 'Waiting For Patient Reply' })
      } else {
        setDisableSendButton(false)
      }
    }
  }, [selectedUser, messages])
  return (
    <Fragment>
      <Row className="app-row survey-app">
        <Colxx xxs="12" className="d-flex">
          <ChatApplicationMenu />
        </Colxx>
        <Colxx xxs="12" className="chat-app-main">
          {selectedUser && <ChatHeading
            name={selectedUser?.name}
            selectedUser={selectedUser}
            thumb={'selectedUser?.thumb'}
            wa_id={selectedUser?.wa_id}
          />}
          {selectedUser ?
            <InfiniteScroll
              dataLength={Object.values(messages?.[selectedUser?.id] || {})?.length || 0}
              next={onInfiniteLoad}
              style={{ display: 'flex', flexDirection: 'column-reverse', padding: '20px', }}
              inverse={true}
              height={deviceWidth() === 'desktop' ? '66vh' : '75vh'}
              hasMore={selectedUser?.isInfiniteLoading}
              loader={<div><div style={{ position: 'relative' }} className="loading" /></div>}
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }>
              {Object.values(messages?.[selectedUser?.id] || {}).length ? Object.values(messages?.[selectedUser?.id] || {})?.sort((a, b) => b?.created - a?.created)?.map((item, index, array) => {
                const sender = allContacts?.find(x => x.id === item.from);
                const previousMessage = array[index - 1];
                const sameSender = previousMessage && previousMessage.from === item.from;
                return <MessageCard
                  key={index}
                  sender={sender}
                  item={item}
                  currentUserid={user?.uid}
                  sameSender={sameSender}

                />
              }) : ''}
            </InfiniteScroll> :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', width: '100%' }}>No chat selected</div>
          }
          {selectedUser && <SaySomething
            placeholder={'Type Something...'}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleChatInputPress={handleChatInputPress}
            handleSendButtonClick={handleChatInputPress}
            disableSendButton={disableSendButton}
          />}
        </Colxx>
      </Row>
    </Fragment>
  );
};

export default Chat;
