
import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {
	CHANGE_LOCALE,
	GET_BRANDS,
	GET_CATEGORIES,
	GET_CONDITIONS,
	GET_SERVICES,
	GET_ADDONS,
	GET_VARIATIONS_CATEGORIES,
	GET_SPECIALITIES,
	GET_SYMPTOMS,
	SET_SEARCH,
	GET_SIGNS,
	GET_TYPES,
	GET_PROMOS,
	GET_LANGUAGES,
	GET_NATIONALITIES,
	GET_DRIP_INGREDIENTS,
	GET_TEST_PARAMETERS,
	GET_SAMPLE_TYPES,
	GET_ORGANS,
	GET_ACTIVE_SUBSTANCES,
	GET_SUBSCRIPTIONS
} from '../actions';

const INIT_STATE = {
	locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return { ...state, locale: action.payload };
		case GET_SPECIALITIES:
			return { ...state, specialities: action.payload };
		case GET_BRANDS:
			return { ...state, brands: action.payload };
		case GET_SERVICES:
			return { ...state, services: action.payload };
		case GET_ADDONS:
			return { ...state, addons: action.payload };
		case GET_VARIATIONS_CATEGORIES:
			return { ...state, variations_categories: action.payload };
		case GET_CATEGORIES:
			return { ...state, categories: action.payload };
		case GET_CONDITIONS:
			return { ...state, conditions: action.payload };
		case GET_SYMPTOMS:
			return { ...state, symptoms: action.payload };
		case GET_SIGNS:
			return { ...state, signs: action.payload };
		case GET_LANGUAGES:
			return { ...state, languages: action.payload };
		case GET_NATIONALITIES:
			return { ...state, nationalities: action.payload };
		case GET_TYPES:
			return { ...state, types: action.payload };
		case GET_PROMOS:
			return { ...state, offers: action.payload };
		case SET_SEARCH:
			return { ...state, search: action.payload };
		case GET_DRIP_INGREDIENTS:
			return { ...state, drip_ingredients: action.payload };
		case GET_TEST_PARAMETERS:
			return { ...state, test_parameters: action.payload };
		case GET_SAMPLE_TYPES:
			return { ...state, sample_types: action.payload };
		case GET_ORGANS:
			return { ...state, organs: action.payload };
		case GET_ACTIVE_SUBSTANCES:
			return { ...state, active_substances: action.payload };
		case GET_SUBSCRIPTIONS:
			return { ...state, subscriptions: action.payload };
		default: return { ...state };
	}
}