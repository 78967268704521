import moment from "moment";
import React, { Fragment, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import YearsDatePicker from "../../../../components/common/YearsDatePicker";
import { firestore } from "../../../../helpers/Firebase";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
// import PDFComponent from "../../../../components/common/soaPDFComponent";
import { exportCSV } from "../../../../helpers/Utils";
import { useSelector } from "react-redux";

const months = [
    { title: 'Jan', count: '01' },
    { title: 'Feb', count: '02' },
    { title: 'Mar', count: '03' },
    { title: 'Apr', count: '04' },
    { title: 'May', count: '05' },
    { title: 'June', count: '06' },
    { title: 'July', count: '07' },
    { title: 'Aug', count: '08' },
    { title: 'Sept', count: '09' },
    { title: 'Oct', count: '10' },
    { title: 'Nov', count: '11' },
    { title: 'Dec', count: '12' }
]

const SOA = ({ provider }) => {
    const [year, setSelectedYear] = useState(moment().format('YYYY'))
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state.authUser)

    const fetchData = async (dateRange) => {
        let providersRef = firestore().collection('providers').doc(provider.id).collection("SOA").where('type', '==', 'settlement')
        let setteledSnap = await providersRef.get()
        let asyncOptionsSetteled = setteledSnap.docs.map(async res => {
            return {
                ...res.data(),
                key: res.id,
            }
        })
        let ref = firestore().collection('orders').where("provider.id", "==", provider.id)
        if (dateRange?.end) {
            console.log(' moment(dateRange.end).valueOf()', moment(dateRange.end).valueOf());
            let userBookingsSnap = await ref.where('completed', '<=', moment(dateRange.end).valueOf()).where('status', 'in', ['completed', 'rated']).get()
            let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {
                return {
                    ...res.data(),
                    key: res.id,
                    bookingId: res.id,
                }

            })
            Promise.all([...asyncOptionsOnline, ...asyncOptionsSetteled]).then((ress) => {
                setLoading(false)
                if (ress.length) {
                    exportCSV(ress, user)
                    // createPDF()
                }
            })
        }
    }
    // }, [provider, dateRange])

    // const createPDF = async () => {
    //     const pdf = new jsPDF("portrait", "pt", "a4");
    //     const data = await html2canvas(document.querySelector("#pdf"));
    //     const img = data.toDataURL("image/png");
    //     const imgProperties = pdf.getImageProperties(img);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    //     pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    //     pdf.save("statment_of_account.pdf");
    // };
    return (
        <Colxx xxs="12" className="mb-4">
            {loading && <div className="loading" />}

            <Card className={'rounded'}>
                <CardBody>
                    <CardTitle className={'d-flex justify-content-between align-items-center mb-2'}>
                        <div>Statment Of Account</div>
                        <YearsDatePicker updateYear={(e) => { setSelectedYear(e) }} />
                    </CardTitle>
                    <Fragment>
                        {months?.filter(r => moment(`${r?.count}/01/${year}`).valueOf() < moment().valueOf())?.sort((a, b) => b.count - a.count).map((m) => {
                            return (
                                <div key={m.title} className="mt-2" style={{ border: '1px solid #00000030', borderRadius: '8px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px'
                                        }}
                                    // aria-expanded={m.title === month?.title}
                                    >
                                        <div style={{ fontSize: '16px', fontWeight: '700', }}>{m.title + ' ' + year}</div>

                                        <i className="iconsminds-download-1" onClick={() => {
                                            let day = moment(`${m?.count}/01/${year}`).valueOf()
                                            let startDate = moment(day).startOf('months').startOf('day').valueOf()
                                            let endDate = moment(day).endOf('months').endOf('day').valueOf()
                                            setLoading(true)
                                            fetchData({ start: startDate, end: endDate })
                                        }} style={{ fontSize: '20px', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            )
                        })}
                    </Fragment>
                </CardBody>
            </Card>
            {/* <PDFComponent data={data} dateRange={dateRange} /> */}
        </Colxx>
    );

};

export default SOA;