import React from "react";
import {
    withGoogleMap,
    GoogleMap,
    Polygon
} from "react-google-maps";

const refs = {
    map: undefined,
}
const MapWithAMarker = withGoogleMap(props => {

    let path;
    let geojson = props?.geojson

    if (geojson?.type === "MultiPolygon") {
        path = {}
        geojson && geojson.coordinates && Object.keys(geojson.coordinates).map(recc => {
            let polys = geojson.coordinates?.[recc]?.reduce((val, currentVal) => {
                val.push({ lat: currentVal.latitude, lng: currentVal.longitude })
                return val
            }, [])
            return path[recc] = polys
        })
    } else {
        path = geojson?.coordinates?.reduce((val, currentVal) => {
            val.push({ lat: currentVal.latitude, lng: currentVal.longitude })
            return val
        }, [])
    }
    return (
        <GoogleMap
            ref={(ref) => refs.map = ref}
            defaultZoom={13}
            options={{ disableDefaultUI: true }}
            center={props?.center?.lat && props?.center?.lng ? props?.center : {
                lat: 25.1903,
                lng: 55.2747,
            }}
            defaultCenter={props?.center?.lat && props?.center?.lng ? props?.center : {
                lat: 25.1903,
                lng: 55.2747,
            }}>
            {geojson?.type === "MultiPolygon" ?
                Object.values(path).map(p => <Polygon path={p}
                    options={{
                        fillColor: "red",
                        fillOpacity: 0.4,
                        strokeColor: "red",
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }} />)
                : <Polygon path={path}
                    options={{
                        fillColor: "red",
                        fillOpacity: 0.4,
                        strokeColor: "red",
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }} />}
        </GoogleMap>
    )
});

const GMap = ({ geojson }) => {
    const coordinate = (cord) => {
        let x = (cord?.type === 'MultiPolygon' ? Object.values(cord?.coordinates)?.[0] : cord?.coordinates)?.map(c => c.latitude)
        let y = (cord?.type === 'MultiPolygon' ? Object.values(cord?.coordinates)?.[0] : cord?.coordinates)?.map(c => c.longitude)

        let minX = Math.min.apply(null, x)
        let maxX = Math.max.apply(null, x)

        let minY = Math.min.apply(null, y)
        let maxY = Math.max.apply(null, y)

        return {
            lat: (minX + maxX) / 2,
            lng: (minY + maxY) / 2
        }
    }

    return (

        <MapWithAMarker
            geojson={geojson}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o"
            loadingElement={<div className="map-item" />}
            center={coordinate(geojson)}
            containerElement={<div className="map-item" />}
            mapElement={<div className="map-item" />} />
    )
};

export default GMap;