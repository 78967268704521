import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ApplicationMenu from "../../components/common/ChatMenu";
import { setSelectedUser, setUserFromRequests } from "../../redux/chat/actions";
import { getFirstCharacters } from "../../helpers/Utils";
import moment from "moment";

class ChatApplicationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: ""
    };
  }

  handleSearchContact = keyword => {
    this.setState({
      searchKey: keyword || ''
    });
  };
  handleContactClick = (user) => {
    this.props.setSelectedUser(user);
    this.props.setUserFromRequests(undefined);
  }


  render() {
    const {
      allContacts,
    } = this.props.chatApp;
    const { messages } = this.props.intl;
    const { searchKey } = this.state;


    return (
      <ApplicationMenu>
        <div className="pt-4 pr-4 pl-4 pb-0">
          <div className="form-group">
            <input
              type="text"
              className="form-control rounded"
              placeholder={messages["menu.search"]}
              value={this.state.searchKey}
              onChange={e => this.handleSearchContact(e.target.value)}
            />
          </div>
        </div>
        <PerfectScrollbar
          style={{ maxHeight: '90%' }}
          option={{ suppressScrollX: true, wheelPropagation: false }}>
          <div className="pt-2 pr-4 pl-4 pb-2">
            {
              allContacts?.filter(c => (c.name)?.toLowerCase()?.includes(searchKey) || (c.wa_id)?.toLowerCase()?.includes(searchKey))?.map((item, index) => {
                let chatMessages = item?.chatMessages?.sort((msg1, msg2) => msg1?.created - msg2?.created);
                const lastMessage = chatMessages?.[chatMessages?.length - 1];
                return (
                  <div
                    key={index}
                    className="d-flex flex-row mb-3 border-bottom pb-3"
                  >
                    <NavLink
                      className="d-flex"
                      to="#"
                      style={{ width: '100%' }}
                      onClick={() => this.handleContactClick(item)}
                    >
                      <div
                        style={{
                          background: '#3498db',
                          padding: '5px',
                          color: '#fff',
                          width: '40px',
                          height: '40px',
                          minWidth: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: '500',
                          borderRadius: '200px',
                          letterSpacing: '.06em'
                        }}
                        className="mr-3"
                      >{getFirstCharacters(item?.name || 'Metadoc Team')}</div>
                      <div className="d-flex flex-grow-1" style={{ width: '55%' }} >
                        <div className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                          <div className="min-width-zero">
                            <p className="mb-0 truncate" style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',

                            }}>{item?.name}</p>
                            <p className="mb-0 truncate text-muted text-small">{moment(lastMessage?.created).format('DD/MM/YYYY hh:mm A')}</p>
                          </div>
                        </div>
                      </div>
                      <p className="mb-0 float-right" style={{ fontSize: '10px', color: 'darkred', fontWeight: 'bold' }}>{item.exp ? 'Expired' : ''}</p>

                    </NavLink>
                  </div>
                );
              })}
          </div>
        </PerfectScrollbar>
      </ApplicationMenu>
    );
  }
}

const mapStateToProps = ({ authUser, chatApp }) => {
  const { user } = authUser;

  return { user, chatApp };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      setSelectedUser,
      setUserFromRequests
    }
  )(ChatApplicationMenu)
);
