import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import Referral_partners from "./referral_partners";

const Applications = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Route path={`${match.url}/`} component={Referral_partners} />
      <Redirect to="/error" />
    </Switch>
  </Suspense>
);
export default Applications;
