import React from "react";
import ServiceCategory from "./components/service_category";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { firestore } from "../../../helpers/Firebase";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import { setSearch } from "../../../redux/actions";

const ServicesCategories = () => {
  const dispatch = useDispatch();
  const { services } = useSelector(state => state.settings)
  const { activeItem } = useSelector(state => state.activeItem)

  const authUser = useSelector(state => state.authUser)


  const selectService = async (serviceData) => {
    let post = { general: serviceData };
    let { addNew, id } = serviceData;
    let tapsData = ["variations"]
    if (!addNew) {
      await Promise.all(tapsData.map(async rec => {
        let snap = await firestore().collection('services').doc(id).collection(rec).where('deactivated', '!=', true).get()
        let data = snap.docs.reduce((val, currentVal) => {
          val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }
          return val
        }, {})
        post[rec] = data
        return data

      }))
    }
    dispatch(setSearch(''))
    dispatch(SetActiveItem(post))
  }

  return (
    <div>
      {
        activeItem ?
          <ServiceCategory
            authUser={authUser}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          /> : <MainPage
            title="Services Categories"
            selected='services'
            data={services || []}
            setSelectedItem={(e) => selectService(e)}
          />

      }
    </div>
  );
};


export default ServicesCategories;
