/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const quillModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    // ["link"],
    ["clean"]
  ],

};

export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "list",
  "bullet",
  "indent",
  // "link",
  "align"
]

//live
export const firebaseConfig = {
  apiKey: "AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o",
  authDomain: "metadoc-app.firebaseapp.com",
  projectId: "metadoc-app",
  storageBucket: "metadoc-app.appspot.com",
  messagingSenderId: "809013643436",
  appId: "1:809013643436:web:a6463fd1dec16aa251f709",
  measurementId: "G-YQTFPK7T87"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_color";

