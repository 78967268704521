import React, { useEffect, useState } from "react";
import {
    Row,
    Card,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Badge,
} from "reactstrap";

import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";

import { useDispatch, useSelector } from "react-redux";
import Locations from "./locationComponents/location";
import { UpdateItem } from "../../../../redux/activeItem/actions";

const Location = () => {
    const { activeItem } = useSelector(state => state.activeItem)
    const [locations, setLocations] = useState(activeItem?.locations || {})
    const [editLocation, setEditLocation] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setLocations(activeItem?.locations)
        // eslint-disable-next-line
    }, [activeItem?.locations])

    return (
        <Card className="rounded">
            <CardBody>
                <Row className="survey-app">
                    <Colxx xxs="12" className="mb-2">
                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                            <h4 className="mb-0">Areas Covered</h4>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => setEditLocation({ addNew: true })}>Add Areas
                                </Button>
                            </div>

                        </div>
                        <Separator className="mb-5" />
                        <Row>
                            <Colxx xxs="12" className="mb-3">
                                {
                                    locations && Object.keys(locations).map((key, index) => {
                                        let item = locations[key]
                                        return !item.deleted && (
                                            <div key={index} className='rounded mb-4' style={{ boxShadow: 'none' }}>
                                                <div className='d-flex justify-content-between m-4'>
                                                    <div className="w-80">
                                                        <h4>{item?.title}</h4>
                                                        <div>
                                                            {item.areas_list && Object.values(item.areas_list).map(areaTitle => {
                                                                return (
                                                                    <p className="d-sm-inline-block mb-1 mr-1" key={areaTitle.id}>
                                                                        <Badge
                                                                            className="mb-1"
                                                                            color={'dark'}
                                                                            pill>
                                                                            {areaTitle.title}
                                                                        </Badge>
                                                                    </p>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end align-items-center w-20'>
                                                        <Button style={{ margin: '4px' }} outline color='danger' onClick={() => setConfirmDelete(item)} size='xs'>Delete</Button>
                                                        <Button outline color='dark' onClick={() => setEditLocation(JSON.parse(JSON.stringify(item)))} size='xs'>Edit</Button>
                                                    </div>
                                                </div>

                                                <Separator />
                                            </div>
                                        )
                                    })
                                }
                            </Colxx>
                        </Row>
                        {
                            confirmDelete && <Modal isOpen={true}>
                                <ModalBody>Are you sure you want to delete this area group?</ModalBody>
                                <ModalFooter>
                                    <Button color='danger' onClick={() => setConfirmDelete(false)}>Cancel</Button>
                                    <Button onClick={() => {
                                        let locs = JSON.parse(JSON.stringify(locations))
                                        locs[confirmDelete?.id].deleted = true
                                        locs[confirmDelete?.id].updated = true
                                        dispatch(UpdateItem({ key: 'general', val: { ...activeItem?.general, updated: true } }))
                                        dispatch(UpdateItem({ key: 'locations', val: JSON.parse(JSON.stringify(locs)) }))
                                        setConfirmDelete(false)
                                    }}>Confirm</Button>
                                </ModalFooter>

                            </Modal>
                        }
                    </Colxx>
                </Row>
                {editLocation && <Locations selectedLocation={editLocation} dismiss={() => setEditLocation(false)} />}
            </CardBody>
        </Card>
    );
};

export default Location;