import React, { useState } from "react";
import { Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Colxx } from "./CustomBootstrap";
const RichProfile = ({
    title,
    dismiss,
    elements,
    newRichProfile,
    updateHelpers
}) => {
    const [loading, setLoading] = useState(false)
    const [updatedHelper, UpdateHelper] = useState({ addNew: true })

    const submit = async () => {
        updateHelpers(JSON.parse(JSON.stringify(updatedHelper)))
        setLoading(false)
    }

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Modal wrapClassName="modal-right" backdrop="static" isOpen={newRichProfile} >
                    <ModalHeader>
                        <h3>{title}</h3>
                    </ModalHeader>
                    <ModalBody>
                        {elements?.map((item, index) => {
                            return (
                                <Colxx key={index} xxs="12" className="mb-4 p-0">
                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                        <div>{item.title}</div>
                                    </div>
                                    <Input
                                        type={item.type}
                                        placeholder={"e.g. " + item.title}
                                        value={updatedHelper?.[item.key]}
                                        className="customInput"
                                        onChange={(e) => {
                                            UpdateHelper({ ...updatedHelper, [item.key]: e.target.value, updated: true })
                                        }} />
                                </Colxx>
                            )
                        })}
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'space-between' }}>

                        <Button
                            color="danger"
                            outline
                            className="btn"
                            size="lg"
                            onClick={() => dismiss()}>
                            Dismiss
                        </Button>
                        <Button
                            // disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedHelper)), ['name'])}
                            color="primary"
                            className="btn"
                            size="lg"
                            onClick={() => {
                                setLoading(true)
                                submit()
                            }}>
                            Add New
                        </Button>
                    </ModalFooter>
                </Modal>
            ))
    );
};

export default RichProfile;