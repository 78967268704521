import React, { useState } from "react";
import { Row, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { Colxx, Separator } from "./CustomBootstrap";
const Addons = ({
  dismiss,
  editAddon,
  addNewAddon
}) => {
  const [loading, setLoading] = useState(false)
  const [addon, setAddon] = useState(editAddon || {})

  const submit = () => {
    addNewAddon(addon)
    setLoading(false)
    dismiss()
  }
  return (
    (
      loading ? (
        <div className="loading" />
      ) : (
        <Modal wrapClassName="modal-right" backdrop="static" isOpen={editAddon} >
          <ModalHeader>
            <h3>{'Addon Information'}</h3>
          </ModalHeader>
          <ModalBody>
            <Colxx xxs="12 p-0">
              {[{
                key: 'addonInfo', title: 'Addon Information',
                data: [
                  { key: 'name', title: 'Name', value: addon?.name?.en || addon?.name, width: '12', type: 'text', show: true },
                  { key: 'price', title: 'Price', value: addon?.price, width: '12', type: 'number', show: true },
                  { key: 'count', title: 'Count', value: addon?.count, width: '12', type: 'number', show: true },
                ]
              },
              ].map((rec, recIndex) => {
                return (
                  <Row key={recIndex}>
                    <Separator />
                    {rec.data.map((item, index) => {
                      return item.show && (
                        <Colxx key={index} xxs="12" md={item.width} className="mb-4 ">
                          <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }} >
                            <div className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                              <div>{item.title}</div>
                            </div>
                          </div>
                          <InputGroup>
                            {item.key === 'price' ? <InputGroupAddon addonType='prepend'>
                              <InputGroupText>AED</InputGroupText>
                            </InputGroupAddon> : ''}
                            <Input
                              type={item.type}
                              placeholder={"e.g. " + item.title}
                              value={item.value}
                              className="customInput"
                              onChange={(e) => {
                                setAddon({ ...addon, [item.key]: e.target.value })

                              }} />
                          </InputGroup>

                        </Colxx>
                      )
                    })}
                  </Row>
                )
              })}


            </Colxx>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'space-between' }}>

            <Button
              color="danger"
              outline
              className="btn"
              size="lg"
              onClick={() => dismiss()}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn"
              size="lg"
              onClick={() => {
                setLoading(true)
                submit()
              }}>
              Add New Addon
            </Button>
          </ModalFooter>
        </Modal>
      ))
  );
};

export default Addons;