import React, { Fragment, useEffect, useState } from "react";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { arrayMove, List } from "react-movable";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from "../../../helpers/Firebase";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Switch from "rc-switch";
import { setSearch } from "../../../redux/actions";

const MainPage = ({
  title,
  selected,
  data,
  setSelectedItem,
}) => {

  const search = useSelector(state => state.settings.search) || ''
  const { user } = useSelector(state => state.authUser)
  const dispatch = useDispatch();

  const [deleteItem, setDeleteItem] = useState(false)
  const [filteredItems, setFilteredItems] = useState([])
  const [changeSorting, setChangeSorting] = useState(false)

  const deleteItemFunc = (item) => {
    firestore().collection(selected).doc(item.id).update({ deactivated: true, lastUpdate: Date.now(), active: false }).then(() => {
      setDeleteItem(false)
    })
  }

  const toggleModal = () => {
    setSelectedItem({ addNew: true })
  };

  const onClickItem = (item) => {
    setSelectedItem(item || {})
  };

  useEffect(() => {
    setFilteredItems(data && data?.sort((a, b) => a.index - b.index).filter(item => {
      return (item?.name?.en || item?.name) && (item?.name?.en || item?.name).toLowerCase().includes(search?.toLowerCase())
    }))
    // eslint-disable-next-line 
  }, [search, data])

  const changeSortingFunc = () => {
    filteredItems.map(async (r, index) => {
      await firestore().collection(selected).doc(r.id).update({ index: index, lastUpdate: Date.now() })
    })
    setChangeSorting(false)
  }
  return (
    !data && !data.length ? <div className="loading" /> :
      <Fragment>
        <div className={"disable-text-selection"}>
          <ListPageHeading
            heading={title}
            toggleModal={toggleModal}
          />
          <Row>
            <Colxx xxs="12" className="mb-2">

              <List
                values={filteredItems}
                onChange={({ oldIndex, newIndex }) => {
                  if (search) {
                    dispatch(setSearch(''))
                  } else {
                    setFilteredItems(arrayMove(filteredItems, oldIndex, newIndex))
                    setChangeSorting(true)
                  }
                }}
                renderList={({ children, props }) => <ul className="p-0" {...props}>{children}</ul>}

                renderItem={({ value, props }) => {
                  props.style = {
                    ...props.style, borderBottom: '1px solid #f3f3f3', listStyle: 'none',
                    padding: '1.5rem',
                    background: 'white',
                    borderRadius: '0.75rem',
                    // boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
                  }
                  return (<li {...props} className="mt-3" >
                    <Row className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <i className="simple-icon-cursor-move" />
                      </div>
                      <Colxx xxs="1">
                        <Switch
                          className="custom-switch custom-switch-primary"
                          checked={value.active}
                          onChange={switchCheckedPrimary => {
                            firestore().collection(selected).doc(value.id).update({ active: switchCheckedPrimary, lastUpdate: Date.now() })
                          }}
                        />
                      </Colxx>
                      <Colxx xxs="2">
                        <span>{value?.name?.en}</span>
                      </Colxx>

                      <Colxx >
                        <span>{value?.name?.ar}</span>
                      </Colxx>

                      <Colxx xxs="3" >
                        <Button
                          color="primary"
                          size="sm"
                          outline
                          className="float-right"
                          onClick={() => onClickItem(value)}>
                          Edit
                        </Button>
                        {user.role === 'superAdmin' ? <Button
                          color="danger"
                          size="sm"
                          className="float-right mr-2"
                          outline
                          onClick={() => { setDeleteItem(value) }}>
                          Delete
                        </Button> : ''}

                      </Colxx>
                    </Row>
                  </li>)
                }}
              />
            </Colxx>
          </Row>

          <Modal backdrop="static" isOpen={deleteItem}>
            <ModalHeader >
              Delete {deleteItem?.name?.en || deleteItem?.name}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete {deleteItem?.name?.en || deleteItem?.name}?</Label>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => {
                setDeleteItem(false)
              }}>Dismiss</Button>
              <Button color='danger' onClick={() => {
                deleteItemFunc(deleteItem)
              }}>Delete</Button>
            </ModalFooter>

          </Modal>
          <Modal backdrop="static" isOpen={changeSorting}>
            <ModalHeader >
              Change Sorting
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to change sorting?</Label>
                </Colxx>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => {
                setChangeSorting(false)
              }}>Cancel</Button>
              <Button color='danger' onClick={() => {
                changeSortingFunc()
              }}>Change</Button>
            </ModalFooter>

          </Modal>
        </div>

      </Fragment >);
};

export default MainPage;