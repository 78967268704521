/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const GET_SERVICES = "GET_SERVICES";
export const GET_ADDONS = "GET_ADDONS";
export const GET_BRANDS = "GET_BRANDS";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_CONDITIONS = "GET_CONDITIONS";
export const GET_VARIATIONS_CATEGORIES = "GET_VARIATIONS_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_SEARCH = "SET_SEARCH";
export const GET_SYMPTOMS = "GET_SYMPTOMS";
export const GET_SIGNS = "GET_SIGNS";
export const GET_TYPES = "GET_TYPES";
export const GET_PROMOS = "GET_PROMOS";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_DRIP_INGREDIENTS = "GET_DRIP_INGREDIENTS";
export const GET_TEST_PARAMETERS = "GET_TEST_PARAMETERS";
export const GET_SAMPLE_TYPES = "GET_SAMPLE_TYPES";
export const GET_ORGANS = "GET_ORGANS";
export const GET_ACTIVE_SUBSTANCES = "GET_ACTIVE_SUBSTANCES";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";


/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

//Providers
export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_PROFISSIONALS = "GET_PROFISSIONALS";

//Partnerss
export const GET_PARTNERS = "GET_PARTNERS";

//Bookings
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_ASSIGNED_REQUESTS = "GET_ASSIGNED_REQUESTS";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_DELAYED_APPOINTMENTS = "GET_DELAYED_APPOINTMENTS";


//ACTIVE ITEM
export const GET_ACTIVE_ITEM = "GET_ACTIVE_ITEM";
export const UPDATE_ACTIVE_ITEM = "UPDATE_ACTIVE_ITEM";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";
export const CHAT_DELETE_MESSAGE = "CHAT_DELETE_MESSAGE"
export const USER_FROM_REQUESTS = "USER_FROM_REQUESTS"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
