import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import Helper from "./components/helper";
import VariationsCategories from './variations_categories'
import Medicines from './medicines'
const ActiveHelper = ({ type, title, selected }) => {
    const dispatch = useDispatch();
    const { conditions, specialities, services, symptoms, signs, sample_types, organs } = useSelector(state => state.settings)
    const { activeItem } = useSelector(state => state.activeItem)
    let data = useSelector(state => state.settings?.[type]) || []


    const elements = {
        specialities: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'picture', title: 'Speciality Picture', width: '10', type: 'picture' },
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
                { key: 'related_conditions', title: 'Related Conditions', width: '10', data: conditions, type: 'select', selected: 'conditions', formatCreateLabel: 'Add New Condition', },
                { key: 'related_services', title: 'Related Services', width: '10', data: services?.filter(rec => rec.active), type: 'select' }]
        }],
        conditions: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'picture', title: 'Condition Picture', width: '10', type: 'picture' },
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Definition', width: '10', type: 'textarea', translateButton: true },
                { key: 'related_symptoms', title: 'Related Symptoms', width: '10', data: symptoms, type: 'select', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', },
                { key: 'related_signs', title: 'Related Signs', width: '10', data: signs, type: 'select', selected: 'signs', formatCreateLabel: 'Add New Sign', },
                { key: 'related_specialities', title: 'Related Specialities', width: '10', data: specialities, type: 'select', selected: 'specialities', formatCreateLabel: 'Add New Speciality', },
            ]
        }],
        categories: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'picture', title: 'Category Picture', width: '10', type: 'picture' },
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        brands: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'picture', title: 'Brand Picture', width: '10', type: 'picture' },
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        symptoms: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        signs: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        languages: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                // { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        nationalities: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                // { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        types: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
            ]
        }],
        variations_categories: [{
            key: 'generalInfo',
            title: 'General Information',
            sortable: true,
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '12', type: 'textarea', translateButton: true },
            ]
        }],
        addons: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '12', type: 'textarea', translateButton: true },
                { key: 'price', title: 'Price (AED)', width: '4', type: 'number' },
                { key: 'min_count', title: 'Minimum Count', width: '4', type: 'number' },
                { key: 'max_count', title: 'Maximuim Count', width: '4', type: 'number' },
            ]
        }],
        medicines: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text' },
                { key: 'pharmacy_generic_name', title: 'Pharmacy Generic Name', width: '12', type: 'text' },
                { key: 'price', title: 'Price (AED)', width: '4', type: 'number' },

            ]
        }],
        drip_ingredients: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Ingredient Name', width: '12', type: 'text', translateButton: true },
                { key: 'benefits', title: 'Main benefits', width: '12', type: 'select', formatCreateLabel: 'Add New Benefit', },
                { key: 'related_symptoms', title: 'Related Symptoms', width: '12', data: symptoms, type: 'select', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', },
            ]
        }],
        test_parameters: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Parameter Name', width: '12', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '12', type: 'textarea', translateButton: true },
                { key: 'sample_volume', title: 'Sample Volume (ml)', width: '12', type: 'number' },
                { key: 'fasting_hours ', title: 'Fasting Hours', width: '12', type: 'number' },
                {
                    key: 'fasting_items', title: 'Fasting Items', width: '12', data: [
                        { name: 'Water', key: 'water', id: 'water' },
                        { name: 'Food', key: 'food', id: 'food' },
                    ], type: 'select'
                },
                { key: 'sample_types', title: 'Sample Types', width: '12', data: sample_types, type: 'select', selected: 'sample_types', formatCreateLabel: 'Add New Sample', },
                { key: 'related_symptoms', title: 'Related Symptoms', width: '12', data: symptoms, type: 'select', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', },
                { key: 'related_conditions', title: 'Related Conditions', width: '12', data: conditions, type: 'select', selected: 'conditions', formatCreateLabel: 'Add New Condition', },
                { key: 'organs_screened', title: 'Organs Screened', width: '12', data: organs, type: 'select', selected: 'organs', formatCreateLabel: 'Add New Organ', },
            ]
        }],
        sample_types: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '12', type: 'textarea', translateButton: true },
            ]
        }],
        organs: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                { key: 'details', title: 'Details', width: '12', type: 'textarea', translateButton: true },
            ]
        }],
        active_substances: [{
            key: 'generalInfo',
            title: 'General Information',
            data: [
                { key: 'name', title: 'Name', width: '12', type: 'text', translateButton: true },
                { key: 'treated_symptoms', title: 'Treated Symptoms', width: '12', data: symptoms, type: 'select', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', },
                { key: 'side-effects', title: 'Potential Side Effects', width: '12', data: symptoms, type: 'select', selected: 'symptoms', formatCreateLabel: 'Add New Symptom', },
                { key: 'related_conditions', title: 'Related Conditions', width: '12', data: conditions, type: 'select', selected: 'conditions', formatCreateLabel: 'Add New Condition', },
            ]
        }],
    }

    const authUser = useSelector(state => state.authUser)

    const selectHelper = async (helperData) => {
        let post = { general: helperData };
        dispatch(SetActiveItem(post))
    }

    return (
        <div>
            {
                activeItem ?
                    <Helper
                        authUser={authUser}
                        title={title}
                        selected={selected}
                        selectedItem={activeItem}
                        elements={elements[selected]}
                        dismiss={() => {
                            dispatch(SetActiveItem(false))
                        }}
                    /> :
                    (type === 'variations_categories' ?
                        <VariationsCategories title={title}
                            selected={selected}
                            data={data || []}
                            setSelectedItem={(e) => selectHelper(e)} /> :
                        type === 'medicines' ?
                            <Medicines title={title}
                                selected={selected}
                                data={data || []}
                                setSelectedItem={(e) => selectHelper(e)} /> :
                            <MainPage
                                title={title}
                                selected={selected}
                                data={data || []}
                                setSelectedItem={(e) => selectHelper(e)}
                            />)
            }
        </div>
    );
};

export default ActiveHelper;