import React, { useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Label,
} from "reactstrap";

import classnames from "classnames";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { formatAddress } from "../../helpers/Utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import Rating from "./Rating";

const CustomerHistory = ({
    customer,
    dismiss,
}) => {
    const [activeTab, setActiveTab] = useState(1)
    const [tabs] = useState([
        { name: 'Personal Details', key: 1 },
        { name: 'Customer Orders', key: 2 },
    ])
    return (

        <Modal size="lg" backdrop="static" isOpen={customer} >

            <ModalBody>
                <h3>{'Customer Information'}</h3>

                <Row>
                    <Colxx xxs="12">
                        <Nav tabs className="separator-tabs ml-0 mb-5">
                            {
                                tabs.map(item => {
                                    return !item.hide && (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            key={item.key}
                                            className={classnames({
                                                active: activeTab === item.key,
                                                "nav-link": true
                                            })}
                                            onClick={() => {
                                                setActiveTab(item.key);
                                            }}>
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>

                                {[{
                                    key: 'personalDetails', title: 'Personal Details',
                                    data: [
                                        { key: 'name', title: 'Name', width: '6', type: 'text', data: customer?.displayName },
                                        { key: 'gender', title: 'Gender', width: '6', type: 'textarea', data: customer?.gender },
                                    ]
                                },
                                {
                                    key: 'location', title: 'Location Details',
                                    data: customer?.locations || []
                                },
                                {
                                    key: 'contactDetails:', title: 'Contact Details',
                                    data: [
                                        { key: 'number', title: 'Mobile number', width: '6', type: 'text', data: customer?.number },
                                        { key: 'email', title: 'Email', width: '6', type: 'text', data: customer?.email }
                                    ]
                                }, {
                                    key: 'attachments', title: 'Attachments',
                                    data: []
                                },].map((rec, recIndex) => {
                                    return rec?.data?.length ? (
                                        <Row key={recIndex}>
                                            <Colxx xxs="12" className="mt-3">
                                                <h4>{rec.title}</h4>
                                            </Colxx>
                                            {rec.data.map((item, index) => {
                                                return (

                                                    <Colxx xxs="12" md={item.width || '6'} key={index}>
                                                        <Label className="mb-0  text-semi-muted">{rec.key === 'location' ? `Address ${index + 1}` : item.title}:</Label>
                                                        <p className="font-weight-bold" id={item.id} style={{ fontSize: '0.8rem' }}>{rec.key === 'location' ? formatAddress(item) : (item?.data || '--')}</p>
                                                    </Colxx>
                                                )
                                            })}
                                        </Row>
                                    ) : ''
                                })}

                            </TabPane>
                            <TabPane tabId={2}>
                                <PerfectScrollbar
                                    option={{ suppressScrollX: true, wheelPropagation: false }}
                                >
                                    <div className="p-4" style={{ height: '500px' }}>
                                        {customer?.orders?.sort((a, b) => b.created - a.created)?.map((item, index) => {
                                            return (
                                                <>
                                                    <Row className={`d-flex align-items-center ${index !== 0 && "mt-4"}`}>

                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Order Number</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Service</Colxx>
                                                        <Colxx xxs="3" style={{ fontWeight: '700' }}>Date</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Amount</Colxx>
                                                        <Colxx xxs="2" style={{ fontWeight: '700' }}>Rating</Colxx>
                                                    </Row>
                                                    <Row key={index} className="d-flex align-items-center">

                                                        <Colxx xxs="2">
                                                            <span>#{item?.orderNumber}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>{item?.service?.name?.en || item?.service?.name}</span>
                                                        </Colxx>
                                                        <Colxx xxs="3">
                                                            <span>{moment(item?.bookingDate?.date).format('DD/MM/YYYY') + " " + moment(item?.bookingDate?.time?.start).format('hh:mm A')}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <span>AED {item?.receipt?.total}</span>
                                                        </Colxx>
                                                        <Colxx xxs="2">
                                                            <Rating total={5} rating={item?.rate} />
                                                        </Colxx>
                                                    </Row>
                                                    <Separator className="mt-4" />
                                                </>
                                            )
                                        })}
                                    </div>
                                </PerfectScrollbar>
                            </TabPane>

                        </TabContent>

                    </Colxx>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => dismiss()}>
                    Dismiss
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CustomerHistory;