import {
	CHAT_GET_CONTACTS,
	CHAT_CHANGE_CONVERSATION,
	CHAT_GET_CONVERSATIONS,
	CHAT_DELETE_MESSAGE,
	USER_FROM_REQUESTS
} from '../actions';

const INIT_STATE = {
	allContacts: null,
	selectedUser: null,
	messages: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case CHAT_GET_CONTACTS:
			return { ...state, allContacts: action.payload };
		case CHAT_GET_CONVERSATIONS:
			return {
				...state, messages: {
					...state.messages,
					[action?.payload?.parent_id]: {
						...state.messages[action?.payload?.parent_id],
						[action?.payload?.id]: action?.payload,
					}
				}
			};
		case CHAT_DELETE_MESSAGE:
			let messages = state.messages[action?.payload?.parent_id]
			delete messages[action?.payload?.id]
			return {
				...state, messages: {
					...state.messages,
					[action?.payload?.parent_id]: messages
				}
			};
		case CHAT_CHANGE_CONVERSATION:
			return { ...state, selectedUser: action.payload };
		case USER_FROM_REQUESTS:
			return { ...state, userFromRequests: action.payload };

		default: return { ...state };
	}
}
