import React from "react";
import Provider from "./components/provider";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { firestore } from "../../../helpers/Firebase";
import { SetActiveItem } from "../../../redux/activeItem/actions";

const Providers = () => {
  const dispatch = useDispatch();
  const { allProviders } = useSelector(state => state.providers)
  const { activeItem } = useSelector(state => state.activeItem)

  const { user } = useSelector(state => state.authUser)

 

  const selectProvider = async (providerData) => {
    let post = { general: providerData };
    let { addNew, id } = providerData;
    let tapsData = ["professionals", "variations", "users", "defaultSettings"]
    if (!addNew) {
      await Promise.all(tapsData.map(async rec => {
        let snap = await firestore().collection('providers').doc(id).collection(rec).where('deactivated', '!=', true).get()
        if (rec === 'defaultSettings') {
          snap = await firestore().collection('providers').doc(id).collection(rec).get()
        }
        let data = snap?.docs?.reduce((val, currentVal) => {
          if (rec === 'defaultSettings') {
            val[currentVal.id] = { ...currentVal.data() }
          } else {
            val[currentVal.id] = { ...currentVal.data(), id: currentVal.id }

          }
          return val
        }, {})
        post[rec] = data
        return data

      }))
    }
    dispatch(SetActiveItem(post))
  }

  return (
    <div>
      {
        activeItem ?
          <Provider
            authUser={user}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          /> : <MainPage
            title="Providers"
            selected='providers'
            data={allProviders || []}
            setSelectedItem={(e) => selectProvider(e)}
          />

      }
    </div>
  );
};


export default Providers;
