import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
    Collapse,
    Card,
    CardBody,
    CardTitle,
    Row,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    CustomInput,
} from "reactstrap";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import YearsDatePicker from "../../../../components/common/YearsDatePicker";
import { firestore } from "../../../../helpers/Firebase";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deviceWidth, uploadFile } from "../../../../helpers/Utils";
const months = [
    { title: 'Jan', count: '01' },
    { title: 'Feb', count: '02' },
    { title: 'Mar', count: '03' },
    { title: 'Apr', count: '04' },
    { title: 'May', count: '05' },
    { title: 'June', count: '06' },
    { title: 'July', count: '07' },
    { title: 'Aug', count: '08' },
    { title: 'Sept', count: '09' },
    { title: 'Oct', count: '10' },
    { title: 'Nov', count: '11' },
    { title: 'Dec', count: '12' }
]

const Payments = ({ provider }) => {
    const [month, setSelectedMonth] = useState(false)
    const [year, setSelectedYear] = useState(moment().format('YYYY'))
    const [settleModal, setSettleModal] = useState(false)
    const [data, setData] = useState({})
    const [dateRange, setDateRange] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (month) {
            let day = moment(`${month?.count}/01/${year}`).valueOf()
            let startDate = moment(day).startOf('months').startOf('day').valueOf()
            let endDate = moment(day).endOf('months').endOf('day').valueOf()
            setDateRange({ start: startDate, end: endDate })
        }
    }, [month, year])



    useEffect(() => {

        async function fetchData() {
            let ref = firestore().collection('providers').doc(provider.id).collection("SOA")
            let userBookingsSnap = await ref.get()
            let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {
                return {
                    ...res.data(),
                    key: res.id,
                }

            })
            Promise.all(asyncOptionsOnline).then((ress) => {
                setData({ allData: ress, filteredData: [] })
                setLoading(false)

            })
        }

        fetchData();
    }, [provider])


    useEffect(() => {
        let filtered = data?.allData?.reduce((val, currentVal) => {
            // if (currentVal.start >= moment(dateRange.start).valueOf() && currentVal.end <= moment(dateRange.end).valueOf() && !moment().isBetween(moment(currentVal.start), moment(currentVal.end))) {
            if (currentVal.start >= moment(dateRange.start).valueOf() && currentVal.end <= moment(dateRange.end).valueOf()) {
                val.push(currentVal)
            }
            return val
        }, [])
        setData({ ...data, filteredData: filtered })
        setLoading(false)
        // eslint-disable-next-line
    }, [dateRange])

    const calculateMonthTotal = (month, date) => {
        let total = 0
        let day = moment(`${month}/${date ? moment(date).format("DD") : '01'}/${year}`).valueOf()
        let endDate = date ? moment(day).endOf('day').valueOf() : moment(day).endOf('months').endOf('day').valueOf()
        let filtered = data?.allData?.reduce((val, currentVal) => {
            if (currentVal.end <= moment(currentVal?.type === 'settlement' && date ? moment(date).valueOf() : endDate).valueOf()) {
                val.push(currentVal)
            }
            return val
        }, [])
        console.log('filteredfiltered', filtered);
        if (filtered?.length) {
            filtered.map(item => {
                return (
                    total += item.total
                )
            })
        }
        return total
    }

    const submit = async () => {
        let id = firestore().collection('providers').doc().id
        if (id) {
            let date = moment(settleModal?.end).valueOf()
            let post = {
                start: date,
                end: date,
                created: Date.now(),
                total: Number(settleModal?.total),
                notes: settleModal?.notes || '',
                type: "settlement",
                id: id
            }
            // if (attachment && attachment.length > 0 && attachment[0]?.name) {
            //     uploadPicture(attachment, 'providers', provider?.id, 'picture', 'SOA', id)
            // }
            if (settleModal?.attachment?.files) {
                uploadFile(settleModal?.attachment?.files, 'providers', provider?.id, 'attachment', 'SOA', id)
                delete settleModal?.attachment?.files
            }
            await firestore().collection('providers').doc(provider?.id).collection('SOA').doc(id).set(post, { merge: true })

            setData({ allData: [...data?.allData, post], filteredData: [...data.filteredData, post] })

            setSettleModal(false)
            setLoading(false)
        }
    }
    return (
        <Colxx xxs="12" className="mb-4">
            <Card className={'rounded'}>
                <CardBody>
                    <CardTitle className={'d-flex justify-content-between align-items-center mb-2'}>
                        <div>Payments</div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <Button
                                className={'mr-2'}
                                color="success"
                                size="md"
                                onClick={() => {
                                    let total = month ? Math.abs(calculateMonthTotal(month?.count).toFixed(2)) : ''
                                    setSettleModal({ total: total, defaultTotal: total })
                                }}>
                                Settle Payment
                            </Button>
                            <YearsDatePicker updateYear={(e) => { setSelectedYear(e) }} />
                        </div>
                    </CardTitle>
                    <Fragment>
                        {months.filter(r => moment(`${r?.count}/01/${year}`).valueOf() < moment().valueOf()).sort((a, b) => b.count - a.count).map((m) => {
                            return (
                                <div key={m.title} className="mt-2" style={{ border: '1px solid #00000030', borderRadius: '8px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px 25px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {

                                            setSelectedMonth(m)
                                            if (m.title !== month?.title) {
                                                setLoading(true)
                                            }
                                        }}
                                        aria-expanded={m.title === month?.title}>
                                        <div style={{ fontSize: '16px', fontWeight: '700', }}><i className={m.title === month?.title ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} style={{ marginRight: '12px' }} /> {m.title + ' ' + year}</div>
                                        <div style={{ fontSize: '16px', fontWeight: '700', }}>{`AED ${calculateMonthTotal(m?.count).toFixed(2)}`}</div>
                                    </div>
                                    {m.title === month?.title && <Separator />}
                                    <Collapse isOpen={m.title === month?.title}>
                                        {loading && <div className="loading" style={{ position: 'relative', margin: '50px 0' }} />}
                                        {((data?.filteredData)?.length ?
                                            <>
                                                <Row className="d-flex align-items-center ml-4 mr-4 mb-3 mt-4">
                                                    <Colxx xxs="2" className='font-weight-bold' style={{ fontSize: '16px' }}>Payment</Colxx>
                                                    <Colxx xxs="2" className='font-weight-bold' style={{ fontSize: '16px' }}>Transfered On</Colxx>
                                                    <Colxx xxs="2" className='font-weight-bold' style={{ fontSize: '16px' }}>Status</Colxx>
                                                    <Colxx xxs="2" className='font-weight-bold' style={{ fontSize: '16px' }}>Amount</Colxx>
                                                    <Colxx xxs="2" className='font-weight-bold' style={{ fontSize: '16px' }}>SP Balance</Colxx>
                                                    <Colxx className='font-weight-bold' style={{ fontSize: '16px' }}></Colxx>
                                                </Row>
                                                {(data?.filteredData).sort((a, b) => b.end - a.end)?.map((r, ind) => {
                                                    let selectedItem = r
                                                    // let settleItem = data?.allData?.sort((a, b) => b.end - a.end)?.[0]
                                                    return (
                                                        <div key={ind}>
                                                            <Row className="d-flex align-items-center mr-4 ml-4 mt-3 mb-3">
                                                                <Colxx xxs="2" className='font-weight-bold'>
                                                                    <div style={{ fontSize: '14px', color: '#30303070' }}>{`Payment ${(data?.filteredData)?.length - ind}`}</div>
                                                                    <div>{selectedItem.start !== selectedItem.end ? `${moment(selectedItem.start).format("DD/MM/YYYY")} - ${moment(selectedItem.end).format("DD/MM/YYYY")}` : `${moment(selectedItem.start).format("DD/MM/YYYY")}`}</div>
                                                                </Colxx>
                                                                <Colxx xxs="2" className='font-weight-bold'>{moment().isBetween(moment(selectedItem.start), moment(selectedItem.end)) ? '' : moment(selectedItem.end).format('DD/MM/YYYY')}</Colxx>
                                                                <Colxx xxs="2" className='font-weight-bold' style={{ color: selectedItem?.type !== 'settlement' ? '#30303070' : '#28a745' }}>{moment().isBetween(moment(selectedItem.start), moment(selectedItem.end)) ? 'Pending' : (selectedItem?.type === 'settlement' ? "Settlement" : "Rolled over")}</Colxx>
                                                                <Colxx xxs="2" className='font-weight-bold'>{`AED ${Number(selectedItem?.total || 0).toFixed(2)}`}</Colxx>
                                                                <Colxx xxs="2" className='font-weight-bold'>{moment().isBetween(moment(selectedItem.start), moment(selectedItem.end)) ? '' : `AED ${calculateMonthTotal(m?.count, selectedItem.end).toFixed(2)}`}</Colxx>
                                                                <Colxx className='d-flex align-items-center justify-content-end'>
                                                                    {selectedItem?.type === 'settlement' && <Button
                                                                        color="primary"
                                                                        size="md"
                                                                        onClick={() => {
                                                                            setSettleModal({ ...selectedItem, view: true })
                                                                        }}>
                                                                        View
                                                                    </Button>}
                                                                </Colxx>

                                                            </Row>
                                                            {(ind !== data?.filteredData?.length - 1) && <Separator className="ml-4" />}
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            : <div style={{
                                                height: '200px',
                                                display: 'flex',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>No Records</div>)}
                                    </Collapse>
                                </div>
                            )
                        })}

                        {settleModal && <Modal
                            isOpen={settleModal}
                            backdrop="static">
                            <ModalHeader >{<div><span style={{
                                fontWeight: '700',
                            }}>{'Settle Payment'}</span> </div>}</ModalHeader>
                            <ModalBody>
                                {[
                                    {
                                        key: 'general',
                                        title: '',
                                        data: [
                                            { key: 'total', title: 'Settlement Amount', width: '12', type: 'number', value: '', disabled: settleModal?.view },
                                            { key: 'end', title: 'Settlement Date', width: '12', type: 'date', value: '', disabled: settleModal?.view },
                                            { key: 'notes', title: 'Additional Notes', width: '12', type: 'textarea', optional: true },
                                            { key: 'attachment', title: 'Attachments', width: '12', type: 'dropzone', value: '' },
                                        ]
                                    }].map((rec, recIndex) => {
                                        return (
                                            <Row key={recIndex}>
                                                <Colxx xxs="12" className="mt-2 mb-2">
                                                    <h4>{rec.title}</h4>
                                                </Colxx>
                                                {rec.data.map((item, index) => {
                                                    return !item.hide && (
                                                        <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                            <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }} >
                                                                <div className="d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                                                                    <div>{item.title}</div>
                                                                    {item.optional && <span
                                                                        style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                                                        className={`ml-1 badge badge-pill badge-light badge-top-left`}>
                                                                        Optional
                                                                    </span>}
                                                                </div>

                                                            </div>
                                                            {item.type === 'date' ?
                                                                <DatePicker
                                                                    customInput={<CustomInput />}
                                                                    selected={settleModal?.[item.key]}
                                                                    onChange={(e) => {
                                                                        setSettleModal({ ...settleModal, [item.key]: e, updated: true })
                                                                    }}
                                                                    maxDate={moment().valueOf()}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    withPortal={deviceWidth() !== 'desktop'}
                                                                /> : item.type === 'dropzone' ?
                                                                    <InputGroup>
                                                                        <CustomInput
                                                                            type="file"
                                                                            id="exampleCustomFileBrowser4"
                                                                            name="customFile"
                                                                            disabled={settleModal?.view}
                                                                            label={settleModal?.[item.key]?.name}
                                                                            onChange={async ({ target: { files } }) => {
                                                                                const [{ name }] = files;
                                                                                setSettleModal({ ...settleModal, [item.key]: { name: name, files: files }, updated: true })
                                                                            }}
                                                                        />
                                                                        {settleModal?.[item.key] && !settleModal?.view ? <InputGroupAddon addonType="append">
                                                                            <Button outline color="danger" onClick={() => {
                                                                                delete settleModal?.[item.key]
                                                                                setSettleModal({ ...settleModal, updated: true })
                                                                            }}>
                                                                                <i className="glyph-icon simple-icon-trash" />
                                                                            </Button>
                                                                        </InputGroupAddon> : ''}
                                                                        {settleModal?.[item.key]?.license ? <InputGroupAddon addonType="append">
                                                                            <Button outline color="primary" onClick={() => {
                                                                                window.open(settleModal?.[item.key]?.license, '_blank')
                                                                            }}>
                                                                                <i className="glyph-icon simple-icon-eye" />
                                                                            </Button>
                                                                        </InputGroupAddon> : ''}
                                                                    </InputGroup>
                                                                    :
                                                                    <InputGroup>
                                                                        {item.key === 'total' && <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>AED</InputGroupText>
                                                                        </InputGroupAddon>}
                                                                        <Input
                                                                            disabled={item.disabled}
                                                                            type={item.type}
                                                                            placeholder={"e.g. " + item.title}
                                                                            value={settleModal?.[item.key]}
                                                                            className="customInput"
                                                                            onChange={(e) => {
                                                                                setSettleModal({ ...settleModal, [item.key]: e.target.value, updated: true })
                                                                            }} />

                                                                        {settleModal?.total !== settleModal?.defaultTotal && item.key === 'total' && !settleModal?.view ? <InputGroupAddon addonType="append">
                                                                            <Button
                                                                                onClick={() => {

                                                                                    setSettleModal({ ...settleModal, total: settleModal?.defaultTotal })
                                                                                }}
                                                                                outline style={{ background: '#fff', zIndex: '0' }} color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>

                                                                        </InputGroupAddon> : ''}
                                                                    </InputGroup>
                                                            }
                                                        </Colxx>
                                                    )
                                                })}
                                            </Row>
                                        )
                                    })}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" outline onClick={() => setSettleModal(false)}>
                                    Cancel
                                </Button>
                                {!settleModal?.view && <Button
                                    disabled={!settleModal?.total}
                                    color="primary" outline onClick={() => {
                                        submit()
                                    }} >Apply</Button>}
                            </ModalFooter>
                        </Modal>}
                    </Fragment>
                </CardBody>
            </Card>
        </Colxx>
    );
};

export default Payments;