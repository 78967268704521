import React, { Fragment } from "react";
import { getFirstCharacters } from "../../helpers/Utils";
import moment from "moment";

const ChatHeading = ({ name, thumb, wa_id, selectedUser }) => {
  let chatMessages = selectedUser?.chatMessages?.sort((msg1, msg2) => msg1?.created - msg2?.created);
  const lastMessage = chatMessages?.[chatMessages?.length - 1];
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-row chat-heading">
        <div className="d-flex">
          <div
            style={{
              background: '#3498db',
              padding: '5px',
              color: '#fff',
              width: '50px',
              height: '50px',
              minWidth: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: '500',
              borderRadius: '200px',
              letterSpacing: '.06em'
            }}
            className="mr-3"
          >{getFirstCharacters(name || 'Metadoc Team')}</div>
        </div>
        <div className=" d-flex min-width-zero">
          <div className="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
            <div className="min-width-zero">
              <div className="list-item-heading mb-1 truncate ">{name}</div>
              <p className="mb-0 truncate text-muted text-small">{moment(lastMessage?.created).format('DD/MM/YYYY hh:mm A')}</p>

              {/* {checkTimeRange() && <p className="mb-0 text-muted text-small">
                +{wa_id}
              </p>} */}
            </div>
          </div>
        </div>
      </div>
      <div className="separator mb-5" />
    </Fragment>
  );
};

export default ChatHeading;
