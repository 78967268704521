import React, { Fragment, useEffect, useState } from "react";
import Booking from "./components/booking";
import NewBooking from "./components/newBooking";
import ManageOrder from "./components/ManageOrder";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import moment from "moment";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import ReactTable from "react-table";
import AcceptOrderModal from "../../../components/common/AcceptOrderModal";
import OrdersFilter from "../../../components/common/ordersFilter";
import DataTablePagination from "../../../components/DatatablePagination";
import { deviceWidth } from "../../../helpers/Utils";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { MenuItem } from "@trendmicro/react-dropdown";
import classNames from "classnames";

const Bookings = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authUser)
  const [assignedModal, setAssignedModal] = useState(false)
  const { allPartners } = useSelector(state => state.partners)

  const { requests, assignedRequests } = useSelector(state => state.bookings)
  const { activeItem } = useSelector(state => state.activeItem)
  const [acceptOrderModal, setAcceptOrderModal] = useState(false)
  const tabs = [
    { name: 'Pending Payment', index: 1, selected: 'notPaid', type: 'status', notes: true, status: ['new'], hide: user.role !== 'superAdmin' },
    { name: 'All Requests', index: 2, selected: 'requests', type: 'status', notes: true, status: ['pending'] },
    { name: 'Requests with notes', index: 3, selected: 'notes', type: 'status', notes: true, title: 'Requests with notes', status: ['pending'] }
  ]
  const [activeTab, setActiveTab] = useState(tabs[1])

  const status = {
    new: { label: "Pending Payment", status: 'new', color: "#c43d4b", action: 'Accept', nextStatus: acceptOrderModal?.provider?.id ? 'accepted' : 'pending' },
    pending: { label: "Pending Assigment", status: 'pending', nextStatus: 'accepted', color: "#3498db", action: 'Accept' },
    accepted: { label: "Accepted", status: 'accepted', nextStatus: 'ontheway', color: "#145388", action: 'On the way' },
    ontheway: { label: "On The Way", status: 'ontheway', nextStatus: 'active', color: "#3e884f", action: 'Arrive' },
    active: { label: "Session Started", status: 'active', nextStatus: 'completed', color: "#3195a5", action: 'Complete' },
    completed: { label: "Completed", status: 'completed', nextStatus: 'completed', color: "#3e884f", action: '' },
    rated: { label: "Rated", status: 'rated', color: "#b69329", action: '' },
    canceled: { label: "Canceled", status: 'canceled', color: "#c43d4b", action: 'Cancel' },
    rejected: { label: "Rejected", status: 'rejected', color: "#c43d4b", action: 'Reject' },
  }

  const [selectedFilter, setFilter] = useState({})
  const [filterOpen, setFilterOpen] = useState(false)
  const search = useSelector(state => state.settings.search) || ''
  const [filteredItems, setFilteredItems] = useState({})
  const authUser = useSelector(state => state.authUser)
  const toggleModal = () => {
    dispatch(SetActiveItem({ general: { addNew: true } }))
  };
  const onClickItem = (item) => {
    dispatch(SetActiveItem({ general: item }))
  };
  const filter = (selectedFilter) => {
    let filteredBookings = []
    filteredBookings = Object.values(requests || {})?.filter(item => {
      return (
        (String(item?.orderNumber)?.includes(search?.toLowerCase()) || (item?.contact?.name)?.toLowerCase()?.includes(search?.toLowerCase())) &&
        (selectedFilter?.service?.length ? selectedFilter.service.includes(item?.service?.id) : true) &&
        (selectedFilter?.partners?.length ? selectedFilter.partners.includes(item?.brand?.id) : true) &&
        (selectedFilter?.provider?.length ? selectedFilter.provider.includes(item?.provider?.id) : true) &&
        (selectedFilter?.professional?.length ? selectedFilter.professional.includes(item?.professional?.id) : true) &&
        (selectedFilter?.status?.length ? selectedFilter.status.includes(item.status) &&
          (selectedFilter.notes ? (selectedFilter.selected === 'notes' ? item.notes : true) : true) : true)
      )
    }).concat(assignedRequests || [])
    setFilteredItems(filteredBookings)
  }

  useEffect(() => {
    if (selectedFilter.status) {
      filter(selectedFilter)
    }
    // eslint-disable-next-line 
  }, [selectedFilter, requests, search, assignedRequests])
  const bookingDataColumns = [
    { Header: "Order ID", accessor: "orderNumber", type: 'label' },
    { Header: "Created", accessor: "created", type: 'date' },
    { Header: "Start at", accessor: "bookingDate.date", type: 'date' },
    { Header: "Contact", accessor: "contact.name", type: 'label' },
    { Header: "Assigned Provider", accessor: "provider.name", type: 'label', hide: (user.role !== 'superAdmin' ? true : false) },
    { Header: "Assigned Professional", accessor: "professional.name", type: 'label', hide: (user.role !== 'superAdmin' ? true : false) },
    { Header: "Area", accessor: "location.area", type: 'label' },
    { Header: "Selected Service", accessor: "service.name", type: 'label' },
    { Header: "Status", accessor: "status", type: 'status' },
    { Header: "Payment", accessor: "paid", type: 'payment', hide: (user.role !== 'superAdmin' ? true : false) || selectedFilter.selected === 'notPaid' },
    { Header: "", width: 20, accessor: "manage", func: (e) => onClickItem(e), color: "dark", title: <i className="simple-icon-options-vertical" />, type: 'button' },
  ]
  const dataTableColumns = () => {
    let array = (bookingDataColumns).map(rec => {
      return (
        {
          Header: rec.Header,
          accessor: rec.accessor,
          width: deviceWidth() === 'desktop' ? (rec.width || 'auto') : 110,
          resizable: false,
          show: rec.hide ? false : true,
          Cell: props => <div className="list-item-heading" style={{ width: '100%' }}>
            {rec.type === 'label' ?
              <div className="list-item-heading" style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineClamp: 2,
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>{props.value?.en || props.value || (rec.Header === "Assigned Professional" ? "Unassigned" : "--")}</div>
              : rec.type === 'payment' ?
                <div className="list-item-heading" style={{ color: props.value ? '#3e884f' : "#c43d4b", fontSize: '11px', fontWeight: '700' }}>{props.value ? 'Paid' : "Not Paid"}</div>
                : rec.type === 'status' ?
                  <div className="list-item-heading" style={{ color: status[props.value].color }}>{status[props.value].label}</div>
                  : rec.type === 'date' ?
                    <div className="list-item-heading" style={{ color: rec.accessor !== 'created' && '#87cefa', fontWeight: rec.accessor !== 'created' && 'bold' }}>{moment(props.value).format('DD/MM/YYYY')}<br />{moment(props.value).format('hh:mm A')}</div>
                    : rec.type === 'button' ?
                      <div className="providerfieldText" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {user?.role !== 'concierge' && !props.original?.alreadyAssigned ?
                          <div className="cursor__pointer" style={{ position: 'absolute' }} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation();
                          }}>
                            <Dropdown>
                              <Dropdown.Toggle btnStyle="link" noCaret>
                                <i className="simple-icon-options" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{ left: '-140px', width: '150px' }}>
                                {user?.role === 'superAdmin' ? <MenuItem
                                  disabled={['ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                  onSelect={() => {
                                    rec.func({ ...props.original, manageOrder: 'reschedule' })
                                  }}
                                >
                                  Reschedule
                                </MenuItem> : ''}

                                {user?.role === 'superAdmin' ? <MenuItem
                                  disabled={['ontheway', 'active', 'completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                  onSelect={() => {
                                    rec.func({ ...props.original, manageOrder: 'reassign' })
                                  }}
                                >
                                  Reassign
                                </MenuItem> : ''}


                                <MenuItem
                                  disabled={['canceled', 'rejected']?.includes(props.original.status)}
                                  onSelect={() => {
                                    rec.func({ ...props.original, manageOrder: 'addons' })
                                  }}
                                >
                                  Addons
                                </MenuItem>
                                {user?.role === 'superAdmin' ? <MenuItem
                                  disabled={['completed', 'rated', 'canceled', 'rejected']?.includes(props.original.status)}
                                  onSelect={() => {
                                    rec.func({ ...props.original, manageOrder: 'status' })
                                  }}
                                >
                                  Status
                                </MenuItem> : ''}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div> : ''}
                      </div>
                      : ''
            }
          </div>
        }
      )
    })
    return array
  }
  return (
    <Fragment>
      {
        activeItem && (activeItem?.general?.addNew || activeItem?.general?.manageOrder) ?

          (activeItem?.general?.addNew ? <NewBooking
            authUser={authUser}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}
          /> : <ManageOrder
            authUser={authUser}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          />) : (activeItem && <Booking
            authUser={authUser}
            selectedItem={activeItem}
            dismiss={() => {
              dispatch(SetActiveItem(false))
            }}

          />)

      }
      {(!activeItem?.general?.addNew) && <div className={`app-row survey-app  ${filterOpen ? '' : "shown"}`}>
        <ListPageHeading
          heading={'Requests'}
          toggleModal={toggleModal}
          hide={user.role === 'superAdmin' ? false : (user.role === 'admin' || allPartners?.[0]?.customLocations)}
          fromModifiers={true}

        />
        <Nav tabs className="separator-tabs ml-0 mb-5">
          {
            tabs.map(item => {
              return !item.hide && (
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    color: item.selected === 'notPaid' && requests?.filter(r => r.status === 'new')?.length ? '#dc3545' : '',
                  }}
                  key={item.index}
                  className={classNames({
                    active: activeTab.index === item.index,
                    "nav-link": true
                  })}
                  onClick={() => {
                    setActiveTab(item);
                    setFilter({ ...selectedFilter, ...item })
                  }}>
                  {item.name}
                  {requests && requests.filter(x => item.status.includes(x.status) && (item.notes ? (item.selected === 'notes' ? x.notes : true) : true)).length ?
                    <div style={{
                      fontSize: '10px',
                      border: '1px solid',
                      borderRadius: '10px',
                      width: '18px',
                      height: '18px',
                      marginLeft: '8px',
                      fontWeight: '700',
                      display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                      className={classNames({
                        active: activeTab.index === item.index,
                      })}>  <span className="float-right">
                        {requests && requests.filter(x => item.status.includes(x.status) && (item.notes ? (item.selected === 'notes' ? x.notes : true) : true)).length}
                      </span>
                    </div> : ''}

                </div>
              )
            })
          }
        </Nav>
        <Row className="survey-app">
          <Colxx xxs="12">
            {
              filteredItems && filteredItems.length ?
                <ReactTable
                  data={filteredItems}
                  columns={dataTableColumns()}
                  defaultPageSize={10}
                  pageSize={filteredItems.length < 10 ? filteredItems.length : 10}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className={"bookingsReactTable"}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: e => {
                        if (!rowInfo?.original?.alreadyAssigned) {
                          dispatch(SetActiveItem({ general: rowInfo.original }))
                        } else {
                          setAssignedModal(true)
                        }
                      }, style: {
                        border: rowInfo?.original?.alreadyAssigned ? `#c1c1c1` : '',
                        borderRadius: rowInfo?.original?.alreadyAssigned ? `16px` : '',
                        background: rowInfo?.original?.alreadyAssigned ? `#c1c1c185` : '',
                      }
                    }
                  }}

                  defaultSorted={[
                    {
                      id: "created",
                      desc: true
                    }
                  ]}
                />
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', width: '100%' }}>No Requests</div>
            }
          </Colxx>
        </Row>

      </div>}
      {acceptOrderModal && <AcceptOrderModal booking={acceptOrderModal} orderID={acceptOrderModal?.id} status={status} success={(e) => {
        setAcceptOrderModal(false)
      }}
        dismiss={() => {
          setAcceptOrderModal(false)
        }} />}

      <OrdersFilter
        setFilterOpen={(e) => setFilterOpen(e)}
        activeServices={[]}
        washers={[]}
        todoItems={requests || []}
        filter={(selectedFilter) => {
          setFilter({ ...selectedFilter, ...activeTab })
        }}
        bookingType={activeTab?.selected}
      />
      <Modal backdrop="static" isOpen={assignedModal}>
        <ModalHeader >
          Already Assigned
        </ModalHeader>
        <ModalBody>
          <Row>
            <Colxx xxs="12" >
              {/* <Label>We apologize, but this order has already been assigned to another provider. We appreciate your interest and apologize for any inconvenience this may cause.</Label> */}
              <Label>Sorry, this order has already been claimed by another provider. Thanks for considering us and we hope you have a great day!</Label>
            </Colxx>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => {
            setAssignedModal(false)
          }}>Dismiss</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};


export default Bookings;
