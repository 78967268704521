import React, { Component } from "react";
import {
    Row,
    Button,
    Input,
    Label,
    Nav,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    ModalFooter,
    Card,
} from "reactstrap";
import Script from 'react-load-script';
import Services from "./services";
import Providers from "./providers";

import { Colxx, Separator } from "../../../../../components/common/CustomBootstrap";
import { firestore } from '../../../../../helpers/Firebase'

import axios from 'axios'
import { connect } from "react-redux";
import { UpdateItem } from "../../../../../redux/activeItem/actions";
import classnames from "classnames";
import { createNotification, makeid } from "../../../../../helpers/Utils";
import Map from "./map";

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: null,
            search: '',
            tabs: [
                { name: 'Areas Covered', key: 1 },
                { name: 'Services', key: 2 },
                { name: 'Providers', key: 3 },
            ],
            activeTab: 1,
            pathCoordinates: []


        };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    }

    componentWillReceiveProps(prop) {
        if (prop.selectedLocation !== this.props.selectedLocation) {
            this.getList()
        }
    }
    componentWillMount() {
        this.getList()

    }
    getList() {
        let { selectedLocation } = this.props
        this.setState({ list: selectedLocation })
    }

    render() {
        let { list, tabs, activeTab, pathCoordinates } = this.state
        let { dismiss } = this.props

        return (
            <Modal
                backdrop="static" isOpen={true}
                size="lg">
                <ModalBody>
                    <Row>
                        <Colxx xxs="12">
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return !item.hide && (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    this.setState({ activeTab: item.key })
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Label>
                                        {"Cluster Title"}
                                    </Label>
                                    <Input
                                        type="text"
                                        value={list?.title}
                                        onChange={event => {
                                            list.title = event.target.value
                                            list.updated = true
                                            this.setState({ list });
                                        }}

                                    />
                                    <Card className={'rounded mt-2'} style={{ overflow: 'hidden' }}>
                                        <Map geojson={pathCoordinates} />
                                    </Card>
                                    <div className='mt-4'>
                                        <Label>
                                            {"Select areas you serve"}
                                        </Label>
                                        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o"
                                            onLoad={this.handleScriptLoad}
                                        />
                                        <Input
                                            id='autocomplete'
                                            type="text"
                                            value={this.state.search}
                                            onChange={event => {
                                                this.setState({ search: [event.target.value] });
                                            }}

                                        />
                                    </div>

                                    {
                                        list && list.areas_list && Object.values(list.areas_list).map(areaTitle => {
                                            return (
                                                <div id={areaTitle?.id} key={areaTitle?.id}>
                                                    <Row className='p-2 d-flex align-items-center' onClick={() => { }}>
                                                        <Colxx className='w-80'>
                                                            <div>
                                                                {areaTitle?.title}</div>
                                                        </Colxx>
                                                        <Colxx className='w-20'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <Button style={{ margin: '4px' }} outline color='danger' onClick={() => {
                                                                    list.areas_list = list.areas_list?.filter((r) => r.id !== areaTitle.id)
                                                                    this.setState({ list })

                                                                }} size='xs'>Delete</Button>
                                                                <Button outline color='dark' onClick={async () => {
                                                                    let mapref = await firestore().collection('locations').doc(areaTitle?.id).get()
                                                                    if (mapref.exists) {
                                                                        this.setState({ pathCoordinates: mapref.data()?.geojson })
                                                                    } else {
                                                                        let coordinates;
                                                                        let geojson = list.locations?.[areaTitle?.id]?.geojson
                                                                        if (geojson?.type === "MultiPolygon") {
                                                                            coordinates = {}
                                                                            geojson && geojson.coordinates && geojson.coordinates.map(recc => {
                                                                                let polys = recc?.[0]?.reduce((val, currnetVal) => {
                                                                                    let latitude = currnetVal?.[1]
                                                                                    let longitude = currnetVal?.[0]
                                                                                    val.push({ latitude, longitude })
                                                                                    return val
                                                                                }, [])
                                                                                return coordinates[makeid()] = polys
                                                                            })
                                                                        } else {
                                                                            coordinates = geojson?.coordinates?.[0]?.reduce((val, currnetVal) => {
                                                                                let latitude = currnetVal?.[1]
                                                                                let longitude = currnetVal?.[0]
                                                                                val.push({ latitude, longitude })
                                                                                return val
                                                                            }, [])
                                                                        }


                                                                        this.setState({ pathCoordinates: { ...geojson, coordinates } })
                                                                    }
                                                                }} size='xs'>Show On Map</Button>
                                                            </div>
                                                        </Colxx>
                                                    </Row>
                                                    <Separator />
                                                </div>
                                            )
                                        })
                                    }

                                </TabPane>
                                <TabPane tabId={2}>
                                    <Services selectedLocation={list} update={(e) => {
                                        list.variations = e
                                        list.updated = true
                                        this.setState({ list: JSON.parse(JSON.stringify(list)) })
                                    }} />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Providers selectedLocation={list} update={(e) => {
                                        list.providers = e
                                        list.updated = true
                                        this.setState({ list: JSON.parse(JSON.stringify(list)) })
                                    }} />
                                </TabPane>

                            </TabContent>

                        </Colxx>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => {
                        this.setState({ list: false })
                        dismiss()
                    }}>Cancel</Button>
                    <Button disabled={!list || !list.areas_list} onClick={() => {
                        this.addList()
                    }}>Save</Button>
                </ModalFooter>


            </Modal>
        )
    }
    addList() {
        let { list } = this.state
        let { activeItem, dismiss } = this.props
        let locationID = list?.id || firestore().collection('locations').doc().id;

        // return 
        if (list?.locations || list.addNew) {
            Object.keys(list?.locations || {}).map(async (r) => {
                let location = list?.locations?.[r]
                let coordinates;
                if (location?.geojson?.type === "MultiPolygon") {
                    coordinates = {}
                    location.geojson && location.geojson.coordinates && location.geojson.coordinates.map((recc, index) => {
                        let polys = recc?.[0]?.reduce((val, currnetVal) => {
                            let latitude = currnetVal?.[1]
                            let longitude = currnetVal?.[0]
                            val.push({ latitude, longitude })
                            return val
                        }, [])
                        return coordinates["polygon_" + String(index)] = polys
                    })
                } else {
                    coordinates = location?.geojson?.coordinates?.[0]?.reduce((val, currnetVal) => {
                        let latitude = currnetVal?.[1]
                        let longitude = currnetVal?.[0]
                        val.push({ latitude, longitude })
                        return val
                    }, [])
                }


                let post = {
                    display_name: location?.display_name,
                    geojson: { ...location?.geojson, coordinates },
                    osm_id: location?.osm_id,
                    osm_type: location?.osm_type,
                    place_id: location?.place_id,
                    type: location?.type,

                }
                await firestore().collection('locations').doc(r).set(post, { merge: true }).then(() => {
                    delete list.locations
                    this.props.UpdateItem({ key: 'general', val: { ...activeItem?.general, updated: true } })
                    this.props.UpdateItem({ key: 'locations', val: { ...activeItem?.locations, [locationID]: { ...list, id: locationID } } })

                    dismiss()
                    this.setState({ list: false })
                })
            })
        } else {
            this.props.UpdateItem({ key: 'general', val: { ...activeItem?.general, updated: true } })
            this.props.UpdateItem({ key: 'locations', val: { ...activeItem?.locations, [locationID]: { ...list, id: locationID } } })
            dismiss()
            this.setState({ list: false })
        }


    }
    handleScriptLoad() {
        // Declare Options For Autocomplete 
        // var circle = new google.maps.Circle(
        //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });
        var options = {
            types: ['(regions)'],
            componentRestrictions: { country: 'ae' },
            // language: 'en'
        };

        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options);
        // this.autocomplete.setBounds(circle.getBounds());

        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',
            'formatted_address', 'geometry', 'type', 'name', 'vicinity', 'place_id']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect);
    }
    async handlePlaceSelect() {
        let { list } = this.state
        let _this = this
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        // Check if address is valid
        let { formatted_address } = addressObject

        let polygonResults = await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${formatted_address}&polygon_geojson=1&format=json`)
        if (polygonResults?.data) {
            let data = polygonResults?.data?.find(r => ['boundary', 'place'].includes(r.class) && ["suburb", 'administrative', 'neighbourhood'].includes(r.type))

            if (data) {
                let polygon = String(data?.place_id)
                let polygonTitle = data?.display_name
                list.areas_list = list.areas_list || []
                list.areas_list.push({ id: polygon, title: polygonTitle })
                list.locations = list?.locations || {}
                list.locations[polygon] = data
                list.updated = true
                _this.setState(
                    {
                        list,
                        search: '',
                    }
                );
            } else {
                createNotification('error', 'Could not get this location', 'Error')
            }

        }
    }
}

const mapStateToProps = ({ activeItem }) => {
    return {
        activeItem: activeItem?.activeItem
    };
};
const mapActionsToProps = (dispatch) => ({
    UpdateItem: (data) => dispatch(UpdateItem(data)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(Start);


