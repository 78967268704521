import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainPage from "../../../components/common/MainPage";
import { SetActiveItem } from "../../../redux/activeItem/actions";
import Promo from "./components/promo";

const ActiveHelper = () => {
  const dispatch = useDispatch();
  const { activeItem } = useSelector(state => state.activeItem)
  const data = useSelector(state => state.settings?.offers)
  const authUser = useSelector(state => state.authUser)


  const selectHelper = async (helperData) => {
    let post = { general: helperData };
    dispatch(SetActiveItem(post))
  }

  return (
    <div>
      {activeItem && <Promo
        authUser={authUser}
        selectedItem={activeItem}
        dismiss={() => {
          dispatch(SetActiveItem(false))
        }}
      />}
      <MainPage
        title="Promocodes"
        selected={'offers'}
        data={data?.filter(r => !r.affiliate) || []}
        setSelectedItem={(e) => selectHelper(e)}
      />
    </div>
  );
};

export default ActiveHelper;