import React from "react";
import start from '../../assets/css/img/star.png'
import startActive from '../../assets/css/img/staractive.png'
const Rating = props => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
    {
      [1, 2, 3, 4, 5].map((item) => {
        return (item <= Number(props.rating) ?
          <img style={{ width: '13px', marginRight: '2px' }} key={item} src={startActive} alt="" /> :
          <img style={{ width: '13px', marginRight: '2px' }} key={item} src={start} alt="" />)
      })
    }

  </div>

);


export default Rating;