import {
    GET_REQUESTS,
    GET_APPOINTMENTS,
    GET_DELAYED_APPOINTMENTS,
    GET_ASSIGNED_REQUESTS
} from '../actions';


export const GetAllRequests = (data) => {
    return (
        {
            type: GET_REQUESTS,
            payload: data
        }
    )
}

export const GetAssignedRequests = (data) => {
    return (
        {
            type: GET_ASSIGNED_REQUESTS,
            payload: data
        }
    )
}
export const GetAllAppointments = (data) => {
    return (
        {
            type: GET_APPOINTMENTS,
            payload: data
        }
    )
}



export const GetDelayedAppointments = (data) => {
    return (
        {
            type: GET_DELAYED_APPOINTMENTS,
            payload: data
        }
    )
}

