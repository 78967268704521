import React, { Fragment, useEffect, useState } from "react";
import {
    Row,
    Nav,
    TabContent,
    TabPane,
    Input,
    Button,
    Card,
    CardBody,
    CustomInput,
} from "reactstrap";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";

import classnames from "classnames";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import { firestore, functions } from '../../../../helpers/Firebase'
import Services from "../../../../components/common/services";
import Banners from "../../../../components/common/banners";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveItem, UpdateItem } from "../../../../redux/activeItem/actions";
import Users from "../../../../components/common/users";
import Location from './locations'
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { ReverseGeocode, checkIfDisabled, createNotification, getAddressDetails, uploadPicture } from "../../../../helpers/Utils";
import TranslatePopup from "../../../../components/common/translatePopup";


const refs = {
    map: undefined,
}
const MapWithAMarker = withScriptjs(
    withGoogleMap(props => {
        return (
            <GoogleMap
                ref={(ref) => refs.map = ref}
                defaultZoom={15}
                options={{ disableDefaultUI: true }}
                onDragEnd={() => {
                    props.getAddressFromLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })
                }}
                onCenterChanged={() =>
                    props.updateLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })}
                onClick={(e) => props.updateLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
                center={props.latLng || {
                    lat: 25.1903,
                    lng: 55.2747,
                }}
                defaultCenter={props.latLng || {
                    lat: 25.1903,
                    lng: 55.2747,
                }}>
                <SearchBox
                    style={{ left: '200px' }}
                    ref={(ref) => refs.searchBox = ref}
                    controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                    onPlacesChanged={props.onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            marginTop: `27px`,
                            marginLeft: `27px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                        }}
                    />
                </SearchBox>
                <Marker position={props.latLng} />

            </GoogleMap>
        )
    }

    )
);

const Partner = ({
    authUser,
    dismiss,
}) => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const { activeItem } = useSelector(state => state.activeItem)
    const { brands } = useSelector(state => state.settings)
    const [translateObject, showTranslatePopup] = useState(false)

    const dispatch = useDispatch()
    const [partnerPictures, setPartnerPictures] = useState([])
    const [updatedPartner, UpdatedPartner] = useState(activeItem?.general || {})
    const { user } = useSelector(state => state.authUser)

    const [tabs] = useState([
        { name: 'General Information', key: 1 },
        { name: 'Admins', key: 2 },
        { name: 'General Apperance', key: 3 },
        // { name: 'Services Categories', key: 4, hide: updatedPartner?.syncMetadoc },
        { name: 'Services Categories', key: 4 },
        { name: 'Banners', key: 5 },
        // { name: 'Locations', key: 6, hide: updatedPartner?.syncMetadoc || user.role !== 'superAdmin' },
        { name: 'Locations', key: 6, hide: user.role !== 'superAdmin' },
        { name: 'Settings', key: 7 },

    ]);

    useEffect(() => {
        dispatch(UpdateItem({ key: 'general', val: updatedPartner }))
        // eslint-disable-next-line
    }, [updatedPartner])

    const onDrop = (picture, type) => {
        let pictures = []
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                1920,
                1080,
                'jpeg',
                60,
                0,
                uri600 => {
                    pictures.push({
                        name: '600',
                        data: uri600
                    });
                    setPartnerPictures(pictures)

                },
                'blob'
            );

        } else {
            setPartnerPictures(pictures)


            UpdatedPartner({ ...updatedPartner, [type]: [], updated: true })
        }
    }

    const submit = async () => {
        let { general } = JSON.parse(JSON.stringify(activeItem));

        let id = general.id || firestore().collection('partners').doc().id;

        if (general) {
            if (general.addNew) {
                general.active = true
                general.created = Date.now()
            }
            delete general.addNew
            delete general.updated
            delete general.password

            general.deactivated = false
            general.lastUpdate = Date.now()

            if (partnerPictures && partnerPictures.length > 0 && partnerPictures[0].name) {
                uploadPicture(partnerPictures, 'partners', id, 'picture')
            }
            await firestore().collection('partners').doc(id).set(general, { merge: true })
        }
        ['users', 'variations', 'banners', 'locations', 'settings'].map(async (item) => {
            let data = activeItem?.[item];
            if (data && Object.values(data).length) {
                let updatedData = Object.values(data).filter(r => r.updated)
                await Promise.all(updatedData.map(async rec => {
                    let Ref = firestore().collection('partners').doc(id).collection(item)
                    let documentID = rec?.id || Ref.doc().id;
                    if (rec.deleted) {
                        if (item === 'users') {
                            const deleteUser = functions().httpsCallable('deleteAccount');
                            await deleteUser({ uid: documentID, parent_id: id })
                        }
                        await Ref.doc(documentID).delete();
                    } else {
                        if (rec.addNew) {
                            rec.active = true
                            rec.created = Date.now()
                            if (item === 'users') {
                                const createUser = functions().httpsCallable('createAccounts');
                                let result = await createUser({ uid: documentID, email: rec.email, pw: rec.password, role: 'concierge', parent_id: general?.url, brand_id: id })

                                if (result?.data?.errorInfo) {
                                    return createNotification("error", result?.data?.errorInfo?.message, 'Error creating user ' + rec.email)
                                }
                            }
                        }
                        delete rec.addNew
                        delete rec.updated
                        delete rec.password

                        rec.lastUpdate = Date.now()

                        if (item === 'users') {
                            rec.partner_id = id
                            rec.partner_name = general?.name
                            rec.deactivated = false
                        } else {
                            delete rec.label
                            delete rec.value
                            delete rec.key
                        }
                        if (rec.pictures && rec?.pictures?.length > 0 && rec?.pictures?.[0]?.name) {
                            uploadPicture(rec.pictures, 'partners', id, 'picture', 'banners', documentID)
                            delete rec.pictures
                        }
                        // if (item === 'banners' || item === 'locations') {
                        await Ref.doc(documentID).set(rec);
                        // } else {
                        //     await Ref.doc(documentID).set(rec, { merge: true });

                        // }
                    }

                }))
            }
        })
        dispatch(SetActiveItem(false))
        setLoading(false)
    }

    return (
        (
            loading ? (
                <div className="loading" />
            ) : (
                <Fragment>
                    <Row>
                        <Colxx xxs="12">
                            <h1>{updatedPartner?.addNew ? 'New Partner' : updatedPartner?.name?.en}</h1>
                            <Nav tabs className="separator-tabs ml-0 mb-5">
                                {
                                    tabs.map(item => {
                                        return !item.hide && (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                key={item.key}
                                                className={classnames({
                                                    active: activeTab === item.key,
                                                    "nav-link": true
                                                })}
                                                onClick={() => {
                                                    setActiveTab(item.key);
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {[{
                                                key: 'generalInfo',
                                                title: 'General Information',
                                                data: [
                                                    { key: 'name', title: 'Name', width: '10', type: 'text', translateButton: true },
                                                    { key: 'details', title: 'Details', width: '10', type: 'textarea', translateButton: true },
                                                    { key: 'parent_id', title: 'Parent Brand', width: '10', type: 'textarea' }]
                                            },
                                            {
                                                key: 'addressInfo',
                                                title: 'Address Information',
                                                data: [
                                                    { key: 'address', parent: 'address', title: 'Address', width: '10', type: 'text', translateButton: true },
                                                    { key: 'area', parent: 'address', title: 'Area', width: '10', type: 'text', translateButton: true },
                                                    { key: 'building', parent: 'address', title: 'Building', width: '10', type: 'text', translateButton: true }
                                                ]
                                            }].map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2 ">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>
                                                        {rec.key === 'addressInfo' &&
                                                            <Colxx xxs="10" className="mb-4">
                                                                {
                                                                    <MapWithAMarker
                                                                        onPlacesChanged={() => {
                                                                            let locations = refs.searchBox.getPlaces()[0]
                                                                            let location = locations?.geometry?.location
                                                                            let latLng = { lat: location.lat(), lng: location.lng() }

                                                                            let loc = getAddressDetails(locations, latLng)
                                                                            let addressDetails = {}
                                                                            Object.keys(loc).map(l => {
                                                                                return addressDetails[l] = { ...updatedPartner.address?.[l], en: loc?.[l] }
                                                                            })
                                                                            console.log('addressDetailsaddressDetails', addressDetails);
                                                                            UpdatedPartner({
                                                                                ...updatedPartner,
                                                                                address: {
                                                                                    ...updatedPartner.address,
                                                                                    ...addressDetails,
                                                                                    latLng: latLng,
                                                                                },
                                                                                updated: true
                                                                            })
                                                                        }}
                                                                        // onPlacesChanged={() => {
                                                                        //     let locations = refs.searchBox.getPlaces()[0]
                                                                        //     let location = locations?.geometry?.location
                                                                        //     let latLng = { lat: location.lat(), lng: location.lng() }
                                                                        //     UpdatedPartner({ ...updatedPartner, address: { ...updatedPartner.address, latLng: latLng }, updated: true })
                                                                        // }}
                                                                        updateLatLng={(latLng) => UpdatedPartner({ ...updatedPartner, address: { ...updatedPartner.address, latLng: latLng }, updated: true })}
                                                                        getAddressFromLatLng={async (latLng) => {
                                                                            try {
                                                                                let response = await ReverseGeocode(latLng)
                                                                                let loc = getAddressDetails(response?.results?.[0], latLng)
                                                                                let addressDetails = {}
                                                                                Object.keys(loc).map(l => {
                                                                                    return addressDetails[l] = { ...updatedPartner.address?.[l], en: loc?.[l] }
                                                                                })
                                                                                UpdatedPartner({
                                                                                    ...updatedPartner,
                                                                                    address: {
                                                                                        ...updatedPartner.address,
                                                                                        ...addressDetails,
                                                                                        latLng: latLng,
                                                                                    },
                                                                                    updated: true
                                                                                })
                                                                            } catch (error) {
                                                                                console.log('error', error);

                                                                            }

                                                                        }}
                                                                        latLng={updatedPartner?.address?.latLng}
                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o&v=3.exp&libraries=geometry,drawing,places&language=en"
                                                                        loadingElement={<div className="map-item" />}
                                                                        containerElement={<div className="map-item" />}
                                                                        mapElement={<div className="map-item" />} />
                                                                }

                                                            </Colxx>}
                                                        {rec.data.map((item, index) => {
                                                            let brand = brands.find(rec => updatedPartner?.parent_id === rec.id) || false
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                                                        <div>{item.title}</div>
                                                                        {item.translateButton && <div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }} onClick={() => showTranslatePopup({ key: item.key, value: rec.key === 'addressInfo' ? updatedPartner?.address?.[item.key] : updatedPartner?.[item.key], type: item.type })}>Translate</div>}
                                                                    </div>
                                                                    {item.key === 'parent_id' ?
                                                                        <Select
                                                                            components={{ Input: CustomSelectInput }}
                                                                            className="basic-multi-select"
                                                                            placeholder={item.title}
                                                                            value={brand ? { ...brand, value: brand?.name?.en || brand?.name, label: brand?.name?.en || brand?.name, key: brand.id } : {}}
                                                                            options={brands.map(rec => {
                                                                                return ({ ...rec, value: rec?.name?.en || rec?.name, label: rec?.name?.en || rec?.name, key: rec.id })
                                                                            })}
                                                                            onChange={val => {
                                                                                UpdatedPartner({ ...updatedPartner, [item.key]: val.key, updated: true })
                                                                            }}
                                                                        />
                                                                        : <Input
                                                                            type={item.type}
                                                                            placeholder={"e.g. " + item.title}
                                                                            value={updatedPartner?.[item.parent]?.[item.key]?.en || updatedPartner?.[item.parent]?.[item.key] || updatedPartner?.[item.key]?.en || updatedPartner?.[item.key]}

                                                                            // value={rec.key === 'addressInfo' ? updatedPartner?.address?.[item.key]?.en : updatedPartner?.[item.key]?.en}
                                                                            className="customInput"
                                                                            onChange={(e) => {
                                                                                if (item.parent) {
                                                                                    if (item?.translateButton) {
                                                                                        UpdatedPartner({ ...updatedPartner, [item.parent]: { ...updatedPartner?.[item.parent], [item.key]: { ...updatedPartner?.[item.parent]?.[item.key], en: e.target.value } }, updated: true })
                                                                                    } else {
                                                                                        UpdatedPartner({ ...updatedPartner, [item.parent]: { ...updatedPartner?.[item.parent], [item.key]: e.target.value, }, updated: true })
                                                                                    }
                                                                                } else {
                                                                                    if (item?.translateButton) {
                                                                                        UpdatedPartner({ ...updatedPartner, [item.key]: { ...updatedPartner?.[item.key], en: e.target.value }, updated: true })
                                                                                    } else {
                                                                                        UpdatedPartner({ ...updatedPartner, [item.key]: e.target.value, updated: true })
                                                                                    }
                                                                                }
                                                                                // if (rec.key === 'addressInfo') {
                                                                                //     UpdatedPartner({ ...updatedPartner, address: { ...updatedPartner.address, [item.key]: e.target.value, }, updated: true })
                                                                                // } else {
                                                                                //     UpdatedPartner({ ...updatedPartner, [item.key]: { ...updatedPartner?.[item.key], en: e.target.value }, updated: true })
                                                                                // }
                                                                            }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Users type={'users'} title={'Users'} parent={'partners'} />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {[{ key: 'apperanceInfo', title: 'General Apperance', data: [{ key: 'picture', title: 'Cover Photo', width: '10', type: '' }, { key: 'primaryColor', title: 'Primary Color', width: '10', type: 'text' }, { key: 'url', title: 'URL', width: '10', type: 'text' }] },
                                            ].map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>
                                                        {rec.data.map((item, index) => {
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="mb-2" >{item.title}</div>
                                                                    {item.key === 'picture' ?
                                                                        <ImageUploader
                                                                            className={'w-100'}
                                                                            withIcon={true}
                                                                            name={item.key}
                                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                                            buttonText='Choose images'
                                                                            onChange={(picture) => onDrop(picture, item.key)}
                                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                            maxFileSize={15728640}
                                                                            withPreview={true}
                                                                            singleImage={true}
                                                                            defaultImages={updatedPartner && updatedPartner[item.key]}
                                                                        /> : <Input
                                                                            type={item.type}
                                                                            disabled={item.key === 'url' && !updatedPartner?.addNew && updatedPartner?.url}
                                                                            placeholder={"e.g. " + item.title}
                                                                            value={updatedPartner?.[item.key]}
                                                                            className="customInput"
                                                                            onChange={(e) => {
                                                                                UpdatedPartner({ ...updatedPartner, [item.key]: e.target.value, updated: true })
                                                                            }} />}
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId={4}>
                                    <Services section="partner" />
                                </TabPane>
                                <TabPane tabId={5}>
                                    <Banners parent={'partners'} title={'Banners'} type={'banners'} />
                                </TabPane>
                                <TabPane tabId={6}>
                                    <Location partner={updatedPartner} />
                                </TabPane>
                                <TabPane tabId={7}>
                                    <Card className="rounded">
                                        <CardBody>
                                            {[{
                                                key: 'settingsInfo',
                                                title: 'Settings',
                                                data: [
                                                    { key: 'cashPaymentWeb', title: 'Web Cash Payment', desc: 'Enable Web Cash Payment', width: '10', type: 'checkbox' },
                                                ]
                                            },
                                            ].map((rec, recIndex) => {
                                                return (
                                                    <Row key={recIndex} className="w-80">
                                                        <Colxx xxs="12" className="mb-2">
                                                            <h4>{rec.title}</h4>
                                                        </Colxx>
                                                        {rec.data.map((item, index) => {
                                                            return (
                                                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                                                    <div className="mb-2" >{item.title}</div>
                                                                    <CustomInput
                                                                        className="custom-checkbox mb-0 mt-1 d-inline-block"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        checked={activeItem?.settings?.[item.key]?.active}
                                                                        onChange={(e) => {
                                                                            let settings = activeItem?.settings ? { ...activeItem?.settings } : {}

                                                                            dispatch(UpdateItem({ key: 'settings', val: { ...settings, [item.key]: { ...settings?.[item.key], id: item.key, active: e.target.checked, updated: true } } }))
                                                                        }}
                                                                        label={<div style={{ color: '#3498db', cursor: 'pointer', fontWeight: '700' }}>{item?.desc}</div>}
                                                                    />
                                                                </Colxx>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                            <Button
                                disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedPartner)), ['name', 'details', 'parent_id'])}
                                color="primary"
                                className="btn-shadow float-right mt-4"
                                size="lg"
                                onClick={() => {
                                    setLoading(true)
                                    submit()
                                }}>
                                Save Changes
                            </Button>
                            <Button
                                color="danger"
                                className="btn-shadow float-right m-4"
                                size="lg"
                                onClick={() => dismiss()}>
                                Dismiss
                            </Button>
                        </Colxx>
                    </Row>
                    {translateObject && <TranslatePopup dismiss={() => showTranslatePopup(false)} translateObject={translateObject} submit={(e) => {
                        if (translateObject.key === 'address' || translateObject.key === 'area') {
                            UpdatedPartner({ ...updatedPartner, address: { ...updatedPartner.address, [translateObject.key]: e }, updated: true })
                        } else {
                            UpdatedPartner({ ...updatedPartner, [translateObject.key]: e, updated: true })
                        }
                        showTranslatePopup(false)
                    }} />
                    }
                </Fragment >
            ))
    );
};

export default Partner;